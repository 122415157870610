import { Grid } from "@mui/material";
import React from "react";
import { About } from "./About/About";
import { Shortcut } from "./Shortcuts/Shortcut";

export const FooterWidgets = ({ children, ...props }) => {
  const { logoPath } = props;
  const handleFunctionClick = (id) => {
    window.location.href = `/ai/${id}`;
  };

  const handleProductClick = (id) => {
    window.location.href = `/ai/${id}`;
  };

  const handleCompanyClick = (id) => {
    window.location.href = `${id}`;
  };

  const handleLinkdinClick = (id) => {
    window.location.href = `/ai`;
  };

  const handleGitHubClick = (id) => {
    window.location.href = `/ai`;
  };

  const handleXClick = (id) => {
    window.location.href = `/ai`;
  };

  const handleYouTubeClick = (id) => {
    window.location.href = `/ai`;
  };

  const productData = [
    {
      text: "AI Agents",
      context: "products/ai-agents",
    },

    {
      text: "AI Teams",
      context: "products/ai-teams",
    },
    {
      text: "AI Tools",
      context: "products/ai-tools",
    },
    {
      text: "Catalyst, the AI Sales Agent",
      context: "agents/catalyst-the-sales-agent",
    },
    {
      text: "Integrations",
      context: "products/integrations",
    },
  ];

  const functionData = [
    {
      text: "Sales",
      context: "functions/sales",
    },
    {
      text: "Marketing",
      context: "functions/marketing",
    },
    {
      text: "Operations",
      context: "functions/operations",
    },
    {
      text: "Research",
      context: "functions/research",
    },
    {
      text: "Customer Support",
      context: "functions/customer-support",
    },
  ];

  // const supportData = [
  //   {
  //     text: "Documentation",
  //     context: "Resources/Documentation",
  //   },
  //   {
  //     text: "API & Python SDK",
  //     context: "Resources/API-Python-SDK",
  //   },
  //   {
  //     text: "Book demo",
  //     context: "Resources/Book-Demo",
  //   },
  //   {
  //     text: "Enterprise",
  //     context: "Resources/Enterprise",
  //   },
  // ];

  // const learnData = [
  //   {
  //     text: "Learn",
  //     context: "Resources/Learn",
  //   },
  //   {
  //     text: "Blog",
  //     context: "Resources/Blog",
  //   },
  //   {
  //     text: "Topics",
  //     context: "Resources/Topics",
  //   },
  //   {
  //     text: "Tools",
  //     context: "Resources/Tools",
  //   },
  //   {
  //     text: "Workflows",
  //     context: "Resources/Workflows",
  //   },
  // ];

  const companyData = [
    {
      text: "Home",
      context: "/",
    },
    {
      text: "Web",
      context: "/web",
    },
    {
      text: "AI",
      context: "/ai",
    },

    {
      text: "About",
      context: "/about",
    },
  ];

  // const legalData = [
  //   {
  //     text: "Privacy policy",
  //     context: "Privacy-Policy",
  //   },
  //   {
  //     text: "Terms",
  //     context: "Terms",
  //   },
  //   {
  //     text: "OpenAI DPA",
  //     context: "OpenAI-DPA",
  //   },
  // ];

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          mb: 5,
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      >
        <About
          handleLinkdinClick={handleLinkdinClick}
          handleGitHubClick={handleGitHubClick}
          handleXClick={handleXClick}
          handleYouTubeClick={handleYouTubeClick}
          logoPath={logoPath}
        />
      </Grid>

      <Grid item xs={12} lg={2}></Grid>

      <Grid
        item
        xs={6}
        sm={4}
        lg={2}
        sx={{
          mb: 5,
        }}
      >
        <Shortcut
          header={"Function"}
          contents={functionData}
          handleClick={handleFunctionClick}
        />
      </Grid>

      <Grid
        item
        xs={6}
        sm={4}
        lg={2}
        sx={{
          mb: 5,
        }}
      >
        <Shortcut
          header={"Product"}
          contents={productData}
          handleClick={handleProductClick}
        />
      </Grid>

      {/* <Grid item xs={12} lg={2}>
        <Shortcut
          header={"Support"}
          contents={supportData}
          handleClick={handleSupportClick}
        />
      </Grid> */}

      {/* <Grid item xs={12} lg={6} sx={{ mt: 5 }}></Grid> */}

      {/* <Grid item xs={12} lg={2} sx={{ mt: 5 }}>
        <Shortcut
          header={"Learn"}
          contents={learnData}
          handleClick={handleLearnClick}
        />
      </Grid> */}

      <Grid
        item
        xs={12}
        sm={4}
        lg={2}
        sx={{
          mb: 5,
        }}
      >
        <Shortcut
          header={"Company"}
          contents={companyData}
          handleClick={handleCompanyClick}
        />
      </Grid>

      {/* <Grid item xs={12} lg={2} sx={{ mt: 5 }}>
        <Shortcut
          header={"Legal"}
          contents={legalData}
          handleClick={handleLegalClick}
        />
      </Grid> */}

      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          mb: 5,
          display: {
            xs: "block",
            lg: "none",
          },
        }}
      >
        <About
          handleLinkdinClick={handleLinkdinClick}
          handleGitHubClick={handleGitHubClick}
          handleXClick={handleXClick}
          handleYouTubeClick={handleYouTubeClick}
          logoPath={logoPath}
        />
      </Grid>
    </Grid>
  );
};
