import StarRateIcon from "@mui/icons-material/StarRate";
import Rating from "@mui/material/Rating";

export const StarRating = ({ children, ...props }) => {
  const defaultStyle = {
    paddingTop: "20px",
    paddingLeft: "20px",
    rating: 5,
    size: "large",
  };

  const { customStyle = {} } = props;

  const mergeStyle = { ...defaultStyle, ...customStyle };

  return (
    <Rating
      name="read-only"
      value={mergeStyle.rating}
      size="large"
      readOnly
      icon={<StarRateIcon fontSize="inherit" />}
      sx={{
        paddingTop: mergeStyle.paddingTop,
        paddingLeft: mergeStyle.paddingLeft,
      }}
    />
  );
};
