import { KeyboardArrowUp } from "@mui/icons-material";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import "../../../AIHome/globalAI.css";

export const AgentsButton = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const handleBoshTheSalesAgentClick = () => {
    window.location.href = `/ai/agents/catalyst-the-sales-agent`;
  };

  const handleInboundClick = () => {
    window.location.href = `/ai/agents/catalyst-inbound-agent`;
  };

  const handleOutboundClick = () => {
    window.location.href = `/ai/agents/catalyst-outbound-agent`;
  };

  let { activeDropdown, handleButtonClick } = props;

  return (
    <Box
      sx={{
        [theme.breakpoints.down("lg")]: { position: "relative" },
      }}
    >
      {isLargeScreen && (
        <Button
          variant="text"
          style={{ color: theme.palette.text.primary }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setIsHovered(!isHovered)}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              fontFamily="Inter, sans-serif" // Add your desired font family here
              fontSize="1rem"
            >
              Agents
            </Typography>
            {!isHovered && (
              <KeyboardArrowDownIcon onClick={() => setIsHovered(!isHovered)} />
            )}

            {isHovered && (
              <KeyboardArrowUp onClick={() => setIsHovered(!isHovered)} />
            )}
          </Box>
        </Button>
      )}

      {!isLargeScreen && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button
            variant="text"
            index={2}
            style={{ color: theme.palette.text.primary }}
            onMouseEnter={() => {
              // setIsHovered(true);
              handleButtonClick(2);
            }}
            onMouseLeave={() => {
              // setIsHovered(false);
              handleButtonClick(null);
            }}
            onClick={() => {
              // setIsHovered(!isHovered);
              handleButtonClick(2);
            }}
          >
            <Typography
              variant="h6"
              fontFamily="Inter, sans-serif" // Add your desired font family here
              fontSize="1rem"
            >
              Agents
            </Typography>
          </Button>
          {activeDropdown === 2 ? (
            <KeyboardArrowUp
              onClick={() => {
                // setIsHovered(!isHovered);
                handleButtonClick(null);
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={() => {
                // setIsHovered(!isHovered);
                handleButtonClick(2);
              }}
            />
          )}{" "}
        </Box>
      )}

      {isLargeScreen && isHovered && (
        <Card
          sx={{
            position: "absolute",
            [theme.breakpoints.down("lg")]: {
              width: "100%",
              position: "relative",
            },
            [theme.breakpoints.up("lg")]: {
              width: "25%",
              position: "absolute",
            },
            zIndex: 3,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} // Hide the card when the mouse leaves it
        >
          <CardContent
            className="customHoverEffect"
            onClick={handleBoshTheSalesAgentClick}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <MoveToInboxIcon />

              <Box sx={{ ml: "10px" }}>
                <Typography variant="body2">
                  Catalyst The Sales Agent
                </Typography>
              </Box>
            </Box>
          </CardContent>

          <CardContent
            className="customHoverEffect"
            onClick={handleInboundClick}
            sx={{
              ml: "3rem",
            }}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <MoveToInboxIcon />
              <Box sx={{ ml: "10px" }}>
                <Typography variant="body2">Catalyst Inbound Agent</Typography>
              </Box>
            </Box>
          </CardContent>

          <CardContent
            className="customHoverEffect"
            onClick={handleOutboundClick}
            sx={{
              ml: "3rem",
            }}
          >
            <Box display="flex" flexDirection="row" alignItems="center">
              <ForwardToInboxIcon />
              <Box sx={{ ml: "10px" }}>
                <Typography variant="body2">Catalyst Outbound Agent</Typography>
              </Box>
            </Box>
          </CardContent>
        </Card>
      )}
    </Box>
  );
};
