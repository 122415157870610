import MenuIcon from "@mui/icons-material/Menu";
import { useMediaQuery } from "@mui/material";
import Button from "@mui/material/Button";
import ButtonBase from "@mui/material/ButtonBase";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { useTheme } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import React from "react";
import "../TourAndTravel/HomePages/tourandtravel.css";
import DropDownMenu from "./DropDownMenu";
import "./NavigationBar.css";

export function NavigationBar() {
  const [drawerOpen, setDrawerOpen] = React.useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const handleSmHomeClick = (event) => {
    window.location.href = "/Tour";
  };

  const handleSmPackageClick = (event) => {
    alert("Package sm is working");
  };

  const handleSmShopClick = (event) => {
    alert("Shop sm is working");
  };

  const handleSmAboutClick = (event) => {
    alert("About sm is working");
  };

  const handleSmNewsClick = (event) => {
    alert("News sm is working");
  };

  const handleSmContactClick = (event) => {
    alert("Contact sm is working");
  };

  const handleLgBookNowClick = (event) => {
    alert("Book Now is working");
  };

  const handleSmBookNowClick = (event) => {
    alert("Book now sm is working");
  };

  const handleSmPagesClick = (event) => {
    alert("Pages sm is working");
  };

  return (
    <Toolbar style={{}}>
      {isSmallScreen ? (
        <IconButton
          edge="start"
          color="inherit"
          aria-label="menu"
          onClick={handleDrawerToggle}
          className="menuBox"
        >
          <MenuIcon />
        </IconButton>
      ) : (
        <Box className="menuBox">
          <DropDownMenu></DropDownMenu>

          <Button
            onClick={handleLgBookNowClick}
            className="bookNowButton zoomHoverEffect"
            style={{
              backgroundColor: "red",
              color: theme.palette.text.primary,
              fontWeight: "bold",
              borderRadius: "5px",
              padding: "5px",
              marginRight: "5px",
            }}
          >
            Book Now
          </Button>
        </Box>
      )}
      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={handleDrawerToggle}
        sx={{
          ".MuiDrawer-paper": {
            background: "transparent",
          },
        }}
      >
        <ButtonBase onClick={handleSmHomeClick} sx={{ pt: 5 }}>
          <ListItem>
            <ListItemText primary="Home" />
          </ListItem>
        </ButtonBase>
        <ButtonBase onClick={handleSmPackageClick}>
          <ListItem>
            <ListItemText primary="Packages" />
          </ListItem>
        </ButtonBase>
        <ButtonBase onClick={handleSmShopClick}>
          <ListItem>
            <ListItemText primary="Shop" />
          </ListItem>
        </ButtonBase>
        <ButtonBase onClick={handleSmAboutClick}>
          <ListItem>
            <ListItemText primary="About" />
          </ListItem>
        </ButtonBase>

        <ButtonBase onClick={handleSmPagesClick}>
          <ListItem>
            <ListItemText primary="Pages" />
          </ListItem>
        </ButtonBase>

        <ButtonBase onClick={handleSmNewsClick}>
          <ListItem>
            <ListItemText primary="News" />
          </ListItem>
        </ButtonBase>
        <ButtonBase onClick={handleSmContactClick}>
          <ListItem>
            <ListItemText primary="Contact" />
          </ListItem>
        </ButtonBase>
        <ButtonBase onClick={handleSmBookNowClick}>
          <ListItem>
            <ListItemText primary="Book Now" />
          </ListItem>
        </ButtonBase>
      </Drawer>
      <Typography variant="h6">Tour and Travel</Typography>
    </Toolbar>
  );
}
