import { Box, Container } from "@mui/material";
import React from "react";

export const CommonContainer = ({ children, ...props }) => {
  const { customStyle } = props;

  const defaultStyle = {
    position: "relative",
    pt: 6,
    pl: 6,
    pr: 6,
    pb: 6,
    transform: "translate(0%)",
  };

  const mergedStyle = { ...defaultStyle, ...customStyle };

  return (
    <Box
      sx={{
        position: mergedStyle.position,
        pt: mergedStyle.pt,
        pl: mergedStyle.pl,
        pr: mergedStyle.pr,
        pb: mergedStyle.pb,
        transform: mergedStyle.transform,
      }}
    >
      <Container maxWidth="lg">{children}</Container>
    </Box>
  );
};
