import { Grid } from "@mui/material";
import { TabsContainer } from "./TabsContainer";
import { SalesTabDetails } from "./TabWidgets/SalesTabDetails";

export const SalesTabPanel = (props) => {
  const { value } = props;

  const handleUse = (id) => {
    window.location.href = `/ai`;
  };

  return (
    <TabsContainer value={value} index={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <SalesTabDetails
            imageSrc="/airippler/ai-tools/sales/Industry.png"
            imageAlt="Description of the image"
            title="Industry Insights Tool"
            description={`The Industry Insights Tool utilizes AI to enrich your data with the latest industry trends from top sources like Google Search. It helps businesses personalize cold outreach by providing highly relevant insights, automating the process of gathering and summarizing industry trends, and highlighting potential challenges and opportunities where your product or service can add value. Perfect for sales teams aiming to boost productivity and enhance the effectiveness of their outreach strategies.`}
            handleButtonClick={handleUse}
            buttonText={"Use"}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <SalesTabDetails
            imageSrc="/airippler/ai-tools/sales/human-capacity-increase.png"
            imageAlt="Description of the image"
            title="Lead Enrichment Tool"
            description={`The Lead Enrichment Tool streamlines gathering detailed lead information using only email addresses. It automates the extraction of key data such as job titles, company names, industries, and more—empowering sales and marketing teams to target leads more effectively. Powered by AI, it ensures accurate and efficient enrichment, saving you valuable time and effort.`}
            handleButtonClick={handleUse}
            buttonText={"Use"}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <SalesTabDetails
            imageSrc="/airippler/ai-tools/sales/human-capacity-increase.png"
            imageAlt="Description of the image"
            title="Research Companies & Find Leads"
            description={`The Research Companies & Find Leads tool simplifies the process of gathering company information and identifying key contacts. Perfect for sales and marketing teams, it automates the search for actionable insights, saving time and ensuring you have the most relevant leads at your fingertips.`}
            handleButtonClick={handleUse}
            buttonText={"Use"}
          />
        </Grid>
      </Grid>
    </TabsContainer>
  );
};
