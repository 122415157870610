import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import InsightsIcon from "@mui/icons-material/Insights";
import RateReviewIcon from "@mui/icons-material/RateReview";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RouteToAIPortal } from "../../../../../CodingRippler/CodingRipplerWidgets";

export const OperationsTabDetails = (props) => {
  return (
    <Grid
      container
      spacing={5}
      sx={{
        pl: {
          lg: 10,
        },
      }}
    >
      <Grid item xs={12}>
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            className="typography-custom-css"
          >
            Revolutionize your workflows with AI-driven automation
          </Typography>

          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginTop={1}
          >
            Streamline operations, reduce costs, and increase productivity with
            intelligent automation.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <InsightsIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            {" "}
            Turn data into insights for smarter decisions
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <TrendingUpIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            {" "}
            Identify trends early to stay ahead
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <VisibilityIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            {" "}
            Monitor competitors to maintain an edge
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <RateReviewIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            {" "}
            Analyze customer feedback for continuous improvement
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={4}
        >
          <Button
            variant="text"
            size="large"
            endIcon={<ArrowRightAltIcon />}
            style={{
              fontSize: "1rem",
              padding: "1rem 2rem",
              borderRadius: "14px",
              color: "#6056ff",
            }}
            onClick={RouteToAIPortal}
          >
            Learn more
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
