import { Box, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import Rating from "@mui/material/Rating";
import { useTheme } from "@mui/material/styles";
import TextField from "@mui/material/TextField";
import React, { useEffect, useState } from "react";

import { TypographyOxygen } from "../../BoilarPlateJs/TypographyStyled";

export const TakeReviews = ({ children, ...props }) => {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [checked, setChecked] = useState(false);

  const handleClick = (event) => {
    const value = event.target.textContent.replace(" Star", "");
    setValue(parseInt(value, 10));
  };

  const handleCickOnCheckBox = (event) => {
    setChecked(!checked);
  };

  const handleSubmit = () => {
    alert("Review submitted");
  };

  useEffect(() => {
    const savedName = localStorage.getItem("name");
    const savedEmail = localStorage.getItem("email");
    const savedConsent = localStorage.getItem("consent");

    if (savedName) setName(savedName);
    if (savedEmail) setEmail(savedEmail);
    if (savedConsent) setChecked(savedConsent === "true");
  }, []);

  // Save data to localStorage whenever it changes
  useEffect(() => {
    if (checked) {
      localStorage.setItem("name", name);
      localStorage.setItem("email", email);
      localStorage.setItem("consent", checked);
    } else {
      localStorage.removeItem("name");
      localStorage.removeItem("email");
      localStorage.removeItem("consent");
    }
  }, [name, email, checked]);

  return (
    <Grid container>
      <Grid item xs={12} pt={5}>
        <TypographyOxygen fontSize={"1.5rem"}>Leave a Review</TypographyOxygen>
      </Grid>
      <Grid item xs={12} pt={5}>
        <Box display="flex" flexDirection={"row"}>
          <TypographyOxygen fontSize={"1.2rem"}>Rating:</TypographyOxygen>
          <Box p={theme.spacing(1)}>
            {" "}
            <Rating
              name="simple-controlled"
              value={value}
              size="large"
              onClick={handleClick}
            />
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} pt={5}>
        <TextField
          id="outlined-multiline-static"
          label="Review"
          multiline
          rows={4}
          defaultValue=""
          variant="outlined"
          style={{ width: "85%" }}
        />
      </Grid>

      <Grid item xs={12} pt={5}>
        <TextField
          id="outlined-basic"
          label="Name"
          variant="outlined"
          style={{ width: "70%" }}
          value={name}
          onChange={(e) => setName(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} pt={5}>
        <TextField
          id="outlined-basic"
          label="Email"
          variant="outlined"
          style={{ width: "70%" }}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
      </Grid>

      <Grid item xs={12} pt={5}>
        <FormControlLabel
          control={
            <Checkbox
              checked={checked}
              onClick={handleCickOnCheckBox}
              name="userConsent"
              id="userConsentId"
              inputProps={{ "aria-label": "controlled" }}
            />
          }
          label="Save my name,and email in this browser for the next time I comment."
        />
      </Grid>

      <Grid item xs={12} pt={5}>
        <Button variant="contained" onClick={handleSubmit}>
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};
