import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import {
  CommonHeaderTypography,
  CommonSubHeaderTypography,
} from "../../../../AIUtil/AIUtils";
import { PageHeader, PageSubHeader } from "../../../../AIWidgets";

export const InboundTabDetails = ({ children, ...props }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <PageHeader
          headerText={
            <CommonHeaderTypography>
              Work every lead and scale your sales activity.
            </CommonHeaderTypography>
          }
        />

        <Box m={2} />
        <PageSubHeader
          headerText={
            <CommonSubHeaderTypography>
              Nurture Leads and Book Meetings 24/7 with Catalyst. Seamlessly
              Integrates with Your Tech Stack.
            </CommonSubHeaderTypography>
          }
        />
      </Grid>
    </Grid>
  );
};
