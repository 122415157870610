import { Box, CardMedia, Grid } from "@mui/material";
import {
  CommonContainer,
  HeroSubHeader,
  HeroText,
  LeadContainer,
} from "../../CodingRipplerWidgets";

export const WhyUs = ({ children, ...props }) => {
  const { key, data, leadData, isPhone, isPhoneorTab } = props;

  let leadContainerStyle = {
    textAlign: "start",
    typography: {
      xs: "h6",
      md: "h6",
      lg: "h6",
    },
  };

  return (
    <Grid
      container
      spacing={2}
      height={{
        xs: "auto",
        lg: "100vh",
      }}
      key={key}
      ref={data.reference}
      className="white-bg"
    >
      <Grid item xs={12}></Grid>
      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      ></Grid>
      <Grid item xs={12} lg={6}>
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          {/* <Description /> */}
          <CommonContainer>
            <HeroText variant={"h4"} customStyle={{ textAlign: "left" }}>
              Driven by Commitment, Powered by Expertise in Web Development
            </HeroText>
            <Box m={2} />
            <HeroSubHeader customStyle={{ textAlign: "left" }}>
              Empowering you to create exceptional products, from subscription
              services to e-commerce stores.{" "}
            </HeroSubHeader>
          </CommonContainer>

          <Box m={2} />
          <CommonContainer>
            <LeadContainer
              data={leadData}
              customStyle={leadContainerStyle}
              isPhone={isPhone}
              isPhoneorTab={isPhoneorTab}
            />
          </CommonContainer>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          display: {
            xs: "none", // Display on extra-small screens
            lg: "block", // Hide on large screens
          },
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <CardMedia
            component="img"
            image="/codingrippler/webrippler/whyus/expertise.png"
            alt="Description of the image"
            sx={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={1} lg={1}></Grid>
    </Grid>
  );
};
