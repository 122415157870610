import SettingsIcon from "@mui/icons-material/Settings";
import { Box, CardMedia, Grid } from "@mui/material";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { RequestDemo, TryForFree } from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  AICommonContainer,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
} from "../../AIUtil/AIUtils";
import {
  CardWithMediaTitleAndDescription,
  InLineMediaContainer,
  PageHeader,
  PageSubHeader,
  TopicContainer,
} from "../../AIWidgets";

export const AIOperationsHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  const useCaseData = [
    {
      title: "Streamline Ticket Categorization",
      description:
        "Automatically classify and route requests to the right team—ensuring faster resolution from the start.",
      image: "/airippler/features/no-coding-required.png",
      minHeight: "500px",
    },
    {
      title: "Instant Stakeholder Communication",
      description:
        "Effortlessly keep stakeholders in the loop with real-time, autonomous updates, ensuring seamless collaboration.",
      image: "/airippler/features/templatesToGetStarted.png",
      minHeight: "500px",
    },
    {
      title: "Efficient Ticket Resolution & Logging",
      description:
        "Accelerate issue resolution by automating next steps and ensuring every request is logged with precision.",
      image: "/airippler/features/teachYourProcess.png",
      minHeight: "500px",
    },
  ];

  const keyFeatureData = [
    {
      title: "Ensure Reliable Performance",
      description:
        "Take control with built-in instructions, workflow builder, and temperature adjustments, so your LLMs always deliver the results you expect.",
      image: "/airippler/features/IntegrateWithTechTrack.png",
    },
    {
      title: "Your Data, Your Privacy",
      description:
        "Rest easy knowing your data stays private—never used for model training, ensuring full confidentiality and security.",
      image: "/airippler/features/teachYourProcess.png",
    },
    {
      title: "Seamless Integration with Your Tools",
      description:
        "Connect effortlessly with your existing tools like Hubspot, Salesforce, Zapier, and Gmail, making it easier than ever to scale and automate.",
      image: "/airippler/features/agent-skill.png",
    },
  ];

  const ListOfOurCustomers = [
    "/airippler/marquee/Webflow.svg",
    "/airippler/marquee/Async-Api.svg",
    "/airippler/marquee/Zapier.svg",
    "/airippler/marquee/Zoho.svg",
    "/airippler/marquee/Google-Gemini.svg",
    "/airippler/marquee/Hubspot.svg",
  ];

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="for Operations">
          <SettingsIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                <span className="theme-specfic-colored-text">
                  Maximize Results with AI-Powered Operations
                </span>{" "}
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Streamline Processes, Reduce Workload, and Drive Efficiency with
                AI
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ResponsiveBoxCenteredChildren>
          <TryForFree />
          <Box m={2} />
          <RequestDemo />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      <Grid item xs={12}>
        <AICommonContainer>
          <CardMedia
            component="img"
            image="/airippler/home-page/homePage.png"
            alt="Description of the image"
            width="100%"
            height="100%"
          />{" "}
        </AICommonContainer>
      </Grid>

      {/* Advertise page   */}

      <Grid item xs={12}>
        <AICommonContainer>
          <CommonSubHeaderTypography customStyle={{ opacity: 0.8 }}>
            Companies of all sizes trust AI Rippler
          </CommonSubHeaderTypography>

          <Box m={2} />
          <InLineMediaContainer data={ListOfOurCustomers} />
        </AICommonContainer>
      </Grid>

      {/* Example Use Case Page  */}

      <Grid item xs={12}>
        <TopicContainer topicText="Example use cases">
          <SettingsIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                From Optimization to Seamless Execution in Record Time
              </CommonHeaderTypography>
            }
          />
        </CommonContainer>

        <Box m={2} />

        <AICommonBoxContainer>
          <CardWithMediaTitleAndDescription data={useCaseData} />
        </AICommonBoxContainer>
      </Grid>

      <Grid item xs={12}>
        <TopicContainer topicText="Key Features">
          <SettingsIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Transform Your Operations with a Custom Assistant in Minutes
              </CommonHeaderTypography>
            }
          />
        </CommonContainer>

        <Box m={2} />
        <AICommonBoxContainer>
          <CardWithMediaTitleAndDescription data={keyFeatureData} />
        </AICommonBoxContainer>
      </Grid>
    </Grid>
  );
};
