import { Box } from "@mui/system";
import React from "react";
import { StarRating } from "../../BoilarPlateJs/RatingUtils";
import { TypographyOxygen } from "../../BoilarPlateJs/TypographyStyled";

export const TourNameAndRatingPage = ({ children, ...props }) => {
  const { individualTourInfo } = props;

  return (
    <Box
      display={"flex"}
      justifyContent="center"
      alignItems="center"
      flexDirection={"column"}
      style={{
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <TypographyOxygen align="center">
        {" "}
        {individualTourInfo.tripHeading}{" "}
      </TypographyOxygen>

      <Box
        display={"flex"}
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent={"center"}
        alignContent={"center"}
      >
        <Box>
          <StarRating customStyle={{ rating: individualTourInfo.tripRating }} />
        </Box>

        <Box pt={"10px"}>
          <TypographyOxygen fontSize={"1.5rem"} fontWeight={"bold"}>
            {" "}
            ({individualTourInfo.totalReviewsSubmitted} review)
          </TypographyOxygen>
        </Box>
      </Box>
    </Box>
  );
};
