import { useTheme } from "@emotion/react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoIcon from "@mui/icons-material/Info";
import StarIcon from "@mui/icons-material/Star";
import StarRateIcon from "@mui/icons-material/StarRate";
import { Box, CardMedia, Grid, Typography, useMediaQuery } from "@mui/material";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Rating from "@mui/material/Rating";
import Tooltip from "@mui/material/Tooltip";
import { useNavigate } from "react-router-dom";
import "./CardViewContainer.css";
import { truncate } from "./JsUtils";
import { TypographyOxygen } from "./TypographyStyled";

export const CardViewContainer = ({ children, ...props }) => (
  <Card
    sx={{
      borderRadius: "2.5vw",
      overflow: "auto",
      margin: (theme) => theme.spacing(2),
      ...props,
      maxWidth: "100vw",
    }}
    spacing={2}
    className="cardViewContainer cardHoverEffect"
  >
    <CardContent>{children}</CardContent>
  </Card>
);

export const CardViewContainerWithImage = ({ children, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const { tourData } = props;

  const isLowPriorityTrip = tourData.isLowPriorityTrip || false;
  const anyOffer = tourData.anyOffer || false;
  const typeOfOffer = tourData.typeOfOffer || "Special Offer";
  const tripRating = tourData.tripRating || 5;
  const showDiscount = tourData.showDiscount || false;
  const actualTripAmount = tourData.actualTripAmount || "$500";
  const discountedTripAmount = tourData.discountedTripAmount || "$300";
  const tripHeading = tourData.tripHeading || "Goa, India";
  const tripDescription =
    tourData.tripDescription ||
    "This is some text about the tour. This is some text about the tour. This is some text about the tour.";
  const tripDurationInDays = tourData.tripDurationInDays || "5 Days";
  const tripCustomerRating = tourData.tripCustomerRating || "10/10";

  const truncatedDescriptionText = truncate({
    str: tripDescription,
    maxLength: 80,
  });

  const truncatedHeadingText = truncate({ str: tripHeading, maxLength: 21 });

  let offerBadgeColor = "#eb4293";
  if (
    typeOfOffer === "Early Bird Offer" ||
    typeOfOffer === "Limited Time Offer"
  ) {
    offerBadgeColor = "#00d084";
  }

  const handleCardClick = (id) => {
    window.location.href = `/Tour/Tours?id=${id}`;
  };

  return (
    <Card
      sx={{
        overflow: "hidden",
        borderRadius: "15px",
        margin: (theme) => theme.spacing(2),
        ...props,
        maxWidth: "100vw",
        maxHeight: "100vh",
        position: "relative",
      }}
      spacing={2}
      className="cardHoverEffect"
      onClick={() => {
        handleCardClick(tourData.pkId);
      }}
    >
      <Box
        display={isLowPriorityTrip ? "none" : "flex"}
        justifyContent={"space-between"}
        position={"absolute"}
        width={"100%"}
      >
        <Rating
          name="read-only"
          value={tripRating}
          size="large"
          readOnly
          icon={<StarRateIcon fontSize="inherit" />}
          sx={{ paddingTop: "20px", paddingLeft: "20px" }}
        />
        {isSmallScreen ? (
          <Box display={anyOffer ? "flex" : "none"}>
            <Badge
              badgeContent={typeOfOffer}
              sx={{
                ".MuiBadge-badge": {
                  minWidth: "170px",
                  minHeight: "40px",
                  transform: "rotate(45deg)",
                  transformOrigin: "center",
                  backgroundColor: offerBadgeColor,
                  top: "20px",
                  right: "-40px",
                },
              }}
            ></Badge>
          </Box>
        ) : (
          <Box display={anyOffer ? "flex" : "none"}>
            <Badge
              badgeContent={typeOfOffer}
              sx={{
                ".MuiBadge-badge": {
                  minWidth: "200px",
                  minHeight: "40px",
                  transform: "rotate(45deg)",
                  transformOrigin: "center",
                  backgroundColor: offerBadgeColor,
                  top: "30px",
                  right: "-50px",
                  fontFamily: "Oxygen, sans-serif",
                  fontWeight: "bold",
                },
              }}
            ></Badge>
          </Box>
        )}
      </Box>
      <CardMedia
        component="img"
        height={isSmallScreen ? "200" : "250"}
        image="/tourandtravel/PopularTourBackground.jpg"
        alt="Tour Image"
      ></CardMedia>

      <Box display={"flex"} justifyContent={"flex-end"}>
        <Badge
          badgeContent={
            <Box display={"flex"}>
              <Box display={showDiscount ? "flex" : "none"}>
                <TypographyOxygen
                  fontSize="1.2rem"
                  textDecoration="line-through"
                >
                  {actualTripAmount}
                </TypographyOxygen>
              </Box>
              <TypographyOxygen fontSize="1.2rem">
                {discountedTripAmount}
              </TypographyOxygen>
            </Box>
          }
          sx={{
            ".MuiBadge-badge": {
              minWidth: "150px",
              minHeight: "60px",
              backgroundColor: theme.palette.primary.main,
              fontFamily: "oxygen, sans-serif",
              fontWeight: "bold",
              transform: "translate(0%, -100%)", // Adjust the button's position
              right: "0",
            },
          }}
        ></Badge>
      </Box>

      <CardContent
        style={{
          borderTop: "2px solid",
          borderColor: theme.palette.primary.main,
        }}
      >
        {truncatedHeadingText ? (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Tooltip title={tripHeading}>
              <TypographyOxygen
                variant="h6"
                color="text.secondary"
                fontSize={isSmallScreen ? "1.2" : "1.8rem"}
              >
                {truncatedHeadingText}
              </TypographyOxygen>
            </Tooltip>
          </Box>
        ) : (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <TypographyOxygen
              variant="h6"
              color="text.secondary"
              fontSize={isSmallScreen ? "1.2" : "1.8rem"}
            >
              {tripHeading}
            </TypographyOxygen>
          </Box>
        )}

        {truncatedDescriptionText ? (
          <Tooltip title={tripDescription}>
            <TypographyOxygen
              fontSize={isSmallScreen ? "0.8rem" : "1.2rem"}
              fontWeight={"normal"}
            >
              {truncatedDescriptionText}
            </TypographyOxygen>
          </Tooltip>
        ) : (
          <TypographyOxygen
            fontSize={isSmallScreen ? "0.8rem" : "1.2rem"}
            fontWeight={"normal"}
          >
            {tripDescription}
          </TypographyOxygen>
        )}
      </CardContent>

      <CardContent
        style={{
          borderTop: "2px solid #505050",
        }}
      >
        <Grid container>
          <Grid item xs={5} style={{ borderRight: "2px solid  #505050" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <AccessTimeIcon style={{ marginRight: "10px" }} />
              <Typography component="span" spacing={2}>
                {tripDurationInDays}
              </Typography>{" "}
            </Box>
          </Grid>
          <Grid item xs={4} style={{ borderRight: "2px solid  #505050" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <StarIcon style={{ marginRight: "10px" }} />
              <Typography component="span" spacing={2}>
                {tripCustomerRating}
              </Typography>{" "}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <InfoIcon />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const CardViewContainerWithImageV2 = ({
  children,
  data,
  parentCardCustomStyles = {
    borderRadius: "15px",
    margin: (theme) => theme.spacing(2),
    maxWidth: "100vw",
    maxHeight: "100vh",
    position: "relative",
    height: "auto",
    width: "auto",
  },
  anyRelativeElement = null,
  cardMediaCustomStyles = { height: "250" },
  cardContents = null,
  cardContentsCustomStyles = {
    height: "auto",
    borderColor: "auto",
    borderTop: "auto",
    borderColorSecondary: "#505050",
  },
  cardAction = null,
  ...props
}) => {
  const handleCardClick = (id) => {
    window.location.href = `/Tour/Tours?id=${id}`;
  };
  return (
    <Card
      sx={{
        overflow: "hidden",
        borderRadius: "15px",
        margin: (theme) => theme.spacing(2),
        ...props,
        maxWidth: parentCardCustomStyles.maxWidth,
        maxHeight: parentCardCustomStyles.maxHeight,
        position: parentCardCustomStyles.position,
        height: parentCardCustomStyles.height,
        width: parentCardCustomStyles.width,
      }}
      spacing={2}
      className="cardHoverEffect"
      onClick={() => {
        handleCardClick(data.pkId);
      }}
    >
      {anyRelativeElement ? (
        <CardContent sx={{ position: "absolute" }}>
          {anyRelativeElement}
        </CardContent>
      ) : (
        <></>
      )}

      <CardMedia
        component="img"
        height={cardMediaCustomStyles.height}
        image={data.imagePath}
        alt="Tour Image"
      ></CardMedia>
      <CardContent
        style={{
          borderTop: cardContentsCustomStyles.borderTop,
          borderColor: cardContentsCustomStyles.borderColor,
        }}
      >
        {cardContents}
      </CardContent>

      <CardContent
        sx={{
          borderTop: "2px solid #505050",
        }}
      >
        {cardAction}
      </CardContent>
    </Card>
  );
};

export const ImageCardViewContainer = ({ children, ...props }) => {
  const defaultStyle = {
    displayType: "flex",
    justifyContent: "center",
    alignItems: "flex-end",
    imageHeight: "15vh",
    imageWidth: "15vw",
    borderRadius: "15px",
  };

  const { data, customStyle, contentChildren } = props;

  const mergedStyle = { ...defaultStyle, ...customStyle };

  const handleCardClick = (name) => {
    window.location.href = `/Tour/Destination?destinationName=${name}`;
  };

  return (
    <Box
      component={Card}
      sx={{
        backgroundImage: `url(${data.path})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        height: `${mergedStyle.imageHeight}`,
        width: `${mergedStyle.imageWidth}`,
        display: "flex",
        justifyContent: "center",
        alignItems: `${mergedStyle.alignItems}`,
        borderRadius: mergedStyle.borderRadius,
      }}
      className="cardHoverEffect"
      onClick={() => {
        handleCardClick(data.destination);
      }}
    >
      <CardContent>{contentChildren}</CardContent>
    </Box>
  );
};

export const CardViewContainerV2 = ({ children, ...props }) => {
  const defaultStyle = {
    cardWidth: "30vw",
    cardHeight: "30vh",
    borderRadius: "15px",
    className: "cardViewContainer cardHoverEffect",
  };

  const { customBoxStyle } = props;

  const mergedStyle = { ...defaultStyle, ...customBoxStyle };

  return (
    <Box
      spacing={2}
      className={mergedStyle.className}
      bgcolor={"#121212"}
      sx={{
        width: mergedStyle.cardWidth,
        height: mergedStyle.cardHeight,
        transform: "translate(0%, -40%)",
      }}
    >
      <CardContent>{children}</CardContent>
    </Box>
  );
};

export const CardViewContainerWithImageV3 = ({
  children,
  isLowPriorityTrip = false,
  anyOffer = false,
  typeOfOffer = "Special Offer",
  tripRating = 5,
  tripImage = "tourandtravel/PopularTourBackground.jpg",
  showDiscount = false,
  actualTripAmount = "$500",
  discountedTripAmount = "$300",
  tripHeading = "Goa, India",
  tripDescription = "This is some text about the tour. This is some text about the tour. This is some text about the tour.",
  tripDurationInDays = "5 Days",
  tripCustomerRating = "10/10",
  ...props
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const truncatedDescriptionText = truncate({
    str: tripDescription,
    maxLength: 80,
  });

  const truncatedHeadingText = truncate({ str: tripHeading, maxLength: 21 });

  let offerBadgeColor = "#eb4293";
  if (
    typeOfOffer === "Early Bird Offer" ||
    typeOfOffer === "Limited Time Offer"
  ) {
    offerBadgeColor = "#00d084";
  }

  // Inside your component
  const navigate = useNavigate();

  return (
    <Card
      sx={{
        overflow: "hidden",
        borderRadius: "15px",
        margin: (theme) => theme.spacing(2),
        ...props,
        maxWidth: "100vw",
        maxHeight: "100vh",
        position: "relative",
      }}
      spacing={2}
      className="cardHoverEffect"
    >
      <Box
        display={isLowPriorityTrip ? "none" : "flex"}
        justifyContent={"space-between"}
        position={"absolute"}
        width={"100%"}
      >
        <Rating
          name="read-only"
          value={tripRating}
          size="large"
          readOnly
          icon={<StarRateIcon fontSize="inherit" />}
          sx={{ paddingTop: "20px", paddingLeft: "20px" }}
        />
        {isSmallScreen ? (
          <Box display={anyOffer ? "flex" : "none"}>
            <Badge
              badgeContent={typeOfOffer}
              sx={{
                ".MuiBadge-badge": {
                  minWidth: "170px",
                  minHeight: "40px",
                  transform: "rotate(45deg)",
                  transformOrigin: "center",
                  backgroundColor: offerBadgeColor,
                  top: "20px",
                  right: "-40px",
                },
              }}
            ></Badge>
          </Box>
        ) : (
          <Box display={anyOffer ? "flex" : "none"}>
            <Badge
              badgeContent={typeOfOffer}
              sx={{
                ".MuiBadge-badge": {
                  minWidth: "200px",
                  minHeight: "40px",
                  transform: "rotate(45deg)",
                  transformOrigin: "center",
                  backgroundColor: offerBadgeColor,
                  top: "30px",
                  right: "-50px",
                  fontFamily: "Oxygen, sans-serif",
                  fontWeight: "bold",
                },
              }}
            ></Badge>
          </Box>
        )}
      </Box>
      <CardMedia
        component="img"
        height={isSmallScreen ? "200" : "250"}
        image="/tourandtravel/PopularTourBackground.jpg"
        alt="Tour Image"
        onClick={() => {
          navigate("/Tour/Tours/");
        }}
      ></CardMedia>

      <Box display={"flex"} justifyContent={"flex-end"}>
        <Badge
          badgeContent={
            <Box display={"flex"}>
              <Box display={showDiscount ? "flex" : "none"}>
                <TypographyOxygen
                  fontSize="1.2rem"
                  textDecoration="line-through"
                >
                  {actualTripAmount}
                </TypographyOxygen>
              </Box>
              <TypographyOxygen fontSize="1.2rem">
                {discountedTripAmount}
              </TypographyOxygen>
            </Box>
          }
          sx={{
            ".MuiBadge-badge": {
              minWidth: "150px",
              minHeight: "60px",
              backgroundColor: theme.palette.primary.main,
              fontFamily: "oxygen, sans-serif",
              fontWeight: "bold",
              transform: "translate(0%, -100%)", // Adjust the button's position
              right: "0",
            },
          }}
        ></Badge>
      </Box>

      <CardContent
        style={{
          borderTop: "2px solid",
          borderColor: theme.palette.primary.main,
        }}
      >
        {truncatedHeadingText ? (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <Tooltip title={tripHeading}>
              <TypographyOxygen
                variant="h6"
                color="text.secondary"
                fontSize={isSmallScreen ? "1.2" : "1.2rem"}
              >
                {truncatedHeadingText}
              </TypographyOxygen>
            </Tooltip>
          </Box>
        ) : (
          <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <TypographyOxygen
              variant="h6"
              color="text.secondary"
              fontSize={isSmallScreen ? "1.2" : "1.2rem"}
            >
              {tripHeading}
            </TypographyOxygen>
          </Box>
        )}

        {truncatedDescriptionText ? (
          <Tooltip title={tripDescription}>
            <TypographyOxygen
              fontSize={isSmallScreen ? "0.8rem" : "1.0rem"}
              fontWeight={"normal"}
            >
              {truncatedDescriptionText}
            </TypographyOxygen>
          </Tooltip>
        ) : (
          <TypographyOxygen
            fontSize={isSmallScreen ? "0.8rem" : "1.0rem"}
            fontWeight={"normal"}
          >
            {tripDescription}
          </TypographyOxygen>
        )}
      </CardContent>

      <CardContent
        style={{
          borderTop: "2px solid #505050",
        }}
      >
        <Grid container>
          <Grid item xs={5} style={{ borderRight: "2px solid  #505050" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <AccessTimeIcon style={{ marginRight: "10px" }} />
              <Typography component="span" spacing={2}>
                {tripDurationInDays}
              </Typography>{" "}
            </Box>
          </Grid>
          <Grid item xs={4} style={{ borderRight: "2px solid  #505050" }}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <StarIcon style={{ marginRight: "10px" }} />
              <Typography component="span" spacing={2}>
                {tripCustomerRating}
              </Typography>{" "}
            </Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <InfoIcon />
            </Box>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export const CardViewContainerWithImageV4 = ({ children, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { tourData } = props;

  const isLowPriorityTrip = tourData.isLowPriorityTrip || false;
  const anyOffer = tourData.anyOffer || false;
  const typeOfOffer = tourData.typeOfOffer || "Special Offer";
  const tripRating = tourData.tripRating || 5;

  const showDiscount = tourData.showDiscount || false;
  const actualTripAmount = tourData.actualTripAmount || "$500";
  const discountedTripAmount = tourData.discountedTripAmount || "$300";
  const tripHeading = tourData.tripHeading || "Goa, India";

  let offerBadgeColor = "#eb4293";
  if (
    typeOfOffer === "Early Bird Offer" ||
    typeOfOffer === "Limited Time Offer"
  ) {
    offerBadgeColor = "#00d084";
  }

  const handleCardClick = (id) => {
    window.location.href = `/Tour/Tours?id=${id}`;
  };

  return (
    <Card
      sx={{
        overflow: "hidden",
        borderRadius: "15px",
        margin: (theme) => theme.spacing(2),
        ...props,
        maxWidth: "100vw",
        maxHeight: "100vh",
        position: "relative",
      }}
      spacing={2}
      className="cardHoverEffect"
      onClick={() => {
        handleCardClick(tourData.pkId);
      }}
    >
      <Box position="relative">
        <Box
          display={isLowPriorityTrip ? "none" : "flex"}
          justifyContent={"space-between"}
          position={"absolute"}
          width={"100%"}
        >
          <Rating
            name="read-only"
            value={tripRating}
            size="large"
            readOnly
            icon={<StarRateIcon fontSize="inherit" />}
            sx={{ paddingTop: "20px", paddingLeft: "20px" }}
          />
          {isSmallScreen ? (
            <Box display={anyOffer ? "flex" : "none"}>
              <Badge
                badgeContent={typeOfOffer}
                sx={{
                  ".MuiBadge-badge": {
                    minWidth: "170px",
                    minHeight: "40px",
                    transform: "rotate(45deg)",
                    transformOrigin: "center",
                    backgroundColor: offerBadgeColor,
                    top: "20px",
                    right: "-40px",
                  },
                }}
              ></Badge>
            </Box>
          ) : (
            <Box display={anyOffer ? "flex" : "none"}>
              <Badge
                badgeContent={typeOfOffer}
                sx={{
                  ".MuiBadge-badge": {
                    minWidth: "200px",
                    minHeight: "40px",
                    transform: "rotate(45deg)",
                    transformOrigin: "center",
                    backgroundColor: offerBadgeColor,
                    top: "30px",
                    right: "-50px",
                    fontFamily: "Oxygen, sans-serif",
                    fontWeight: "bold",
                  },
                }}
              ></Badge>
            </Box>
          )}
        </Box>
        <CardMedia
          component="img"
          height={isSmallScreen ? "200" : "250"}
          image="/tourandtravel/PopularTourBackground.jpg"
          alt="Tour Image"
        />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)" }} // Center the box
          sx={{
            //      color: "white", // Assuming white text color for visibility
            textAlign: "center",
            width: "100%",
          }}
        >
          <TypographyOxygen
            variant="h5"
            fontSize={theme.tourAndTravel.headingTextSize}
          >
            {tripHeading}
          </TypographyOxygen>
        </Box>
      </Box>
      <Box display={"flex"} justifyContent={"flex-end"}>
        <Badge
          badgeContent={
            <Box display={"flex"}>
              <Box display={showDiscount ? "flex" : "none"}>
                <TypographyOxygen
                  fontSize="1.2rem"
                  textDecoration="line-through"
                >
                  {actualTripAmount}
                </TypographyOxygen>
              </Box>
              <TypographyOxygen fontSize="1.2rem">
                {discountedTripAmount}
              </TypographyOxygen>
            </Box>
          }
          sx={{
            ".MuiBadge-badge": {
              minWidth: "150px",
              minHeight: "60px",
              backgroundColor: theme.palette.primary.main,
              fontFamily: "oxygen, sans-serif",
              fontWeight: "bold",
              transform: "translate(0%, -100%)", // Adjust the button's position
              right: "0",
            },
          }}
        ></Badge>
      </Box>
    </Card>
  );
};
