import ConstructionIcon from "@mui/icons-material/Construction";
import FlagIcon from "@mui/icons-material/Flag";
import IntegrationInstructionsOutlinedIcon from "@mui/icons-material/IntegrationInstructionsOutlined";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { RequestDemo, TryForFree } from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
} from "../../AIUtil/AIUtils";
import {
  LLLmsWidget,
  MarqueeEffect,
  ProductsPageHeader,
  ProductsPageSubHeader,
  ToolTemplateContainer,
  TopicContainer,
  TriggerContainer,
} from "../ProductsWidget";

export const AIIntegrationsHome = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="Integrations">
          <IntegrationInstructionsOutlinedIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                Integrate, Automate, and Elevate Your Operations{" "}
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Accelerate growth and efficiency by connecting, automating, and
                optimizing every aspect of your operations.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveBoxCenteredChildren>
          <TryForFree />
          <Box m={2} />
          <RequestDemo />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      <Grid item xs={12}>
        <MarqueeEffect />
      </Grid>

      {/* Trigger page
       */}
      <Grid item xs={12}>
        <TopicContainer topicText="Automation">
          <FlagIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                Put Your AI Agents to Work Around the Clock
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Automate tasks effortlessly with custom integrations.{" "}
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <AICommonBoxContainer>
          <CommonContainer>
            <TriggerContainer
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          </CommonContainer>
        </AICommonBoxContainer>
      </Grid>

      <Grid item xs={12}>
        <TopicContainer topicText="Integration Tools">
          <ConstructionIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                Optimize Your Workflows with Powerful AI-Driven Tools{" "}
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Streamline tasks and enhance productivity with our powerful,
                easy-to-use tools.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      {/* Build Custom AI Tools
       */}

      <Grid item xs={12}>
        <ToolTemplateContainer />
      </Grid>

      {/* LLM Model   */}
      <Grid item xs={12}>
        <TopicContainer topicText="LLMs">
          <ConstructionIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                Choose the right LLMs for the job
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                We integrate with all major LLMs and always keep them up to
                date.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <LLLmsWidget
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            isPhoneOrTab={isPhoneOrTab}
          />
        </CommonContainer>
      </Grid>
    </Grid>
  );
};
