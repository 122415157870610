import GitHubIcon from "@mui/icons-material/GitHub";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { Box, CardMedia, Stack, Typography } from "@mui/material";
import React from "react";
import { RouteToAIPortal } from "../../../../CodingRippler/CodingRipplerWidgets";

export const About = ({ children, ...props }) => {
  const {
    handleLinkdinClick,
    handleGitHubClick,
    handleXClick,
    handleYouTubeClick,
    logoPath,
  } = props;

  return (
    <Stack spacing={2}>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-start",
          width: { xs: "10rem", lg: "15rem" },
          overflow: "hidden",
        }}
      >
        <CardMedia
          component="img"
          image={logoPath}
          alt="CodingRippler"
          onClick={() => RouteToAIPortal()}
          sx={{
            objectFit: "contain",
            maxHeight: "100%",
            maxWidth: "100%",
            cursor: "pointer", // Add this line to change the cursor on hover
          }}
        />
      </Box>

      <Box>
        <Typography
          variant="h6"
          fontWeight="normal"
          className="sub-header typography-custom-css"
        >
          AI Rippler: Your Partner in Innovative AI Solutions
        </Typography>
      </Box>

      <Stack spacing={2} direction="row">
        <LinkedInIcon onClick={handleLinkdinClick} sx={{ cursor: "pointer" }} />
        <GitHubIcon onClick={handleGitHubClick} sx={{ cursor: "pointer" }} />
        <XIcon onClick={handleXClick} sx={{ cursor: "pointer" }} />
        <YouTubeIcon onClick={handleYouTubeClick} sx={{ cursor: "pointer" }} />
      </Stack>

      <Box>
        <Typography
          variant="body1"
          fontFamily='"Inter", sans-serif'
          fontWeight="normal"
          className="sub-header"
        >
          © 2025 CodingRippler Tech Private Ltd. All rights reserved.
        </Typography>
      </Box>

      <Box>{/* If any award is added              */}</Box>
    </Stack>
  );
};
