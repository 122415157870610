import React from "react";
import { Grid, Box } from "@mui/material";
import { TypographyOxygen } from "../../BoilarPlateJs/TypographyStyled";
import { StraigtLine } from "../../BoilarPlateJs/TitleDecorationBottomCenter";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

export const TourHighLights = ({ children, ...props }) => {
  const { individualTourInfo } = props;

  const tourIncludeValues = individualTourInfo?.tourIncludes.split(",");
  const tourExcludeValues = individualTourInfo?.tourExludes.split(",");

  return (
    <Grid container>
      <Grid item sm={12} md={6}>
        <TypographyOxygen variant="h6" fontSize={"1.2rem"}>
          DEPARTURE/RETURN LOCATION
        </TypographyOxygen>
      </Grid>

      <Grid item sm={12} md={6}>
        <TypographyOxygen
          variant="h6"
          fontSize={"1.2rem"}
          fontWeight={"normal"}
        >
          San Francisco International Airport
        </TypographyOxygen>
      </Grid>

      <Grid item xs={12}>
        <StraigtLine customStyle={{ pt: 2, pb: 2 }} />
      </Grid>

      <Grid item sm={12} md={6}>
        <TypographyOxygen variant="h6" fontSize={"1.2rem"}>
          DEPARTURE TIME
        </TypographyOxygen>
      </Grid>

      <Grid item sm={12} md={6}>
        <TypographyOxygen
          variant="h6"
          fontSize={"1.2rem"}
          fontWeight={"normal"}
        >
          Please arrive at least 2 hours before the flight.
        </TypographyOxygen>
      </Grid>

      <Grid item xs={12}>
        <StraigtLine customStyle={{ pt: 2, pb: 2 }} />
      </Grid>

      <Grid item sm={12} md={6}>
        <TypographyOxygen variant="h6" fontSize={"1.2rem"}>
          INCLUDED
        </TypographyOxygen>
      </Grid>

      <Grid item sm={12} md={6}>
        <Grid container>
          {tourIncludeValues.map((value, index) => (
            <Grid item md={6}>
              <Box display="flex" alignItems="center">
                <CheckCircleIcon />
                <TypographyOxygen
                  variant="h6"
                  fontSize={"1.0rem"}
                  fontWeight={"normal"}
                >
                  {value.trim()}{" "}
                </TypographyOxygen>
              </Box>{" "}
            </Grid>
          ))}
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <StraigtLine customStyle={{ pt: 2, pb: 2 }} />
      </Grid>

      <Grid item sm={12} md={6}>
        <TypographyOxygen variant="h6" fontSize={"1.2rem"}>
          NOT INCLUDED
        </TypographyOxygen>
      </Grid>

      <Grid item sm={12} md={6}>
        <Grid container>
          {tourExcludeValues.map((value, index) => (
            <Grid item md={6}>
              <Box display="flex" alignItems="center">
                <CancelIcon />
                <TypographyOxygen
                  variant="h6"
                  fontSize={"1.0rem"}
                  fontWeight={"normal"}
                >
                  {value.trim()}{" "}
                </TypographyOxygen>
              </Box>{" "}
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
