import React from "react";
import Chart from "react-apexcharts";

export const ApexChartOfTypeRadialBar = ({ children, ...props }) => {
  const { seriesData, labels, colors } = props;
  var options = {
    chart: {
      type: "radialBar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          margin: 20,
          size: "25%",
          position: "front",
        },
        track: {
          strokeWidth: "67%",
          margin: 15, // margin is in pixels
        },

        dataLabels: {
          show: true,
          name: {
            fontSize: "2.5rem",
            fontWeight: "bold",
            offsetY: -15, // Adjust the position of the name label
          },
          value: {
            fontSize: "1.5rem",
            fontWeight: "bold",
            offsetY: 10, // Adjust the position of the total label
          },
          total: {
            show: true,
            label: "Total",
            formatter: function(w) {
              // By default this function returns the average of all series. The below is just an example.
              return "2,324";
            },
          },
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: colors,
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 1,
        stops: [0, 50, 100],
      },
    },
    stroke: {
      lineCap: "round",
    },
    labels: labels,
    colors: colors, // Colors for each data series
  };

  return (
    <Chart
      options={options}
      series={seriesData}
      type="radialBar"
      height={"100%"}
      width={"100%"}
    />
  );
};
