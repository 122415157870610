import { Box, Stack, Typography } from "@mui/material";

export const Shortcut = ({ children, ...props }) => {
  const { header, contents, handleClick } = props;
  return (
    <Stack spacing={2}>
      <Box>
        <Typography
          variant="h6"
          fontWeight="bold"
          className="typography-custom-css"
        >
          {header}
        </Typography>
      </Box>

      <Box>
        {contents.map((content, index) => (
          <Typography
            key={index}
            mt={2}
            className="typography-custom-css"
            onClick={() => {
              handleClick(content.context);
            }}
            sx={{ cursor: "pointer" }}
          >
            {content.text}
          </Typography>
        ))}
      </Box>
    </Stack>
  );
};
