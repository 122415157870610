import AccessTimeIcon from "@mui/icons-material/AccessTime";
import BarChartIcon from "@mui/icons-material/BarChart";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import ConstructionOutlinedIcon from "@mui/icons-material/ConstructionOutlined";
import Diversity2OutlinedIcon from "@mui/icons-material/Diversity2Outlined";
import Diversity3OutlinedIcon from "@mui/icons-material/Diversity3Outlined";
import EventAvailableIcon from "@mui/icons-material/EventAvailable";
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import PsychologyOutlinedIcon from "@mui/icons-material/PsychologyOutlined";
import ReviewsIcon from "@mui/icons-material/Reviews";
import RunCircleIcon from "@mui/icons-material/RunCircle";
import ScheduleIcon from "@mui/icons-material/Schedule";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import SportsScoreOutlinedIcon from "@mui/icons-material/SportsScoreOutlined";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomInOutlinedIcon from "@mui/icons-material/ZoomInOutlined";
import {
  Card,
  CardContent,
  CardMedia,
  Container,
  Fab,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useEffect, useMemo, useRef, useState } from "react";
import { PageHeader, PageSubHeader } from "../AIWidgets";
import { DatabaseTabPanel } from "./BoshTheSalesAgent/Tabs/DatabaseTabPanel";
import { InboundTabPanel } from "./BoshTheSalesAgent/Tabs/InboundTabPanel";
import { OutboundTabPanel } from "./BoshTheSalesAgent/Tabs/OutboundTabPanel";

import { keyframes } from "@mui/system";
import { Services } from "../../CodingRippler/CodingRipplerHome/WhatWeDo/WhatWeDoWidgets";
import { PercentageContainer } from "../../CodingRippler/CodingRipplerWidgets";
import {
  CommonHeaderTypography,
  CommonSubHeaderTypography,
} from "../AIUtil/AIUtils";

export const LeadContainer = ({ children, ...props }) => {
  const { isPhoneorTab } = props;
  const data = [
    {
      value: 50,
      suffix: "%",
      prefix: "",
      text: "Increase in Lead Engagement",
    },

    {
      value: 2,
      suffix: "x",
      text: "Faster Response Time​",
    },
    {
      value: 25,
      suffix: "%",
      prefix: "+",
      text: "Higher Conversion Rates​​",
    },
    {
      value: 30,
      suffix: "%",
      prefix: "+",
      text: "Less Time on Admin​",
    },
    {
      value: 100,
      suffix: "%",
      prefix: "",
      text: "Availability​​​",
    },
  ];
  return (
    <Grid container spacing={2}>
      {data.map((item, index) => (
        <Grid item xs={12} sm={4} lg={2} key={index}>
          <PercentageContainer
            value={item.value}
            text={item.text}
            suffix={item.suffix}
            prefix={item.prefix}
            isPhoneorTab={isPhoneorTab}
          />
        </Grid>
      ))}
    </Grid>
  );
};
export const AdvertiseLeadGenerationTool = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} lg={12}>
        <Box>
          <PageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h3"}>
                <span className="theme-specfic-colored-text">
                  Catalyst’s Impact
                </span>{" "}
                Proven Metrics for Success
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Empowering Sales Teams with 24/7 AI-Driven Support
              </CommonSubHeaderTypography>
            }
          />

          <Box m={2} />

          <Grid container>
            <Grid item sm={1} lg={2} />

            <Grid item xs={12} sm={10}>
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                width="100%"
                height="100%"
                sx={
                  {
                    // border: "1px solid #f1e5ff",
                  }
                }
              >
                <LeadContainer isPhoneorTab={isPhoneOrTab} />
              </Box>
            </Grid>
            <Grid item sm={1} lg={2} />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};

export const AIAgentsFeatures = ({ children, ...props }) => {
  const services = {
    services: [
      {
        title: "Instant Lead Engagement",
        description:
          "Catalyst responds to leads in seconds, ensuring no opportunity slips through the cracks.",
        icon: <AccessTimeIcon />,
      },
      // {
      //   title: "Always-On Support",
      //   description:
      //     "Never miss a beat. Catalyst is always available to engage with leads and support your sales team.",
      //   icon: <AlarmIcon />,
      // },
      {
        title: "Easy Tech Integration",
        description:
          "Connect Catalyst with your existing tools like Salesforce, HubSpot, and Zapier for a smooth workflow.",
        icon: <SettingsInputComponentIcon />,
      },
      {
        title: "AI-Powered Insights",
        description:
          "Get actionable data and insights to help make smarter decisions and optimize your sales strategy.",
        icon: <BarChartIcon />,
      },
    ],
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Services WhatWeDoData={services.services} />
      </Grid>

      {/* <Grid item xs={12} sm={6} lg={3}>
        <Box>
          <AccessTimeIcon fontSize="large" className="theme-background-color" />
          <Typography
            variant="h6"
            fontWeight="500"
            className="typography-custom-css"
          >
            Instant Lead Engagement
          </Typography>

          <Box m={1} />

          <Typography
            variant="body1"
            className="typography-custom-css sub-header"
          >
            Catalyst responds to leads in seconds, ensuring no opportunity slips
            through the cracks.{" "}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Box>
          <AlarmIcon fontSize="large" className="theme-background-color" />
          <Typography
            variant="h6"
            fontWeight="500"
            className="typography-custom-css"
          >
            Always-On Support
          </Typography>
          <Box m={1} />
          <Typography
            variant="body1"
            className="typography-custom-css"
            fontWeight="normal"
          >
            Never miss a beat. Catalyst is always available to engage with leads
            and support your sales team.{" "}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Box>
          <SettingsInputComponentIcon
            fontSize="large"
            className="theme-background-color"
          />
          <Typography variant="h6" fontWeight="500">
            Easy Tech Integration
          </Typography>

          <Box m={1} />

          <Typography
            variant="body1"
            className="typography-custom-css"
            fontWeight="normal"
          >
            Connect Catalyst with your existing tools like Salesforce, HubSpot,
            and Zapier for a smooth workflow.{" "}
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12} sm={6} lg={3}>
        <Box>
          <BarChartIcon fontSize="large" className="theme-background-color" />
          <Typography
            variant="h6"
            fontWeight="500"
            className="typography-custom-css"
          >
            AI-Powered Insights
          </Typography>

          <Box m={1} />

          <Typography
            variant="body1"
            className="typography-custom-css"
            fontWeight="normal"
          >
            Get actionable data and insights to help make smarter decisions and
            optimize your sales strategy.{" "}
          </Typography>
        </Box>
      </Grid> */}
    </Grid>
  );
};

export const HowItWorkContainer = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const issmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMiddleScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  let variant = "fullWidth";

  if (issmallScreen) {
    variant = "scrollable";
  } else if (isMiddleScreen) {
    variant = "scrollable";
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card className="tab-container-ai-tools">
      <Box className="tab-container-child-ai-tools">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="use case tabs"
          TabIndicatorProps={{ style: { display: "none" } }}
          variant={variant}
          scrollButtons="auto"
        >
          <Tab
            icon={
              <MoveToInboxIcon
                className={value === 0 ? "theme-specfic-colored-text" : ""}
              />
            }
            iconPosition="start"
            label="Inbound"
            className={value === 0 ? "tab-selected" : "tab-default"}
            value={0}
            disableRipple
          />
          <Tab
            icon={
              <ForwardToInboxIcon
                className={value === 1 ? "theme-specfic-colored-text" : ""}
              />
            }
            iconPosition="start"
            label="Outbound"
            className={value === 1 ? "tab-selected" : "tab-default"}
            value={1}
            disableRipple
          />
          <Tab
            icon={
              <PsychologyOutlinedIcon
                className={value === 2 ? "theme-specfic-colored-text" : ""}
              />
            }
            iconPosition="start"
            label="Database Farming"
            className={value === 2 ? "tab-selected" : "tab-default"}
            value={2}
            disableRipple
          />
        </Tabs>
      </Box>
      <InboundTabPanel value={value} />
      <OutboundTabPanel value={value} />
      <DatabaseTabPanel value={value} />
    </Card>
  );
};

export const TopicGraphicalContainer = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        background: "linear-gradient(#6056ff, #943bff 87%, #f1e5ff 102%)",

        height: {
          xs: "100%",
        },
      }}
      width={"2.5rem"}
      ml={2}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height={{
          xs: "100%",
        }}
        width="100%"
        gap={2}
      >
        <Box
          height={{
            xs: 175,
            lg: 175,
          }}
        >
          <Fab
            variant="contained"
            size="medium"
            sx={{
              border: "3px solid #943bff",
              transform: "translate(0%, -25%)",
            }}
            p={10}
          >
            <SportsScoreOutlinedIcon fontSize="large" />
          </Fab>
        </Box>

        {/* <Box m={{ xs: 10, sm: 10, md: 12, lg: 13 }} /> */}

        <Box
          height={{
            xs: 175,
            lg: 175,
          }}
        >
          <Fab
            variant="contained"
            size="medium"
            sx={{
              border: "3px solid #943bff",
            }}
            p={10}
          >
            <ConstructionOutlinedIcon fontSize="large" />
          </Fab>
        </Box>

        {/* <Box m={{ xs: 10, sm: 10, md: 12, lg: 16 }} /> */}

        <Box
          height={{
            xs: 175,
            lg: 175,
          }}
        >
          <Fab
            variant="contained"
            size="medium"
            sx={{
              border: "3px solid #943bff",
            }}
            p={10}
          >
            <LightModeOutlinedIcon fontSize="large" />
          </Fab>
        </Box>
        {/* <Box m={{ xs: 6, sm: 10, md: 12, lg: 12 }} /> */}

        <Box
          height={{
            xs: 175,
            lg: 175,
          }}
        >
          <Fab
            variant="contained"
            size="medium"
            sx={{
              border: "3px solid #943bff",
            }}
            p={10}
          >
            <ZoomInOutlinedIcon fontSize="large" />
          </Fab>
        </Box>
      </Box>
    </Box>
  );
};

export const RepresentStepsVertically = ({ children, ...props }) => {
  const { steps } = props;

  return (
    <Box
      sx={{
        background: "linear-gradient(#6056ff, #943bff 87%, #f1e5ff 102%)",

        height: {
          xs: "100%",
        },
      }}
      width={"2.5rem"}
      ml={2}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="flex-start"
        alignItems="center"
        height={{
          xs: "100%",
        }}
        width="100%"
        gap={2}
      >
        {steps.map((item, index) => (
          <Box
            height={{
              xs: 175,
              lg: 175,
            }}
            key={index}
          >
            <Fab
              variant="contained"
              size="medium"
              sx={{
                border: "3px solid #943bff",
                transform:
                  index === 0 ? "translate(0%, -25%)" : "translate(0%, 0%)",

                backgroundColor: "white",
                zIndex: 1,
                "&:hover": {
                  backgroundColor: "#f0f0f0", // Optional: Darker shade on hover
                },
              }}
              p={10}
            >
              {item.icon}
            </Fab>
          </Box>
        ))}

        {/* <Box m={{ xs: 10, sm: 10, md: 12, lg: 13 }} /> */}

        {/* <Box
          height={{
            xs: 175,
            lg: 175,
          }}
        >
          <Fab
            variant="contained"
            size="medium"
            sx={{
              border: "3px solid #943bff",
            }}
            p={10}
          >
            <ConstructionOutlinedIcon fontSize="large" />
          </Fab>
        </Box>

        <Box
          height={{
            xs: 175,
            lg: 175,
          }}
        >
          <Fab
            variant="contained"
            size="medium"
            sx={{
              border: "3px solid #943bff",
            }}
            p={10}
          >
            <LightModeOutlinedIcon fontSize="large" />
          </Fab>
        </Box>

        <Box
          height={{
            xs: 175,
            lg: 175,
          }}
        >
          <Fab
            variant="contained"
            size="medium"
            sx={{
              border: "3px solid #943bff",
            }}
            p={10}
          >
            <ZoomInOutlinedIcon fontSize="large" />
          </Fab>
        </Box> */}
      </Box>
    </Box>
  );
};
export const TopicContainer = ({ children, ...props }) => {
  const { timeLine, topicText, topicDescription, customStyle } = props;
  return (
    <Box
      height={{
        xs: 175,
        lg: 175,
      }}
      width={{ xs: "100%", lg: "100%" }}
    >
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        justifyContent="flex-start"
        alignItems={"center"}
      >
        <Typography
          variant="h5"
          fontWeight="semi-bold"
          className="typography-custom-css"
          sx={{
            ...customStyle,
          }}
        >
          {timeLine}
        </Typography>

        <Typography
          variant="h4"
          fontWeight="bold"
          className="typography-custom-css"
          sx={{
            ...customStyle,
          }}
        >
          {topicText}
        </Typography>
      </Box>
      <Box m={2} />
      <Typography
        variant="h6"
        className="sub-header typography-custom-css"
        fontWeight="normal"
        sx={{
          ...customStyle,
        }}
      >
        {topicDescription}
      </Typography>
      <Box m={7} />
    </Box>
  );
};

export const TopicContainerSm = ({ children, ...props }) => {
  const { timeLine, topicText, topicDescription, customStyle } = props;

  const { isPhone } = props;

  return (
    <Box
      height={{
        xs: 175,
        lg: 175,
      }}
      width={{ xs: "100%", lg: "100%" }}
    >
      <Box
        display="flex"
        flexDirection="row"
        gap={2}
        justifyContent="flex-start"
        alignItems={"center"}
        textAlign="left"
      >
        <Typography
          variant={isPhone ? "body1" : "h5"}
          fontWeight="semi-bold"
          className="typography-custom-css"
          sx={{
            ...customStyle,
          }}
        >
          {timeLine}
        </Typography>

        <Typography
          variant={isPhone ? "body1" : "h4"}
          fontWeight="bold"
          className="typography-custom-css"
          sx={{
            ...customStyle,
          }}
        >
          {topicText}
        </Typography>
      </Box>
      <Box m={2} />
      <Typography
        variant={isPhone ? "body1" : "h5"}
        className="sub-header typography-custom-css"
        fontWeight="normal"
        sx={{
          ...customStyle,
        }}
      >
        {topicDescription}
      </Typography>
      <Box m={7} />
    </Box>
  );
};

export const GettingStartedDetails = ({ children, ...props }) => {
  return (
    <Box
      height={"75vh"}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box m={2} />
      <Card
        sx={{
          borderRadius: "12px", // Rounded corners
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            alignItems="center"
            gap={2}
          >
            <ReviewsIcon fontSize="large" />
            <Box>
              <Typography
                variant="h6"
                fontWeight="normal"
                className="sub-header typography-custom-css"
              >
                Analyze the Impact of Your BDR Agent in Action
              </Typography>
            </Box>
          </Box>
          <Box m={2} />
        </CardContent>
      </Card>

      <Card
        sx={{
          borderRadius: "12px", // Rounded corners
        }}
      >
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent="center"
            alignItems="center"
          >
            <RunCircleIcon fontSize="large" />
            <Box>
              <Typography
                variant="h6"
                fontWeight="normal"
                className="sub-header typography-custom-css"
              >
                Autopilot Your BDR Agent Operating 24/7
              </Typography>
            </Box>
          </Box>
          <Box m={2} />
        </CardContent>
      </Card>

      <Box m={2} />
    </Box>
  );
};

export const ApproachIntro = ({ children, ...props }) => {
  const { data } = props;
  return (
    <Box
      height={"75vh"}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box m={2} />

      {data.map((item, index) => (
        <Card
          key={index}
          sx={{
            borderRadius: "12px", // Rounded corners
          }}
        >
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              {item.icon}
              <Box>
                <Typography
                  variant="h6"
                  fontWeight="normal"
                  className="sub-header typography-custom-css"
                >
                  {item.title}
                </Typography>
              </Box>
            </Box>
            <Box m={2} />
          </CardContent>
        </Card>
      ))}

      <Box m={2} />

      {/* <Card>
        <CardContent>
          <Box
            display="flex"
            flexDirection="row"
            gap={2}
            justifyContent="center"
            alignItems="center"
          >
            <RunCircleOutlinedIcon fontSize="large" />
            <Box>
              <Typography
                variant="h6"
                fontWeight="normal"
                className="sub-header typography-custom-css"
              >
                Autopilot Your BDR Agent Operating 24/7
              </Typography>
            </Box>
          </Box>
          <Box m={2} />
        </CardContent>
      </Card> */}

      <Box m={2} />
    </Box>
  );
};

export const GettingStartedContainer = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const data = [
    {
      timeLine: "Week 1",
      title: "Initial Setup",
      description:
        "Mapping your processes and ingesting initial training data.",
    },
    {
      timeLine: "Week 2",
      title: "Configuration",
      description:
        "Integrating with your tech stack and configuring personalization for your needs.",
    },
    {
      timeLine: "Week 3-4",
      title: "Go Live",
      description:
        "Final testing, fine-tuning, and preparing for a smooth launch.",
    },
    {
      timeLine: "Week 5+",
      title: "Scale Up",
      description:
        "Grow your prospect volume, monitor progress, and fine-tune for ongoing optimization.",
    },
  ];
  return (
    <Container>
      {!isPhoneOrTab && (
        <Grid container spacing={4}>
          <Grid item xs={5}>
            <GettingStartedDetails />
          </Grid>

          <Grid item xs={1}>
            <TopicGraphicalContainer />
          </Grid>

          <Grid item xs={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              height={{ xs: "100%" }}
              gap={2}
            >
              {data.map((item, index) => (
                <TopicContainer
                  key={index}
                  timeLine={item.timeLine}
                  topicText={item.title}
                  topicDescription={item.description}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
      {isPhoneOrTab && (
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <TopicGraphicalContainer />
          </Grid>

          <Grid item xs={9}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              height={{ xs: "100%" }}
              gap={2}
            >
              {data.map((item, index) => (
                <TopicContainerSm
                  key={index}
                  timeLine={item.timeLine}
                  topicText={item.title}
                  topicDescription={item.description}
                  isPhone={isPhone}
                  isTab={isTab}
                  deviceWithSmallHeight={deviceWithSmallHeight}
                  isPhoneOrTab={isPhoneOrTab}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export const OurApproach = ({ children, ...props }) => {
  const {
    isPhoneOrTab,
    isPhone,
    isTab,
    deviceWithSmallHeight,
    pageContent,
  } = props;

  const data = pageContent.steps;
  return (
    <Container>
      {!isPhoneOrTab && (
        <Grid container spacing={4}>
          <Grid item xs={5}>
            <ApproachIntro data={pageContent.introduction} />
          </Grid>

          <Grid item xs={1}>
            <RepresentStepsVertically steps={data} />
          </Grid>

          <Grid item xs={5}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              height={{ xs: "100%" }}
              gap={2}
            >
              {data.map((item, index) => (
                <TopicContainer
                  key={index}
                  timeLine={item.timeLine}
                  topicText={item.title}
                  topicDescription={item.description}
                  customStyle={{
                    color: "white",
                  }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
      {isPhoneOrTab && (
        <Grid container spacing={4}>
          <Grid item xs={3}>
            <RepresentStepsVertically steps={data} />
          </Grid>

          <Grid item xs={9}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="flex-start"
              alignItems="center"
              height={{ xs: "100%" }}
              gap={2}
            >
              {data.map((item, index) => (
                <TopicContainerSm
                  key={index}
                  timeLine={item.timeLine}
                  topicText={item.title}
                  topicDescription={item.description}
                  isPhone={isPhone}
                  isTab={isTab}
                  deviceWithSmallHeight={deviceWithSmallHeight}
                  isPhoneOrTab={isPhoneOrTab}
                  customStyle={{
                    color: "white",
                  }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

export const BeforeAI = ({ children, ...props }) => {
  const { negativeData } = props;

  return (
    <Stack>
      <Box display="flex" flexDirection="column" gap={2}>
        {negativeData.map((item, index) => (
          <Card
            sx={{
              minWidth: "100%",
              maxWidth: "100%",
            }}
            key={index}
          >
            <CardContent>
              <Box
                display="flex"
                flexDirection="row"
                gap={2}
                justifyContent="flex-start"
                alignItems="center"
              >
                {item.icon}
                <Typography
                  variant="h6"
                  fontWeight="normal"
                  className="sub-header typography-custom-css"
                  fontFamily='"Oxygen", sans-serif'
                >
                  {item.title}
                </Typography>
              </Box>
            </CardContent>
          </Card>
        ))}

        <Box
          display="flex"
          flexDirection="row"
          gap={2}
          width={"100vw"}
          justifyContent="center"
          alignItems={"center"}
        >
          <Box
            sx={{
              width: "100px",
              height: "75px",
              border: "none",
            }}
          >
            <CardMedia
              component="img"
              image="/airippler/ai-agents/inbound/downArrow.svg"
              alt="AI"
              width="100%"
              height="100%"
              sx={{ objectFit: "contain" }}
            />
          </Box>
        </Box>
      </Box>
    </Stack>
  );
};

export const AfterAI = ({ children, ...props }) => {
  const { positiveData } = props;

  return (
    <Stack spacing={2}>
      {positiveData.map((item, index) => (
        <Card
          sx={{
            minWidth: "100%",
            maxWidth: "100%",
          }}
          key={index}
        >
          <CardContent>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-start"
              alignItems="center"
              gap={2}
            >
              {item.icon}
              <Typography
                variant="h6"
                fontWeight="normal"
                className="sub-header typography-custom-css"
                fontFamily='"Oxygen", sans-serif'
              >
                {item.title}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      ))}
    </Stack>
  );
};

export const WhyAI = ({ children, ...props }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("sm"));

  const { negativeData, positiveData } = props;

  return (
    <>
      {isLargeScreen && (
        <Grid container spacing={2}>
          {negativeData.map((item, index) => (
            <Grid item xs={12} key={index}>
              <Box
                display="flex"
                sm="row"
                gap={2}
                justifyContent="center"
                alignItems="center"
              >
                <Card
                  sx={{
                    minWidth: "40%",
                    maxWidth: "40%",
                  }}
                >
                  <CardContent>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap={2}
                    >
                      {item.icon}
                      <Typography
                        variant="h6"
                        fontWeight="normal"
                        className="sub-header typography-custom-css"
                        fontFamily='"Oxygen", sans-serif'
                      >
                        {item.title}{" "}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>

                <Box
                  sx={{
                    width: "100px",
                    height: "75px",
                    border: "none",
                  }}
                >
                  <CardMedia
                    component="img"
                    image="/airippler/ai-agents/inbound/forward.svg"
                    alt="AI"
                    width="100%"
                    height="100%"
                    sx={{ objectFit: "contain" }}
                  />
                </Box>

                <Card
                  sx={{
                    minWidth: "40%",
                    maxWidth: "40%",
                  }}
                >
                  <CardContent>
                    <Box
                      display="flex"
                      flexDirection="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      gap={2}
                    >
                      {positiveData[index].icon}
                      <Typography
                        variant="h6"
                        fontWeight="normal"
                        className="sub-header typography-custom-css"
                        fontFamily='"Oxygen", sans-serif'
                      >
                        {positiveData[index].title}
                      </Typography>
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          ))}
        </Grid>
      )}

      {!isLargeScreen && (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <BeforeAI negativeData={negativeData} positiveData={positiveData} />
            <AfterAI negativeData={negativeData} positiveData={positiveData} />
          </Grid>
        </Grid>
      )}
    </>
  );
};

export const HowInboundWorksProps = ({ children, ...props }) => {
  const {
    title,
    description,
    icon,
    index,
    currentStepIndicator,
    setCurrentStepIndicator,
    doAReset,
    setDoAReset,
  } = props;
  const spin = keyframes`
  0% { transform: rotate(0deg); }
  25% { color: orange; }
  50% { transform: rotate(180deg); color: orange; }
  75% { color: orange; }
  100% { transform: rotate(360deg); green; }
`;

  const colors = useMemo(
    () => [
      "orange",
      "green",
      "#32bc5c1a",
      "white",
      "#868a97",
      "rgba(255, 223, 191, 1)",
      "rgba(230, 255, 230, 1)",
    ],
    []
  );

  const circleRefStep = useRef(null);

  const [stepIdentifiers, setStepIdentifiers] = useState({
    stepColor: null,
    stepFinalColor: colors[1],
    stepBgColor: colors[3],
    stepFinalBgColor: colors[2],
    stepAnimationInprogress: false,
    stepAnimationCompleted: false,
  });

  useEffect(() => {
    const startStepAnimation = () => {
      setStepIdentifiers((prevState) => ({
        ...prevState,
        stepAnimationInprogress: true,
        stepColor: colors[0],
        stepBgColor: colors[5],
      }));

      setDoAReset(false);
    };

    const stopStepAnimation = () => {
      setStepIdentifiers((prevState) => ({
        ...prevState,
        stepAnimationInprogress: false,
        stepAnimationCompleted: true,
        stepColor: colors[1],
        stepBgColor: colors[6],
      }));

      setCurrentStepIndicator((prevState) => {
        const newStepIndicator = [...prevState];

        if (index !== newStepIndicator.length - 1) {
          newStepIndicator[index] = 0;
          newStepIndicator[index + 1] = 1;
        } else {
          newStepIndicator[index] = 0;
          setTimeout(() => {
            const defaultStepIndicator = Array(newStepIndicator.length).fill(0);
            defaultStepIndicator[0] = 1;
            setCurrentStepIndicator(defaultStepIndicator);
            setDoAReset(true);
          }, 2000);
        }
        return newStepIndicator;
      });
    };

    // current is used here to check if the element is endered or not
    const circleElementStep = circleRefStep.current;

    // if it is rendered then start the animation
    if (circleElementStep && currentStepIndicator[index]) {
      startStepAnimation();

      // here we are adding an event listener to our circle element which keeps a track of
      // the animation iteration and when the animation is completed we stop the animation
      // by giving stoping function as an input to the event listener

      circleElementStep.addEventListener(
        "animationiteration",
        stopStepAnimation
      );
    } else {
      if (doAReset) {
        setStepIdentifiers((prevState) => ({
          ...prevState,
          stepColor: null,
          stepFinalColor: colors[1],
          stepBgColor: colors[3],
          stepFinalBgColor: colors[2],
          stepAnimationInprogress: false,
          stepAnimationCompleted: false,
        }));
      }
    }

    // define a call back funtion to remove these event listeners

    return () => {
      if (circleElementStep) {
        circleElementStep.removeEventListener(
          "animationiteration",
          stopStepAnimation
        );
      }
    };
  }, [
    currentStepIndicator,
    setCurrentStepIndicator,
    colors,
    doAReset,
    setDoAReset,
    index,
  ]);

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isBetweenMediumAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const isXtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  let fontSize = "0.6rem";

  if (isSmallScreen) {
    fontSize = "1rem";
  } else if (isMediumScreen) {
    fontSize = "1rem";
  } else if (isBetweenMediumAndLargeScreen) {
    fontSize = "1rem";
  } else if (isXtraLargeScreen) {
    fontSize = "1rem";
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: {
          xs: "flex-start",
          lg: "center",
        },
        alignItems: {
          xs: "flex-start",
          md: "flex-start",
          lg: "center",
        },
        gap: 2,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "row",
            lg: "column",
          },
          justifyContent: {
            xs: "flex-start",
            lg: "center",
          },
          alignItems: {
            xs: "center",
            lg: "center",
          },
          gap: 2,
        }}
      >
        <Card
          sx={{
            width: "36px",
            height: "27px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Card>

        <Card
          sx={{
            backgroundColor: stepIdentifiers.stepBgColor,
            display: "flex",
            alignItems: "center",
            padding: "4px 8px",
            pointerEvents: "none",
            borderRadius: "16px", // Adjust the value as needed for the desired roundness
          }}
        >
          <FiberSmartRecordIcon
            ref={circleRefStep}
            sx={{
              fontSize: "12px",
              color: stepIdentifiers.stepColor,
              animation:
                stepIdentifiers.stepAnimationInprogress === true
                  ? `${spin} 2s linear infinite`
                  : "none",
            }}
          />
          <Typography
            ml={1}
            variant="body2"
            sx={{
              fontSize: { fontSize },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        </Card>
      </Box>

      <Card
        sx={{
          minWidth: "100%",
          width: "100%",
          visibility: {
            xs: "visible",
            lg: stepIdentifiers.stepAnimationCompleted ? "visible" : "hidden",
          },
          position: {
            xs: "relative",
            lg: "absolute",
          },
          // Ensure the card does not affect the parent's layout
          top: "100%",
          transform: {
            lg: "translateY(10%)",
          },
        }}
      >
        <CardContent>
          <Typography
            variant="caption"
            fontWeight="normal"
            sx={{
              fontSize: { fontSize },
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export const HowOutboundWorksProps = ({ children, ...props }) => {
  const {
    title,
    description,
    icon,
    index,
    currentStepIndicator,
    setCurrentStepIndicator,
    doAReset,
    setDoAReset,
  } = props;
  const spin = keyframes`
  0% { transform: rotate(0deg); }
  25% { color: orange; }
  50% { transform: rotate(180deg); color: orange; }
  75% { color: orange; }
  100% { transform: rotate(360deg); green; }
`;

  const colors = useMemo(
    () => [
      "orange",
      "green",
      "#32bc5c1a",
      "white",
      "#868a97",
      "rgba(255, 223, 191, 1)",
      "rgba(230, 255, 230, 1)",
    ],
    []
  );

  const circleRefStep = useRef(null);

  const [stepIdentifiers, setStepIdentifiers] = useState({
    stepColor: null,
    stepFinalColor: colors[1],
    stepBgColor: colors[3],
    stepFinalBgColor: colors[2],
    stepAnimationInprogress: false,
    stepAnimationCompleted: false,
  });

  useEffect(() => {
    const startStepAnimation = () => {
      setStepIdentifiers((prevState) => ({
        ...prevState,
        stepAnimationInprogress: true,
        stepColor: colors[0],
        stepBgColor: colors[5],
      }));

      setDoAReset(false);
    };

    const stopStepAnimation = () => {
      setStepIdentifiers((prevState) => ({
        ...prevState,
        stepAnimationInprogress: false,
        stepAnimationCompleted: true,
        stepColor: colors[1],
        stepBgColor: colors[6],
      }));

      setCurrentStepIndicator((prevState) => {
        const newStepIndicator = [...prevState];

        if (index !== newStepIndicator.length - 1) {
          newStepIndicator[index] = 0;
          newStepIndicator[index + 1] = 1;
        } else {
          newStepIndicator[index] = 0;
          setTimeout(() => {
            const defaultStepIndicator = Array(newStepIndicator.length).fill(0);
            defaultStepIndicator[0] = 1;
            setCurrentStepIndicator(defaultStepIndicator);
            setDoAReset(true);
          }, 2000);
        }
        return newStepIndicator;
      });
    };

    // current is used here to check if the element is endered or not
    const circleElementStep = circleRefStep.current;

    // if it is rendered then start the animation
    if (circleElementStep && currentStepIndicator[index]) {
      startStepAnimation();

      // here we are adding an event listener to our circle element which keeps a track of
      // the animation iteration and when the animation is completed we stop the animation
      // by giving stoping function as an input to the event listener

      circleElementStep.addEventListener(
        "animationiteration",
        stopStepAnimation
      );
    } else {
      if (doAReset) {
        setStepIdentifiers((prevState) => ({
          ...prevState,
          stepColor: null,
          stepFinalColor: colors[1],
          stepBgColor: colors[3],
          stepFinalBgColor: colors[2],
          stepAnimationInprogress: false,
          stepAnimationCompleted: false,
        }));
      }
    }

    // define a call back funtion to remove these event listeners

    return () => {
      if (circleElementStep) {
        circleElementStep.removeEventListener(
          "animationiteration",
          stopStepAnimation
        );
      }
    };
  }, [
    currentStepIndicator,
    setCurrentStepIndicator,
    colors,
    doAReset,
    setDoAReset,
    index,
  ]);

  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isBetweenMediumAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const isXtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  let fontSize = "0.6rem";

  if (isSmallScreen) {
    fontSize = "1rem";
  } else if (isMediumScreen) {
    fontSize = "1rem";
  } else if (isBetweenMediumAndLargeScreen) {
    fontSize = "1rem";
  } else if (isXtraLargeScreen) {
    fontSize = "1rem";
  }
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: {
          xs: "flex-start",
          lg: "center",
        },
        alignItems: {
          xs: "flex-start",
          md: "flex-start",
          lg: "center",
        },
        gap: 2,
        position: "relative",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "row",
            lg: "column",
          },
          justifyContent: {
            xs: "flex-start",
            lg: "center",
          },
          alignItems: {
            xs: "center",
            lg: "center",
          },
          gap: 2,
        }}
      >
        <Card
          sx={{
            width: "36px",
            height: "27px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {icon}
        </Card>

        <Card
          sx={{
            backgroundColor: stepIdentifiers.stepBgColor,
            display: "flex",
            alignItems: "center",
            padding: "4px 8px",
            pointerEvents: "none",
            borderRadius: "16px", // Adjust the value as needed for the desired roundness
          }}
        >
          <FiberSmartRecordIcon
            ref={circleRefStep}
            sx={{
              fontSize: "12px",
              color: stepIdentifiers.stepColor,
              animation:
                stepIdentifiers.stepAnimationInprogress === true
                  ? `${spin} 2s linear infinite`
                  : "none",
            }}
          />
          <Typography
            ml={1}
            variant="body2"
            sx={{
              fontSize: { fontSize },
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {title}
          </Typography>
        </Card>
      </Box>

      <Card
        sx={{
          minWidth: "100%",
          width: "100%",
          visibility: {
            xs: "visible",
            lg: stepIdentifiers.stepAnimationCompleted ? "visible" : "hidden",
          },
          position: {
            xs: "relative",
            lg: "absolute",
          },
          // Ensure the card does not affect the parent's layout
          top: "100%",
          transform: {
            lg: "translateY(10%)",
          },
        }}
      >
        <CardContent>
          <Typography
            variant="caption"
            fontWeight="normal"
            sx={{
              fontSize: { fontSize },
            }}
          >
            {description}
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

export const HowInboundWorks = ({ children, ...props }) => {
  const { steps } = props;

  const [currentStepIndicator, setCurrentStepIndicator] = useState([
    1,
    0,
    0,
    0,
    0,
    0,
    0,
    0,
  ]);

  const [doAReset, setDoAReset] = useState(false);

  const calculateMargins = (index, total, componentWidth) => {
    // Concave shape calculation for larger screens
    const angle = (index / (total - 1)) * Math.PI - Math.PI; // Angle for each image (-π to π)
    const topMargin = 0 + 25 * Math.sin(angle); // Calculate top margin in percentage
    const leftMargin = 2; // Incremental left margin

    return { topMargin: `${topMargin}%`, leftMargin: `${leftMargin}%` };
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: {
          lg: "50vh",
        },
        display: "flex",
        flexDirection: {
          xs: "column",
          lg: "row",
          transform: {
            xs: "translateY(0%)",
            lg: "translateY(-50%)",
          },
        },
        justifyContent: {
          xs: "flex-start",
          lg: "flex-start",
        },
        alignItems: {
          xs: "flex-start",
          md: "flex-start",
          lg: "center",
        },
        ml: 2,
        mr: 2,
      }}
    >
      {steps.map((item, index) => {
        const { topMargin, leftMargin } = calculateMargins(index, steps.length);

        return (
          <Box
            key={index}
            sx={{
              marginTop: {
                xs: 4,
                lg: `${topMargin}`,
              },
              marginLeft: {
                xs: 2,
                sm: 4,
                md: "25%",
                lg: `${leftMargin}`,
              },
              flex: 1,
            }}
          >
            <HowInboundWorksProps
              key={index}
              title={item.title}
              description={item.description}
              icon={item.icon}
              index={index}
              currentStepIndicator={currentStepIndicator}
              setCurrentStepIndicator={setCurrentStepIndicator}
              doAReset={doAReset}
              setDoAReset={setDoAReset}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export const HowOutboundWorks = ({ children, ...props }) => {
  const { steps } = props;

  const [currentStepIndicator, setCurrentStepIndicator] = useState([
    1,
    0,
    0,
    0,
    0,
    0,
  ]);

  const [doAReset, setDoAReset] = useState(false);

  const calculateMargins = (index, total, componentWidth) => {
    // Concave shape calculation for larger screens
    const angle = (index / (total - 1)) * Math.PI - Math.PI; // Angle for each image (-π to π)
    const topMargin = 0 + 25 * Math.sin(angle); // Calculate top margin in percentage
    const leftMargin = 2; // Incremental left margin

    return { topMargin: `${topMargin}%`, leftMargin: `${leftMargin}%` };
  };

  return (
    <Box
      sx={{
        position: "relative",
        height: {
          lg: "50vh",
        },
        display: "flex",
        flexDirection: {
          xs: "column",
          lg: "row",
          transform: {
            xs: "translateY(0%)",
            lg: "translateY(-50%)",
          },
        },
        justifyContent: {
          xs: "flex-start",
          lg: "flex-start",
        },
        alignItems: {
          xs: "flex-start",
          md: "flex-start",
          lg: "center",
        },
        ml: 2,
        mr: 2,
      }}
    >
      {steps.map((item, index) => {
        const { topMargin, leftMargin } = calculateMargins(index, steps.length);

        return (
          <Box
            key={index}
            sx={{
              marginTop: {
                xs: 4,
                lg: `${topMargin}`,
              },
              marginLeft: {
                xs: 2,
                sm: 4,
                md: "25%",
                lg: `${leftMargin}`,
              },
              flex: 1,
            }}
          >
            <HowOutboundWorksProps
              key={index}
              title={item.title}
              description={item.description}
              icon={item.icon}
              index={index}
              currentStepIndicator={currentStepIndicator}
              setCurrentStepIndicator={setCurrentStepIndicator}
              doAReset={doAReset}
              setDoAReset={setDoAReset}
            />
          </Box>
        );
      })}
    </Box>
  );
};

export const AccelerateSales = ({ children, ...props }) => {
  const data = [
    {
      title: "Catalyst Frees Your Team to Close",
      src: "/airippler/features/IntegrateWithTechTrack.png",
      details: [
        {
          title: "Send Personalized Messages at Scale",
          icon: (
            <ForwardToInboxOutlinedIcon
              className={"theme-specfic-colored-text"}
            />
          ),
        },
        {
          title: "Reply to Messages Within Minutes",
          icon: <ScheduleIcon className={"theme-specfic-colored-text"} />,
        },
        {
          title: "Run Multi-Touch Follow-Up Sequences",
          icon: <LeaderboardIcon className={"theme-specfic-colored-text"} />,
        },
        {
          title: "Update CRM and Generate Handoff Notes",
          icon: <EventAvailableIcon className={"theme-specfic-colored-text"} />,
        },
      ],
    },

    {
      title: "Catalyst Fits into Your Team’s Workflow",
      src: "/airippler/features/IntegrateWithTechTrack.png",
      details: [
        {
          title: "Research: Google, LinkedIn, Snowflake & More",
          icon: <ZoomInIcon className={"theme-specfic-colored-text"} />,
        },
        {
          title: "Prospect Communication: Gmail, Outlook",
          icon: <ChatOutlinedIcon className={"theme-specfic-colored-text"} />,
        },
        {
          title: "Team Communication: Slack, Teams, Gmail",
          icon: (
            <Diversity2OutlinedIcon className={"theme-specfic-colored-text"} />
          ),
        },
        {
          title: "Major CRMs: Salesforce, HubSpot, & More",
          icon: (
            <Diversity3OutlinedIcon className={"theme-specfic-colored-text"} />
          ),
        },
      ],
    },
  ];
  return (
    <Container>
      <Grid container spacing={2}>
        {data.map((item, index) => (
          <Grid item xs={12} md={6} key={index}>
            <Card>
              <CardContent m={4}>
                <Typography
                  variant="h5"
                  fontWeight="bold"
                  className="typography-custom-css"
                >
                  {item.title}
                </Typography>
              </CardContent>
              <CardMedia
                component="img"
                image={item.src}
                sx={{
                  objectFit: "contain",
                  height: 200,
                }}
              />
              <CardContent m={4}>
                <Box display="flex" flexDirection="column" gap={2}>
                  {item.details.map((detail, index) => (
                    <Box
                      display={"flex"}
                      flexDirection="row"
                      alignItems="center"
                      gap={2}
                      key={index}
                    >
                      {detail.icon}
                      <Typography
                        variant="h6"
                        className="typography-custom-css  sub-header"
                      >
                        {detail.title}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};
