import {
  Box,
  Button,
  CardMedia,
  Container,
  Grid,
  Typography,
} from "@mui/material";
import { RouteToContactUs } from "../CodingRipplerHome/ContactUs/ContactUs";
import {
  HeroSubHeader,
  RouteToAIPortal,
  RouteToWebPortal,
} from "../CodingRipplerWidgets";

export const WRFooter = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone } = props;

  const clickHome = () => {
    window.location.href = "/";
  };

  const buttonStyle = {
    padding: "1rem 2rem",
    borderRadius: "14px",
    color: "#6056ff",
  };

  const buttonStyleSm = {
    padding: "0.5rem 1rem",
    borderRadius: "14px",
    color: "#6056ff",
  };

  const clickHandler = () => {
    window.location.href = "/";
  };

  const logoPath = "/codingrippler/brand-logos/wr-logo-dark.svg";

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: isPhone ? "column" : "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            height: "100%",
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: isPhoneorTab ? 75 : 140,
              width: isPhoneorTab ? "15rem" : "20rem",
              overflow: "hidden",
              transform: isPhone ? "translate(0%, 0)" : "translate(5%, 0)",
            }}
          >
            <CardMedia
              component="img"
              image={logoPath}
              alt="CodingRippler"
              onClick={() => clickHandler()}
              sx={{
                objectFit: "contain",
                maxHeight: "100%",
                maxWidth: "100%",
                cursor: "pointer", // Add this line to change the cursor on hover
              }}
            />
          </Box>

          <Box gap={2} p={2}>
            <Button
              variant="text"
              size={isPhone ? "small" : "large"}
              sx={isPhoneorTab ? buttonStyleSm : buttonStyle}
              onClick={clickHome}
            >
              Home
            </Button>

            <Button
              variant="text"
              size={isPhone ? "small" : "large"}
              sx={isPhoneorTab ? buttonStyleSm : buttonStyle}
              onClick={() => {
                RouteToWebPortal();
              }}
            >
              Web
            </Button>

            <Button
              variant="text"
              size={isPhone ? "small" : "large"}
              sx={isPhoneorTab ? buttonStyleSm : buttonStyle}
              onClick={() => {
                RouteToAIPortal();
              }}
            >
              AI
            </Button>

            <Button
              variant="outlined"
              size={isPhone ? "small" : "large"}
              sx={isPhoneorTab ? buttonStyleSm : buttonStyle}
              onClick={() => {
                RouteToContactUs();
              }}
            >
              Let's Talk
            </Button>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Container>
          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <Typography
              variant="body1"
              fontFamily='"Inter", sans-serif'
              fontWeight="normal"
              className="sub-header"
            >
              © 2025 CodingRippler Tech Private Ltd. All rights reserved.
            </Typography>
          </Box>

          <Box
            width="100%"
            display="flex"
            flexDirection="row"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <HeroSubHeader
              variant="body1"
              customStyle={{ textAlign: { xs: "left", sm: "center" } }}
            >
              Web Rippler: Your Partner in Innovative Web Solutions
            </HeroSubHeader>
          </Box>
        </Container>
      </Grid>

      <Grid item xs={12}>
        <Container>
          <Box
            display="flex"
            flexDirection="row"
            justifyContent="center"
            sx={{ mt: 2 }}
          >
            <HeroSubHeader
              variant="body1"
              customStyle={{ textAlign: { xs: "left", sm: "center" } }}
            >
              Your privacy is important to us. We ensure that your data is
              handled with care.
            </HeroSubHeader>
          </Box>
        </Container>
      </Grid>

      <Grid item xs={12} sx={{ mt: 2 }}></Grid>
    </Grid>
  );
};
