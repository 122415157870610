import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import BrushIcon from "@mui/icons-material/Brush";
import ExploreIcon from "@mui/icons-material/Explore";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import WebIcon from "@mui/icons-material/Web";
import { Box, Grid } from "@mui/material";
import React from "react";
import { SlideInFromTopEffect } from "../../../BoilarPlateJs/MotionEffects";
import {
  CommonContainer,
  HeroSubHeader,
  HeroText,
  RouteToAIPortal,
  RouteToWebPortal,
  TopicContainer,
} from "../../CodingRipplerWidgets";
import { Services } from "./WhatWeDoWidgets";

export const WhatWeDo = ({ children, ...props }) => {
  const { deviceWithSmallHeight } = props;

  const WhatWeDoData = {
    header: (
      <>
        We design stunning{" "}
        <span className="theme-specfic-colored-text">websites </span> and{" "}
        <span className="theme-specfic-colored-text">innovative AI</span>{" "}
        solutions{" "}
      </>
    ),
    subHeader:
      "We create and launch websites and AI-enhanced products tailored for startups and companies.",
    customStyle: {},
    services: [
      {
        title: "Web Design & Development",
        description:
          "We design fast, user-friendly websites that not only look great but drive real business growth by focusing on user experience and performance.",
        icon: (
          <WebIcon
            onClick={() => {
              RouteToWebPortal("/web");
            }}
          />
        ),
      },
      {
        title: "Custom AI Solutions",
        description:
          "Leverage AI-powered automation and analytics to streamline workflows, optimize decision-making, and future-proof your business.",
        icon: (
          <TipsAndUpdatesIcon
            onClick={() => {
              RouteToAIPortal();
            }}
          />
        ),
      },
      {
        title: "Digital Product Design",
        description:
          "From concept to launch, we create digital products with intuitive designs that increase user satisfaction and drive retention.",
        icon: (
          <BrushIcon
            onClick={() => {
              RouteToWebPortal("/web");
            }}
          />
        ),
      },
      {
        title: "Brand Identity & Strategy",
        description:
          "Build a strong brand that resonates with your audience through our innovative identity and strategic branding solutions.",
        icon: (
          <ExploreIcon
            onClick={() => {
              RouteToWebPortal("/web");
            }}
          />
        ),
      },
      {
        title: "Conversion Optimization",
        description:
          "Increase your digital presence’s effectiveness with strategies that convert visitors into loyal customers, tailored to your business goals.",
        icon: (
          <TrendingUpIcon
            onClick={() => {
              RouteToWebPortal("/web");
            }}
          />
        ),
      },
      {
        title: "Business Process Automation",
        description:
          "Enhance operational efficiency by automating repetitive tasks, freeing up resources for strategic business initiatives.",
        icon: (
          <AutoModeIcon
            onClick={() => {
              RouteToAIPortal();
            }}
          />
        ),
      },
    ],
  };

  return (
    <Grid container>
      <Grid item xs={12}>
        <TopicContainer topicText="What We Do">
          <AutoAwesomeIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <HeroText variant="h4">{WhatWeDoData.header}</HeroText>
          <Box m={2} />
          <HeroSubHeader>{WhatWeDoData.subHeader}</HeroSubHeader>
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <Grid container>
            <Grid
              item
              lg={1}
              sx={{
                display: { xs: "none", lg: "block" },
              }}
            />
            <Grid item xs={12} lg={10}>
              <CommonContainer>
                <Services WhatWeDoData={WhatWeDoData.services} />
              </CommonContainer>
            </Grid>
            <Grid
              item
              lg={1}
              sx={{
                display: { xs: "none", lg: "block" },
              }}
            />
          </Grid>
        </SlideInFromTopEffect>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          minHeight: { xs: "12vh", sm: "0vh" },
          display: {
            xs: deviceWithSmallHeight ? "none" : "block",
            sm: "none",
          },
        }}
      >
        {/* <SlideInFromTopEffect>
          <AdditionalInfo
            additionalInfo={additionalInfo}
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
          />
        </SlideInFromTopEffect> */}
      </Grid>
    </Grid>
  );
};
