import React from "react";
import { Box } from "@mui/system";
import { TypographyOxygen } from "../../BoilarPlateJs/TypographyStyled";
import { Fab, Grid, useTheme } from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { CardViewContainerWithImageV2 } from "../../BoilarPlateJs/CardViewContainer";
import { RatingAndDiscount } from "../RatingsAndOffers";
import { TripHeadingAndDescription } from "../TripHeadingAndDescription";
import { TourAndTravelCardActions } from "../CardActions";
import { useMediaQuery } from "@mui/material";

export const DealsAndDiscountsPage = ({ dealsAndDiscountsData }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isBetweenMiddleAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const isExtraSmallScreen = useMediaQuery("(max-width:380px)");
  var headingFontSize = "3.0rem";
  var subHeadingFontSize = "1.0rem";
  if (isSmallScreen) {
    if (isExtraSmallScreen) {
      headingFontSize = "2.0rem";
      subHeadingFontSize = "1.0rem";
    }
  }

  const viewMore = () => {
    window.location.href = "/Tour/DealsAndDiscount";
  };

  // #20B2AA
  return (
    <Grid container id="dealsAndDiscountsPage" bgcolor={"#429cb5"} pt={5}>
      <Grid item xs={12} pl={isSmallScreen ? 2 : 4} pt={isSmallScreen ? 2 : 4}>
        <TypographyOxygen variant="h3" fontSize={headingFontSize}>
          {" "}
          DEALS AND DISCOUNTS
        </TypographyOxygen>
      </Grid>
      <Grid
        item
        xs={12}
        md={10}
        pl={
          isSmallScreen || isMediumScreen || isBetweenMiddleAndLargeScreen
            ? 2
            : 4
        }
      >
        <TypographyOxygen variant="body1" fontSize={subHeadingFontSize}>
          {" "}
          Time is running out to seize these fantastic Deals and Discounts! Take
          advantage of dynamic savings now—don't wait, make your purchase today.
        </TypographyOxygen>
      </Grid>
      <Grid
        item
        xs={12}
        md={2}
        pl={
          isSmallScreen || isMediumScreen || isBetweenMiddleAndLargeScreen
            ? 2
            : isMediumScreen
            ? 4
            : "auto"
        }
      >
        <Fab variant="extended" size="large" color="primary" onClick={viewMore}>
          VIEW MORE
          <ArrowRightIcon />
        </Fab>
      </Grid>

      {isSmallScreen ? (
        dealsAndDiscountsData.slice(0, 4).map((deal, index) => (
          <Grid item xs={12} key={index}>
            <CardViewContainerWithImageV2
              data={deal}
              parentCardCustomStyles={{}}
              anyRelativeElement={
                <RatingAndDiscount
                  data={deal}
                  width={"75vw"}
                ></RatingAndDiscount>
              }
              cardMediaHeight={"200px"}
              cardContentsCustomStyles={{
                height: "150px",
                borderTop: "2px solid",
                borderColor: theme.palette.primary.main,
              }}
              cardContents={
                <TripHeadingAndDescription
                  data={deal}
                  maxLengthForDescription={60}
                />
              }
              cardAction={<TourAndTravelCardActions data={deal} />}
            />
          </Grid>
        ))
      ) : isMediumScreen || isBetweenMiddleAndLargeScreen ? (
        dealsAndDiscountsData.slice(0, 4).map((deal, index) => (
          <Grid item xs={6} key={index}>
            <CardViewContainerWithImageV2
              data={deal}
              parentCardCustomStyles={{}}
              anyRelativeElement={
                <RatingAndDiscount
                  data={deal}
                  width={"39vw"}
                ></RatingAndDiscount>
              }
              cardMediaHeight={"200px"}
              cardContentsCustomStyles={{
                height: "150px",
                borderTop: "2px solid",
                borderColor: theme.palette.primary.main,
              }}
              cardContents={
                <TripHeadingAndDescription
                  data={deal}
                  maxLengthForDescription={60}
                />
              }
              cardAction={<TourAndTravelCardActions data={deal} />}
            />
          </Grid>
        ))
      ) : (
        <Grid item xs={12} pl={3} pr={3}>
          <Box display={"flex"}>
            {dealsAndDiscountsData.slice(0, 4).map((deal, index) => (
              <CardViewContainerWithImageV2
                key={index}
                data={deal}
                parentCardCustomStyles={{ width: "25vw" }}
                anyRelativeElement={
                  <RatingAndDiscount
                    data={deal}
                    width={"18vw"}
                  ></RatingAndDiscount>
                }
                cardMediaHeight={"200px"}
                cardContentsCustomStyles={{
                  height: "150px",
                  borderTop: "2px solid",
                  borderColor: theme.palette.primary.main,
                }}
                cardContents={
                  <TripHeadingAndDescription
                    data={deal}
                    maxLengthForDescription={60}
                  />
                }
                cardAction={<TourAndTravelCardActions data={deal} />}
              />
            ))}
          </Box>
        </Grid>
      )}
    </Grid>
  );
};
