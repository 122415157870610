import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { styled } from "@mui/system";

const CustomBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
}));

export const AboutUs = () => {
  return (
    <CustomBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h4">About Us</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="body1">
            We are a small agency of talented designers & developers.
          </Typography>
        </Grid>
      </Grid>
    </CustomBox>
  );
};
