import { Box, Card, CardContent, IconButton, Typography } from "@mui/material";
import { useState } from "react";

export const FAQsHeader = (children, ...props) => {
  return (
    <Box display="flex" justifyContent="center" gap={2}>
      <Box>
        <Typography
          variant="h3"
          fontWeight="bold"
          className="typography-custom-css"
          sx={{
            textAlign: "center",
          }}
        >
          FAQs
        </Typography>
      </Box>
    </Box>
  );
};

export const FAQsSubHeaderHome = (children, ...props) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight="normal"
        className="sub-header typography-custom-css"
        sx={{
          textAlign: { xs: "center", lg: "center" },
        }}
      >
        Can’t find the answer here?{" "}
        <span className="theme-specfic-colored-text">
          Visit our help center.
        </span>
      </Typography>
    </Box>
  );
};

export const FAQsCard = ({ children, question, answer, ...props }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { openingIcon, closingIcon } = props;

  return (
    <Card onClick={() => setIsOpen(!isOpen)} style={{ marginBottom: "1rem" }}>
      <CardContent>
        <Box
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h5"
            fontWeight="bold"
            className="typography-custom-css"
            sx={{}}
          >
            {question}
          </Typography>
          <IconButton
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            {isOpen ? closingIcon : openingIcon}
          </IconButton>
        </Box>
        {isOpen && (
          <Typography
            marginTop={2}
            variant="h6"
            className="sub-header typography-custom-css"
            lineHeight={2}
          >
            {answer}
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
