import { TabsContainer } from "./TabsContainer";
import { DatabaseTabDetails } from "./TabWidgets/DatabaseTabDetails";

export const DatabaseTabPanel = (props) => {
  const { value } = props;

  return (
    <TabsContainer value={value} index={2}>
      <DatabaseTabDetails />
    </TabsContainer>
  );
};
