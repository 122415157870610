import { Card, CardContent } from "@mui/material";
import { Box } from "@mui/system";
import { RequestDemo, TryForFree } from "./AIHome/HomePageWidgets/Buttons";
import {
  IntroHeaderHomeV2,
  IntroSubHeaderHome,
  IntroSubHeadingButtons,
} from "./AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  ResponsiveBoxCenteredChildren,
} from "./AIUtil/AIUtils";

import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { CommonContainer } from "../CodingRippler/CodingRipplerWidgets";
import { AIAgentsHome } from "./AIAgents/AIAgentsHome";
import { AIFunctionsHome } from "./AIFunctions/AIFunctionsHome";
import { AIHome } from "./AIHome/AIHome";
import { AINavigation } from "./AIHome/AINavigation/AINavigation";
import { FooterWidgets } from "./AIHome/Footers/FooterWidgets";
import { ProductsHome } from "./AIProducts/ProductsHome";
import { PricingHome } from "./Pricing/PricingHome";

export const AI = ({ children, ...props }) => {
  //  const { toggleTheme } = useThemeMode();

  const theme = useTheme();

  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("lg"));
  const deviceWithSmallHeight = useMediaQuery("(max-height: 750px)");
  const isPhoneOrTab = isPhone || isTab;

  let { logoPath } = props;

  // useEffect(() => {
  //   toggleTheme("light");
  // }, []);

  return (
    <Grid id="AI" container className="white-linear-gradient-background">
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <AINavigation
          isPhone={isPhone}
          isTab={isTab}
          deviceWithSmallHeight={deviceWithSmallHeight}
          isPhoneOrTab={isPhoneOrTab}
          logoPath={logoPath}
        />
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          minHeight: { xs: "5vh", lg: "10vh" },
        }}
      ></Grid>

      <Routes>
        <Route
          path="/*"
          element={
            <AIHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/products/*"
          element={
            <ProductsHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/functions/*"
          element={
            <AIFunctionsHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/agents/*"
          element={
            <AIAgentsHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/pricing"
          element={
            <PricingHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
      </Routes>

      {/* Build your AI Hub today   */}
      <Grid item xs={12} sx={{ mb: 10 }}>
        <AICommonBoxContainer>
          <CommonContainer>
            <Card
              sx={{
                p: 2,
                boxShadow: "0px 0px 6px 3px rgba(0, 0, 0, 0.2)", // Custom shadow
                borderRadius: "24px",
                minHeight: {
                  xs: deviceWithSmallHeight ? "100vh" : "75vh",
                  sm: "50vh",
                  lg: "50vh",
                },
                height: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
              }}
              position="relative"
            >
              <CardContent>
                <IntroHeaderHomeV2 />
                <Box m={2} />
                <IntroSubHeaderHome />

                <Box m={2} />

                <ResponsiveBoxCenteredChildren>
                  <TryForFree />
                  <Box m={2} />
                  <RequestDemo />
                </ResponsiveBoxCenteredChildren>
                <Box m={2} />
                <IntroSubHeadingButtons />
              </CardContent>
            </Card>
          </CommonContainer>
        </AICommonBoxContainer>
      </Grid>

      <Grid
        item
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
        sx={{
          backgroundColor: theme.palette.background.default,
        }}
      >
        <Box
          sx={{
            minHeight: "50vh",
            borderTop: "1px",
            borderBottom: "1px",
            padding: "16px",
            width: "100%",
            boxShadow: "0px 0px 6px 3px rgba(0, 0, 0, 0.2)", // Custom shadow
          }}
        >
          <AICommonBoxContainer>
            <FooterWidgets
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
              logoPath={logoPath}
            />
          </AICommonBoxContainer>
        </Box>
      </Grid>
    </Grid>
  );
};
