import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import { Box, Button, useTheme } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { getButtonStyle } from "../CodingRipplerWidgets";

export const Parallax = ({ children, ...props }) => {
  const [offsetY, setOffsetY] = useState(0);
  const parallaxRef = useRef(null);

  const { isPhone, isTab } = props;

  let translateY = "10";
  if (isPhone) {
    translateY = "125";
  } else if (isTab) {
    translateY = "27.5";
  }

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getParallaxTransform = () => {
    if (offsetY <= 200) {
      return "translateY(0)";
    }
    const translateY = Math.min(
      ((offsetY - 200) / (parallaxRef.current?.offsetHeight || 1)) * 100,
      25
    );

    // const translateY = offsetY * 0.1;
    return `translateY(-${translateY}%)`;
  };

  const parallaxStyle = {
    transform: getParallaxTransform(),
    transition: "transform 0.1s ease-out",
    height: "100%", // Increase the height by 25%
    marginBottom: `-${translateY}%`, // Remove whitespace
  };

  return (
    <Box ref={parallaxRef} sx={parallaxStyle}>
      {children}
    </Box>
  );
};

export const AnimatedButton = ({ children, ...props }) => {
  const { text, clickHandler, customStyle } = props;
  const theme = useTheme();
  const buttonRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    setPosition({ x: clientX, y: clientY });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setPosition({ x: 0, y: 0 });
  };

  return (
    <Button
      variant="contained"
      ref={buttonRef}
      size="large"
      endIcon={<ArrowRightAltOutlinedIcon />}
      className={isHovered ? "button-animation-hover" : "button-animation"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        ...getButtonStyle({ isHovered, theme, buttonRef, position }),
        fontSize: "1rem",
        padding: "1rem 2rem",
        borderRadius: "14px",
        ...customStyle,
      }}
      onClick={clickHandler}
    >
      {text}
    </Button>
  );
};

export const ButtonWithPrefix = ({ children, ...props }) => {
  const { text, clickHandler, customStyle, prefix } = props;
  const theme = useTheme();
  const buttonRef = useRef(null);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    setPosition({ x: clientX, y: clientY });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setPosition({ x: 0, y: 0 });
  };

  return (
    <Button
      variant="contained"
      ref={buttonRef}
      size="large"
      startIcon={prefix}
      // endIcon={<ArrowRightAltOutlinedIcon />}
      className={isHovered ? "button-animation-hover" : "button-animation"}
      onMouseEnter={() => setIsHovered(true)}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      style={{
        ...getButtonStyle({ isHovered, theme, buttonRef, position }),
        fontSize: "1rem",
        padding: "1rem 2rem",
        borderRadius: "14px",
        ...customStyle,
      }}
      onClick={clickHandler}
    >
      {text}
    </Button>
  );
};

export const ParallaxWithCustomSpeed = ({
  children,
  speed = 0.1,
  ...props
}) => {
  const [offsetY, setOffsetY] = useState(0);
  const parallaxRef = useRef(null);

  const handleScroll = () => {
    setOffsetY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const getParallaxTransform = () => {
    const translateY = offsetY * speed;
    return `translateY(-${translateY}px)`;
  };

  const parallaxStyle = {
    transform: getParallaxTransform(),
    transition: "transform 0.1s ease-out",
    height: "100%", // Increase the height by 25%
    //    marginBottom: `-${translateY}%`, // Remove whitespace
  };

  return (
    <Box ref={parallaxRef} sx={parallaxStyle}>
      {children}
    </Box>
  );
};
