import { KeyboardArrowUp } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import "../../../AIHome/globalAI.css";

export const FunctionButton = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const handleClick = (context) => {
    window.location.href = context;
  };

  const shortcuts = [
    {
      name: "Sales",
      context: `/ai/functions/sales`,
      icon: <TrendingUpIcon />,
    },
    {
      name: "Marketing",
      context: `/ai/functions/marketing`,
      icon: <LeaderboardIcon />,
    },
    {
      name: "Customer Support",
      context: `/ai/functions/customer-support`,
      icon: <SupportAgentIcon />,
    },
    {
      name: "Research",
      context: `/ai/functions/research`,
      icon: <SearchIcon />,
    },
    {
      name: "Operations",
      context: `/ai/functions/operations`,
      icon: <SettingsIcon />,
    },
  ];

  let { activeDropdown, handleButtonClick } = props;

  return (
    <Box
      sx={{
        [theme.breakpoints.down("lg")]: { position: "relative" },
      }}
    >
      {isLargeScreen && (
        <Button
          variant="text"
          style={{ color: theme.palette.text.primary }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setIsHovered(!isHovered)}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              fontFamily="Inter, sans-serif" // Add your desired font family here
              fontSize="1rem"
            >
              Function{" "}
            </Typography>{" "}
            {!isHovered && (
              <KeyboardArrowDownIcon onClick={() => setIsHovered(!isHovered)} />
            )}
            {isHovered && (
              <KeyboardArrowUp onClick={() => setIsHovered(!isHovered)} />
            )}
          </Box>
        </Button>
      )}

      {!isLargeScreen && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button
            variant="text"
            style={{ color: theme.palette.text.primary }}
            onMouseEnter={() => {
              // setIsHovered(true);
              handleButtonClick(1);
            }}
            onMouseLeave={() => {
              // setIsHovered(false);
              handleButtonClick(null);
            }}
            onClick={() => {
              // setIsHovered(!isHovered);
              handleButtonClick(1);
            }}
          >
            <Typography
              variant="h6"
              fontFamily="Inter, sans-serif" // Add your desired font family here
              fontSize="1rem"
            >
              Function{" "}
            </Typography>{" "}
          </Button>
          {activeDropdown === 1 ? (
            <KeyboardArrowUp
              onClick={() => {
                // setIsHovered(!isHovered);
                handleButtonClick(null);
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={() => {
                // setIsHovered(!isHovered);
                handleButtonClick(1);
              }}
            />
          )}{" "}
        </Box>
      )}

      {isLargeScreen && isHovered && (
        <Card
          sx={{
            position: "absolute",
            zIndex: 3,
            [theme.breakpoints.down("lg")]: { width: "100%" },
            [theme.breakpoints.up("lg")]: { width: "25%" },
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} // Hide the card when the mouse leaves it
        >
          {shortcuts.map((shortcut, index) => (
            <CardContent
              className="customHoverEffect"
              key={index}
              onClick={() => {
                handleClick(shortcut.context);
              }}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                {shortcut.icon}
                <Box sx={{ ml: "10px" }}>
                  <Typography
                    variant="body2"
                    fontFamily="Inter, sans-serif" // Add your desired font family here
                  >
                    {shortcut.name}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          ))}
        </Card>
      )}

      {!isLargeScreen && activeDropdown === 1 && (
        <Card
          sx={{
            position: "absolute",
            [theme.breakpoints.down("lg")]: {
              width: "100%",
              position: "relative",
            },
            [theme.breakpoints.up("lg")]: {
              width: "25%",
              position: "absolute",
            },
            zIndex: 3,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} // Hide the card when the mouse leaves it
        >
          {shortcuts.map((shortcut, index) => (
            <CardContent
              className="customHoverEffect"
              onClick={() => {
                handleClick(shortcut.context);
              }}
              key={index}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                {shortcut.icon}
                <Box sx={{ ml: "10px" }}>
                  <Typography
                    variant="body2"
                    fontFamily="Inter, sans-serif" // Add your desired font family here
                  >
                    {shortcut.name}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          ))}
        </Card>
      )}
    </Box>
  );
};
