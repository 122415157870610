import { CardContent } from "@mui/material";
import React from "react";
import { About } from "./About";
import { ContactUs } from "./ContactUs";
import { HomeButton } from "./HomeButton";
import { Services } from "./Services";

export const ButtonContainers = ({ children, ...props }) => {
  const { customTextColor } = props;

  return (
    <>
      <CardContent sx={{ flexGrow: 1, maxWidth: "100%" }}>
        <HomeButton customTextColor={customTextColor} />
      </CardContent>

      <CardContent sx={{ flexGrow: 1, maxWidth: "100%" }}>
        <Services customTextColor={customTextColor} />
      </CardContent>
      {/* <CardContent sx={{ ml: "-1rem", flexGrow: 1, maxWidth: "100%" }}>
        <Portfolio customTextColor={customTextColor} />
      </CardContent> */}
      <CardContent sx={{ flexGrow: 1, maxWidth: "100%" }}>
        <About customTextColor={customTextColor} context="/about" />
      </CardContent>

      {/* <CardContent sx={{ flexGrow: 1, maxWidth: "100%" }}>
        <CareerButton customTextColor={customTextColor} />
      </CardContent> */}
      <CardContent sx={{ flexGrow: 1, maxWidth: "100%", position: "relative" }}>
        <ContactUs customTextColor={customTextColor} />
      </CardContent>
    </>
  );
};
