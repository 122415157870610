import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import {
  Documentation,
  ScheduleACall,
} from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  CommonHeaderTypography,
  ResponsiveBoxCenteredChildren,
} from "../../AIUtil/AIUtils";
import {
  AIApiKeyFeatures,
  ProductsPageHeader,
  TopicContainer,
  YourCodeContainer,
} from "../ProductsWidget";
import ArticleIcon from "@mui/icons-material/Article";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";

export const AIApiHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="Managed API">
          <ArticleIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                Trigger{" "}
                <span className="theme-specfic-colored-text">AI Agents </span>{" "}
                with Custom APIs{" "}
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveBoxCenteredChildren>
          <Documentation />
          <Box m={2} />
          <ScheduleACall />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      <Grid item xs={12}>
        <YourCodeContainer />
      </Grid>

      <Grid item xs={12}>
        <TopicContainer topicText="Key features">
          <ArticleIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                Unlock LLM Potential with AI Rippler
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <AICommonBoxContainer>
          <AIApiKeyFeatures />{" "}
        </AICommonBoxContainer>
      </Grid>
    </Grid>
  );
};
