import { Box, Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import {
  CardViewContainerWithImage,
  CardViewContainerWithImageV4,
} from "../../BoilarPlateJs/CardViewContainer";
import { SwiperSlideContainerV3 } from "../../BoilarPlateJs/SwiperSlide";
import { TypographyOxygen } from "../../BoilarPlateJs/TypographyStyled";

export const YouMayAlsoLikePage = ({ children, ...props }) => {
  const theme = useTheme();

  const { tourData } = props;

  const { noOfSlidesToShow } = props;
  const { individualTourInfo } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box>
          <TypographyOxygen fontSize={theme.tourAndTravel.headingTextSize}>
            YOU MAY ALSO LIKE
          </TypographyOxygen>
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SwiperSlideContainerV3 noOfSlidesToShow={noOfSlidesToShow}>
          {tourData.map(
            (tour, index) =>
              tour.pkId !== individualTourInfo.pkId && (
                <CardViewContainerWithImage key={tour.tourId} tourData={tour} />
              )
          )}
        </SwiperSlideContainerV3>
      </Grid>
    </Grid>
  );
};

export const YouMayAlsoLikePageReverse = ({ children, ...props }) => {
  const { sliceBy = 4 } = props;
  const { startIndex = 0 } = props;
  const { tourData } = props;
  const { individualTourInfo } = props;
  const filteredData = tourData.filter((tour) => {
    // Replace the condition below with your actual filter condition
    return tour.pkId !== individualTourInfo.pkId && tour.anyOffer;
  });

  return (
    <Grid container id="popularTours">
      <Grid item xs={12}></Grid>
      <Grid item xs={12}>
        {filteredData
          .slice(startIndex, sliceBy)
          .reverse()
          .map(
            (tour, index) =>
              tour.pkId !== individualTourInfo.pkId &&
              tour.anyOffer && (
                <Box pt={2}>
                  <CardViewContainerWithImageV4 key={index} tourData={tour} />
                </Box>
              )
          )}
      </Grid>
    </Grid>
  );
};
