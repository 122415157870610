import SearchIcon from "@mui/icons-material/Search";
import { Box, CardMedia, Grid } from "@mui/material";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { RequestDemo, TryForFree } from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  AICommonContainer,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
} from "../../AIUtil/AIUtils";
import {
  CardWithMediaTitleAndDescription,
  InLineMediaContainer,
  PageHeader,
  PageSubHeader,
  TopicContainer,
} from "../../AIWidgets";

export const AIResearchHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;
  const useCaseData = [
    {
      title: "Accelerate Data Discovery with Speed",
      description:
        "Get real-time access to data insights, speeding up your decision-making with AI-driven analysis.",
      image: "/airippler/features/no-coding-required.png",
      minHeight: "500px",
    },
    {
      title: "Uncover Game-Changing Insights",
      description:
        "AI uncovers patterns and trends in your data, giving you actionable insights that lead to smarter strategies.",
      image: "/airippler/features/templatesToGetStarted.png",
      minHeight: "500px",
    },
    {
      title: "Revolutionize Your Research with AI",
      description:
        "Streamline your research process with AI tools that deliver faster, more accurate findings at a fraction of the time.",
      image: "/airippler/features/teachYourProcess.png",
      minHeight: "500px",
    },
  ];

  const keyFeatureData = [
    {
      title: "AI-Driven Hallucination Control",
      description:
        "Maintain LLM accuracy with advanced controls: tailor instructions, design workflows, and fine-tune temperature settings for reliable and precise results.",
      image: "/airippler/features/IntegrateWithTechTrack.png",
    },
    {
      title: "Absolute Data Privacy, Guaranteed",
      description:
        "Your business data remains fully protected. It’s never used for model training, ensuring complete confidentiality and trust.",
      image: "/airippler/features/teachYourProcess.png",
    },
    {
      title: "Effortless Tool Integrations",
      description:
        "Connect seamlessly with HubSpot, Salesforce, Zapier, Gmail, and more to create unified, efficient workflows.",
      image: "/airippler/features/agent-skill.png",
    },
  ];

  const ListOfOurCustomers = [
    "/airippler/marquee/Webflow.svg",
    "/airippler/marquee/Async-Api.svg",
    "/airippler/marquee/Zapier.svg",
    "/airippler/marquee/Zoho.svg",
    "/airippler/marquee/Google-Gemini.svg",
    "/airippler/marquee/Hubspot.svg",
  ];

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="for Research">
          <SearchIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                <span className="theme-specfic-colored-text">
                  Accelerate Research with AI Insights
                </span>{" "}
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Unlock the full potential of your research with AI-powered tools
                that deliver fast, accurate insights.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveBoxCenteredChildren>
          <TryForFree />
          <Box m={2} />
          <RequestDemo />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      <Grid item xs={12}>
        <AICommonContainer>
          <CardMedia
            component="img"
            image="/airippler/home-page/homePage.png"
            alt="Description of the image"
            width="100%"
            height="100%"
          />
        </AICommonContainer>
      </Grid>

      {/* Advertise page   */}

      <Grid item xs={12}>
        <AICommonContainer>
          <CommonSubHeaderTypography customStyle={{ opacity: 0.8 }}>
            Companies of all sizes trust AI Rippler
          </CommonSubHeaderTypography>

          <Box m={2} />
          <InLineMediaContainer data={ListOfOurCustomers} />
        </AICommonContainer>
      </Grid>

      {/* Example Use Case Page  */}

      <Grid item xs={12}>
        <TopicContainer topicText="Example use cases">
          <SearchIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                From Data Collection to Actionable Insights in Record Time
              </CommonHeaderTypography>
            }
          />
        </CommonContainer>

        <Box m={2} />
        <AICommonBoxContainer>
          <CardWithMediaTitleAndDescription data={useCaseData} />
        </AICommonBoxContainer>
      </Grid>

      <Grid item xs={12}>
        <TopicContainer topicText="Key Features">
          <SearchIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Unleash AI to Unlock Actionable Research Insights Instantly
              </CommonHeaderTypography>
            }
          />
        </CommonContainer>
        <Box m={2} />

        <AICommonBoxContainer>
          <CardWithMediaTitleAndDescription data={keyFeatureData} />
        </AICommonBoxContainer>
      </Grid>
    </Grid>
  );
};
