import { keyframes } from "@emotion/react";
import FiberSmartRecordIcon from "@mui/icons-material/FiberSmartRecord";
import {
  Box,
  Card,
  CardMedia,
  Fab,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";

const spin = keyframes`
  0% { transform: rotate(0deg); }
  25% { color: #6056ff; }
  50% { transform: rotate(180deg); color: orange; }
  75% { color: orange; }
  100% { transform: rotate(360deg); green; }

`;

export const SupportTabAnimationComponent = (props) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));
  const isMidddleScreen = useMediaQuery(theme.breakpoints.down("lg"));

  let marginLeft = 50;

  if (isSmallScreen) {
    marginLeft = 0;
  } else if (isMidddleScreen) {
    marginLeft = 0;
  } else if (isExtraLargeScreen) {
    marginLeft = 50;
  }

  const [currentStep, setCurrentStep] = useState(1);

  const circleRefStep1 = useRef(null);
  const [step1CurrentColor, setStep1CurrentColor] = useState("#868a97");
  const [step1CurrentBgColor, setStep1CurrentBgColor] = useState("white");
  const circleRefStep2 = useRef(null);
  const [step2CurrentColor, setStep2CurrentColor] = useState("#868a97");
  const [step2CurrentBgColor, setStep2CurrentBgColor] = useState("white");

  const circleRefStep3 = useRef(null);
  const [step3CurrentColor, setStep3CurrentColor] = useState("#868a97");
  const [step3CurrentBgColor, setStep3CurrentBgColor] = useState("white");

  const circleRefStep4 = useRef(null);
  const [step4CurrentColor, setStep4CurrentColor] = useState("#868a97");
  const [step4CurrentBgColor, setStep4CurrentBgColor] = useState("white");

  const circleRefStep5 = useRef(null);
  const [step5CurrentColor, setStep5CurrentColor] = useState("#868a97");
  const [step5CurrentBgColor, setStep5CurrentBgColor] = useState("white");

  const circleRefStep6 = useRef(null);
  const [step6CurrentColor, setStep6CurrentColor] = useState("#868a97");
  const [step6CurrentBgColor, setStep6CurrentBgColor] = useState("white");

  const colors = useMemo(() => ["#868a97", "green", "#32bc5c1a", "white"], []);

  // const startStep1Animation = useCallback(() => {
  //   setStep1CurrentColor(colors[0]);
  //   setStep2CurrentColor(colors[0]);
  //   setStep3CurrentColor(colors[0]);
  //   setStep4CurrentColor(colors[0]);
  //   setStep5CurrentColor(colors[0]);
  //   setStep6CurrentColor(colors[0]);

  //   setStep1CurrentBgColor(colors[3]);
  //   setStep2CurrentBgColor(colors[3]);
  //   setStep3CurrentBgColor(colors[3]);
  //   setStep4CurrentBgColor(colors[3]);
  //   setStep5CurrentBgColor(colors[3]);
  //   setStep6CurrentBgColor(colors[3]);

  //   setCurrentStep(1);
  // }, [colors]);

  const startStep1Animation = useCallback(() => {
    setStep1CurrentColor(colors[0]);
    setStep2CurrentColor(colors[0]);
    setStep3CurrentColor(colors[0]);
    setStep4CurrentColor(colors[0]);
    setStep5CurrentColor(colors[0]);
    setStep6CurrentColor(colors[0]);

    setStep1CurrentBgColor(colors[3]);
    setStep2CurrentBgColor(colors[3]);
    setStep3CurrentBgColor(colors[3]);
    setStep4CurrentBgColor(colors[3]);
    setStep5CurrentBgColor(colors[3]);
    setStep6CurrentBgColor(colors[3]);

    setCurrentStep(1);
  }, [colors]);

  const stopStep1Animation = useCallback(() => {
    setStep1CurrentColor(colors[1]);
    setStep1CurrentBgColor(colors[2]);
    setCurrentStep(2);
  }, [colors]);

  const stopStep2Animation = useCallback(() => {
    setStep2CurrentBgColor(colors[2]);
    setStep2CurrentColor(colors[1]);
    setCurrentStep(3);
  }, [colors]);

  const stopStep3Animation = useCallback(() => {
    setStep3CurrentBgColor(colors[2]);
    setStep3CurrentColor(colors[1]);
    setCurrentStep(4);
  }, [colors]);

  const stopStep4Animation = useCallback(() => {
    setStep4CurrentBgColor(colors[2]);
    setStep4CurrentColor(colors[1]);
    setCurrentStep(5);
  }, [colors]);

  const stopStep5Animation = useCallback(() => {
    setStep5CurrentBgColor(colors[2]);
    setStep5CurrentColor(colors[1]);
    setCurrentStep(6);
  }, [colors]);

  const stopStep6Animation = useCallback(() => {
    setStep6CurrentBgColor(colors[2]);
    setStep6CurrentColor(colors[1]);
    setCurrentStep(null);

    setTimeout(() => {
      setCurrentStep(1);

      setStep1CurrentColor(colors[0]);
      setStep2CurrentColor(colors[0]);
      setStep3CurrentColor(colors[0]);
      setStep4CurrentColor(colors[0]);
      setStep5CurrentColor(colors[0]);
      setStep6CurrentColor(colors[0]);

      setStep1CurrentBgColor(colors[3]);
      setStep2CurrentBgColor(colors[3]);
      setStep3CurrentBgColor(colors[3]);
      setStep4CurrentBgColor(colors[3]);
      setStep5CurrentBgColor(colors[3]);
      setStep6CurrentBgColor(colors[3]);
    }, 1000);
  }, [colors]);

  useEffect(() => {
    const circleElementStep1 = circleRefStep1.current;
    const circleElementStep2 = circleRefStep2.current;
    const circleElementStep3 = circleRefStep3.current;
    const circleElementStep4 = circleRefStep4.current;
    const circleElementStep5 = circleRefStep5.current;
    const circleElementStep6 = circleRefStep6.current;

    if (circleElementStep1) {
      startStep1Animation();
      circleElementStep1.addEventListener(
        "animationiteration",
        stopStep1Animation
      );
    }

    if (circleElementStep2) {
      circleElementStep2.addEventListener(
        "animationiteration",
        stopStep2Animation
      );
    }

    if (circleElementStep3) {
      circleElementStep3.addEventListener(
        "animationiteration",
        stopStep3Animation
      );
    }

    if (circleElementStep4) {
      circleElementStep4.addEventListener(
        "animationiteration",
        stopStep4Animation
      );
    }

    if (circleElementStep5) {
      circleElementStep5.addEventListener(
        "animationiteration",
        stopStep5Animation
      );
    }

    if (circleElementStep6) {
      circleElementStep6.addEventListener(
        "animationiteration",
        stopStep6Animation
      );
    }

    return () => {
      if (circleElementStep1) {
        circleElementStep1.removeEventListener(
          "animationiteration",
          stopStep1Animation
        );
      }

      if (circleElementStep2) {
        circleElementStep2.removeEventListener(
          "animationiteration",
          stopStep2Animation
        );
      }

      if (circleElementStep3) {
        circleElementStep3.removeEventListener(
          "animationiteration",
          stopStep3Animation
        );
      }

      if (circleElementStep4) {
        circleElementStep4.removeEventListener(
          "animationiteration",
          stopStep4Animation
        );
      }

      if (circleElementStep5) {
        circleElementStep5.removeEventListener(
          "animationiteration",
          stopStep5Animation
        );
      }

      if (circleElementStep6) {
        circleElementStep6.removeEventListener(
          "animationiteration",
          stopStep6Animation
        );
      }
    };
  }, [
    startStep1Animation,
    stopStep1Animation,
    stopStep2Animation,
    stopStep3Animation,
    stopStep4Animation,
    stopStep5Animation,
    stopStep6Animation,
  ]);

  return (
    <Box
      sx={{
        position: "relative",
        overflow: { xs: "hidden", lg: "visible" },
        "&::before": {
          content: '""',
          position: "absolute",
          top: { xs: "0%", lg: "0%" },
          left: { xs: "20px", lg: "0%" },
          transform: {
            xs: "translate(0%, 0%)",
            lg: "translate(-30%, 2%)",
            xl: "translate(-60%, 2%)",
          },
          width: { xs: "1%", lg: "100%" },
          height: { xs: "100%", lg: "100%" },
          backgroundImage: {
            xs: "url(/airippler/sales-tab-icons/dash-line-small.svg)",
            lg: "url(/airippler/sales-tab-icons/dash-line-large.svg)",
          },
          backgroundSize: { xs: "cover", lg: "cover" },
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          opacity: 0.2,
        },
      }}
    >
      <Grid container spacing={10}>
        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            ml={`${1 * marginLeft}px`}
          >
            <Card
              sx={{
                marginRight: "20px",
                borderRadius: "4px",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 1,
                zIndex: 1,
              }}
            >
              <CardMedia
                component="img"
                image="/airippler/sales-tab-icons/gmail-2.svg"
                alt="Description of the image"
                height={"50%"}
                width={"50%"}
                sx={{ objectFit: "contain" }}
              />
            </Card>
            <Box
              sx={{
                opacity: 1,
                zIndex: 1,
                backgroundColor: theme.palette.background.default, // Use theme's background color
              }}
            >
              <Fab
                variant="extended"
                size="small"
                sx={{
                  color: step1CurrentColor,
                  backgroundColor: step1CurrentBgColor,
                }}
                p={10}
                pointerEvents="none"
              >
                <FiberSmartRecordIcon
                  ref={circleRefStep1}
                  sx={{
                    fontSize: "12px", // specify the desired size
                    color: step1CurrentColor,
                    animation:
                      currentStep === 1 ? `${spin} 2s linear infinite` : "none",
                  }}
                />
                <Typography
                  ml={1}
                  variant="body2"
                  className="typography-custom-css"
                  sx={{ color: step1CurrentColor }}
                >
                  Receive an enquiry
                </Typography>
              </Fab>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            ml={`${2 * marginLeft}px`}
            className="typography-custom-css"
          >
            <Card
              sx={{
                marginRight: "20px",
                borderRadius: "4px",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 1,
                zIndex: 1,
              }}
            >
              <CardMedia
                component="img"
                image="/airippler/support-tab-icons/filter.svg"
                alt="Description of the image"
                height={"50%"}
                width={"50%"}
                sx={{ objectFit: "contain" }}
              />
            </Card>

            <Box
              sx={{
                opacity: 1,
                zIndex: 1,
                backgroundColor: theme.palette.background.default, // Use theme's background color
              }}
            >
              <Fab
                variant="extended"
                size="small"
                sx={{
                  color: step2CurrentColor,
                  backgroundColor: step2CurrentBgColor,
                }}
                p={10}
                pointerEvents="none"
              >
                <FiberSmartRecordIcon
                  ref={circleRefStep2}
                  sx={{
                    fontSize: "12px", // specify the desired size
                    color: step2CurrentColor,
                    animation:
                      currentStep === 2 ? `${spin} 2s linear infinite` : "none",
                  }}
                />
                <Typography
                  ml={1}
                  variant="body2"
                  sx={{ color: step2CurrentColor }}
                  className="typography-custom-css"
                >
                  Classify request
                </Typography>
              </Fab>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            ml={`${3 * marginLeft}px`}
          >
            <Card
              sx={{
                marginRight: "20px",
                borderRadius: "4px",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 1,
                zIndex: 1,
              }}
            >
              <CardMedia
                component="img"
                image="/airippler/support-tab-icons/slack.svg"
                alt="Description of the image"
                height={"50%"}
                width={"50%"}
                sx={{ objectFit: "contain" }}
              />
            </Card>
            <Box
              sx={{
                opacity: 1,
                zIndex: 1,
                backgroundColor: theme.palette.background.default, // Use theme's background color
              }}
            >
              <Fab
                variant="extended"
                size="small"
                sx={{
                  color: step3CurrentColor,
                  backgroundColor: step3CurrentBgColor,
                }}
                p={10}
                pointerEvents="none"
              >
                <FiberSmartRecordIcon
                  ref={circleRefStep3}
                  sx={{
                    fontSize: "12px", // specify the desired size
                    color: step3CurrentColor,
                    animation:
                      currentStep === 3 ? `${spin} 2s linear infinite` : "none",
                  }}
                />
                <Typography
                  ml={1}
                  variant="body2"
                  sx={{ color: step3CurrentColor }}
                  className="typography-custom-css"
                >
                  Triage and response
                </Typography>
              </Fab>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            ml={`${3 * marginLeft}px`}
          >
            <Card
              sx={{
                marginRight: "20px",
                borderRadius: "4px",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 1,
                zIndex: 1,
              }}
            >
              <CardMedia
                component="img"
                image="/airippler/sales-tab-icons/linkedIn.svg"
                alt="Description of the image"
                height={"50%"}
                width={"50%"}
                sx={{ objectFit: "contain" }}
              />
            </Card>

            <Box
              sx={{
                opacity: 1,
                zIndex: 1,
                backgroundColor: theme.palette.background.default, // Use theme's background color
              }}
            >
              <Fab
                variant="extended"
                size="small"
                sx={{
                  color: step4CurrentColor,
                  backgroundColor: step4CurrentBgColor,
                }}
                p={10}
                pointerEvents="none"
              >
                <FiberSmartRecordIcon
                  ref={circleRefStep4}
                  sx={{
                    fontSize: "12px", // specify the desired size
                    color: step4CurrentColor,
                    animation:
                      currentStep === 4 ? `${spin} 2s linear infinite` : "none",
                  }}
                />
                <Typography
                  variant="body2"
                  ml={1}
                  sx={{ color: step4CurrentColor }}
                  className="typography-custom-css"
                >
                  Identify opportunity
                </Typography>
              </Fab>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            ml={`${2 * marginLeft}px`}
          >
            <Card
              sx={{
                marginRight: "20px",
                borderRadius: "4px",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 1,
                zIndex: 1,
              }}
            >
              <CardMedia
                component="img"
                image="/airippler/support-tab-icons/summary.svg"
                alt="Description of the image"
                height={"50%"}
                width={"50%"}
                sx={{ objectFit: "contain" }}
              />
            </Card>

            <Box
              sx={{
                opacity: 1,
                zIndex: 1,
                backgroundColor: theme.palette.background.default, // Use theme's background color
              }}
            >
              <Fab
                variant="extended"
                size="small"
                sx={{
                  color: step5CurrentColor,
                  backgroundColor: step5CurrentBgColor,
                }}
                p={10}
                pointerEvents="none"
              >
                <FiberSmartRecordIcon
                  ref={circleRefStep5}
                  sx={{
                    fontSize: "12px", // specify the desired size
                    color: step5CurrentColor,
                    animation:
                      currentStep === 5 ? `${spin} 2s linear infinite` : "none",
                  }}
                />
                <Typography
                  ml={1}
                  variant="body2"
                  sx={{ color: step5CurrentColor }}
                  className="typography-custom-css"
                >
                  Populate summary
                </Typography>
              </Fab>
            </Box>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box
            display="flex"
            justifyContent="flex-start"
            alignItems="center"
            ml={`${1 * marginLeft}px`}
          >
            <Card
              sx={{
                marginRight: "20px",
                borderRadius: "4px",
                width: "40px",
                height: "40px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                opacity: 1,
                zIndex: 1,
              }}
            >
              <CardMedia
                component="img"
                image="/airippler/support-tab-icons/slack.svg"
                alt="Description of the image"
                height={"50%"}
                width={"50%"}
                sx={{ objectFit: "contain" }}
              />
            </Card>

            <Box
              sx={{
                opacity: 1,
                zIndex: 1,
                backgroundColor: theme.palette.background.default, // Use theme's background color
              }}
            >
              <Fab
                variant="extended"
                size="small"
                sx={{
                  color: step6CurrentColor,
                  backgroundColor: step6CurrentBgColor,
                }}
                p={10}
                pointerEvents="none"
              >
                <FiberSmartRecordIcon
                  ref={circleRefStep6}
                  sx={{
                    fontSize: "12px", // specify the desired size
                    color: step6CurrentColor,
                    animation:
                      currentStep === 6 ? `${spin} 2s linear infinite` : "none",
                  }}
                />
                <Typography
                  ml={1}
                  variant="body2"
                  sx={{ color: step6CurrentColor }}
                  className="typography-custom-css"
                >
                  Escalate{" "}
                </Typography>
              </Fab>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
