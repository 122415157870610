import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import * as React from "react";

export default function DropDownMenu({ buttonText, ...props }) {
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const clickHome = (event) => {
    //   setOpen(false);
    setOpenTours(false);
    setOpenBookings(false);
    setOpenPages(false);
    setOpenBlogs(false);
    window.location.href = "/Tour";
  };

  const handlHomeHover = (event) => {
    setOpenTours(false);
    setOpenBookings(false);
    setOpenPages(false);
    setOpenBlogs(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      //      setOpen(false);
      setOpenTours(false);
      setOpenBookings(false);
      setOpenPages(false);
      setOpenBlogs(false);
    } else if (event.key === "Escape") {
      //      setOpen(false);
      setOpenTours(false);
      setOpenBookings(false);
      setOpenPages(false);
      setOpenBlogs(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  // const prevOpen = React.useRef(open);
  // React.useEffect(() => {
  //   if (prevOpen.current === true && open === false) {
  //     //This can only be called when the state of open will change that to it became false
  //     // so this will bring the shift focus to new element which is anchorRef.current
  //     //      anchorRef.current?.focus();
  //   }

  //   prevOpen.current = open;
  // }, [open]);

  const [openTours, setOpenTours] = React.useState(false);
  const anchorToursRef = React.useRef(null);

  const handleToursToggle = () => {
    setOpenTours(true);
    //    setOpen(false);
    //    setOpenTours(false);
    setOpenBookings(false);
    setOpenPages(false);
    setOpenBlogs(false);
  };
  const handleToursClose = (event) => {
    if (
      anchorToursRef.current &&
      anchorToursRef.current.contains(event.target)
    ) {
      return;
    }

    setOpenTours(false);
  };

  const [openBookings, setOpenBookings] = React.useState(false);
  const anchorBookingsRef = React.useRef(null);

  const handleBookingsToggle = () => {
    setOpenBookings((previousBookingsOpen) => !previousBookingsOpen);
    setOpen(false);
    setOpenTours(false);
    //    setOpenBookings(false);
    setOpenPages(false);
    setOpenBlogs(false);
  };
  const handleBookingsClose = (event) => {
    if (
      anchorBookingsRef.current &&
      anchorBookingsRef.current.contains(event.target)
    ) {
      return;
    }

    setOpenBookings(false);
  };

  // Pages menu
  const [openPages, setOpenPages] = React.useState(false);
  const anchorPagesRef = React.useRef(null);

  const handlePagesToggle = () => {
    setOpenPages((previousPagesOpen) => !previousPagesOpen);
    setOpenBookings((previousBookingsOpen) => !previousBookingsOpen);
    setOpen(false);
    setOpenTours(false);
    setOpenBookings(false);
    //    setOpenPages(false);
    setOpenBlogs(false);
  };
  const handlePagesClose = (event) => {
    if (
      anchorPagesRef.current &&
      anchorPagesRef.current.contains(event.target)
    ) {
      return;
    }

    setOpenPages(false);
  };

  // React.useEffect(() => {
  //   if (previousPagesOpen.current === true && openPages === false) {
  //     //      anchorPagesRef.current?.focus();
  //   }
  //   previousPagesOpen.current = openPages;
  // }, [openPages]);

  // Blogs
  const [openBlogs, setOpenBlogs] = React.useState(false);
  const anchorBlogsRef = React.useRef(null);

  const handleBlogsToggle = () => {
    setOpenBlogs((previousBlogsOpen) => !previousBlogsOpen);
    setOpenBookings((previousBookingsOpen) => !previousBookingsOpen);
    setOpen(false);
    setOpenTours(false);
    setOpenBookings(false);
    setOpenPages(false);
    //    setOpenBlogs(false);
  };

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          color="inherit"
          ref={anchorRef}
          id="home-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={clickHome}
          onMouseOver={handlHomeHover}
        >
          Home
        </Button>
        {/* <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="home-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    onMouseLeave={handleClose}
                  >
                    <MenuItem onClick={handleClose}>Profile</MenuItem>
                    <MenuItem onClick={handleClose}>My account</MenuItem>
                    <MenuItem onClick={handleClose}>Logout</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper> */}

        <Button
          color="inherit"
          ref={anchorToursRef}
          id="tours-button"
          aria-controls={openTours ? "composition-menu" : undefined}
          aria-expanded={openTours ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToursToggle}
          onMouseOver={handleToursToggle}
        >
          Tours
        </Button>
        <Popper
          open={openTours}
          anchorEl={anchorToursRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleToursClose}>
                  <MenuList
                    autoFocusItem={openTours}
                    id="tours-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    onMouseLeave={handleToursClose}
                  >
                    <MenuItem onClick={handleToursClose}>Search Tours</MenuItem>
                    <MenuItem onClick={handleToursClose}>Single Tour</MenuItem>
                    <MenuItem onClick={handleToursClose}>Hot Deals</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Button
          color="inherit"
          ref={anchorBookingsRef}
          id="bookings-button"
          aria-controls={openBookings ? "composition-menu" : undefined}
          aria-expanded={openBookings ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleBookingsToggle}
          onMouseOver={handleBookingsToggle}
        >
          Bookings
        </Button>
        <Popper
          open={openBookings}
          anchorEl={anchorBookingsRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleBookingsClose}>
                  <MenuList
                    autoFocusItem={openBookings}
                    id="bookings-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    onMouseLeave={handleBookingsClose}
                  >
                    <MenuItem onClick={handleBookingsClose}>
                      Prices By Periods
                    </MenuItem>
                    <MenuItem onClick={handleBookingsClose}>
                      Optional Service
                    </MenuItem>
                    <MenuItem onClick={handleBookingsClose}>
                      Adult & Kids
                    </MenuItem>
                    <MenuItem onClick={handleBookingsClose}>Other</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        {/* Need to comeback here to fix the away focus issue  */}
        <Button
          color="inherit"
          ref={anchorPagesRef}
          id="pages-button"
          aria-controls={openPages ? "composition-menu" : undefined}
          aria-expanded={openPages ? "true" : undefined}
          aria-haspopup="true"
          onClick={handlePagesToggle}
          onMouseOver={handlePagesToggle}
        >
          Pages
        </Button>
        <Popper
          open={openPages}
          anchorEl={anchorPagesRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handlePagesClose}>
                  <MenuList
                    autoFocusItem={openPages}
                    id="pages-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    onMouseLeave={handlePagesClose}
                  >
                    <MenuItem onClick={handlePagesClose}>Faqs</MenuItem>
                    <MenuItem onClick={handlePagesClose}>Gallery</MenuItem>
                    <MenuItem onClick={handlePagesClose}>Contact Us</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>

        <Button
          color="inherit"
          ref={anchorBlogsRef}
          id="blogs-button"
          aria-controls={openBlogs ? "composition-menu" : undefined}
          aria-expanded={openBlogs ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleBlogsToggle}
          onMouseOver={handleBlogsToggle}
        >
          Blogs
        </Button>
        {/* <Popper
          open={openBlogs}
          anchorEl={anchorBlogsRef.current}
          role={undefined}
          placement="bottom-start"
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === "bottom-start" ? "left top" : "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleBlogsClose}>
                  <MenuList
                    autoFocusItem={openBlogs}
                    id="blogs-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                    onMouseLeave={handleBlogsClose}
                  >
                    <MenuItem onClick={handleBlogsClose}>FAQS</MenuItem>
                    <MenuItem onClick={handleBlogsClose}>GALLERY</MenuItem>
                    <MenuItem onClick={handleBlogsClose}>CONTACT US</MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper> */}
      </div>
    </Stack>
  );
}
