import React from "react";
import { Box } from "@mui/system";
import "../TourAndTravel/HomePages/tourandtravel.css";

//This function is used to truncate the string to a given length
export const truncate = ({ str, maxLength }) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + "...";
  } else {
    // if the string is already shorter than the maxLength then return null to handle the tooltip case
    // becuase if it is null we can display the non-truncated string
    return null;
  }
};

export const CircularImageContainer = ({ children, ...props }) => {
  const { customStyles } = props;

  const defaultStyle = {
    width: "66px",
    height: "66px",
    background: "#47a0ff",
    borderRadius: "100%",
    position: "relative",
    color: "#fff",
  };

  const mergeStyle = { ...defaultStyle, ...customStyles };
  return (
    <Box
      container
      display={"flex"}
      justifyContent={"center"}
      sx={{
        width: mergeStyle.width,
        height: mergeStyle.height,
        background: mergeStyle.background,
        borderRadius: mergeStyle.borderRadius,
        position: mergeStyle.position,
        color: mergeStyle.color,
        "&::before": {
          content: '""',
          display: "block",
          position: "absolute",
          width: "100%",
          height: "100%",
          border: "1px dashed #000",
          borderRadius: "100%",
        },
      }}
    >
      {children}
    </Box>
  );
};
