import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { StraigtLine } from "../../BoilarPlateJs/TitleDecorationBottomCenter";
import { TypographyOxygen } from "../../BoilarPlateJs/TypographyStyled";
import { SocialMediaConnector } from "./SocialMediaConnector";
import { TourHighLights } from "./TourHighLights";

export const TourDescription = ({ children, ...props }) => {
  const theme = useTheme();

  const { individualTourInfo } = props;

  return (
    <Grid container spacing={2} pt={4}>
      <Grid item sm={12}>
        <TypographyOxygen
          variant="body2"
          color="primary"
          fontWeight={"normal"}
          lineHeight={2}
          fontSize={"1.2rem"}
        >
          Embark on an unforgettable journey through the enchanting landscapes
          and vibrant cultures of {individualTourInfo.tripHeading}. This
          meticulously curated tour offers an immersive experience, combining
          historical landmarks, breathtaking natural scenery, and authentic
          local encounters.
        </TypographyOxygen>
        <StraigtLine
          customStyle={{
            borderBottom: "2px solid" + theme.palette.primary.main,
            pt: 5,
            pb: 2,
          }}
        />

        <TourHighLights individualTourInfo={individualTourInfo} />

        <StraigtLine
          customStyle={{
            borderBottom: "2px solid" + theme.palette.primary.main,
            pt: 5,
            pb: 2,
          }}
        />

        <TypographyOxygen
          variant="body2"
          color="primary"
          fontWeight={"normal"}
          lineHeight={2}
          fontSize={"1.2rem"}
        >
          Join us on this extraordinary tour and create memories that will last
          a lifetime. Discover the beauty, culture, and charm of{" "}
          {individualTourInfo.tripHeading} with our expert team guiding you
          every step of the way.
        </TypographyOxygen>

        <SocialMediaConnector />
      </Grid>
    </Grid>
  );
};
