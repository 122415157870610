import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { CardMedia, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import React from "react";
import { RouteToContactUs } from "../../CodingRipplerHome/ContactUs/ContactUs";
import { HeroSubHeader, HeroText } from "../../CodingRipplerWidgets";

export const WhyItMatters = ({ children, ...props }) => {
  const { deviceWithSmallHeight, pageContent } = props;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            lg: "block",
          },
        }}
      ></Grid>

      <Grid item xs={12} lg={10}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            lg={4}
            sx={{
              display: {
                xs: "block",
                lg: "block",
              },
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: { xs: "flex-start", lg: "center" },
                minHeight: {
                  xs: 175,
                  sm: 250,
                },
                height: {
                  xs: "25vh",
                  sm: deviceWithSmallHeight ? "30vh" : "20vh",
                  lg: "30vh",
                },
              }}
            >
              <CardMedia
                component="img"
                image={pageContent?.image}
                sx={{
                  width: "100%",
                  height: "100%",
                  objectFit: "contain",
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} lg={8} sx={{}}>
            <Box
              sx={{
                position: "relative",
                minHeight: {
                  xs: 400,
                  sm: 300,
                },
                height: {
                  xs: deviceWithSmallHeight ? "50vh" : "30vh",
                  sm: deviceWithSmallHeight ? "30vh" : "25vh",
                  lg: "30vh",
                },
                display: "flex",
                flexDirection: "column",
                justifyContent: { xs: "center", lg: "center" },
                alignItems: "center",
              }}
            >
              <HeroText
                variant="h5"
                // customStyle={{
                //   textAlign: "left",
                // }}
              >
                {pageContent.title}
              </HeroText>
              <Box m={2} />
              <HeroSubHeader
              // customStyle={{
              //   textAlign: "left",
              // }}
              >
                {pageContent.benefits}
              </HeroSubHeader>

              <Box m={2} />

              <Button
                variant="text"
                size="large"
                endIcon={<ArrowRightAltIcon />}
                style={{
                  fontSize: "1rem",
                  padding: "1rem 2rem",
                  borderRadius: "14px",
                  color: "#6056ff",
                }}
                onClick={RouteToContactUs}
              >
                Contact Us
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        md={1}
        lg={1}
        sx={{
          display: {
            xs: "none",
            md: "none",
            lg: "block",
          },
        }}
      ></Grid>
    </Grid>
  );
};
