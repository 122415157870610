import {
  Box,
  Breadcrumbs,
  Grid,
  Link as MuiLink,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Link as RdLink, useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import { CommonContainer } from "../../BoilarPlateJs/CommonTextContainer";
import { PageNotFound } from "../../BoilarPlateJs/PageNotFound";
import { StraigtLine } from "../../BoilarPlateJs/TitleDecorationBottomCenter";
import { TourDetails } from "./TourDetails";
import { TourNameAndRatingPage } from "./TourNameAndRatingPage";

export const IndividualTours = ({ children, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isBetweenMiddleAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );

  var backgroundHeight = "100vh";
  if (isSmallScreen) {
    backgroundHeight = "80vh";
  } else if (isMediumScreen) {
    backgroundHeight = "80vh";
  } else if (isBetweenMiddleAndLargeScreen) {
    backgroundHeight = "80vh";
  }

  const { tourData } = props;
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get("id");

  const filteredTourData = tourData
    ? tourData.filter((tour) => tour.pkId === Number(id))
    : [];

  // If filteredTourData is empty, display a 404 page
  if (tourData.length === 0 || filteredTourData.length === 0) {
    return (
      <>
        <PageNotFound redirect={"/Tour"} />
      </>
    );
  }

  return (
    <>
      <Box
        sx={{
          height: backgroundHeight,
          position: "relative", // This is necessary for the absolute positioning of the pseudo-element
          "&::before": {
            content: '""',
            backgroundImage: `url(${filteredTourData[0].imagePath})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            opacity: 0.4,
            position: "absolute",
            backgroundColor: "#429cb5",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          },
        }}
        textAlign={"center"}
      >
        <CommonContainer>
          <Grid>
            <Breadcrumbs aria-label="breadcrumb">
              <MuiLink
                component={RdLink}
                color="inherit"
                to="/Tour"
                underline="none" // Add this line to remove the underline
              >
                Home
              </MuiLink>
              <MuiLink
                component={RdLink}
                color="inherit"
                to={"/Tour/"}
                underline="none" // Add this line to remove the underline
              >
                Tours
              </MuiLink>
              <Typography color="textPrimary">
                {filteredTourData[0].tripHeading}
              </Typography>
            </Breadcrumbs>
          </Grid>

          <Box pt={3}>
            <StraigtLine />
          </Box>
        </CommonContainer>
        <TourNameAndRatingPage individualTourInfo={filteredTourData[0]} />
      </Box>

      <Link to="destination" spy={true} smooth={true} duration={500}>
        <TourDetails
          individualTourInfo={filteredTourData[0]}
          tourData={tourData}
        />
      </Link>
    </>
  );
};
