import React, { useState } from "react";
import "../CodingRippler.css";
import { Button, Box, Typography, Card, CardContent } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useTheme } from "@mui/material/styles";
import { KeyboardArrowUp } from "@mui/icons-material";

export const Portfolio = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  const { customTextColor } = props;

  const shortcuts = [
    {
      name: "Parallax",
      context: "/web/parallax",
    },
    {
      name: "Grid View",
      context: "/web/grid",
    },
    {
      name: "Masanory",
      context: "/web/masanory",
    },
    {
      name: "E-commerce",
      context: "/web/e-commerce",
    },
    {
      name: "Basic",
      context: "/web/basic",
    },
    {
      name: "Dashboard",
      context: "/web/dashboards",
    },
  ];

  const handleShortcutClick = (context) => {
    window.location.href = `${context}`;
  };

  return (
    <Box>
      <Button
        variant="text"
        style={{
          color: customTextColor ? "white" : theme.palette.text.primary,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setIsHovered(!isHovered)}
      >
        <Box display="flex" alignItems="center">
          <Typography
            variant="h6"
            fontFamily="Inter, sans-serif" // Add your desired font family here
            fontSize="1rem"
          >
            Portfolio
          </Typography>
          {!isHovered && (
            <KeyboardArrowDownIcon onClick={() => setIsHovered(!isHovered)} />
          )}

          {isHovered && (
            <KeyboardArrowUp onClick={() => setIsHovered(!isHovered)} />
          )}
        </Box>
      </Button>

      {isHovered && (
        <Card
          sx={{
            position: "absolute",
            [theme.breakpoints.down("lg")]: {
              width: "100%",
              position: "relative",
            },
            [theme.breakpoints.up("lg")]: {
              width: "15%",
              position: "absolute",
            },
            zIndex: 3,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} // Hide the card when the mouse leaves it
        >
          {shortcuts.map((shortcut, index) => (
            <CardContent
              key={index}
              className="customHoverEffect"
              onClick={() => {
                handleShortcutClick(shortcut.context);
              }}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                <Box sx={{ ml: "10px" }}>
                  <Typography variant="body2">{shortcut.name}</Typography>
                </Box>
              </Box>
            </CardContent>
          ))}
        </Card>
      )}
    </Box>
  );
};
