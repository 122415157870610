import { Box, Grid } from "@mui/material";
import { jarallax } from "jarallax";
import React, { useEffect, useRef } from "react";
import {
  CommonContainer,
  HeroSubHeader,
  HeroText,
  ProductCardContainer,
} from "../../CodingRipplerWidgets";
import { BasicAdditionalFeatures } from "./BasicAdditionalFeatures";
import { BasicService } from "./BasicService";

export const BasicHome = ({ children, ...props }) => {
  const {
    isPhoneorTab,
    isPhone,
    isTab,
    deviceWithSmallHeight,
    pageContent,
  } = props;

  const parallaxRef1 = useRef(null);

  useEffect(() => {
    jarallax(parallaxRef1.current, { speed: 0.2 });
  }, []);

  const gridContent = [
    {
      image: "/codingrippler/parallax/goby.jpg",
      title: "Goby",
      description: "Electric toothbrush, designed for you",
      buttonText: "Learn More",
    },
    {
      image: "/codingrippler/parallax/nike-shoes-1.jpg",
      title: "Nike",
      description: "Just do it",
      buttonText: "Learn More",
    },
    {
      image: "/codingrippler/parallax/curology.jpg",
      title: "Curology",
      description: "Curology custom skincare",
      buttonText: "Learn More",
    },
    {
      image: "/codingrippler/parallax/nike-shoes-2.jpg",
      title: "Nike",
      description: "Just do it",
      buttonText: "Learn More",
    },
  ];

  return (
    <>
      <Box
        ref={parallaxRef1}
        sx={{
          backgroundImage: `url(${"/codingrippler/parallax/larq-bottles.jpg"})`, // Replace with your SVG image path
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          width: "100%",
          overflow: "hidden", // Ensures content fits within the 70vh height
          minHeight: {
            xs: 500,
          },
          height: {
            xs: "70vh",
            lg: "70vh",
          },
        }}
      >
        <Grid container>
          <Grid
            item
            lg={2}
            sx={{
              display: { xs: "none", lg: "block" },
            }}
          />
          <Grid item xs={12} lg={10}>
            <CommonContainer>
              <HeroText
                customStyle={{ color: "white", textAlign: "left" }}
                variant={isPhone ? "h4" : "h2"}
              >
                OUR WORK
              </HeroText>

              <Box
                sx={{
                  height: "20px",
                }}
              />
              <HeroSubHeader
                variant={isPhone ? "h6" : "h5"}
                customStyle={{ color: "white", textAlign: "left" }}
              >
                Our founder is a distinguished academic and researcher in
                Software Engineering
              </HeroSubHeader>
            </CommonContainer>
          </Grid>

          <Grid item xs={12} />
        </Grid>
      </Box>

      <ProductCardContainer
        isPhone={isPhone}
        isTab={isTab}
        deviceWithSmallHeight={deviceWithSmallHeight}
        productsInfo={gridContent}
      />
      <BasicService
        isPhone={isPhone}
        isTab={isTab}
        deviceWithSmallHeight={deviceWithSmallHeight}
        pageContent={pageContent}
      />

      <BasicAdditionalFeatures
        backgroundImage={"/codingrippler/background/service-bg.jpg"}
        isPhone={isPhone}
        isTab={isTab}
        deviceWithSmallHeight={deviceWithSmallHeight}
        isPhoneorTab={isPhoneorTab}
      />

      {/* <GridView
        gridContent={gridContent}
        isPhone={isPhone}
        isTab={isTab}
        deviceWithSmallHeight={deviceWithSmallHeight}
        isPhoneorTab={isPhoneorTab}
      /> */}
    </>
  );
};
