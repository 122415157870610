import "./App.css";

// import { useEffect } from "react";
// import { visitCount } from "./utils/apiService";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import { TourAndTravelServices } from "./components/AdvertisePages/TourAndTravelServices";
import { AI } from "./components/AI/AI";
import { CodingRippler } from "./components/CodingRippler/CodingRippler";
import { WebRippler } from "./components/CodingRippler/WebRippler";
import { Contact } from "./components/CodingRippler/CodingRipplerHome/ContactUs/Contact";
import { About } from "./components/CodingRippler/CodingRipplerHome/AboutUs/About";

function App() {
  // useEffect(() => {
  //   visitCount().catch((error) => console.error("Error:", error));
  // }, []);

  const logoPathLight = "/codingrippler/brand-logos/logo-main-light.svg";
  const logoPathDark = "/codingrippler/brand-logos/logo-main-dark.svg";
  const WebRipplerLogo = "/codingrippler/brand-logos/wr-logo-dark.svg";
  const aiLogoLight = "/codingrippler/brand-logos/ai-logo-dark.svg";

  return (
    <Router>
      <Routes>
        <Route path="/Tour/*" element={<TourAndTravelServices />} />
        <Route path="/ai/*" element={<AI logoPath={aiLogoLight} />} />
        <Route path="/*" element={<CodingRippler logoPath={logoPathDark} />} />

        <Route path="/about/*" element={<About logoPath={logoPathLight} />} />

        <Route
          path="/web/*"
          element={<WebRippler logoPath={WebRipplerLogo} />}
        />

        <Route
          path="/contact/*"
          element={<Contact logoPath={logoPathLight} />}
        />
      </Routes>
    </Router>
  );
}

export default App;
