import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { RequestDemo, TryForFree } from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  AnimatedBox,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
  YouTubeBox,
} from "../../AIUtil/AIUtils";
import {
  AgentsFeatureContainer,
  FAQsCard,
  ProductsPageHeader,
  ProductsPageSubHeader,
  TopicContainer,
} from "../ProductsWidget";

export const AgentTeamsHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="AI Agent Teams">
          <GroupOutlinedIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                <span className="theme-specfic-colored-text">
                  Manage a Powerful AI Agent Team
                </span>
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Empower your AI agents to work in harmony, streamlining
                processes and scaling your business effortlessly.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveBoxCenteredChildren>
          <TryForFree />
          <Box m={2} />
          <RequestDemo />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      <Grid item xs={12}>
        <Container sx={{ mt: 2, mb: 2 }}>
          <AnimatedBox>
            <YouTubeBox />
          </AnimatedBox>
        </Container>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TopicContainer topicText="AI Teammates">
          <GroupOutlinedIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                Meet Your AI Team{" "}
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Your AI teammates are ready to streamline your processes from
                day one.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <AICommonBoxContainer>
          <AgentsFeatureContainer />
        </AICommonBoxContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                <span>Your AI Team Questions Answered</span>
              </CommonHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CommonContainer>
          <FAQsCard question="What is an AI team?">
            An AI team consists of multiple AI agents working together to
            automate tasks, streamline processes, and achieve business goals.
            These agents can be customized and trained to work collaboratively
            across different functions and workflows:{" "}
          </FAQsCard>
          <FAQsCard question="How can AI agents work together as a team?">
            AI agents can collaborate through shared goals, data, and triggers.
            You can design their interactions so they perform complex tasks that
            require input from multiple agents, ensuring a seamless process
            across different departments and functions:{" "}
          </FAQsCard>
          <FAQsCard question="Do I need to code to create an AI team?">
            No, with our no-code platform, you can easily create and manage your
            AI agents without any coding skills. Simply customize and set
            triggers to get your agents working together:{" "}
          </FAQsCard>

          <FAQsCard question="How can I scale my AI team?">
            You can scale your AI team by adding more agents, integrating new
            tools, and incorporating additional data sources to handle
            increasing tasks and workloads. The AI agents can grow alongside
            your business needs.
          </FAQsCard>

          <FAQsCard question="What kind of tasks can my AI team automate?">
            Your AI team can automate a variety of tasks, including data
            collection, customer support, sales outreach, content generation,
            and much more. Customize each agent to perform specific functions
            that align with your business goals.
          </FAQsCard>

          <FAQsCard question="How do I monitor my AI team's performance?">
            Our platform allows you to monitor conversations, review agent
            performance, and track the overall success of your AI team. You can
            adjust workflows and fine-tune agents based on feedback and
            performance data.
          </FAQsCard>

          <FAQsCard question="Can I integrate my AI team with other tools?">
            Yes! You can integrate your AI team with popular tools like Slack,
            Salesforce, Google Workspace, and more. This helps your agents fit
            seamlessly into your existing workflows.
          </FAQsCard>
        </CommonContainer>
      </Grid>
    </Grid>
  );
};
