import { Box, Grid, TextField } from "@mui/material";
import { useState } from "react";
import {
  CommonButtonV2,
  HeroSubHeader,
  HeroText,
} from "../../CodingRipplerWidgets";

export const SubmitYourEmail = ({ children, ...props }) => {
  const {
    isPhoneorTab,
    isPhone,
    isTab,
    deviceWithSmallHeight,
    pageContent,
  } = props;

  if (isPhone) {
    // customStyleAdvertisementHeader = {
    //   fontSize: "2.5rem",
    // };

    // customStyleAdvertisementSubHeader = {
    //   fontSize: "1.25rem",
    // };

    if (deviceWithSmallHeight) {
    }
  } else if (isTab) {
  }

  const [email, setEmail] = useState("");

  const handleSubmit = () => {};

  return (
    <Grid container spacing={5}>
      <Grid
        item
        xs={12}
        minHeight={{
          xs: 500,
        }}
        height={{
          xs: deviceWithSmallHeight ? "75vh" : "60vh",
          sm: "50vh",
          lg: "50vh",
        }}
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
          width="100%"
          height="100%"
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <HeroText variant={isPhoneorTab ? "h4" : "h2"}>
                {pageContent[0].headerText}
              </HeroText>
            </Grid>

            <Grid item xs={12}>
              <HeroSubHeader>{pageContent[0].subHeaderText}</HeroSubHeader>
            </Grid>

            <Grid item xs={12} />

            <Grid
              item
              xs={1}
              lg={3}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  lg: "block",
                },
              }}
            />
            <Grid item xs={12} lg={6}>
              <Box
                display="flex"
                flexDirection={isPhone ? "column" : "row"}
                justifyContent="space-between"
                gap={2}
              >
                <TextField
                  fullWidth
                  label="Email"
                  variant="outlined"
                  value={email}
                  sx={{
                    backgroundColor: "white",
                    fontSize: "1.2rem",
                  }}
                  onChange={(e) => setEmail(e.target.value)}
                />

                <CommonButtonV2
                  buttonText={"Submit"}
                  onClick={handleSubmit}
                  customStyle={{ fontSize: "1rem" }}
                />
              </Box>
            </Grid>
            <Grid
              item
              xs={1}
              lg={3}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  lg: "block",
                },
              }}
            />
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
