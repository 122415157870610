import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import "../../../AIHome/globalAI.css";

export const PricingButton = (children, ...props) => {
  const theme = useTheme();

  const handlePricingClick = () => {
    window.location.href = `/ai/pricing`;
  };

  return (
    // <Box>
    <Button
      variant="text"
      style={{ color: theme.palette.text.primary }}
      onClick={handlePricingClick}
    >
      <Box display="flex" alignItems="center">
        <Typography
          variant="h6"
          fontFamily="Inter, sans-serif" // Add your desired font family here
          fontSize="1rem"
        >
          Pricing
        </Typography>
      </Box>
    </Button>
    // </Box>
  );
};
