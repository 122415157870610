import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React from "react";
import { RoundBadge } from "../BoilarPlateJs/BadgeUtil";
import { StarRating } from "../BoilarPlateJs/RatingUtils";
import { TypographyOxygen } from "../BoilarPlateJs/TypographyStyled";

//RatingsAndOffersV2
export const RatingAndDiscountV2 = ({ data }) => {
  if (data.typeOfOffer !== "Special Offer") {
    data.offerBadgeColor = "#00d084";
  }
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isBetweenMiddleAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const isExtraSmallScreen = useMediaQuery("(max-width:380px)");
  const isSpecialCaseLargeScreen = useMediaQuery(
    "(width: 1280px) and (height: 800px)"
  );
  const isSpecialCaseSmallScreen = useMediaQuery(
    "(width: 540px) and (height: 720px)"
  );

  let badgeMarginRight = -35;
  if (isSmallScreen) {
    badgeMarginRight = -40;
    if (isExtraSmallScreen) {
      badgeMarginRight = -33;
    }

    if (isSpecialCaseSmallScreen) {
      badgeMarginRight = -50;
    }
  } else if (isMediumScreen) {
    badgeMarginRight = -32;
  } else if (isBetweenMiddleAndLargeScreen) {
    badgeMarginRight = -50;
  } else {
    if (isSpecialCaseLargeScreen) {
      badgeMarginRight = -30;
    }
  }

  return (
    <Box
      display={data.isLowPriorityTrip ? "none" : "flex"}
      justifyContent={"space-between"}
      position={"absolute"}
    >
      <Box display={"flex"}>
        <StarRating customStyle={{ rating: data.tripRating, size: "small" }} />
      </Box>
      <Box
        display={data.anyOffer ? "flex" : "none"}
        mt={5}
        mr={badgeMarginRight}
      >
        <RoundBadge
          badgeContent={
            <Box display={"flex"} flexDirection={"column"}>
              <TypographyOxygen fontSize="1rem">
                {data.discountedTripAmount}
              </TypographyOxygen>
              <Box
                display={data.showDiscount ? "flex" : "none"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <TypographyOxygen
                  fontSize="0.6rem"
                  textDecoration="line-through"
                >
                  {data.actualTripAmount}
                </TypographyOxygen>
              </Box>
            </Box>
          }
          backgroundColor={data.offerBadgeColor}
        ></RoundBadge>
      </Box>
    </Box>
  );
};

export const RatingAndDiscount = ({ children, ...props }) => {
  const { data, width } = props;

  return (
    <Box
      display="flex"
      justifyContent={"space-between"}
      alignContent={"center"}
      position={"absolute"}
      width={width}
    >
      <StarRating customStyle={{ rating: data.tripRating, size: "medium" }} />
      <Box sx={{ transform: "translateY(50%)" }}>
        <RoundBadge
          badgeContent={
            <Box display={"flex"} flexDirection={"column"}>
              <TypographyOxygen fontSize="1rem">
                {data.discountedTripAmount}
              </TypographyOxygen>
              <Box
                display={data.showDiscount ? "flex" : "none"}
                justifyContent={"center"}
                alignContent={"center"}
              >
                <TypographyOxygen
                  fontSize="0.6rem"
                  textDecoration="line-through"
                >
                  {data.actualTripAmount}
                </TypographyOxygen>
              </Box>
            </Box>
          }
          backgroundColor={data.offerBadgeColor}
        ></RoundBadge>
      </Box>
    </Box>
  );
};
