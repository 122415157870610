import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import EmailIcon from "@mui/icons-material/Email";
import { Box, Grid } from "@mui/material";
import React from "react";
import {
  SlideInFromLeftEffect,
  SlideInFromRightEffect,
  SlideInFromTopEffect,
} from "../../../BoilarPlateJs/MotionEffects";
import {
  CommonContainer,
  HeroSubHeader,
  HeroText,
  TopicContainer,
} from "../../CodingRipplerWidgets";
import { ButtonWithPrefix } from "../../CRAnimations/Animations";
import { RouteToContactUs } from "../ContactUs/ContactUs";
import {
  OurWorkContainerEven,
  OurWorkContainerOdd,
  OurWorkContainerSm,
} from "./OurWorkWidgets";

export const OurWork = ({ children, ...props }) => {
  const { isPhone } = props;

  const OurWork = [
    {
      title: "Supercharge Your Business with Custom AI That Fits You",
      description:
        "Revolutionize your business operations with bespoke AI solutions that enhance efficiency and empower you to outpace your competitors.",
      icon: "/codingrippler/ourwork/ai-services.png",
      buttonName: "AI Hub",
      context: "/ai",
    },
    {
      title: "Elevate Your Brand with a Custom Web Platform",
      description:
        "Elevate your brand’s online presence with a tailored web platform that seamlessly integrates your unique design and fosters meaningful customer interactions.",
      icon: "/codingrippler/ourwork/web-responsive-design.png",
      buttonName: "Web Portfolio",
      context: "/web",
    },
    {
      title: "Make Your Brand Mobile-Friendly and Always Accessible",
      description:
        "Reach your customers wherever they are with a mobile-ready website that’s fast, sleek, and seamlessly connected to your existing systems.",
      icon: "/codingrippler/ourwork/mobile-responsive-design.png",
      context: "/web",
      buttonName: "Web Portfolio",
    },
    {
      title: "Empower Your Team with Intuitive Dashboards",
      description:
        "Empower your team with dashboards that provide real-time insights, leading to smarter decisions and improved project outcomes.",
      icon: "/codingrippler/ourwork/web-dashboard.png",
      context: "/web",
      buttonName: "Web Portfolio",
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <TopicContainer topicText="Our Work">
            <AutoAwesomeIcon />
          </TopicContainer>
        </SlideInFromTopEffect>
      </Grid>

      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <CommonContainer>
            <HeroText variant="h4">
              Transforming ideas into{" "}
              <span className="theme-specfic-colored-text">impactful </span>{" "}
              brand solutions.
            </HeroText>
            <Box m={2} />
            <HeroSubHeader>
              Focused on Creating Impactful Brand Solutions.
            </HeroSubHeader>
          </CommonContainer>
        </SlideInFromTopEffect>
      </Grid>

      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <ButtonWithPrefix
              text="Contact Us"
              prefix={<EmailIcon />}
              clickHandler={RouteToContactUs}
            />
          </Box>
        </SlideInFromTopEffect>
      </Grid>

      <Grid item xs={12} style={{ overflow: "hidden" }}>
        <Grid container>
          <Grid
            item
            lg={1}
            sx={{
              display: { xs: "none", lg: "block" },
            }}
          />
          <Grid item xs={12} lg={10}>
            {OurWork.map((data, index) =>
              isPhone ? (
                <SlideInFromTopEffect key={index}>
                  <CommonContainer>
                    <OurWorkContainerSm
                      key={index}
                      ourWork={data}
                      buttonName={data.buttonName}
                    />
                  </CommonContainer>
                </SlideInFromTopEffect>
              ) : index % 2 === 0 ? (
                <SlideInFromRightEffect key={index}>
                  <CommonContainer>
                    <OurWorkContainerEven
                      key={index}
                      ourWork={data}
                      buttonName={data.buttonName}
                    />
                  </CommonContainer>
                </SlideInFromRightEffect>
              ) : (
                <SlideInFromLeftEffect key={index}>
                  <CommonContainer>
                    <OurWorkContainerOdd
                      key={index}
                      ourWork={data}
                      buttonName={data.buttonName}
                    />
                  </CommonContainer>
                </SlideInFromLeftEffect>
              )
            )}
          </Grid>
          <Grid
            item
            lg={1}
            sx={{
              display: { xs: "none", lg: "block" },
            }}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
