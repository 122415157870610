import { Box, Grid } from "@mui/material";
import {
  CommonButton,
  CommonContainer,
  HeroSubHeader,
  HeroText,
} from "../../CodingRipplerWidgets";

export const HireUs = ({ children, ...props }) => {
  const { isPhoneorTab, deviceWithSmallHeight, pageContent } = props;

  return (
    <Grid container className="white-bg">
      <Grid
        item
        lg={2}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      ></Grid>
      <Grid
        item
        xs={12}
        lg={8}
        minHeight={{
          xs: 300,
        }}
        height={{
          xs: "50vh",
          sm: deviceWithSmallHeight ? "50vh" : "35vh",
        }}
      >
        <Box
          display="flex"
          flexDirection={isPhoneorTab ? "column" : "row"}
          justifyContent={isPhoneorTab ? "center" : "space-between"}
          alignItems={"center"}
          width="100%"
          height="100%"
          sx={{}}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CommonContainer>
                <HeroText variant={isPhoneorTab ? "h4" : "h3"}>
                  {pageContent[0].headerText}
                </HeroText>
                <Box m={2} />
                <HeroSubHeader>{pageContent[0].subHeaderText}</HeroSubHeader>
              </CommonContainer>
            </Grid>
          </Grid>

          <Box
            sx={{
              width: {
                xs: "100%",
                sm: "50%",
                lg: "20%",
              },
            }}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            {isPhoneorTab ? (
              <CommonContainer>
                <CommonButton buttonText={pageContent[0].buttonText} />
              </CommonContainer>
            ) : (
              <CommonButton buttonText={pageContent[0].buttonText} />
            )}
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={0}
        lg={2}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      ></Grid>
    </Grid>
  );
};
