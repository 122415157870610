import { Container, Link as MuiLink, Typography } from "@mui/material";

export const PageNotFound = ({ children, ...props }) => {
  return (
    <Container>
      <Typography variant="h4" sx={{ mt: 4, mb: 2 }}>
        404 Page Not Found
      </Typography>
      <MuiLink
        //        component={RdLink}
        //        to={redirect}
        sx={{ mt: 2, display: "block" }}
        onClick={() => (window.location.href = `/Tour`)}
      >
        Go back to Home
      </MuiLink>
    </Container>
  );
};
