import AccessTimeIcon from "@mui/icons-material/AccessTime";
import InfoIcon from "@mui/icons-material/Info";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import StarIcon from "@mui/icons-material/Star";
import { Box, Grid } from "@mui/material";
import Typography from "@mui/material/Typography";
import { CalendarIcon } from "@mui/x-date-pickers";
import React from "react";
import { TypographyOxygen } from "../BoilarPlateJs/TypographyStyled";

export const TourAndTravelCardActions = ({ data }) => {
  return (
    <Grid container>
      <Grid item xs={5} style={{ borderRight: "2px solid  #505050" }}>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <AccessTimeIcon style={{ marginRight: "10px" }} />
          <Typography component="span" spacing={2}>
            {data.tripDurationInDays}
          </Typography>{" "}
        </Box>
      </Grid>
      <Grid
        item
        xs={4}
        style={{ borderRight: "2px solid  #505050", pointerEvents: "none" }}
      >
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <StarIcon style={{ marginRight: "10px" }} />
          <Typography component="span" spacing={2}>
            {data.tripCustomerRating}
          </Typography>{" "}
        </Box>
      </Grid>
      <Grid item xs={3}>
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <InfoIcon />
        </Box>
      </Grid>
    </Grid>
  );
};

export const TourActions = ({ children, ...props }) => {
  const { data } = props;

  const { customStyle } = props;

  const defaultStyle = {
    borderRight: "0px solid",
    borderBottom: "0px solid",
    textFontSize: "1.2rem",
    iconLeftMargin: 2,
  };

  const mergedStyle = { ...defaultStyle, ...customStyle };

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        md={3}
        style={{
          borderRight: mergedStyle.borderRight,
          borderBottom: mergedStyle.borderBottom,
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          mt={{ sm: 2, md: 0 }}
          mb={{ sm: 2, md: 0 }}
        >
          <AccessTimeIcon style={{ marginRight: "10px" }} />
          <Box>
            <TypographyOxygen
              component="span"
              fontSize={mergedStyle.textFontSize}
              fontWeight={"normal"}
            >
              {data.tripDurationInDays}
            </TypographyOxygen>{" "}
            <Box display={"flex"} justifyContent={"center"}>
              <TypographyOxygen
                component="span"
                fontSize={mergedStyle.textFontSize}
                fontWeight={"normal"}
              >
                {"Duration"}
              </TypographyOxygen>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid
        item
        xs={12}
        md={3}
        style={{
          borderRight: mergedStyle.borderRight,
          borderBottom: mergedStyle.borderBottom,
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          mt={{ sm: 2, md: 0 }}
          mb={{ sm: 2, md: 0 }}
        >
          <Box ml={mergedStyle.iconLeftMargin}>
            <StarIcon style={{ marginRight: "10px" }} />
          </Box>
          <Box>
            <TypographyOxygen
              component="span"
              fontSize={mergedStyle.textFontSize}
              fontWeight={"normal"}
            >
              {data.tripCustomerRating}
            </TypographyOxygen>{" "}
            <Box display={"flex"} justifyContent={"center"}>
              <TypographyOxygen
                component="span"
                fontSize={mergedStyle.textFontSize}
                fontWeight={"normal"}
              >
                {"Rating"}
              </TypographyOxygen>
            </Box>
          </Box>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={3}
        style={{
          borderRight: mergedStyle.borderRight,
          borderBottom: mergedStyle.borderBottom,
        }}
      >
        <Box
          display={"flex"}
          alignItems={"center"}
          mt={{ sm: 2, md: 0 }}
          mb={{ sm: 2, md: 0 }}
        >
          <Box ml={mergedStyle.iconLeftMargin}>
            <LocationOnIcon style={{ marginRight: "10px" }} />
          </Box>
          <Box>
            <TypographyOxygen
              component="span"
              fontSize={mergedStyle.textFontSize}
              fontWeight={"normal"}
            >
              {data.tripHeading}
            </TypographyOxygen>{" "}
            <Box display={"flex"} justifyContent={"center"}>
              <TypographyOxygen
                component="span"
                fontSize={mergedStyle.textFontSize}
                fontWeight={"normal"}
              >
                {"Location"}
              </TypographyOxygen>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={3}>
        <Box
          display={"flex"}
          alignItems={"center"}
          mt={{ sm: 2, md: 0 }}
          mb={{ sm: 2, md: 0 }}
        >
          <Box ml={mergedStyle.iconLeftMargin}>
            <CalendarIcon style={{ marginRight: "10px" }} />
          </Box>
          <Box>
            <TypographyOxygen
              component="span"
              fontSize={mergedStyle.textFontSize}
              fontWeight={"normal"}
            >
              {" "}
              {data.tourDuration}
            </TypographyOxygen>

            <Box display={"flex"} justifyContent={"center"}>
              <TypographyOxygen
                component="span"
                fontSize={mergedStyle.textFontSize}
                fontWeight={"normal"}
              >
                {"Dates"}
              </TypographyOxygen>{" "}
            </Box>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
