import { Grid } from "@mui/material";
import React from "react";
import {
  CommonHeaderTypography,
  CommonSubHeaderTypography,
} from "../AIUtil/AIUtils";
import { PageHeader, PageSubHeader } from "../AIWidgets";

import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/system";
import { FAQsCard } from "../AIHome/FAQ/FAQWidgets";
import { AdditionalServiceCost, PricingContainer } from "./PricingWidgets";
import { CommonContainer } from "../../CodingRippler/CodingRipplerWidgets";

export const PricingHome = ({ children, ...props }) => {
  const alphaListStyle = {
    listStyleType: "lower-alpha",
  };

  const pricingDetails = [
    {
      title: "Free",
      description: "Kickstart your AI journey with essential tools at no cost.",
      price: "0",
      currency: "$",
      frequency: "per month",
      features_included: [
        "100 credits/day",
        "4 credits per run",
        "1 user",
        "10MB Knowledge storage",
        "Low-code tool builder",
        "1-click app sharing and embedding",
        "Create custom GPT actions",
        "Access to multiple LLM models",
      ],
      isCustom: false,
    },

    {
      title: "Team",
      description:
        "Collaborate and innovate together with advanced capabilities.",
      price: "199",
      currency: "$",
      frequency: "per month",
      features_included: [
        "1000 credits/day",
        "4 credits per run",
        "Up to 3 users",
        "100MB Knowledge storage",
        "Low-code tool builder",
        "1-click app sharing and embedding",
        "Create custom GPT actions",
        "Access to multiple LLM models",
      ],
      isCustom: false,
    },
    {
      title: "Business",
      description: "Empower your organization with robust AI solutions.",
      price: "599",
      currency: "$",
      frequency: "per month",
      features_included: [
        "2000 credits/day",
        "4 credits per run",
        "Up to 10 users",
        "1GB Knowledge storage",
        "Low-code tool builder",
        "1-click app sharing and embedding",
        "Create custom GPT actions",
        "Access to multiple LLM models",
      ],
      isCustom: false,
    },
    {
      title: "Custom",
      description:
        "Unlock unlimited possibilities tailored to your business needs.",
      price: "Contact Us",
      currency: "",
      frequency: "",
      features_included: [
        "Customized credit limits",
        "Flexible user count",
        "Scalable Knowledge storage",
        "Advanced low-code builder tools",
        "1-click app sharing and embedding",
        "Custom GPT action development",
        "Full access to all LLM models",
      ],
      isCustom: true,
    },
  ];

  const additionalServiceCost = [
    {
      title: "Extra Credits",
      description: "Available on all tiers.",
      price: "2",
      currency: "$",
      frequency: "per 1000 credits",
    },

    {
      title: "Extra Knowledge Storage",
      description: "Available on all tiers.",
      price: "100",
      currency: "$",
      frequency: "per GB",
    },
  ];

  const FaqData = [
    {
      question: "What is a credit?",
      answer: (
        <>
          <p>
            Credits are usage units on Catalyst AI, consumed when running
            chains. Costs have two components:
          </p>
          <p>
            <strong>1. Fixed:</strong>
            Each execution deducts:
          </p>
          <ul>
            <li>4 credits on Free and Pro plans</li>
            <li>3 credits on the Team plan</li>
            <li>2 credits on the Business plan</li>
          </ul>
          <p>
            <strong>2. Variable:</strong> Based on:
          </p>
          <ul>
            <li>
              Compute time (free for most steps during the promotional period)
            </li>
            <li>
              Third-party providers like LLMs (cost + 20% without API key)
            </li>
          </ul>
          <p>
            <strong>Example:</strong> Using "GPT3.5-Turbo 4k" (OpenAI cost:
            $0.002/1k tokens):
          </p>
          <ul style={alphaListStyle}>
            <li>Without your API key: 1.1 credits/$0.0022 per 1k tokens</li>
            <li>With your API key: 0 credits/$0 per 1k tokens</li>
          </ul>
          <p>
            <strong>Scenario:</strong> On the Business plan, running a tool 1000
            times with 1k tokens:
          </p>
          <ul>
            <li>Without API key: 2100 credits</li>
            <li>With API key: 1000 credits</li>
          </ul>
        </>
      ),
      icon: [<AddIcon fontSize="large" />, <CloseIcon fontSize="large" />],
    },

    {
      question: "Do I need a credit card to try Catalyst AI?",
      answer: "No, you can use Catalyst AI’s free plan without a credit card.",
      icon: [<AddIcon fontSize="large" />, <CloseIcon fontSize="large" />],
    },
    {
      question: "How does Catalyst AI protect my privacy?",
      answer:
        "Your data is confidential, even from us. Catalyst AI is SOC 2 Type II compliant. Read more about our security practices.",
      icon: [<AddIcon fontSize="large" />, <CloseIcon fontSize="large" />],
    },
  ];

  return (
    <Grid id="PricingHome" container spacing={10} mt={2}>
      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Streamline Your Workflow with Smart AI
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Join Industry Leaders Streamlining Workflows with Catalyst AI.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <PricingContainer pricingDetails={pricingDetails} />
      </Grid>

      <Grid item xs={12}>
        <AdditionalServiceCost pricingDetails={additionalServiceCost} />
      </Grid>

      <Grid item xs={12}>
        {FaqData.map((faq, index) => (
          <FAQsCard
            key={index}
            question={faq.question}
            answer={faq.answer}
            openingIcon={faq.icon[0]}
            closingIcon={faq.icon[1]}
          />
        ))}
      </Grid>
    </Grid>
  );
};
