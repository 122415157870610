import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { useLocation } from "react-router-dom";
import { Link } from "react-scroll";
import { CommonContainer } from "../../../BoilarPlateJs/CommonTextContainer";
import { PageNotFound } from "../../../BoilarPlateJs/PageNotFound";
import { StraigtLine } from "../../../BoilarPlateJs/TitleDecorationBottomCenter";
import { TypographyOxygen } from "../../../BoilarPlateJs/TypographyStyled";
import { FilteredDataContainerWithPagination } from "../FilteredDataContainer";
const tourImage = "/TourAndTravel/popularTour.png";

export const FilterByTourType = ({ children, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { tourData } = props;

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams.get("packageType");

  const filteredTourData = tourData
    ? tourData.filter(
        (tour) => tour.packageName.toLowerCase() === name?.toLowerCase()
      )
    : [];

  // If filteredTourData is empty, display a 404 page
  if (tourData.length === 0 || filteredTourData.length === 0) {
    return (
      <>
        <PageNotFound redirect={"/Tour"} />
      </>
    );
  }

  var fontSize = "2.5rem";

  if (isSmallScreen) {
    fontSize = "1.5rem";
  }

  return (
    <>
      <Box
        sx={{
          height: "75vh",
          width: "100vw",
          position: "relative",
          "&::before": {
            content: '""',
            backgroundImage: `url(${tourImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            opacity: 1,
            position: "absolute",
            backgroundColor: "#429cb5",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          },
        }}
        textAlign={"center"}
      >
        <CommonContainer>
          <Grid container>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  Home
                </Link>
                <Link color="inherit" to="/tours">
                  Tours
                </Link>
                <Typography color="textPrimary">Destination</Typography>
                <Typography color="textPrimary">{name}</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Box pt={3}>
            <StraigtLine />
          </Box>
        </CommonContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <TypographyOxygen fontSize={fontSize} zIndex={1}>
            {name}
          </TypographyOxygen>
        </Box>
      </Box>

      <Link
        to="filteredDataContainer"
        spy={true}
        smooth={true}
        //       offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        {/* <FilteredDataContainer tourData={tourData} /> */}
        <FilteredDataContainerWithPagination tourData={filteredTourData} />
      </Link>
    </>
  );
};
