import { Box, Card, Tab, Tabs } from "@mui/material";
import React, { useState } from "react";

import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { ResearchTabPanel } from "./Tabs/ResearchTabPanel";
import { SalesTabPanel } from "./Tabs/SalesTabPanel";
import { SupportTabPanel } from "./Tabs/SupportTabPanel";

import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import "../globalAI.css";
import { MarketingTabPanel } from "./Tabs/MarketingTabPanel";
import { OperationsTabPanel } from "./Tabs/OperationsTabPanel";

// const TabPanel = (props) => {
//   const { children, value, index, ...other } = props;

//   return (
//     <Box
//       role="tabpanel"
//       hidden={value !== index}
//       id={`tabpanel-${index}`}
//       aria-labelledby={`tab-${index}`}
//       {...other}
//       height="100%"
//     >
//       {value === index && (
//         <Box p={3}>
//           <Typography>{children}</Typography>
//         </Box>
//       )}
//     </Box>
//   );
// };

export const HorizontalCard = () => {
  const [value, setValue] = useState(0);
  const theme = useTheme();

  const issmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMiddleScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));

  let variant = "fullWidth";

  if (issmallScreen) {
    variant = "scrollable";
  } else if (isMiddleScreen) {
    variant = "scrollable";
  }

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card className="tab-container">
      <Box className="tab-container-child">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="use case tabs"
          TabIndicatorProps={{ style: { display: "none" } }}
          variant={variant}
          scrollButtons="auto"
        >
          <Tab
            icon={<TrendingUpIcon />}
            iconPosition="start"
            label="Sales"
            className={value === 0 ? "tab-selected" : "tab-default"}
            value={0}
            disableRipple
          />
          <Tab
            icon={<SupportAgentIcon />}
            iconPosition="start"
            label="Support"
            className={value === 1 ? "tab-selected" : "tab-default"}
            value={1}
            disableRipple
          />
          <Tab
            icon={<SearchIcon />}
            iconPosition="start"
            label="Research"
            className={value === 2 ? "tab-selected" : "tab-default"}
            value={2}
            disableRipple
          />
          <Tab
            icon={<LeaderboardIcon />}
            iconPosition="start"
            label="Marketing"
            className={value === 3 ? "tab-selected" : "tab-default"}
            value={3}
            disableRipple
          />
          <Tab
            icon={<SettingsIcon />}
            iconPosition="start"
            label="Operations"
            className={value === 4 ? "tab-selected" : "tab-default"}
            value={4}
            disableRipple
          />
        </Tabs>
      </Box>
      <SalesTabPanel value={value} />
      <SupportTabPanel value={value} />
      <ResearchTabPanel value={value} />
      <MarketingTabPanel value={value} />
      <OperationsTabPanel value={value} />
    </Card>
  );
};
