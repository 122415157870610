import { TabsContainer } from "./TabsContainer";

import { Grid } from "@mui/material";
import { OperationsTabAnimationComponent } from "./TabWidgets/OperationsTabAnimationComponent";
import { OperationsTabDetails } from "./TabWidgets/OperationsTabDetails";

export const OperationsTabPanel = (props) => {
  const { value } = props;

  return (
    <TabsContainer value={value} index={4}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={7}>
          <OperationsTabDetails />
        </Grid>

        <Grid item xs={12} md={4} lg={5}>
          <OperationsTabAnimationComponent />
        </Grid>
      </Grid>{" "}
    </TabsContainer>
  );
};
