import { Grid } from "@mui/material";
import { TabsContainer } from "./TabsContainer";
import { ResearchTabAnimationComponent } from "./TabWidgets/ResearchTabAnimationComponent";
import { ResearchTabDetails } from "./TabWidgets/ResearchTabDetails";

export const ResearchTabPanel = (props) => {
  const { value } = props;

  return (
    <TabsContainer value={value} index={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={7}>
          <ResearchTabDetails />
        </Grid>

        <Grid item xs={12} md={4} lg={5}>
          <ResearchTabAnimationComponent />
        </Grid>
      </Grid>{" "}
    </TabsContainer>
  );
};
