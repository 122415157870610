import { Grid } from "@mui/material";
import { TabsContainer } from "./TabsContainer";
import { SupportTabAnimationComponent } from "./TabWidgets/SupportTabAnimationComponent";
import { SupportTabDetails } from "./TabWidgets/SupportTabDetails";

export const SupportTabPanel = (props) => {
  const { value } = props;

  return (
    <TabsContainer value={value} index={1}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={7}>
          <SupportTabDetails />
        </Grid>

        <Grid item xs={12} md={4} lg={5}>
          <SupportTabAnimationComponent />
        </Grid>
      </Grid>{" "}
    </TabsContainer>
  );
};
