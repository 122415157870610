import { Routes, Route } from "react-router-dom";
import { BasicHome } from "./BasicHome";
import EmailIcon from "@mui/icons-material/Email";
import LightbulbIcon from "@mui/icons-material/Lightbulb";
import BrushIcon from "@mui/icons-material/Brush";
import CodeIcon from "@mui/icons-material/Code";
import LaunchIcon from "@mui/icons-material/Launch";

export const Basic = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;
  const pageContent = [
    {
      headers: {
        title: "Our Work",
        heading: (
          <>
            {" "}
            Transforming ideas into{" "}
            <span className="theme-specfic-colored-text">impactful </span> brand
            solutions
          </>
        ),
        subHeading: "Focused on Creating Impactful Brand Solutions.",

        buttonText: "Contact Us",
        buttonPrefix: (
          <>
            <EmailIcon />
          </>
        ),
      },
      OurWork: [
        {
          title: "Idea",
          description:
            "We collaborate with your team to understand your ideas, project, and goals. From there, our team crafts a detailed action plan and proposal for your project.",
          icon: (
            <>
              <LightbulbIcon />
            </>
          ),
        },
        {
          title: "Design",
          description:
            "We begin by creating a mockup or prototype of your website or app and present it for your feedback. Then, we refine it together to reach a final design that meets your vision.",
          icon: (
            <>
              <BrushIcon fontSize="large" />
            </>
          ),
        },
        {
          title: "Development",
          description:
            "Using top industry standards, we develop a fast, responsive, and highly scalable website tailored to your needs.",
          icon: (
            <>
              <CodeIcon />
            </>
          ),
        },
        {
          title: "Launch & Maintenance",
          description:
            "Once your project is ready, we help you launch it live. Then, we provide training to your team on how to manage updates and scale it effectively.",
          icon: (
            <>
              <LaunchIcon />
            </>
          ),
        },
      ],
      additionalInfo: [],

      customCss: {
        customBgCss: "white-bg",
      },
    },
  ];

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <BasicHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              pageContent={pageContent}
              isPhoneorTab={isPhoneorTab}
            />
          }
        />
      </Routes>
    </>
  );
};
