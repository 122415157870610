import React from "react";
import { Box, Card, CardMedia } from "@mui/material";
import { ButtonContainers } from "./ButtonContainers";

const NavigationBox = ({ children, ...props }) => {
  const { logoPath, displayScrollViewNavigation } = props;

  const clickHandler = () => {
    window.location.href = "/";
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: "transparent",
        marginLeft: {
          xs: "1%",
          md: "1%",
        },
        marginRight: {
          xs: "1%",
          md: "1%",
        },
        marginTop: {
          xs: "1%",
          md: "1%",
        },
        visibility: displayScrollViewNavigation ? "hidden" : "visible",
      }}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: 140,
          width: "25rem",
          overflow: "hidden",
          transform: "translate(0%, 0)",
        }}
      >
        <CardMedia
          component="img"
          image={logoPath}
          alt="CodingRippler"
          onClick={() => clickHandler()}
          sx={{
            objectFit: "contain",
            maxHeight: "100%",
            maxWidth: "100%",
            cursor: "pointer", // Add this line to change the cursor on hover
          }}
        />
      </Box>
      <Card
        sx={{
          borderRadius: "24px",
          display: "flex", // Enables flexbox
          flexDirection: "row", // Arranges children horizontally
          alignItems: "center", // Vertically centers the items (optional)
          backgroundColor: "white", // Set background to transparent
          boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.2)", // Reduced shadow
        }}
      >
        <ButtonContainers />
      </Card>
    </Box>
  );
};

export default NavigationBox;
