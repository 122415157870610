import { Box, Grid } from "@mui/material";
import React from "react";
import { SlickMotionEffect } from "../BoilarPlateJs/MotionEffects";
import {
  TypographyTourAndTravelHeading,
  TypographyTourAndTravelParagraph,
} from "../BoilarPlateJs/TypographyStyled";
import "../TourAndTravel/HomePages/tourandtravel.css";
import GridFormTourAndTravel from "./GridFormTourAndTravel.js";
// import tourVideo from "./tour.mp4";

export const TourAndTravelHomePage = ({ children, ...props }) => {
  return (
    <Grid
      container
      sx={{
        overflow: "hidden",
        maxWidth: "100vw",
        maxHeight: "100vh",
        position: "relative",
        paddingTop: "60px",
      }}
      id="Tour"
      height={"100vh"}
      width={"100vw"}
    >
      <Grid item xs={12}>
        <Box className="video-background">
          {/* <video autoPlay muted loop>
            <source src={tourVideo} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video> */}
        </Box>
      </Grid>
      <Grid item xs={12}>
        <SlickMotionEffect>
          <Box
            display={"flex"}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <TypographyTourAndTravelHeading>
              {" "}
              Summer time{" "}
            </TypographyTourAndTravelHeading>{" "}
            <TypographyTourAndTravelParagraph>
              Give yourself a{" "}
              <span style={{ textDecoration: "underline" }}>vacation</span>
            </TypographyTourAndTravelParagraph>{" "}
            <GridFormTourAndTravel />
          </Box>
        </SlickMotionEffect>
      </Grid>
    </Grid>
  );
};
