import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Grid, MenuItem, TextField, Typography } from "@mui/material";
import Slider from "@mui/material/Slider";
import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import React, { useState } from "react";
import { CardViewContainerV2 } from "../BoilarPlateJs/CardViewContainerV2";
import "./GridFormTourAndTravel.css";

export default function GridFormTourAndTravel() {
  const [minBudget] = useState(100);
  const [maxBudget] = useState(100000);
  const [amount, setAmount] = useState(5000);
  const [selectedDestination, setSelectedDestination] = React.useState("");

  // Need to make this dynamic by fetching from the database
  const destinations = ["India", "USA", "FRANCE", "UAE"];

  return (
    <CardViewContainerV2 width="fit-content" backgroundColor="#429cb5">
      <Grid container direction="row" overflow="auto" spacing={2}>
        <Grid item xs={12} md={4} overflow="auto">
          <Typography
            variant="p"
            // style={{ color: theme.palette.darkText.secondary }}
          >
            Search your Destination:{" "}
          </Typography>
          <TextField
            id="destinationName"
            select
            value={selectedDestination}
            onChange={(event) => setSelectedDestination(event.target.value)}
            fullWidth
            sx={{
              padding: "5px",
              fontWeight: "bold",
              "& .MuiOutlinedInput-root": {
                borderRadius: "3rem 3rem 3rem 3rem",
              },
            }}
            SelectProps={{
              IconComponent: LocationOnIcon, // use your custom icon
            }}
          >
            <MenuItem value="">None</MenuItem> {/* Add this line */}
            {destinations.map((destination) => (
              <MenuItem key={destination} value={destination}>
                {destination}
              </MenuItem>
            ))}
          </TextField>{" "}
        </Grid>
        <Grid item xs={12} md={4}>
          <Typography
            variant="p"
            // style={{ color: theme.palette.darkText.secondary }}
          >
            Select your Date:{" "}
          </Typography>
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DatePicker
              onChange={(newValue) => {}}
              components={{
                TextField: TextField,
              }}
              sx={{
                width: "100%",
                padding: "5px",
                "& .MuiOutlinedInput-root": {
                  borderRadius: "3rem 3rem 3rem 3rem",
                },
              }}
            />
          </LocalizationProvider>{" "}
        </Grid>
        <Grid item xs={12} md={4}>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              variant="p"
              // style={{ color: theme.palette.darkText.secondary }}
            >
              Max Price:{" "}
            </Typography>
            <Typography
              variant="p"
              style={{
                alignContent: "flex-end",
                opacity: 1,
                // color: theme.palette.darkText.secondary,
              }}
            >
              $ {amount}{" "}
            </Typography>
          </div>
          <Slider
            value={amount}
            onChange={(event, newValue) => setAmount(newValue)}
            aria-labelledby="budget-slider"
            valueLabelDisplay="auto"
            min={minBudget}
            max={maxBudget}
            sx={{
              color: "#38b6ff",
              //            color: "#ffffff",
              marginTop: "3vh",
              padding: "10px",
            }}
          />{" "}
        </Grid>
      </Grid>
    </CardViewContainerV2>
  );
}
