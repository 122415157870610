import React from "react";
import Chart from "react-apexcharts";

export const ApexChartOfTypeLine = ({ children, ...props }) => {
  const {
    seriesName,
    seriesData,
    xAxisCategory,
    color,
    customHeight,
    customWidth,
    yAxisMin,
    yAxisMax,
  } = props;

  const options = {
    chart: {
      type: "line",
      // height: 350,
      // there is default toolbar is shown with the ApexCharts which is not required for our use case
      // so we are hiding the toolbar
      toolbar: {
        show: false, // Hide the toolbar
      },
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    series: [
      {
        name: seriesName || "dummy",
        data: seriesData,
      },
    ],
    stroke: {
      curve: "smooth",
      zIndex: 1000, // Ensure the line has a higher z-index than the grid
    },
    xaxis: {
      show: false, // Ensure x-axis is visible
      categories: xAxisCategory,
      // axis border is the border around the chart
      // since we are hiding the x-axis border, we need to hide the x-axis border as well
      axisBorder: {
        show: false,
        offsetX: 0, // Ensure no space is taken
        offsetY: 0, // Ensure no space is taken
      },
      // Ticks are the markers denoting data points on axes. By default, the x-axis ticks are generated based on the range of data.
      //  to hide this set show to false
      axisTicks: {
        show: false,
        offsetX: 0, // Ensure no space is taken
        offsetY: 0, // Ensure no space is taken
      },
      // to hide the labels on the x-axis set this to false
      labels: {
        show: false,
        offsetX: 0, // Ensure no space is taken
        offsetY: 0, // Ensure no space is taken
      },
    },

    // we want to hide the y-axis for our use case so we are making show to false

    yaxis: {
      show: false,
      min: yAxisMin || 0, // Set the minimum value for the y-axis
      max: yAxisMax || 250, // Set the maximum value for the y-axis
      offsetX: 0, // Ensure no space is taken
      offsetY: 0, // Ensure no space is taken
    },

    // inner layer of the chart is the grid
    // we are hiding the grid for our use case
    grid: {
      show: false, // Hide the grid if not needed
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: color,
        inverseColors: true,
        opacityFrom: 0.8,
        opacityTo: 1,
        stops: [0, 50, 100],
      },
    },
    tooltip: {},
    colors: color, // Change the color of the graph
    //    colors: ["#FF5733", "#008000", "#0000FF"], // Array of colors for the graph
  };

  return (
    <Chart
      options={options}
      series={options.series}
      type="line"
      height={customHeight || "100%"}
      width={customWidth || "100%"}
    />
  );
};

export const LineCharts = ({ children, ...props }) => {
  const {
    seriesData,
    xAxisCategory,
    color,
    yAxisMin,
    yAxisMax,
    customHeight,
    customWidth,
  } = props;

  const options = {
    chart: {
      type: "area",
      toolbar: {
        show: false, // Hide the toolbar
      },
      zoom: {
        enabled: false, // Disable zooming
      },
    },
    dataLabels: {
      enabled: false, // Hide the data labels
    },

    stroke: {
      curve: "smooth",
    },

    xaxis: {
      show: true, // Ensure x-axis is visible
      categories: xAxisCategory, // Example categories
      // Ticks are the markers denoting data points on axes. By default, the x-axis ticks are generated based on the range of data.
      //  to hide this set show to false
      axisTicks: {
        show: false,
      },

      // since we are hiding the x-axis border, we need to hide the x-axis border as well
      axisBorder: {
        show: false,
      },
      labels: {
        style: {
          fontFamily: '"Inter", sans-serif', // Set the font family for x-axis labels
          fontSize: "1.0rem",
          fontWeight: "bold",
          colors: "#868a97", // Set the font color for x-axis labels
        },
      },
    },
    yaxis: {
      show: true, // Ensure y-axis is visible
      min: yAxisMin, // Set the minimum value for the y-axis
      max: yAxisMax, // Set the maximum value for the y-axis
      labels: {
        style: {
          fontFamily: '"Inter", sans-serif', // Set the font family for y-axis labels
          fontSize: "1.0rem",
          fontWeight: "bold",
          colors: "#868a97", // Set the font color for x-axis labels
        },
      },
    },
    grid: {
      show: true, // Hide the grid if not needed
      strokeDashArray: 5, // Make the grid lines dashed
    },
    fill: {
      type: "gradient",
      gradient: {
        shade: "dark",
        type: "horizontal",
        shadeIntensity: 0.5,
        gradientToColors: color, // Use default gradient colors
        inverseColors: true,
        opacityFrom: 0.1,
        opacityTo: 0.35,
        stops: [0, 100],
      },
    },
    legend: {
      position: "top", // Position the legend at the bottom
      show: false,
    },
    colors: color, // Provide different colors for each series
  };

  // const series = [
  //   {
  //     name: "Series 1",
  //     data: [30, 40, 35, 50, 49, 60, 70, 91, 125, 140, 160, 180], // Example data for Series 1
  //   },
  //   {
  //     name: "Series 2",
  //     data: [20, 30, 25, 40, 39, 50, 60, 81, 105, 120, 140, 160], // Example data for Series 2
  //   },
  //   {
  //     name: "Series 3",
  //     data: [10, 20, 15, 30, 29, 40, 50, 71, 85, 100, 120, 140], // Example data for Series 3
  //   },
  // ];

  return (
    <Chart
      options={options}
      series={seriesData}
      type="area"
      width={customWidth || "100%"}
      height={customHeight || "100%"}
    />
  );
};
