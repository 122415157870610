import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import {
  Box,
  Divider,
  Fab,
  Grid,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React from "react";
import { AvatarComponent } from "../BoilarPlateJs/AvatarComponent";
import {
  CardViewContainerV2,
  ImageCardViewContainer,
} from "../BoilarPlateJs/CardViewContainer";
import { SlickMotionEffect } from "../BoilarPlateJs/MotionEffects";
import { StarRating } from "../BoilarPlateJs/RatingUtils";
import { TitleDecorationBottomCenter } from "../BoilarPlateJs/TitleDecorationBottomCenter";
import {
  TypographyKaushanScript,
  TypographyOxygen,
} from "../BoilarPlateJs/TypographyStyled";

export const ReviewAndPostPage = ({ chidlren, ...props }) => {
  const { customerReviews } = props;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isBetweenMiddleAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const isExtraSmallScreen = useMediaQuery("(max-width:380px)");

  // headig font size
  var headingFontSize = "3rem";

  // image height and width
  var imageHeight = "50vh";
  var imageWidth = "40vw";
  var cardHeight = "30vh";
  var cardWidth = "30vw";

  // post texts
  var postHeadingFontSize = "1.5rem";
  var postParagraphFontSize = "1.0rem";

  //Avatar size control
  var avatarImageWidth = 100;
  var avatarImageHeight = 100;

  // text padding
  var textSize = "1.5rem";

  if (isSmallScreen) {
    imageWidth = "80vw";
    cardWidth = "60vw";
    postParagraphFontSize = "0.8rem";
    textSize = "1.0rem";
    if (isExtraSmallScreen) {
    }
  } else if (isMediumScreen) {
    imageWidth = "80vw";
    cardWidth = "60vw";
    postHeadingFontSize = "2.0rem";
    postParagraphFontSize = "1.5rem";
    avatarImageWidth = 150;
    avatarImageHeight = 150;
  } else if (isBetweenMiddleAndLargeScreen) {
    imageWidth = "80vw";
    cardWidth = "60vw";
    postHeadingFontSize = "2.0rem";
    postParagraphFontSize = "1.5rem";
    avatarImageWidth = 150;
    avatarImageHeight = 150;
  }

  return (
    <Grid container id="reviewAndPostPage" pt={5} pb={5}>
      <Grid item xs={12} lg={6} p={2}>
        <Box textAlign={"center"}>
          <TypographyOxygen fontSize={headingFontSize}>
            LATEST POSTS
          </TypographyOxygen>
        </Box>

        <TitleDecorationBottomCenter
          customStyle={{ marginLeft: 2, marginRight: 2 }}
        />

        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <SlickMotionEffect>
            <ImageCardViewContainer
              data={{ path: "../tourandtravel/beforeYouGo.jpg" }}
              customStyle={{ imageHeight: imageHeight, imageWidth: imageWidth }}
            />
          </SlickMotionEffect>
        </Box>

        <Box display={"flex"} justifyContent={"center"}>
          <SlickMotionEffect>
            <CardViewContainerV2
              customBoxStyle={{
                className: "",
                cardHeight: cardHeight,
                cardWidth: cardWidth,
              }}
            >
              <Box textAlign={"center"}>
                <TypographyOxygen fontSize={postHeadingFontSize}>
                  Before You Go
                </TypographyOxygen>
              </Box>

              <Box>
                <TypographyOxygen
                  variant="body2"
                  fontWeight={"normal"}
                  fontSize={postParagraphFontSize}
                  lineHeight={2}
                >
                  Make sure to check the weather, local customs, and travel
                  advisories before your trip. Safe travels!
                </TypographyOxygen>
              </Box>

              <Box display={"flex"} justifyContent={"center"} p={2}>
                <Fab variant="extended" size="large" color="primary">
                  READ MORE
                  <ArrowRightIcon />
                </Fab>
              </Box>
            </CardViewContainerV2>
          </SlickMotionEffect>
        </Box>
      </Grid>

      <Grid item xs={12} lg={6} p={2}>
        <Box textAlign={"center"}>
          <TypographyOxygen fontSize={headingFontSize}>
            TOURS REVIEWS
          </TypographyOxygen>
        </Box>
        <TitleDecorationBottomCenter
          customStyle={{ marginLeft: 2, marginRight: 2 }}
        />
        {customerReviews.map((customerReview, index) => (
          <Grid container>
            {/* <Grid item xs={4} sx={{ transform: "translateX(10%)" }}> */}
            <Grid item xs={4}>
              <Box
                display={"flex"}
                justifyContent={"center"}
                flexDirection={"column"}
                textAlign={"center"}
                justifyItems={"center"}
                alignItems={"center"}
                sx={{
                  transform: "translateX(5%)",
                }}
              >
                <AvatarComponent
                  src={customerReview.customerProfilePic}
                  alt="Remy Sharp"
                  customStyle={{
                    width: avatarImageWidth,
                    height: avatarImageHeight,
                  }}
                />
                <TypographyKaushanScript fontSize={textSize}>
                  {customerReview.customerName}
                </TypographyKaushanScript>
              </Box>
            </Grid>
            <Grid item xs={8}>
              <TypographyOxygen fontSize={postHeadingFontSize}>
                {customerReview.postHeading}
              </TypographyOxygen>

              <TypographyOxygen
                variant="body2"
                fontWeight={"normal"}
                fontSize={postParagraphFontSize}
                lineHeight={2}
              >
                {customerReview.customerPost}
              </TypographyOxygen>

              <StarRating
                customStyle={{
                  rating: 5,
                  paddingTop: "20px",
                  paddingLeft: "20px",
                }}
                tripRating={5}
              />
              <Divider
                variant="dashed"
                sx={{ paddingTop: "20px", borderStyle: "dashed" }}
              />
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  );
};
