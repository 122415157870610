import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, Grid } from "@mui/material";
import React from "react";
import { SlideInFromTopEffect } from "../../../BoilarPlateJs/MotionEffects";
import { ServicesV2 } from "../../CodingRipplerHome/WhatWeDo/WhatWeDoWidgets";
import {
  CommonContainer,
  HeroSubHeader,
  HeroText,
  TopicContainer,
} from "../../CodingRipplerWidgets";
import { ButtonWithPrefix } from "../../CRAnimations/Animations";

export const BasicService = ({ children, ...props }) => {
  const { pageContent } = props;

  const headers = pageContent[0].headers;
  const customCss = pageContent[0].customCss;
  const whatWeDoData = pageContent[0].OurWork;

  return (
    <Grid
      container
      spacing={2}
      className={customCss.customBgCss ? customCss.customBgCss : "white-bg"}
    >
      <Grid
        item
        xs={12}
        sx={{
          marginTop: { xs: 5, lg: 10 },
        }}
      >
        <SlideInFromTopEffect>
          <TopicContainer topicText={headers.title}>
            <AutoAwesomeIcon />
          </TopicContainer>
        </SlideInFromTopEffect>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <Grid container>
            <Grid item xs={12}>
              <SlideInFromTopEffect>
                <HeroText variant="h4">{headers.heading}</HeroText>
              </SlideInFromTopEffect>
            </Grid>
          </Grid>{" "}
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <Grid container>
          <Grid item xs={12}>
            <SlideInFromTopEffect>
              <CommonContainer>
                <HeroSubHeader>{headers.subHeading}</HeroSubHeader>
              </CommonContainer>
            </SlideInFromTopEffect>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <Box display="flex" justifyContent="center" width={"100%"}>
            <CommonContainer>
              <ButtonWithPrefix
                text={headers.buttonText}
                prefix={headers.buttonPrefix}
              />
            </CommonContainer>
          </Box>
        </SlideInFromTopEffect>
      </Grid>

      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <Grid container>
            <Grid item xs={1} />
            <Grid item xs={12} lg={10}>
              <ServicesV2 WhatWeDoData={whatWeDoData} columnSizeLg={6} />
            </Grid>
            <Grid item xs={1} />
          </Grid>
        </SlideInFromTopEffect>
      </Grid>

      <Grid
        item
        xs={12}
        sx={{
          minHeight: { lg: "5vh" },
        }}
      ></Grid>
    </Grid>
  );
};
