import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box, CardMedia, Grid } from "@mui/material";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { RequestDemo, TryForFree } from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  AICommonContainer,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
} from "../../AIUtil/AIUtils";
import {
  CardWithMediaTitleAndDescription,
  InLineMediaContainer,
  PageHeader,
  PageSubHeader,
  TopicContainer,
} from "../../AIWidgets";

export const AICustomerSupportHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  const useCaseData = [
    {
      title: "Streamline Customer Inquiries",
      description:
        "Your AI teammate automatically routes inquiries to the right team, ensuring quicker resolutions.",
      image: "/airippler/features/no-coding-required.png",
      minHeight: "500px",
    },
    {
      title: "Instant FAQ Responses",
      description:
        "Your AI teammate answers common questions instantly, reducing wait times and increasing customer satisfaction.",
      image: "/airippler/features/templatesToGetStarted.png",
      minHeight: "500px",
    },
    {
      title: "Seamless Escalations",
      description:
        "For complex issues, your AI teammate summarizes and escalates to the right agent, improving efficiency.",
      image: "/airippler/features/teachYourProcess.png",
      minHeight: "500px",
    },
  ];

  const keyFeatureData = [
    {
      title: "Control AI Behavior with Precision",
      description:
        "Ensure consistent AI performance with customizable instructions, workflow control, and temperature settings.",
      image: "/airippler/features/IntegrateWithTechTrack.png",
    },
    {
      title: "Protect Your Data Privacy",
      description:
        "Your data remains private—never used for training AI models, ensuring full confidentiality.",
      image: "/airippler/features/teachYourProcess.png",
    },
    {
      title: "Seamless Tech Stack Integration",
      description:
        "Effortlessly connect with popular tools like HubSpot, Salesforce, Zapier, and Gmail to streamline your operations.",
      image: "/airippler/features/agent-skill.png",
    },
  ];

  const ListOfOurCustomers = [
    "/airippler/marquee/Webflow.svg",
    "/airippler/marquee/Async-Api.svg",
    "/airippler/marquee/Zapier.svg",
    "/airippler/marquee/Zoho.svg",
    "/airippler/marquee/Google-Gemini.svg",
    "/airippler/marquee/Hubspot.svg",
  ];

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="for Customer Support">
          <SupportAgentIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                <span className="theme-specfic-colored-text">
                  Boost Support with AI Efficiency
                </span>{" "}
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Enhance Support Speed, Accuracy, and Customer Satisfaction with
                Seamless AI Integration.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ResponsiveBoxCenteredChildren>
          <TryForFree />
          <Box m={2} />
          <RequestDemo />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      <Grid item xs={12}>
        <AICommonContainer>
          <CardMedia
            component="img"
            image="/airippler/home-page/homePage.png"
            alt="Description of the image"
            width="100%"
            height="100%"
          />
        </AICommonContainer>
      </Grid>

      {/* Advertise page   */}

      <Grid item xs={12}>
        <AICommonContainer>
          <CommonSubHeaderTypography customStyle={{ opacity: 0.8 }}>
            Companies of all sizes trust AI Rippler
          </CommonSubHeaderTypography>

          <Box m={2} />
          <InLineMediaContainer data={ListOfOurCustomers} />
        </AICommonContainer>
      </Grid>

      {/* Example Use Case Page  */}

      <Grid item xs={12}>
        <TopicContainer topicText="Example use cases">
          <SupportAgentIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Turn Every Interaction into a Valuable Opportunity with AI
                Support
              </CommonHeaderTypography>
            }
          />
        </CommonContainer>

        <Box m={2} />

        <AICommonBoxContainer>
          <CardWithMediaTitleAndDescription data={useCaseData} />
        </AICommonBoxContainer>
      </Grid>

      <Grid item xs={12}>
        <TopicContainer topicText="Key Features">
          <SupportAgentIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Easily Build Your AI Support System to Enhance Efficiency
              </CommonHeaderTypography>
            }
          />
        </CommonContainer>

        <Box m={2} />

        <AICommonBoxContainer>
          <CardWithMediaTitleAndDescription data={keyFeatureData} />
        </AICommonBoxContainer>
      </Grid>
    </Grid>
  );
};
