import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import { Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { FeaturesContainer } from "../../AIHome/Features/FeaturesWidgets";
import { RequestDemo, TryForFree } from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  AnimatedBox,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
  YouTubeBox,
} from "../../AIUtil/AIUtils";
import {
  FAQsCard,
  ProductsPageHeader,
  ProductsPageSubHeader,
  TopicContainer,
} from "../ProductsWidget";

export const AIAgentsHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="AI Agent">
          <AutoAwesomeIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                <span className="theme-specfic-colored-text">
                  Recruit AI Agents to Grow Your Business
                </span>
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Expand your capabilities without expanding your workforce.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ResponsiveBoxCenteredChildren>
          <TryForFree />
          <Box m={2} />
          <RequestDemo />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Container sx={{ mt: 2, mb: 2 }}>
          <AnimatedBox>
            <YouTubeBox />
          </AnimatedBox>
        </Container>
      </Grid>

      {/* Feature page  */}

      <Grid item xs={12}>
        <TopicContainer topicText="Features">
          <DataObjectOutlinedIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                Put Your Business on{" "}
                <span className="theme-specfic-colored-text">Autopilot</span>{" "}
                for Better Results
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Streamline your operations and let AI handle the rest.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <AICommonBoxContainer>
          <FeaturesContainer />
        </AICommonBoxContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                <span>Everything You Need to Know About AI Agents</span>
              </CommonHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CommonContainer>
          <FAQsCard question="What is an AI Agent ?">
            An AI agent is intelligent software designed to understand language,
            learn from data, and take actions to achieve specific goals
            automatically. For example, it can act as an assistant that
            comprehends natural language requests and performs tasks for you.
            Key capabilities of AI agents include:
            <ul>
              <li>
                Natural Language Processing: Understanding both text and voice
                requests.
              </li>
              <li>
                Continuous Learning: Improving over time as more data is
                provided.
              </li>
              <li>
                Task Automation: Handling repetitive tasks to save time and
                effort.
              </li>
              <li>Automating repetitive tasks to save time and effort.</li>
              <li>
                Information Retrieval: Accessing data sources to provide
                accurate answers.
              </li>
            </ul>
          </FAQsCard>
          <FAQsCard question="Why Build Your Own AI Agent?">
            Discover how building your own AI agent can transform your business
            and boost productivity:
            <ul>
              <li>
                Automate Routine Tasks Save time and reduce manual effort by
                having AI agents handle repetitive processes like form
                completion and report generation.
              </li>
              <li>
                Instant Access to Information Quickly retrieve relevant
                information from multiple sources, enabling your team to focus
                on strategic priorities.
              </li>
              <li>
                Enhance Customer Service Boost support efficiency with AI that
                can resolve common queries, direct requests, and escalate issues
                as needed.
              </li>
              <li>
                Enable Data-Driven Decisions Leverage AI to analyze trends,
                predict outcomes, and provide actionable insights, empowering
                better decision-making.
              </li>
            </ul>
          </FAQsCard>
          <FAQsCard question="Get Started with Your Own AI Agent in Minutes">
            AI Rippler makes building your own AI agent effortless, with no
            coding required. Just follow these simple steps:
            <ul>
              <li>
                Creating an Agent - Start by naming your agent, setting an
                avatar, and adding a description. This forms the unique identity
                of your AI assistant.
              </li>
              <li>
                Adding Skills and Tools - Choose from a library of pre-built
                skills like API calls and data processing, then drag and drop to
                equip your agent with the tools it needs to perform tasks.
              </li>
              <li>
                Setting Triggers - Define conditions to activate skills, such as
                initiating specific actions when receiving certain queries.
              </li>
              <li>
                Talk to Your Agent - That's it! Start interacting with your
                agent using natural language. The agent will improve as it gets
                more conversations.
              </li>

              <li>
                Engaging with Your Agent - Now, start interacting with your
                agent in natural language. It will continue to improve with each
                conversation, learning and adapting to better meet your needs.
              </li>
            </ul>
          </FAQsCard>

          <FAQsCard question="Next Steps">
            AI Rippler makes it easy for anyone to create AI agents tailored to
            your business needs.
            <ul>
              <li>
                Add more skills and integrate additional data sources to tackle
                a broader range of tasks and use cases.
              </li>

              <li>
                Easily connect your agent to popular tools like Slack, Twitter,
                and more to automate workflows across your platforms.
              </li>

              <li>
                Monitor conversations and interactions to keep improving your
                agent’s performance and accuracy.
              </li>

              <li>
                Share your AI agent with teammates to collaborate, optimize
                workflows, and boost overall productivity.
              </li>
            </ul>
          </FAQsCard>
        </CommonContainer>
      </Grid>
    </Grid>
  );
};
