import { Box, Grid } from "@mui/material";
import React from "react";
import { TypographyOxygen } from "../../BoilarPlateJs/TypographyStyled";

export const OfferContainer = ({ children, ...props }) => {
  // Assuming noOfSlidesToShow is defined somewhere in your component
  const { data } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        <Box
          sx={{
            display: data.anyOffer ? "flex" : "none",
            justifyContent: "flex-end", // Aligns children to the right
          }}
        >
          <Box
            sx={{
              color: "white", // Adjust text color as needed
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: 90,
              height: 90,
              bgcolor: "#ff47a0", // Adjust color as needed
              borderRadius: "50%",
              transform: "translateY(-10%)", // Adjust rotation as needed
              zIndex: 1,
            }}
            textAlign={"center"}
          >
            <TypographyOxygen
              variant="caption"
              sx={{ fontSize: "1rem", transform: "rotate(10deg)" }}
              fontWeight={"bold"}
            >
              {data.typeOfOffer}
            </TypographyOxygen>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
