import { Routes, Route } from "react-router-dom";
import { GridHome } from "./GridHome";

export const GridContainer = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <GridHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneorTab}
            />
          }
        />
      </Routes>
    </>
  );
};
