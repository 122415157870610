import { CardContent } from "@mui/material";
import React from "react";
import { ContactUs } from "../../ContactUs";
import { HomeButton } from "../../HomeButton";
import HomeIcon from "@mui/icons-material/Home";
import HubIcon from "@mui/icons-material/Hub";
import LanguageIcon from "@mui/icons-material/Language";
import { useState } from "react";
import { DropDownButton } from "../../../../AI/AIWidgets";

export const WRNavigationButtonsDrawer = ({ children, ...props }) => {
  const { customTextColor } = props;

  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleButtonClick = (index) => {
    setActiveDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  const buttons = [
    {
      name: "Portfolio",
      shortcuts: [
        {
          name: "Parallax",
          context: "/web/parallax",
        },
        {
          name: "Grid View",
          context: "/web/grid",
        },
        {
          name: "Masanory",
          context: "/web/masanory",
        },
        {
          name: "E-commerce",
          context: "/web/e-commerce",
        },
        {
          name: "Basic",
          context: "/web/basic",
        },
        {
          name: "Dashboard",
          context: "/web/dashboards",
        },
      ],
    },
    {
      name: "Company",
      shortcuts: [
        {
          name: "Back to Coding Rippler",
          context: `/`,
          icon: <HomeIcon />,
        },
        {
          name: "Web Rippler",
          context: `/web`,
          icon: <LanguageIcon />,
        },
        {
          name: "AI Rippler",
          context: `/ai`,
          icon: <HubIcon />,
        },
      ],
    },
  ];

  return (
    <>
      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <HomeButton customTextColor={customTextColor} context="/web" />
      </CardContent>

      {buttons.map((button, index) => (
        <CardContent sx={{ flexGrow: 1, width: "100%" }} key={index}>
          <DropDownButton
            activeDropdown={activeDropdown}
            handleButtonClick={handleButtonClick}
            buttonName={button.name}
            dropDownMenu={button.shortcuts}
            buttonIndex={index}
          />
        </CardContent>
      ))}

      <CardContent sx={{ flexGrow: 1, width: "100%", position: "relative" }}>
        <ContactUs customTextColor={customTextColor} />
      </CardContent>
    </>
  );
};
