import { useTheme } from "@emotion/react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import StarIcon from "@mui/icons-material/Star";
import StarRateIcon from "@mui/icons-material/StarRate";
import { Grid, useMediaQuery } from "@mui/material";
import Badge from "@mui/material/Badge";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Fab from "@mui/material/Fab";
import Rating from "@mui/material/Rating";
import Typography from "@mui/material/Typography";
import { Box } from "@mui/system";
import React from "react";
import { CommonContainer } from "../../BoilarPlateJs/CommonTextContainer";
import { TypographyOxygen } from "../../BoilarPlateJs/TypographyStyled";
//import { dealsAndDiscountData } from "../../../components/AdvertisePages/TourAndTravelServices";
import Button from "@mui/material/Button";
import Pagination from "@mui/material/Pagination";
import { useState } from "react";
import { PageNotFound } from "../../BoilarPlateJs/PageNotFound";

export const FilteredDataContainer = ({ children, ...props }) => {
  const [visibleItems, setVisibleItems] = useState(3);
  const [viewMore, setViewMore] = useState(true);
  const showMoreItems = () => {
    setVisibleItems(dealsAndDiscountData.length); // Or set to dealsAndDiscountData.length to show all
    setViewMore(false);
  };

  const showLessItems = () => {
    setVisibleItems(3);
    setViewMore(true);
  };

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  var commonContaineCustomStyle = null;
  if (isSmallScreen) {
    commonContaineCustomStyle = { pt: 2, pb: 2, pl: 2, pr: 2 };
  }
  const { tourData } = props;
  const dealsAndDiscountData = tourData
    ? tourData.filter((tour) => tour.anyOffer === true)
    : [];

  // If filteredTourData is empty, display a 404 page
  if (tourData.length === 0 || dealsAndDiscountData.length === 0) {
    return (
      <>
        <PageNotFound redirect={"/Tour"} />
      </>
    );
  }

  return (
    <Box id="filteredDataContainer">
      {dealsAndDiscountData && dealsAndDiscountData.length > 0 ? (
        dealsAndDiscountData.slice(0, visibleItems).map((deal, index) => (
          <CommonContainer customStyle={commonContaineCustomStyle}>
            <Card sx={{ display: "flex" }}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box
                    position={"absolute"}
                    sx={{ overflow: "hidden", width: "35%", height: "35%" }}
                  >
                    <Badge
                      badgeContent={"Special Offer"}
                      sx={{
                        ".MuiBadge-badge": {
                          minWidth: "200px",
                          minHeight: "40px",
                          transform: "rotate(-45deg)",
                          //  transformOrigin: "center",
                          backgroundColor: theme.palette.constantColors.pink,
                          top: "-50px",
                          left: "-100px",
                          right: "0px",
                          fontFamily: "Oxygen, sans-serif",
                          fontWeight: "bold",
                        },
                      }}
                    ></Badge>
                  </Box>
                  <CardMedia
                    component="img"
                    height={"100%"}
                    image={"/tourandtravel/PopularTourBackground.jpg"}
                    alt="Tour image"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={5}>
                  <CardContent
                    sx={{
                      flex: "1 0 auto",
                    }}
                  >
                    <Box
                      sx={{
                        [theme.breakpoints.up("lg")]: {
                          borderRight: "2px solid #505050",
                        },
                        [theme.breakpoints.up("md")]: {
                          borderRight: "2px solid #505050",
                        },
                        [theme.breakpoints.down("sm")]: {
                          borderBottom: "2px solid #505050",
                        },
                        [theme.breakpoints.between("sm", "md")]: {
                          borderRight: "2px solid #505050", // This will cover iPad Mini and similar devices
                        },
                      }}
                    >
                      <Box>
                        <TypographyOxygen fontSize={"1.5rem"}>
                          {deal.tripHeading}
                        </TypographyOxygen>
                      </Box>

                      <Box>
                        <TypographyOxygen
                          fontSize={"1.2rem"}
                          fontWeight={"normal"}
                          lineHeight={2}
                        >
                          {deal.tripDescription}
                        </TypographyOxygen>
                      </Box>
                      {!isSmallScreen && (
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"center"}
                          gap={2}
                          padding={theme.spacing(1)}
                        >
                          <Box
                            p={2}
                            width={"40%"}
                            bgcolor={theme.palette.constantColors.dimGray}
                          >
                            <Box display={"flex"} flexDirection={"row"}>
                              <AccessTimeIcon style={{ marginRight: "10px" }} />
                              <Typography component="span" spacing={2}>
                                {deal.tripDurationInDays}
                              </Typography>{" "}
                            </Box>
                          </Box>
                          <Box
                            p={2}
                            width={"40%"}
                            bgcolor={theme.palette.constantColors.dimGray}
                          >
                            <Box display={"flex"} flexDirection={"row"}>
                              <StarIcon style={{ marginRight: "10px" }} />
                              <Typography component="span" spacing={2}>
                                {deal.tripCustomerRating}
                              </Typography>{" "}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Rating
                        name="read-only"
                        value={deal.tripRating}
                        size="large"
                        readOnly
                        icon={<StarRateIcon fontSize="inherit" />}
                        sx={{ marginBottom: theme.spacing(2) }}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <TypographyOxygen
                          fontSize={"1.5rem"}
                          padding={0}
                          sx={{
                            textDecoration: "line-through",
                            color: theme.palette.constantColors.pink,
                          }}
                        >
                          {deal.actualTripAmount}
                        </TypographyOxygen>
                        <TypographyOxygen
                          fontSize={"1.8rem"}
                          padding={0}
                          sx={{
                            color: theme.palette.constantColors.pink,
                          }}
                        >
                          {deal.discountedTripAmount}
                        </TypographyOxygen>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyItems={"center"}
                        pt={theme.spacing(2)}
                      >
                        <Fab variant="extended" size="large" color="primary">
                          VIEW MORE
                        </Fab>
                      </Box>

                      {isSmallScreen && (
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          gap={2}
                          padding={theme.spacing(2)}
                        >
                          <Box
                            p={2}
                            width={"100%"}
                            bgcolor={theme.palette.constantColors.dimGray}
                          >
                            <Box display={"flex"} flexDirection={"row"}>
                              <AccessTimeIcon style={{ marginRight: "10px" }} />
                              <Typography component="span" spacing={2}>
                                {deal.tripDurationInDays}
                              </Typography>{" "}
                            </Box>
                          </Box>
                          <Box
                            p={2}
                            width={"100%"}
                            bgcolor={theme.palette.constantColors.dimGray}
                          >
                            <Box display={"flex"} flexDirection={"row"}>
                              <StarIcon style={{ marginRight: "10px" }} />
                              <Typography component="span" spacing={2}>
                                {deal.tripCustomerRating}
                              </Typography>{" "}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </CommonContainer>
        ))
      ) : (
        <Box>Nothing to show</Box>
      )}

      {dealsAndDiscountData && dealsAndDiscountData.length > 3 && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Button
            onClick={viewMore ? showMoreItems : showLessItems}
            color="primary"
          >
            {viewMore ? "View More" : "Show Less"}
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const FilteredDataContainerWithPagination = ({ children, ...props }) => {
  const itemsPerPage = 3; // Adjust based on your preference
  const [page, setPage] = useState(1);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  var commonContaineCustomStyle = null;
  if (isSmallScreen) {
    commonContaineCustomStyle = { pt: 2, pb: 2, pl: 2, pr: 2 };
  }
  const { tourData } = props;
  const dealsAndDiscountData = tourData
    ? tourData.filter((tour) => tour.anyOffer === true)
    : [];
  const count = Math.ceil(dealsAndDiscountData.length / itemsPerPage);

  const handleChange = (event, value) => {
    setPage(value);
  };

  const handleCardClick = (id) => {
    window.location.href = `/Tour/Tours?id=${id}`;
  };

  if (tourData.length === 0 || dealsAndDiscountData.length === 0) {
    return <PageNotFound redirect={"/Tour"} />;
  }

  return (
    <Box id="filteredDataContainer">
      {dealsAndDiscountData
        .slice((page - 1) * itemsPerPage, page * itemsPerPage)
        .map((deal, index) => (
          <CommonContainer customStyle={commonContaineCustomStyle}>
            <Card sx={{ display: "flex" }}>
              <Grid container>
                <Grid item xs={12} md={4}>
                  <Box
                    position={"absolute"}
                    sx={{ overflow: "hidden", width: "35%", height: "35%" }}
                  >
                    <Badge
                      badgeContent={deal.typeOfOffer}
                      sx={{
                        ".MuiBadge-badge": {
                          minWidth: "200px",
                          minHeight: "40px",
                          transform: "rotate(-45deg)",
                          backgroundColor: theme.palette.constantColors.pink,
                          top: "-50px",
                          left: "-100px",
                          right: "0px",
                          fontFamily: "Oxygen, sans-serif",
                          fontWeight: "bold",
                        },
                      }}
                    ></Badge>
                  </Box>
                  <CardMedia
                    component="img"
                    height={"100%"}
                    image={deal.imagePath}
                    alt="Tour image"
                  />
                </Grid>
                <Grid item xs={12} sm={8} md={5}>
                  <CardContent
                    sx={{
                      flex: "1 0 auto",
                    }}
                  >
                    <Box
                      sx={{
                        [theme.breakpoints.up("lg")]: {
                          borderRight: "2px solid #505050",
                        },
                        [theme.breakpoints.up("md")]: {
                          borderRight: "2px solid #505050",
                        },
                        [theme.breakpoints.down("sm")]: {
                          borderBottom: "2px solid #505050",
                        },
                        [theme.breakpoints.between("sm", "md")]: {
                          borderRight: "2px solid #505050", // This will cover iPad Mini and similar devices
                        },
                      }}
                    >
                      <Box>
                        <TypographyOxygen fontSize={"1.5rem"}>
                          {deal.tripHeading}
                        </TypographyOxygen>
                      </Box>

                      <Box>
                        <TypographyOxygen
                          fontSize={"1.2rem"}
                          fontWeight={"normal"}
                          lineHeight={2}
                        >
                          {deal.tripDescription}
                        </TypographyOxygen>
                      </Box>
                      {!isSmallScreen && (
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"center"}
                          gap={2}
                          padding={theme.spacing(1)}
                        >
                          <Box
                            p={2}
                            width={"40%"}
                            bgcolor={theme.palette.constantColors.dimGray}
                          >
                            <Box display={"flex"} flexDirection={"row"}>
                              <AccessTimeIcon style={{ marginRight: "10px" }} />
                              <Typography component="span" spacing={2}>
                                {deal.tripDurationInDays}
                              </Typography>{" "}
                            </Box>
                          </Box>
                          <Box
                            p={2}
                            width={"40%"}
                            bgcolor={theme.palette.constantColors.dimGray}
                          >
                            <Box display={"flex"} flexDirection={"row"}>
                              <StarIcon style={{ marginRight: "10px" }} />
                              <Typography component="span" spacing={2}>
                                {deal.tripCustomerRating}
                              </Typography>{" "}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </CardContent>
                </Grid>
                <Grid item xs={12} sm={4} md={3}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                  >
                    <CardContent sx={{ flex: "1 0 auto" }}>
                      <Rating
                        name="read-only"
                        value={deal.tripRating}
                        size="large"
                        readOnly
                        icon={<StarRateIcon fontSize="inherit" />}
                        sx={{ marginBottom: theme.spacing(2) }}
                      />

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        <TypographyOxygen
                          fontSize={"1.5rem"}
                          padding={0}
                          sx={{
                            textDecoration: "line-through",
                            color: theme.palette.constantColors.pink,
                          }}
                        >
                          {deal.actualTripAmount}
                        </TypographyOxygen>
                        <TypographyOxygen
                          fontSize={"1.8rem"}
                          padding={0}
                          sx={{
                            color: theme.palette.constantColors.pink,
                          }}
                        >
                          {deal.discountedTripAmount}
                        </TypographyOxygen>
                      </Box>
                      <Box
                        display={"flex"}
                        flexDirection={"column"}
                        justifyItems={"center"}
                        pt={theme.spacing(2)}
                      >
                        <Fab
                          variant="extended"
                          size="large"
                          color="primary"
                          onClick={() => handleCardClick(deal.pkId)}
                        >
                          VIEW MORE
                        </Fab>
                      </Box>

                      {isSmallScreen && (
                        <Box
                          display={"flex"}
                          flexDirection={"column"}
                          justifyContent={"center"}
                          gap={2}
                          padding={theme.spacing(2)}
                        >
                          <Box
                            p={2}
                            width={"100%"}
                            bgcolor={theme.palette.constantColors.dimGray}
                          >
                            <Box display={"flex"} flexDirection={"row"}>
                              <AccessTimeIcon style={{ marginRight: "10px" }} />
                              <Typography component="span" spacing={2}>
                                {deal.tripDurationInDays}
                              </Typography>{" "}
                            </Box>
                          </Box>
                          <Box
                            p={2}
                            width={"100%"}
                            bgcolor={theme.palette.constantColors.dimGray}
                          >
                            <Box display={"flex"} flexDirection={"row"}>
                              <StarIcon style={{ marginRight: "10px" }} />
                              <Typography component="span" spacing={2}>
                                {deal.tripCustomerRating}
                              </Typography>{" "}
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </CardContent>
                  </Box>
                </Grid>
              </Grid>
            </Card>
          </CommonContainer>
        ))}
      {dealsAndDiscountData.length > itemsPerPage && (
        <Box display="flex" justifyContent="center" mt={2}>
          <Pagination count={count} page={page} onChange={handleChange} />
        </Box>
      )}
    </Box>
  );
};
