import { Button, Typography, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import "../../../AIHome/globalAI.css";

export const LoginButton = (children, ...props) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const handleLoginClick = () => {
    window.location.href = `/ai`;
  };

  return (
    <>
      {isLargeScreen && (
        <Button
          variant="text"
          style={{ color: theme.palette.text.primary }}
          onClick={handleLoginClick}
        >
          <Typography
            variant="h6"
            fontFamily="Inter, sans-serif" // Add your desired font family here
            fontSize="1rem"
          >
            Login
          </Typography>
        </Button>
      )}

      {!isLargeScreen && (
        <Button
          variant="outlined"
          style={{ color: theme.palette.text.primary, padding: "10px" }}
          onClick={handleLoginClick}
          fullWidth
        >
          <Typography variant="body2">Login</Typography>
        </Button>
      )}
    </>
  );
};
