import { Box } from "@mui/material";
import React from "react";
//import Carousel from "react-material-ui-carousel";
import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Button, Typography } from "@mui/material";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import "../CodingRippler.css"; // Import the custom CSS file
import { RouteToContactUs } from "../CodingRipplerHome/ContactUs/ContactUs";
import { RouteToAIPortal } from "../CodingRipplerWidgets";

export const CRTopBannerContainer = ({ children, props }) => {
  return (
    <Carousel
      showThumbs={false}
      showStatus={false}
      infiniteLoop={true}
      autoPlay={true}
      interval={5000}
      showIndicators={false}
      aria-label="Top Banners"
    >
      {children}
    </Carousel>
  );
};

export const CRTopBanner = ({ children, ...props }) => {
  const { visibility } = props;

  return (
    <Box
      sx={{
        visibility: visibility ? "visible" : "hidden",
        minHeight: "60px",
        maxHeight: "60px",
      }}
    >
      <CRTopBannerContainer>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Adjust this as needed
            backgroundColor: "white", // No background color
            flexDirection: "row",
            gap: 1,
            p: 2,
          }}
        >
          <Typography
            variant="body1"
            className={"typography-custom-css"}
            fontWeight="bold"
          >
            Unlock Custom AI Solutions Tailored to Your Needs. Elevate Your
            Business with Cutting-Edge Technology.
          </Typography>
          <Button
            variant="outlined"
            size="large"
            onClick={RouteToAIPortal}
            className="button-outlined"
            onKeyDown={(e) => {
              if (e.key === "Enter" || e.key === " ") {
                RouteToAIPortal();
              }
            }}
            aria-label="Check out our AI Services! Click here."
            endIcon={<ArrowRightAltOutlinedIcon />}
            p={10}
            sx={{
              zIndex: 1000,
              //              ...buttonStyle,
            }}
          >
            Discover AI Solutions
          </Button>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%", // Adjust this as needed
            backgroundColor: "white", // No background color
            flexDirection: "row",
            gap: 1,
            p: 2,
          }}
        >
          <CalendarMonthOutlinedIcon className="theme-background-color" />
          <Typography
            variant="body1"
            color="black"
            className={"typography-custom-css"}
            fontWeight="bold"
          >
            Lets grow your business in 2024,{" "}
            <span
              style={{
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={RouteToContactUs}
              onKeyDown={(e) => {
                if (e.key === "Enter" || e.key === " ") {
                  RouteToContactUs();
                }
              }}
            >
              {" "}
              book a free 30min call
            </span>{" "}
            today.
          </Typography>
        </Box>
      </CRTopBannerContainer>
    </Box>
  );
};
