import { CardMedia, Grid } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { SlideInFromTopEffect } from "../../../BoilarPlateJs/MotionEffects";
import { AdditionalInfoV2 } from "../../CodingRipplerHome/WhatWeDo/WhatWeDoWidgets";
import { CommonContainer } from "../../CodingRipplerWidgets";

export const BasicAdditionalFeatures = ({ children, ...props }) => {
  const { customStyle } = props;

  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  if (isPhone) {
  } else if (isTab) {
  }

  const pageContent = [
    {
      headers: {
        image: "/codingrippler/background/service-bg-2.svg",
        title: "Additional Features",
        heading: "Empower Your Brand with a Custom-Built Solution",
        subHeading:
          "Experience tailored design, optimized performance, and dedicated support crafted for your growth.",
        icon: "CheckCircleIcon",
      },
      additionalInfo: [
        {
          title: "Unique, branded designs beyond basic templates.",
        },

        {
          title: "Built to handle growth and deliver fast load times.",
        },

        {
          title: "Expert team on hand for real-time support and updates.",
        },

        {
          title: "Advanced features tailored to your business needs.",
        },
      ],
      customStyle: {
        headerTextColor: "typography-custom-css-white-text",
        subHeaderTextColor: "typography-custom-css-white-text",
      },
    },
  ];

  const headers = pageContent[0].headers;

  return (
    <Grid
      container
      spacing={2}
      className="theme-specific-background-color"
      overflow="hidden"
    >
      <Grid item xs={12} lg={6}>
        {isPhoneorTab ? (
          <Box mt={{ xs: 5, lg: 10 }}>
            <CommonContainer>
              <AdditionalInfoV2
                pageContent={pageContent}
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
              />
            </CommonContainer>
          </Box>
        ) : (
          <CommonContainer>
            <Box
              display="flex"
              minHeight={700}
              height={{ xs: "50vh", lg: "100vh" }}
              justifyContent="center"
              alignItems="center"
            >
              <CardMedia
                component="img"
                image={headers.image}
                alt={headers.title}
                height="100%"
                width="100%"
                sx={{
                  ...customStyle,
                  objectFit: "contain",
                  position: "relative",
                  transform: "scale(0.5)", // Initial scale
                }}
              />
            </Box>
          </CommonContainer>
        )}
      </Grid>

      <Grid item xs={12} lg={6}>
        {isPhoneorTab ? (
          <CommonContainer>
            <CardMedia
              component="img"
              image={headers.image}
              alt={headers.title}
              height="100%"
              width="100%"
              sx={{
                ...customStyle,
                objectFit: "contain",
                position: "relative",
                transform: { xs: "scale(1)", sm: "scale(0.75)" }, // Initial scale
              }}
            />
          </CommonContainer>
        ) : (
          <CommonContainer>
            <Box
              display="flex"
              minHeight={700}
              height="100vh"
              justifyContent="center"
              alignItems="center"
            >
              <SlideInFromTopEffect>
                <AdditionalInfoV2
                  pageContent={pageContent}
                  isPhone={isPhone}
                  isTab={isTab}
                  deviceWithSmallHeight={deviceWithSmallHeight}
                />
              </SlideInFromTopEffect>
            </Box>
          </CommonContainer>
        )}
      </Grid>
    </Grid>
  );
};
