import { Route, Routes } from "react-router-dom";
import { ParallaxHome } from "./ParallaxHome";

export const Parallax = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <ParallaxHome
              isPhone={isPhone}
              isTab={isTab}
              isPhoneorTab={isPhoneorTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            />
          }
        />
      </Routes>
    </>
  );
};
