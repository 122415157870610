import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { SlideInFromTopEffect } from "../BoilarPlateJs/MotionEffects";
import { AboutUs } from "./CodingRipplerHome/AboutUs/AboutUs";
import { CRHome } from "./CodingRipplerHome/CRHome";
import { Services } from "./CRDemo/OurServices/Services";
import { CRFooter } from "./Footer/CRFooter";
import { CodingRipplerNavigation } from "./Navigations/CodingRipplerNavigation";
import { CRNavigationSmallerDevice } from "./Navigations/NavigationSmallerDevice/CRNavigationSmallerDevice";
import { useEffect, useState } from "react";

export const CodingRippler = ({ children, ...props }) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("lg"));
  const deviceWithSmallHeight = useMediaQuery("(max-height: 750px)");

  const isPhoneOrTab = isPhone || isTab;

  const { logoPath } = props;

  const [
    displayScrollViewNavigation,
    setDisplayScrollViewNavigation,
  ] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setDisplayScrollViewNavigation(true);
      } else {
        setDisplayScrollViewNavigation(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <Grid
      container
      sx={{
        maxWidth: "100%",
      }}
    >
      <Grid item xs={12}>
        {isPhoneOrTab ? (
          <CRNavigationSmallerDevice
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            logoPath={logoPath}
          />
        ) : (
          <CodingRipplerNavigation
            logoPath={logoPath}
            displayScrollViewNavigation={displayScrollViewNavigation}
          />
        )}
      </Grid>

      <Routes>
        <Route
          path="/*"
          element={
            <Grid item xs={12}>
              <CRHome
                isPhone={isPhone}
                isTab={isTab}
                isPhoneorTab={isPhoneOrTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                displayScrollViewNavigation={displayScrollViewNavigation}
                logoPath={logoPath}
              />
            </Grid>
          }
        />

        <Route
          path="/about-us/*"
          element={
            <Grid item xs={12}>
              <AboutUs
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
              />
            </Grid>
          }
        />

        <Route
          path="/web/*"
          element={
            <Services
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneOrTab}
            />
          }
        />
      </Routes>

      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <CRFooter
            isPhone={isPhone}
            isPhoneorTab={isPhoneOrTab}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
          />
        </SlideInFromTopEffect>
      </Grid>
    </Grid>
  );
};
