import { Route, Routes } from "react-router-dom";
import { BoshTheSalesAgent } from "./BoshTheSalesAgent/BoshTheSalesAgent";
import { InboundHome } from "./BoshTheSalesAgent/InboundAISDR/InboundHome";
import { OutboundHome } from "./BoshTheSalesAgent/OutboundAIBDR/OutboundHome";

export const AIAgentsHome = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <>
      <Routes>
        <Route
          path="/catalyst-the-sales-agent"
          element={
            <BoshTheSalesAgent
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/catalyst-inbound-agent"
          element={
            <InboundHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/catalyst-outbound-agent"
          element={
            <OutboundHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
      </Routes>
    </>
  );
};
