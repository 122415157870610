import { Fab, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import "../../../AIHome/globalAI.css";

export const SignUpButton = (children, ...props) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const handleSignUpClick = () => {
    window.location.href = `/ai`;
  };

  return (
    // <Button
    //   variant="contained"
    //   style={{ color: theme.palette.text.primary }}
    //   onClick={handleSignUpClick}
    //   className="button"
    // >
    //   Sign Up
    // </Button>

    <>
      {isLargeScreen && (
        <Fab
          variant="extended"
          size="large"
          color="primary"
          className="button"
          onClick={handleSignUpClick}
          p={10}
        >
          Sign Up
        </Fab>
      )}

      {!isLargeScreen && (
        <Fab
          variant="extended"
          size="large"
          color="primary"
          className="button"
          onClick={handleSignUpClick}
          sx={{ width: "100%" }}
        >
          Sign Up
        </Fab>
      )}
    </>
  );
};
