import { Box, Grid, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useRef, useState } from "react";
import { CommonContainer } from "../../BoilarPlateJs/CommonTextContainer";
import { BookNowButton } from "../BookTheTourPage/BookNowButton";
import { TourBookingForm } from "../BookTheTourPage/BookTheTourPage";
import { TourActions } from "../CardActions";
import { OfferContainer } from "../PopularTours/OfferContainer";
import {
  YouMayAlsoLikePage,
  YouMayAlsoLikePageReverse,
} from "../PopularTours/YouMayAlsoLikePage";
import { TabContainer } from "../TourDescriptionTabs";
import { TourReviews } from "../TourReviews/TourReviews";
import { DiscountOnTour } from "./DiscountOnTour";
import { PackageShortcuts } from "./PackageShortcuts";
import { TourDescription } from "./TourDescription";

const tabDetailsLargeScreen = [
  {
    label: "Details",
    content: "Details content",
  },
  {
    label: "Itinerary",
    content: "Itinerary content",
  },
  // {
  //   label: "Location",
  //   content: "Location content",
  // },
  {
    label: "Photos",
    content: "Photos content",
  },
  {
    label: "Book the tour",
    content: "Book the tour",
  },
];

const tabDetailsSmallScreen = [
  {
    label: "Details",
    content: "Details content",
  },
];

const tabDetailsMediumScreen = [
  {
    label: "Details",
    content: "Details content",
  },
  {
    label: "Itinerary",
    content: "Itinerary content",
  },
];

export const TourDetails = ({ children, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isBetweenMiddleAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const tourBookingFormRef = useRef(null);
  const [isVisible, setIsVisible] = useState(true);

  const { individualTourInfo } = props;
  const { tourData } = props;

  const handleClick = () => {
    tourBookingFormRef.current.scrollIntoView({ behavior: "smooth" });
    setIsVisible(false);
  };

  var tabDetails = tabDetailsLargeScreen;
  var transform = "translateY(-100%)";
  var tourActionTextsFontSize = "1.2rem";
  var tourActionBorderRight = "2px solid";
  var tourActionBorderBottom = "0px solid";
  var tabBoxLeftMargin = 10;
  var iconLeftMargin = 2;
  var tabPanleWidth = `calc(70vw - ${2 * tabBoxLeftMargin}px)`;
  var tabRightMargin = "20px";
  var noOfColumns = 4;
  var noOfSlidesToShow = 2;

  if (isSmallScreen) {
    tabDetails = tabDetailsSmallScreen;
    tourActionTextsFontSize = "0.8rem";
    tourActionBorderRight = "0px";
    tourActionBorderBottom = "2px solid";
    tabBoxLeftMargin = 5;
    iconLeftMargin = 0;
    tabPanleWidth = `calc(80vw - ${3 * tabBoxLeftMargin}px)`;
    tabRightMargin = "0px";
    noOfColumns = 12;
    noOfSlidesToShow = 1;
    transform = "translate(0%, -3.5%)";
  } else if (isMediumScreen) {
    tabDetails = tabDetailsMediumScreen;
    transform = "translateY(-70%)";
    tourActionTextsFontSize = "1.2rem";
    tourActionBorderRight = "5px";
    tabBoxLeftMargin = "50%";
    iconLeftMargin = 0;
    tourActionBorderBottom = "2px solid";
    tourActionBorderRight = "0";
    tabPanleWidth = "60vw";
    tabRightMargin = "20px";
    noOfColumns = 4;
    noOfSlidesToShow = 2;
    transform = "translate(0%, -5%)";
  } else if (isBetweenMiddleAndLargeScreen) {
    tabBoxLeftMargin = 10;
    tabPanleWidth = `calc(90vw - ${2 * tabBoxLeftMargin}px)`;
    noOfSlidesToShow = 2;
    transform = "translate(0%, -5%)";
  }

  return isSmallScreen || isMediumScreen || isBetweenMiddleAndLargeScreen ? (
    <CommonContainer customStyle={{ pl: 0, pr: 0, transform: { transform } }}>
      <BookNowButton
        componentVisibility={true}
        handleClick={handleClick}
        isVisible={isVisible}
      />
      <Grid container spacing={2} id="tourDetails">
        <Grid item xs={12}>
          <TabContainer
            tabDetails={tabDetails}
            customStyle={{
              color: "black",
              backgroundColor: "white",
              marginRight: tabRightMargin,
              selectedBackgroundColor: "#429cb5",
              tabIndicatorColor: "#429cb5",
              labelColor: "black",
              tabPanleWidth: tabPanleWidth,
            }}
          >
            <TourActions
              data={individualTourInfo}
              customStyle={{
                borderRight: tourActionBorderRight,
                borderBottom: tourActionBorderBottom,
                textFontSize: tourActionTextsFontSize,
                iconLeftMargin: iconLeftMargin,
              }}
            />

            <PackageShortcuts
              noOfColumns={noOfColumns}
              data={individualTourInfo}
            />

            <TourDescription individualTourInfo={individualTourInfo} />
          </TabContainer>
        </Grid>

        <Grid item xs={12}>
          <TourReviews
            noOfReviews={5.0}
            individualTourInfo={individualTourInfo}
          />
        </Grid>

        <Grid item xs={12}>
          <YouMayAlsoLikePage
            tourData={tourData}
            individualTourInfo={individualTourInfo}
            noOfSlidesToShow={noOfSlidesToShow}
          />
        </Grid>

        <Grid item xs={12}>
          <Box>
            <OfferContainer data={individualTourInfo} />
            <DiscountOnTour data={individualTourInfo} />
            <TourBookingForm
              tourBookingFormRef={tourBookingFormRef}
              bookNowVisibilityState={setIsVisible}
              data={individualTourInfo}
            />
          </Box>{" "}
        </Grid>
      </Grid>
    </CommonContainer>
  ) : (
    <CommonContainer customStyle={{ transform: "translate(0, -7.5%)" }}>
      <Grid container spacing={2} id="tourDetails">
        <Grid item sm={12} lg={9}>
          <TabContainer
            tabDetails={tabDetails}
            customStyle={{
              color: "black",
              backgroundColor: "white",
              marginRight: tabRightMargin,
              selectedBackgroundColor: "#429cb5",
              tabIndicatorColor: "#429cb5",
              labelColor: "black",
              tabPanleWidth: tabPanleWidth,
            }}
          >
            <TourActions
              data={individualTourInfo}
              customStyle={{
                borderRight: tourActionBorderRight,
                borderBottom: tourActionBorderBottom,
                textFontSize: tourActionTextsFontSize,
                iconLeftMargin: iconLeftMargin,
              }}
            />

            <PackageShortcuts
              noOfColumns={noOfColumns}
              data={individualTourInfo}
            />

            <TourDescription individualTourInfo={individualTourInfo} />
          </TabContainer>
        </Grid>

        <Grid item sm={12} md={6} lg={3}>
          <Box>
            <OfferContainer data={individualTourInfo} />
            <DiscountOnTour data={individualTourInfo} />
            <TourBookingForm data={individualTourInfo} />
            <Box>
              <YouMayAlsoLikePageReverse
                tourData={tourData}
                individualTourInfo={individualTourInfo}
                startIndex={0}
                sliceBy={1}
              />
            </Box>{" "}
          </Box>{" "}
        </Grid>

        <Grid item sm={12} lg={9}>
          <TourReviews
            noOfReviews={5.0}
            individualTourInfo={individualTourInfo}
          />
        </Grid>

        <Grid item sm={12} md={6} lg={3}>
          <Box>
            <YouMayAlsoLikePageReverse
              tourData={tourData}
              individualTourInfo={individualTourInfo}
              startIndex={1}
              sliceBy={4}
            />
          </Box>{" "}
        </Grid>

        <Grid item lg={9}>
          <YouMayAlsoLikePage
            tourData={tourData}
            individualTourInfo={individualTourInfo}
            noOfSlidesToShow={2}
          />
        </Grid>
      </Grid>
    </CommonContainer>
  );
};
