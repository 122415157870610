import Box from "@mui/system/Box";
import * as React from "react";

export function CircularContainer({ children, ...props }) {
  const defaultStyles = {
    bgColorHoverEffect: "#429cb5",
    cursor: "pointer",
    borderRadius: "50%",
    overflow: "hidden",
    width: "15vw",
    height: "15vw",
    border: "3px solid white",
    transform: "translate(0%, 0%)",
  };

  const { customStyle } = props;
  const { data } = props;

  const mergedStyles = { ...defaultStyles, ...customStyle };

  const handleCardClick = (name) => {
    window.location.href = `/Tour/Type?packageType=${name}`;
  };

  return (
    <Box
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      sx={{
        "&:hover": {
          backgroundColor: mergedStyles.bgColorHoverEffect,
        },
        cursor: mergedStyles.cursor,
        borderRadius: mergedStyles.borderRadius,
        overflow: mergedStyles.overflow,
        width: mergedStyles.width,
        height: mergedStyles.height,
        border: mergedStyles.border,
        transform: mergedStyles.transform,
      }}
      onClick={() => {
        handleCardClick(data.tour_type);
      }}
    >
      {children}
    </Box>
  );
}
