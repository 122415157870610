//framer motion
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

export const SlickMotionEffect = ({ children, ...props }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Change to false if you want the animation to trigger again whenever it comes into view
  });

  const variants = {
    hidden: { opacity: 0, y: 50, transition: { duration: 1 } },
    show: { opacity: 1, y: 0, transition: { duration: 1 } },
    exit: { opacity: 0, y: -50, transition: { duration: 1 } },
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={inView ? "show" : "exit"}
      exit="exit"
      style={{ width: "100%", maxWidth: "100%" }} // Ensure the width does not exceed the container
    >
      {children}
    </motion.div>
  );
};

export const PopMotionEffect = ({ children, ...props }) => {
  const { ref } = useInView({
    triggerOnce: false, // Change to false if you want the animation to trigger again whenever it comes into view
  });

  return (
    <motion.div
      ref={ref}
      initial={{ scale: 0 }}
      animate={{ scale: 1 }}
      transition={{
        type: "spring",
        stiffness: 260,
        damping: 20,
      }}
      style={{ width: "100%", maxWidth: "100%" }} // Ensure the width does not exceed the container
    >
      {children}{" "}
    </motion.div>
  );
};

export const SlideInFromRightEffect = ({ children, ...props }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Change to false if you want the animation to trigger again whenever it comes into view
  });

  const variants = {
    hidden: { opacity: 0, x: 50, transition: { duration: 1 } },
    show: { opacity: 1, x: 0, transition: { duration: 1 } },
    exit: { opacity: 0, x: -50, transition: { duration: 1 } },
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={inView ? "show" : "exit"}
      exit="exit"
      style={{ width: "100%", maxWidth: "100%" }} // Ensure the width does not exceed the container
    >
      {children}
    </motion.div>
  );
};

export const SlideInFromLeftEffect = ({ children, ...props }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Change to false if you want the animation to trigger again whenever it comes into view
  });

  const variants = {
    hidden: { opacity: 0, x: -50, transition: { duration: 1 } },
    show: { opacity: 1, x: 0, transition: { duration: 1 } },
    exit: { opacity: 0, x: 50, transition: { duration: 1 } },
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={inView ? "show" : "exit"}
      exit="exit"
      style={{ width: "100%", maxWidth: "100%" }} // Ensure the width does not exceed the container
    >
      {children}
    </motion.div>
  );
};

export const SlideInFromBottomEffect = ({ children, ...props }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Change to false if you want the animation to trigger again whenever it comes into view
  });

  const variants = {
    hidden: { opacity: 0, y: 50, transition: { duration: 1 } },
    show: { opacity: 1, y: 0, transition: { duration: 1 } },
    exit: { opacity: 0, y: -50, transition: { duration: 1 } },
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={inView ? "show" : "exit"}
      exit="exit"
      style={{ width: "100%", maxWidth: "100%" }} // Ensure the width does not exceed the container
    >
      {children}
    </motion.div>
  );
};

export const SlideInFromTopEffect = ({ children, ...props }) => {
  const { ref, inView } = useInView({
    triggerOnce: false, // Change to false if you want the animation to trigger again whenever it comes into view
  });

  const variants = {
    hidden: { opacity: 0, y: -50, transition: { duration: 1 } },
    show: { opacity: 1, y: 0, transition: { duration: 1 } },
    exit: { opacity: 0, y: 50, transition: { duration: 1 } },
  };

  return (
    <motion.div
      ref={ref}
      variants={variants}
      initial="hidden"
      animate={inView ? "show" : "exit"}
      exit="exit"
      style={{ width: "100%", maxWidth: "100%" }} // Ensure the width does not exceed the container
      key={props.key ? props.key : ""} // Ensure the width does not exceed the container
    >
      {children}
    </motion.div>
  );
};
