import { CardMedia, Grid } from "@mui/material";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import React from "react";
import { SlickMotionEffect } from "../../BoilarPlateJs/MotionEffects";
import { SwiperSlideContainerV2 } from "../../BoilarPlateJs/SwiperSlide";
import { TitleDecorationBottomCenter } from "../../BoilarPlateJs/TitleDecorationBottomCenter";
import {
  TypographyKaushanScript,
  TypographyOxygen,
} from "../../BoilarPlateJs/TypographyStyled";

var findATourData = [
  {
    path: "../TourAndTravel/PopularTourBackground.jpg",
    imageHeight: "15vh",
    imageWidth: "15vw",
    destination: "INDIA INDIA INDIA",
  },
  {
    path: "../TourAndTravel/PopularTourBackground.jpg",
    imageHeight: "15vh",
    imageWidth: "15vw",
    destination: "USA",
  },
  {
    path: "../TourAndTravel/PopularTourBackground.jpg",
    imageHeight: "15vh",
    imageWidth: "15vw",
    destination: "Maldives",
  },
  {
    path: "../TourAndTravel/PopularTourBackground.jpg",
    imageHeight: "15vh",
    imageWidth: "15vw",
    destination: "Italy",
  },
];

// Step 1: Define the function that encapsulates the JSX logic
function TourCard({ index, tour, ...props }) {
  const { padding = "2%" } = props;
  return (
    <Grid
      container
      sx={{
        padding: padding,
      }}
    >
      <Grid item xs={12}>
        <Box
          key={index}
          display="flex"
          sx={{
            position: "relative",
            flexGrow: 1,
            // "&:not(:last-child)": {
            //   marginRight: "2%",
            // },
          }}
        >
          <SlickMotionEffect>
            <Card
              sx={{
                borderRadius: "15px",
              }}
              p={5}
            >
              <Box
                textAlign="center"
                display="flex"
                alignItems="center"
                justifyContent="center"
                sx={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              >
                <TypographyOxygen variant="body2" fontSize={"1.5rem"}>
                  {tour.destination}
                </TypographyOxygen>
              </Box>
              <CardMedia
                component="img"
                image={tour.path}
                sx={{
                  width: "100%",
                }}
              />
            </Card>
          </SlickMotionEffect>
        </Box>
      </Grid>
    </Grid>
  );
}

export const FindATour = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isBetweenMiddleAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const isExtraSmallScreen = useMediaQuery("(max-width:380px)");
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  //Title Decoration
  var marginLeft = 10;
  var marginRight = 10;

  // subHeading font size
  var subHeadingFontSize = "3.0rem";

  // No of slides to show
  var slidesToShow = 5;

  //Custom styles for CircularContainer

  //grid container containing media padding
  var gridPaddingLeft = 10;
  var gridPaddingRight = 10;

  if (isSmallScreen) {
    marginLeft = 2;
    marginRight = 2;
    slidesToShow = 1;
    gridPaddingLeft = 0;
    gridPaddingRight = 0;
    if (isExtraSmallScreen) {
      subHeadingFontSize = "2.0rem";
    }
  } else if (isMediumScreen) {
    marginLeft = 3;
    marginRight = 3;
    slidesToShow = 1;
  } else if (isBetweenMiddleAndLargeScreen) {
    marginLeft = 4;
    marginRight = 4;
    slidesToShow = 4;
    slidesToShow = 2;
  } else if (isExtraLargeScreen) {
  }

  return (
    <Grid
      container
      id="findaTour"
      sx={{ backgroundColor: "#429cb5" }}
      pt={5}
      pb={5}
    >
      <Grid item xs={12}>
        <Box textAlign={"center"}>
          <TypographyKaushanScript>Find a Tour by</TypographyKaushanScript>
          <TypographyOxygen fontSize={subHeadingFontSize}>
            DESTINATIONS
          </TypographyOxygen>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TitleDecorationBottomCenter
          marginLeft={marginLeft}
          marginRight={marginRight}
        />
      </Grid>

      <Grid
        item
        xs={12}
        pt={5}
        pb={5}
        pl={gridPaddingLeft}
        pr={gridPaddingRight}
      >
        {isSmallScreen || isMediumScreen || isBetweenMiddleAndLargeScreen ? (
          <SwiperSlideContainerV2 slidesToShow={slidesToShow}>
            {findATourData.map((tour, index) => (
              <Grid
                container
                sx={{
                  padding: "5%",
                }}
              >
                <Grid item xs={12}>
                  <Box
                    key={index}
                    display="flex"
                    sx={{
                      position: "relative",
                      flexGrow: 1,
                      // "&:not(:last-child)": {
                      //   marginRight: "2%",
                      // },
                    }}
                  >
                    <Card
                      sx={{
                        borderRadius: "15px",
                      }}
                      p={5}
                    >
                      <Box
                        textAlign="center"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                          position: "absolute",
                          width: "100%",
                          height: "100%",
                        }}
                      >
                        <TypographyOxygen variant="body2" fontSize={"1.5rem"}>
                          {tour.destination}
                        </TypographyOxygen>
                      </Box>
                      <CardMedia
                        component="img"
                        image={tour.path}
                        sx={{
                          width: "100%",
                        }}
                      />
                    </Card>
                  </Box>
                </Grid>
              </Grid>
            ))}
          </SwiperSlideContainerV2>
        ) : (
          <Box display="flex">
            {findATourData.map((tour, index) => TourCard({ index, tour }))}
          </Box>
        )}
      </Grid>
    </Grid>
  );
};
