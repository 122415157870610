import { Grid } from "@mui/material";
import { TabsContainer } from "./TabsContainer";
import { SalesTabAnimationComponent } from "./TabWidgets/SalesTabAnimationComponent";
import { SalesTabDetails } from "./TabWidgets/SalesTabDetails";

export const semicircle = () => {
  return {
    /* <Box
            sx={{
              position: "absolute",
              zIndex: 1,
              width: "100%",
              height: "100%",
              backgroundColor: "#F5F5F5",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            {boxes.map((_, index) => {
              const angle = (index / 360) * 1.5 * Math.PI; // Calculate angle for each box
              const radius = 300; // Radius of the circle
              const centerX = 150; // Center X position
              const centerY = 0; // Center Y position
              const increment = 2; // Increment value for marginTop

              const marginLeft = centerX + radius * Math.sin(angle); // Calculate x position
              const marginTop = index * increment; // Calculate y position

              return (
                <Box
                  key={index}
                  border="1px dotted black"
                  sx={{
                    position: "absolute",
                    left: `${marginLeft}px`,
                    top: `${marginTop}px`,
                    transform: "rotate(90deg)",
                  }}
                ></Box>
              );
            })}
          </Box> */
  };
};

export const SalesTabPanel = (props) => {
  const { value } = props;

  return (
    <TabsContainer value={value} index={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={7}>
          <SalesTabDetails />
        </Grid>

        <Grid item xs={12} md={4} lg={5}>
          <SalesTabAnimationComponent />
        </Grid>
      </Grid>
    </TabsContainer>
  );
};
