import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import ChatIcon from "@mui/icons-material/Chat";
import FavoriteIcon from "@mui/icons-material/Favorite";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { Grid, IconButton, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { TfiWorld } from "react-icons/tfi";
import { CircularImageContainer } from "../BoilarPlateJs/JsUtils";
import { TypographyOxygen } from "../BoilarPlateJs/TypographyStyled";
import "../TourAndTravel/HomePages/tourandtravel.css";

export const WhyChooseUs = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isBetweenMiddleAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const isExtraSmallScreen = useMediaQuery("(max-width:380px)");
  var headingFontSize = "3.0rem";
  var descriptionWidth = "15vw";
  var containerLeftPadding = 15;
  if (isSmallScreen) {
    descriptionWidth = "40vw";
    containerLeftPadding = 5;
    if (isExtraSmallScreen) {
      headingFontSize = "2.0rem";
    }
  } else if (isMediumScreen) {
    descriptionWidth = "80vw";
  } else if (isBetweenMiddleAndLargeScreen) {
    //    descriptionWidth = "20vw";
  }

  return (
    <Grid
      id="whyChooseUs"
      container
      pl={containerLeftPadding}
      pb={10}
      pt={10}
      style={{
        backgroundColor: "#429cb5",
        //       height: "100vh",
      }}
    >
      <Grid item xs={12} pb={5}>
        <TypographyOxygen variant="h3" fontSize={headingFontSize}>
          {" "}
          Why Choose Us
        </TypographyOxygen>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box display={"flex"}>
          <CircularImageContainer>
            <IconButton>
              <TfiWorld></TfiWorld>
            </IconButton>
          </CircularImageContainer>
          <Box pl={2} width={descriptionWidth}>
            <TypographyOxygen fontSize={"1.5rem"}>
              Diverse Destinations
            </TypographyOxygen>

            <TypographyOxygen
              variant="body2"
              fontWeight={"normal"}
              fontSize={"1.0rem"}
              lineHeight={2}
            >
              Wherever you go becomes a part of you somehow.
            </TypographyOxygen>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box display={"flex"}>
          <CircularImageContainer>
            <IconButton>
              <MonetizationOnIcon />
            </IconButton>
          </CircularImageContainer>
          <Box pl={2} width={descriptionWidth}>
            <TypographyOxygen fontSize={"1.5rem"}>
              Diverse Destinations
            </TypographyOxygen>

            <TypographyOxygen
              variant="body2"
              fontWeight={"normal"}
              fontSize={"1.0rem"}
              lineHeight={2}
            >
              Wherever you go becomes a part of you somehow.
            </TypographyOxygen>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box display={"flex"}>
          <CircularImageContainer>
            <IconButton>
              <CameraAltIcon></CameraAltIcon>
            </IconButton>
          </CircularImageContainer>

          <Box pl={2} width={descriptionWidth}>
            <TypographyOxygen fontSize={"1.5rem"}>
              Diverse Destinations
            </TypographyOxygen>

            <TypographyOxygen
              variant="body2"
              fontWeight={"normal"}
              fontSize={"1.0rem"}
              lineHeight={2}
            >
              Wherever you go becomes a part of you somehow.
            </TypographyOxygen>
          </Box>
        </Box>
      </Grid>

      <Grid item xs={12} md={4}>
        <Box display={"flex"}>
          <CircularImageContainer>
            <IconButton>
              <CalendarTodayIcon></CalendarTodayIcon>
            </IconButton>
          </CircularImageContainer>

          <Box pl={2} width={descriptionWidth}>
            <TypographyOxygen fontSize={"1.5rem"}>
              Diverse Destinations
            </TypographyOxygen>

            <TypographyOxygen
              variant="body2"
              fontWeight={"normal"}
              fontSize={"1.0rem"}
              lineHeight={2}
            >
              Wherever you go becomes a part of you somehow.
            </TypographyOxygen>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display={"flex"}>
          <CircularImageContainer>
            <IconButton>
              <ChatIcon></ChatIcon>
            </IconButton>
          </CircularImageContainer>

          <Box pl={2} width={descriptionWidth}>
            <TypographyOxygen fontSize={"1.5rem"}>
              Diverse Destinations
            </TypographyOxygen>

            <TypographyOxygen
              variant="body2"
              fontWeight={"normal"}
              fontSize={"1.0rem"}
              lineHeight={2}
            >
              Wherever you go becomes a part of you somehow.
            </TypographyOxygen>
          </Box>
        </Box>
      </Grid>
      <Grid item xs={12} md={4}>
        <Box display={"flex"}>
          <CircularImageContainer>
            <IconButton>
              <FavoriteIcon></FavoriteIcon>
            </IconButton>
          </CircularImageContainer>

          <Box pl={2} width={descriptionWidth}>
            <TypographyOxygen fontSize={"1.5rem"}>
              Diverse Destinations
            </TypographyOxygen>

            <TypographyOxygen
              variant="body2"
              fontWeight={"normal"}
              fontSize={"1.0rem"}
              lineHeight={2}
            >
              Wherever you go becomes a part of you somehow.
            </TypographyOxygen>
          </Box>
        </Box>
      </Grid>
    </Grid>
  );
};
