import { KeyboardArrowRightOutlined } from "@mui/icons-material";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Fab, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";

export const IntroWidgetsHome = ({ children, ...props }) => {
  const theme = useTheme();
  const handleClick = () => {
    window.location.href = `/ai`;
  };

  const { isPhone } = props;

  return (
    <Box display="flex" justifyContent="center" sx={{ zIndex: 1 }}>
      <Fab
        variant="extended"
        size="large"
        sx={{
          backgroundColor: theme.palette.background.default,
          pointerEvents: "none",
          zIndex: 1, // Ensure this value is lower than the other component
        }}
        p={10}
        pointerEvents="none"
      >
        {!isPhone && (
          <>
            <FiberManualRecordIcon className="theme-background-color" />

            <Typography
              ml={1}
              fontWeight="bold"
              className="typography-custom-css"
            >
              What's new
            </Typography>
            <Box
              mx={2} // Add horizontal margin to space out the border
              borderLeft={1} // Add left border
              height="50%" // Make the border full height
            />
          </>
        )}

        <Box sx={{ pointerEvents: "auto" }}>
          <Typography
            onClick={handleClick}
            className="typography-custom-css"
            fontWeight="bold"
          >
            {isPhone
              ? "Unleash Catalyst"
              : "Unleash Catalyst, AI That Drives Results"}
          </Typography>
        </Box>

        <KeyboardArrowRightOutlined />
      </Fab>
    </Box>
  );
};

export const IntroWidgetsAgents = (children, ...props) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        sx={{ backgroundColor: theme.palette.background.default }}
        p={10}
        pointerEvents="none"
      >
        <AutoAwesomeIcon />

        <Typography ml={1}>Introducing Catalyst</Typography>
      </Fab>
    </Box>
  );
};

export const IntroHeaderHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  return (
    <Box>
      <Box>
        <Typography
          variant={isPhoneOrTab ? "h4" : "h1"}
          fontWeight="bold"
          className="typography-custom-css"
          sx={{
            textAlign: { xs: "center", sm: "center", lg: "center" },
          }}
        >
          Build your
          <span className="theme-specfic-colored-text"> AI Hub</span>
        </Typography>
      </Box>
    </Box>
  );
};

export const IntroHeaderHomeV2 = ({ children, ...props }) => {
  return (
    <Box>
      <Typography
        variant="h4"
        fontWeight="bold"
        className="typography-custom-css"
        sx={{
          textAlign: { xs: "center", lg: "center" },
        }}
      >
        Build your
        <span className="theme-specfic-colored-text"> AI Hub </span>
        today!
      </Typography>
    </Box>
  );
};

export const IntroSubHeaderHome = ({ children, ...props }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Typography
        variant="h6"
        fontWeight="normal"
        className="sub-header typography-custom-css"
        sx={{
          textAlign: { xs: "center", sm: "center", lg: "center" },
        }}
      >
        Put AI agents to work on autopilot. Drive growth without adding to your
        team.
      </Typography>
    </Box>
  );
};

export const IntroHeaderAgents = ({ children, ...props }) => {
  return (
    <Box display="flex" justifyContent="center">
      <Box>
        <Typography
          variant="h1"
          fontFamily='"Oxygen", sans-serif'
          fontWeight="bold"
          sx={{
            textAlign: "center",
            fontSize: {
              xs: "1.5rem", // small screens
              sm: "3rem", // medium screens
              md: "4rem", // large screens
              lg: "5.2rem", // extra large screens
              xl: "7.2rem",
            },
          }}
        >
          Recruit <span className="theme-specfic-colored-text">Catalyst</span>,
          your AI SDR <br />
          Agent
        </Typography>
      </Box>
    </Box>
  );
};

export const IntroSubHeaderAgents = (children, ...props) => {
  return (
    <Box display="flex" justifyContent="center">
      <Typography
        variant="body1"
        fontFamily='"Oxygen", sans-serif'
        fontWeight="normal"
        className="sub-header"
        sx={{
          textAlign: "center",
          fontSize: {
            xs: "0.6rem", // small screens
            sm: "1rem", // medium screens
            md: "1.5rem", // large screens
            lg: "1.5rem", // extra large screens
            xl: "1.5rem",
          },
        }}
      >
        Booking meetings 24/7. Grow your business, not your headcount.
      </Typography>
    </Box>
  );
};
export const IntroSubHeadingButtons = (children, ...props) => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Typography
        variant="body1"
        fontFamily='"Oxygen", sans-serif'
        fontWeight="normal"
        className="sub-header"
        sx={{
          textAlign: "center",
          mr: "5px",
        }}
      >
        Free plan
      </Typography>

      <AutoAwesomeIcon />

      <Typography
        variant="body1"
        fontFamily='"Oxygen", sans-serif'
        fontWeight="normal"
        className="sub-header"
        sx={{
          textAlign: "center",
          ml: "5px",
        }}
      >
        No card required
      </Typography>
    </Box>
  );
};
