import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { Box, Fab, useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useRef, useState } from "react";
import { RouteToContactUs } from "../CodingRipplerHome/ContactUs/ContactUs";
import { getButtonStyle } from "../CodingRipplerWidgets";

export const ContactUs = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const buttonRef = useRef(null);

  // const handleClick = () => {
  //   window.location.href = `/ai`;
  // };

  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    setPosition({ x: clientX, y: clientY });
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setPosition({ x: 0, y: 0 });
  };

  return (
    <>
      {isLargeScreen && (
        <Fab
          ref={buttonRef}
          variant="extended"
          size="large"
          className={isHovered ? "button-animation-hover" : "button-animation"}
          color="primary"
          onMouseEnter={() => setIsHovered(true)}
          onMouseMove={handleMouseMove}
          onMouseLeave={handleMouseLeave}
          onKeyDown={(e) => {
            if (e.key === "Enter" || e.key === " ") {
              RouteToContactUs();
            }
          }}
          style={{
            ...getButtonStyle({ isHovered, theme, buttonRef, position }),
          }}
          onClick={RouteToContactUs}
          p={10}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap={1}
          >
            Let's Talk
            <ArrowRightAltIcon />
          </Box>
        </Fab>
      )}

      {!isLargeScreen && (
        <Fab
          variant="extended"
          size="large"
          color="primary"
          className="button"
          onClick={RouteToContactUs}
          sx={{ width: "100%" }}
        >
          Let's Talk{" "}
        </Fab>
      )}
    </>
  );
};
