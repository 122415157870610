import Badge from "@mui/material/Badge";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import React from "react";

export const RoundBadge = ({
  badgeContent = "Demo",
  backgroundColor = "#eb4293",
  width = "80px",
  height = "80px",
  borderRadius = "50%",
  fontFamily = "Oxygen, sans-serif",
  fontWeight = "bold",
}) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  return isSmallScreen ? (
    <IconButton aria-label="cart">
      <Badge
        badgeContent={badgeContent}
        color="primary"
        sx={{
          ".MuiBadge-badge": {
            width: width,
            height: height,
            borderRadius: borderRadius,
            backgroundColor: backgroundColor,
            fontFamily: fontFamily,
            fontWeight: fontWeight,
          },
        }}
      ></Badge>
    </IconButton>
  ) : (
    <IconButton aria-label="cart">
      <Badge
        badgeContent={badgeContent}
        color="primary"
        sx={{
          ".MuiBadge-badge": {
            width: width,
            height: height,
            borderRadius: borderRadius,
            backgroundColor: backgroundColor,
            //            top: top,
            //            right: right,
            fontFamily: fontFamily,
            fontWeight: fontWeight,
          },
        }}
      ></Badge>
    </IconButton>
  );
};
