import { Box, Fab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { RouteToContactUs } from "../../../CodingRippler/CodingRipplerHome/ContactUs/ContactUs";

export const Demo = (children, ...props) => {
  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        className="button"
        p={10}
        pointerEvents="none"
        color="primary"
        onClick={RouteToContactUs}
      >
        Book a Demo
      </Fab>
    </Box>
  );
};

export const TryForFree = (children, ...props) => {
  const handleTryForFree = () => {
    window.location.href = `/ai`;
  };

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        className="button"
        p={10}
        pointerEvents="none"
        color="primary"
        onClick={handleTryForFree}
      >
        Try for Free
      </Fab>
    </Box>
  );
};

export const Documentation = (children, ...props) => {
  const handleDocumentationClick = () => {
    window.location.href = `/ai`;
  };

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        className="button"
        p={10}
        pointerEvents="none"
        color="primary"
        onClick={handleDocumentationClick}
      >
        Documentation
      </Fab>
    </Box>
  );
};

export const ScheduleACall = ({ children, ...props }) => {
  const theme = useTheme();

  const handleScheduleACallClick = () => {
    window.location.href = `/ai`;
  };

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        p={10}
        pointerEvents="none"
        style={{ backgroundColor: theme.palette.background.default }}
        onClick={handleScheduleACallClick}
      >
        Schedule a Call
      </Fab>
    </Box>
  );
};

export const RequestDemo = (children, ...props) => {
  const theme = useTheme();
  // const requestDemo = () => {
  //   window.location.href = `/ai`;
  // };

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        p={10}
        pointerEvents="none"
        style={{ backgroundColor: theme.palette.background.default }}
        onClick={RouteToContactUs}
      >
        Request Demo
      </Fab>
    </Box>
  );
};
