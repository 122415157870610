import AccountTreeIcon from "@mui/icons-material/AccountTree";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Brightness6Icon from "@mui/icons-material/Brightness6";
import BuildIcon from "@mui/icons-material/Build";
import PaletteIcon from "@mui/icons-material/Palette";
import PhoneIphoneIcon from "@mui/icons-material/PhoneIphone";
import SearchIcon from "@mui/icons-material/Search";
import SecurityIcon from "@mui/icons-material/Security";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import { Box, Button, CardMedia, Grid, Stack } from "@mui/material";
import AOS from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { SlideInFromTopEffect } from "../../../BoilarPlateJs/MotionEffects";
import { RouteToContactUs } from "../../CodingRipplerHome/ContactUs/ContactUs";
import { ContactWebRippler } from "../../CodingRipplerHome/ContactUs/ContactWebRippler";
import { ServicesWithCardView } from "../../CodingRipplerHome/WhatWeDo/WhatWeDoWidgets";
import {
  CommonContainer,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  DynamicLayoutWithImage,
  SliderContainer,
  TopicContainer,
} from "../../CodingRipplerWidgets";
import { WhyItMatters } from "./WhyItMatters";
import { WhatWeHave, WhyUs } from "./WhyUs";

export const OurWebServices = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const pageContent = {
    imagePath: "/codingrippler/webrippler/landing/landing-image-1.jpg",
    whyUs: {
      header: "Develop anything your business needs",
      subHeader:
        "Craft beautiful, modern websites with flexible, fully customizable designs.",
      customStyle: {
        customHeader: {
          textAlign: "left",
          fontSize: {
            xs: "2.5rem",
            lg: "3rem",
          },
        },
        customSubHeader: {
          textAlign: "left",
          fontSize: {
            xs: "1.25rem",
            lg: "1.5rem",
          },
        },
      },

      OurWork: [
        {
          title: "Tailored Expertise Over Templates",
          description:
            "While website builders offer templates, Web Rippler delivers custom solutions tailored to your specific needs, ensuring your website stands out.",
          icon: (
            <>
              <BuildIcon />
            </>
          ),
          benefits:
            "A unique, branded experience builds credibility and attracts more customers.",
          image: "/codingrippler/webrippler/whyItmatters/tailored-solution.png",
        },
        {
          title: "Scalable and Flexible Solutions",
          description:
            "Our websites are designed for growth. Unlike basic builders, we create scalable architectures that adapt as your business evolves.",
          icon: (
            <>
              <AccountTreeIcon />
            </>
          ),
          benefits:
            "As your business expands, your website will need to handle more traffic and complex features without needing a complete overhaul.",
          image: "/codingrippler/webrippler/whyItmatters/scalable-solution.png",
        },
        {
          title: "Comprehensive Support",
          description:
            "With Web Rippler, you receive ongoing support, including updates, security, and optimization—something most website builders lack.",
          icon: (
            <>
              <SupportAgentIcon />
            </>
          ),
          benefits:
            "This means less downtime and peace of mind, allowing you to focus on your business rather than technical issues.",
          image: "/codingrippler/webrippler/whyItmatters/customer-suport.png",
        },
      ],
      leadData: [
        {
          value: 1000,
          suffix: "+",
          prefix: "",
          text: "1,000+ Hours Committed to Building Quality",
        },

        {
          value: 100,
          suffix: "+",
          prefix: "",
          text: "100+ Project Concepts Ready to Launch​",
        },
        {
          value: 3,
          suffix: "",
          prefix: "",
          text: "Serving 3 Unique Branches with Tailored Expertise",
        },
      ],

      highlights: [
        {
          title: "Themeable",
          description:
            "Customize any part of our design to perfectly match your brand.",
          icon: (
            <>
              <PaletteIcon />
            </>
          ),
        },
        {
          title: "Light & Dark Themes",
          description:
            "Choose between light and dark themes to match your audience’s preference.",
          icon: (
            <>
              <Brightness6Icon />
            </>
          ),
        },
        {
          title: "Mobile-Optimized Design",
          description:
            "Fully responsive designs that adapt seamlessly across devices.",
          icon: (
            <>
              <PhoneIphoneIcon />
            </>
          ),
        },
        {
          title: "SEO-Optimized Code",
          description: "Enhanced performance and visibility on search engines.",
          icon: (
            <>
              <SearchIcon />
            </>
          ),
        },
        {
          title: "Enhanced Security",
          description:
            "Robust protection for your data, prioritized in every project.",
          icon: (
            <>
              <SecurityIcon />
            </>
          ),
        },
        {
          title: "Dedicated Support",
          description:
            "6 months of free technical support to keep your site running smoothly.",
          icon: (
            <>
              <SupportAgentIcon />
            </>
          ),
        },
      ],

      ContactWebRippler: {
        header: (
          <>
            {" "}
            Get started with{" "}
            <span className="theme-specfic-colored-text">Web Rippler</span>{" "}
            today{" "}
          </>
        ),
        subHeader:
          "Build a beautiful, modern website with flexible, fully customizable, atomic MUI components.",
        buttonText: "View Pages",
      },
    },
  };

  let customStyleAdvertisementSubHeader = {
    textAlign: "left",
    fontSize: {
      xs: "1.25rem",
      sm: "1.5rem",
      lg: "1.5rem",
    },
  };

  let customStyleAdvertisementHeader = {
    textAlign: "left",
    fontSize: {
      xs: "2.5rem",
      md: "4rem",
    },
  };

  const viewPages = (context) => {
    window.location.href = `/web/portfolio`;
  };

  const contactUs = (context) => {
    window.location.href = `/web/contact`;
  };

  useEffect(() => {
    AOS.init({
      duration: 2000,
      easing: "ease-out-cubic",
    });
  }, []);

  return (
    <>
      <Grid container spacing={2} className="white-bg">
        <Grid
          item
          xs={0}
          lg={1}
          xl={2}
          sx={{
            display: { xs: "none", sm: "none", lg: "block" },
          }}
        />

        <Grid
          item
          xs={12}
          sm={6}
          lg={5}
          xl={4}
          sx={{
            mt: {
              xs: "70px",
              sm: "85px",
              lg: "0px",
            },
          }}
        >
          <CommonContainer>
            <Box
              display="flex"
              justifyContent={"center"}
              alignItems={isPhoneorTab ? "flex-start" : "center"}
              width="100%"
              minHeight={{
                xs: 500,
                sm: 600,
              }}
              height={{
                xs: deviceWithSmallHeight ? "80vh" : "55vh",
                sm: deviceWithSmallHeight ? "80vh" : "45vh",
                lg: "90vh",
              }}
              mt={{
                xs: 5,
                sm: 5,
                lg: 0,
              }}
            >
              <DynamicLayoutWithImage
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                isPhoneorTab={isPhoneorTab}
                pageContent={pageContent}
                lg={12}
              >
                <Stack spacing={2}>
                  <CommonHeaderTypography
                    customStyle={customStyleAdvertisementHeader}
                  >
                    Turn your ideas <br />
                    into{" "}
                    <span className="theme-specfic-colored-text text-decoration-underline">
                      success.
                    </span>{" "}
                  </CommonHeaderTypography>

                  <Box m={2} />

                  <CommonSubHeaderTypography
                    customStyle={customStyleAdvertisementSubHeader}
                  >
                    The Web Rippler team transforms your product into a modern,
                    professional masterpiece—saving you valuable time in the
                    process
                  </CommonSubHeaderTypography>

                  <Box m={2} />

                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    flexDirection={isPhone ? "column" : "row"}
                    gap={2}
                  >
                    <Button
                      fullWidth={isPhone ? true : false}
                      variant="contained"
                      size="large"
                      className="theme-specific-bg-button"
                      onClick={viewPages}
                    >
                      View Pages
                    </Button>

                    <Button
                      fullWidth={isPhone ? true : false}
                      variant="outlined"
                      size="large"
                      className="theme-specific-button"
                      onClick={RouteToContactUs}
                    >
                      Let's Talk
                    </Button>
                  </Box>
                </Stack>
              </DynamicLayoutWithImage>
            </Box>
          </CommonContainer>
        </Grid>

        <Grid
          item
          xs={0}
          sm={1}
          sx={{
            display: { xs: "none", sm: "none", lg: "block" },
          }}
        />

        <Grid
          item
          xs={12}
          sm={6}
          lg={5}
          data-aos="flip-left"
          data-aos-easing="ease-out-cubic"
          data-aos-duration="2000"
          sx={{
            display: { xs: "none", sm: "block" },
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "150px",
            }}
          >
            <CardMedia
              component="img"
              image={pageContent.imagePath}
              alt={pageContent.alt}
              sx={{
                objectFit: "cover",
                width: "100%",
                minHeight: {
                  sm: 500,
                },
                height: {
                  sm: deviceWithSmallHeight ? "70vh" : "40vh",
                  lg: "70vh",
                },
              }}
            />
          </Box>
        </Grid>
      </Grid>

      <Box
        component="svg"
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          transform: "translateY(-95%)",
        }}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 100.1"
      >
        <path fill="#ffffff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
      </Box>

      <Grid container spacing={2} className="white-linear-gradient-background">
        <Grid item xs={12}>
          <CommonContainer>
            <SlideInFromTopEffect>
              <WhyUs
                pageContent={pageContent.whyUs}
                isPhone={isPhone}
                isTab={isTab}
                isPhoneorTab={isPhoneorTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
              />
            </SlideInFromTopEffect>
          </CommonContainer>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            mt: { xs: 5, lg: 10 },
          }}
        >
          <SlideInFromTopEffect>
            <TopicContainer topicText={"Why It Matters"}>
              <AutoAwesomeIcon />
            </TopicContainer>
          </SlideInFromTopEffect>
        </Grid>

        <Grid item xs={12}>
          <CommonContainer>
            <SlideInFromTopEffect>
              <SliderContainer>
                {pageContent.whyUs.OurWork.map((content, index) => (
                  <WhyItMatters
                    key={index}
                    isPhone={isPhone}
                    isTab={isTab}
                    deviceWithSmallHeight={deviceWithSmallHeight}
                    isPhoneorTab={isPhoneorTab}
                    pageContent={content}
                  />
                ))}
              </SliderContainer>
            </SlideInFromTopEffect>
          </CommonContainer>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            mt: { xs: 5, lg: 10 },
          }}
        >
          <SlideInFromTopEffect>
            <TopicContainer topicText={"Why Choose Us"}>
              <AutoAwesomeIcon />
            </TopicContainer>
          </SlideInFromTopEffect>
        </Grid>

        <Grid item xs={12}>
          <CommonContainer>
            <SlideInFromTopEffect>
              <WhatWeHave
                isPhone={isPhone}
                isTab={isTab}
                isPhoneorTab={isPhoneorTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                leadData={pageContent.whyUs.leadData}
                customStyleHeader={pageContent.whyUs.customStyle.customHeader}
                customStyleSubHeader={
                  pageContent.whyUs.customStyle.customSubHeader
                }
              />
            </SlideInFromTopEffect>
          </CommonContainer>
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: { xs: "none", sm: "none", lg: "block" },
          }}
        />
        <Grid item xs={12} lg={10}>
          <SlideInFromTopEffect>
            <Grid container>
              {pageContent.whyUs.highlights.map((data, index) => (
                <Grid item xs={12} sm={6} lg={4} key={index}>
                  <ServicesWithCardView
                    pageContent={data}
                    columnSizeLg={4}
                    columnSizeSm={12}
                  />
                </Grid>
              ))}
            </Grid>
          </SlideInFromTopEffect>
          <Grid item xs={1} />
        </Grid>
        <Grid
          item
          lg={1}
          sx={{
            display: { xs: "none", sm: "none", lg: "block" },
          }}
        />

        <Grid item xs={12}>
          <Box
            component="svg"
            sx={{
              position: "relative",
              width: "100%",
              height: "auto",
              transform: `translateY(${isPhone ? "50%" : "25%"})`,
            }}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920 100.1"
          >
            <path
              fill="#ffffff"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CommonContainer>
            <SlideInFromTopEffect>
              <ContactWebRippler
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                isPhoneorTab={isPhoneorTab}
                pageContent={pageContent.whyUs.ContactWebRippler}
                viewPages={viewPages}
                contactUs={contactUs}
              />
            </SlideInFromTopEffect>
          </CommonContainer>
        </Grid>
      </Grid>
    </>
  );
};
