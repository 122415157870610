import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import FlagIcon from "@mui/icons-material/Flag";
import InsightsIcon from "@mui/icons-material/Insights";
import MailIcon from "@mui/icons-material/Mail";
import SpeedIcon from "@mui/icons-material/Speed";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RouteToAIPortal } from "../../../../../CodingRippler/CodingRipplerWidgets";

export const SupportTabDetails = (props) => {
  return (
    <Grid
      container
      spacing={5}
      sx={{
        pl: {
          lg: 10,
        },
      }}
    >
      <Grid item xs={12}>
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            className="typography-custom-css"
          >
            Respond in minutes, not hours
          </Typography>

          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginTop={1}
          >
            Provide fast, accurate answers aligned with your support guidelines.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <SpeedIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            {" "}
            Resolve issues quickly and efficiently
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <InsightsIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            {" "}
            Collect, analyze, and summarize insights{" "}
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <FlagIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            {" "}
            Escalate complex cases seamlessly
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <MailIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            {" "}
            Direct responses to your inbox
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={4}
        >
          <Button
            variant="text"
            size="large"
            endIcon={<ArrowRightAltIcon />}
            style={{
              fontSize: "1rem",
              padding: "1rem 2rem",
              borderRadius: "14px",
              color: "#6056ff",
            }}
            onClick={RouteToAIPortal}
          >
            Learn more
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
