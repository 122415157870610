import { Tooltip } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { truncate } from "../BoilarPlateJs/JsUtils";
import { TypographyOxygen } from "../BoilarPlateJs/TypographyStyled";

export const TripHeadingAndDescription = ({
  data,
  maxLenthForHeading = 21,
  maxLengthForDescription = 70,
  headingFontSize = "1.2rem",
  descriptionFontSize = "1.0rem",
}) => {
  const truncatedDescriptionText = truncate({
    str: data.tripDescription,
    maxLength: maxLengthForDescription,
  });

  const truncatedHeadingText = truncate({
    str: data.tripHeading,
    maxLength: maxLenthForHeading,
  });

  return (
    <>
      {truncatedHeadingText ? (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <Tooltip title={data.tripHeading}>
            <TypographyOxygen
              variant="h6"
              color="text.secondary"
              fontSize={headingFontSize}
            >
              {truncatedHeadingText}
            </TypographyOxygen>
          </Tooltip>
        </Box>
      ) : (
        <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
          <TypographyOxygen
            variant="h6"
            color="text.secondary"
            fontSize={headingFontSize}
          >
            {data.tripHeading}
          </TypographyOxygen>
        </Box>
      )}

      {truncatedDescriptionText ? (
        <Box display={"flex"}>
          {" "}
          <Tooltip title={data.tripDescription}>
            <TypographyOxygen
              fontSize={descriptionFontSize}
              fontWeight={"normal"}
            >
              {truncatedDescriptionText}
            </TypographyOxygen>
          </Tooltip>{" "}
        </Box>
      ) : (
        <Box>
          <TypographyOxygen
            fontSize={descriptionFontSize}
            fontWeight={"normal"}
          >
            {data.tripDescription}
          </TypographyOxygen>
        </Box>
      )}
    </>
  );
};
