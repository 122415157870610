import DiscountIcon from "@mui/icons-material/Discount";
import { Box } from "@mui/system";
import React from "react";
import { TypographyOxygen } from "../../BoilarPlateJs/TypographyStyled";

export const DiscountOnTour = ({ children, ...props }) => {
  const { data } = props;

  return (
    <Box
      display={{ lg: "flex" }}
      flexDirection={"column"}
      border={1}
      sx={{
        transform: data.anyOffer ? "translateY(-20%)" : "translateY(0%)", // Adjust rotation as needed
      }}
    >
      <Box bgcolor={"#429cb5"} height={"100%"}>
        <Box
          display={data.anyOffer ? "flex" : "none"}
          flexDirection={"row"}
          justifyContent={"center"}
        >
          <Box p={2}>
            <DiscountIcon></DiscountIcon>
          </Box>

          <Box p={2}>
            <TypographyOxygen
              fontSize="1.0rem"
              textDecoration="line-through"
              padding={0}
            >
              {data.actualTripAmount}
            </TypographyOxygen>
          </Box>
        </Box>

        <Box
          display={"flex"}
          flexDirection={"row"}
          justifyContent={"center"}
          pb={2}
        >
          <TypographyOxygen fontSize="1.2rem">
            {data.discountedTripAmount}
          </TypographyOxygen>
        </Box>
      </Box>
      <Box
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        height={"100%"}
        pt={2}
        pb={2}
      >
        <TypographyOxygen fontSize="1.2rem" variant="body2">
          one tour per person
        </TypographyOxygen>
      </Box>
    </Box>
  );
};
