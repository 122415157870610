import GroupsIcon from "@mui/icons-material/Groups";
import HandshakeIcon from "@mui/icons-material/Handshake";
import LayersIcon from "@mui/icons-material/Layers";
import PeopleIcon from "@mui/icons-material/People";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import SearchIcon from "@mui/icons-material/Search";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { Avatar, Box, Card, CardContent, CardMedia, Grid } from "@mui/material";
import { OurApproach } from "../../../AI/AIAgents/AIAgentsWidgets";
import { SlideInFromTopEffect } from "../../../BoilarPlateJs/MotionEffects";
import {
  CardWithBackgroundHoverEffect,
  CommonContainer,
  HeroSubHeader,
  HeroText,
  TopicContainer,
} from "../../CodingRipplerWidgets";
import { ContactCodingRippler } from "../ContactUs/ContactCodingRippler";
import { Services } from "../WhatWeDo/WhatWeDoWidgets";

export const AboutHome = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const pageContent = {
    landingPAge: {
      title: "Driven by Curiosity, Built for You",
      description:
        "As tech enthusiasts, we see every challenge as an opportunity to innovate and bring value to the people and businesses we serve.",
    },

    page2: [
      {
        title: "Our Commitment and Ambition",
        description:
          "Committed to innovation, driven to empower, and inspired to create impact",
      },
      {
        title: "Our core value: Quality and Excellence",
        description:
          "Delivering exceptional quality and relentless pursuit of excellence in everything we do",
      },
    ],

    page3: {
      title: "At Coding Rippler, we are dedicated to empowering success for",
      items: [
        {
          title: "Our Customers",
          description:
            "We create tailored solutions to help you grow and succeed",
          icon: <PeopleIcon />,
        },
        {
          title: "Our Team",
          description:
            "We empower our team to innovate, collaborate, and thrive together",
          icon: <GroupsIcon />,
        },
        {
          title: "Our Partners",
          description:
            "We build lasting relationships to drive shared success and innovation",
          icon: <HandshakeIcon />,
        },
      ],
    },

    page4: {
      ourApproach: {
        title: "Success starts with understanding",
        description:
          "We listen first to craft solutions that meet your goals and challenges.",

        introduction: [
          {
            title:
              "At Coding Rippler, we craft tailored solutions to transform challenges into milestones",
            icon: <RocketLaunchIcon />,
          },
          {
            title:
              "With collaboration and innovation, we make your vision a reality",
            icon: <TipsAndUpdatesIcon />,
          },
        ],
        steps: [
          {
            timeLine: "Discovery",
            title: "",
            description:
              "Mapping your processes and ingesting initial training data.",
            icon: <SearchIcon />,
          },
          {
            timeLine: "Collaboration & Design",
            title: "",
            description:
              "We work together to craft a custom strategy that aligns with your vision and brings it to life.",
            icon: <HandshakeIcon />,
          },
          {
            timeLine: "Implementation",
            title: "",
            description:
              "From concept to execution, we bring the plan to life with precision, delivering a seamless solution.",
            icon: <RocketLaunchIcon />,
          },
          {
            timeLine: "Review & Optimize",
            title: "",
            description:
              "We continuously measure success and make adjustments to ensure maximum impact and growth.",
            icon: <TrendingUpIcon />,
          },
        ],
      },
    },

    page5: {
      title: "The Rippler Advantage",

      advantages: [
        {
          title: "Advanced Innovation",
          description:
            "We leverage the latest in AI and web technology to provide forward-thinking solutions that keep you ahead of the competition.",
          hoverColor: "#6056ff",
        },
        {
          title: "Tailored to You",
          description:
            "Every solution we craft aligns with your specific challenges, goals, and vision—because no two journeys are the same.",
          hoverColor: "#0d162f",
        },
        {
          title: "Commitment to Excellence",
          description:
            "With a focus on precision, quality, and continuous improvement, we ensure our solutions deliver lasting value and impact.",
          hoverColor: "#943bff",
        },
      ],
    },

    page6: {
      title: "The Minds Behind Coding Rippler",

      founders: [
        {
          name: "Harsh",
          designation: "Lead Tech Architect & Co-Founder",
          description:
            "Harsh, a Computer Science graduate from IIT and former Microsoft employee, brings a wealth of experience in building innovative solutions and driving technical excellence.",
          icon: "/codingrippler/ourteam/harsh.jpg",
        },
        {
          name: "Ankit",
          designation: "Managing Director & Co-Founder",
          description:
            "An experienced software engineer with a computer science background, Ankit leads Coding Rippler with a focus on strategic direction and technological innovation.",
          icon: "/codingrippler/ourteam/ankit.jpg",
        },
        {
          name: "Aanchal",
          designation: "Lead Designer & Co-Founder",
          description:
            "With a keen eye for modern design and user experience, Aanchal leads the design vision at Coding Rippler, ensuring every product is not only functional but beautifully crafted to meet user needs.",
          icon: "/codingrippler/ourteam/aanchal.jpg",
        },
      ],
      customStyle: {
        customHeader: {},
        customHeaderInfo: {
          textAlign: "left",
          fontWeight: "500",
        },
        customDescription: {
          textAlign: "left",
          fontWeight: "normal",
        },

        customSubHeader: {},
      },
    },

    page7: {
      contactCodingRippler: {
        header: (
          <>
            {" "}
            Get started with{" "}
            <span className="theme-specfic-colored-text">
              Coding Rippler
            </span>{" "}
            today{" "}
          </>
        ),
        subHeader: "Let’s Build Innovative Solutions Tailored to Your Needs",
        buttonText: "View Pages",
      },
    },
  };
  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          mt: {
            xs: "64px",
            sm: "80px",
            lg: "125px",
          },
        }}
      />
      <Grid item xs={12}>
        <Grid
          container
          sx={{
            minHeight: {
              xs: 750,
              sm: 400,
            },
            height: {
              xs: deviceWithSmallHeight ? "85vh" : "70vh",
              sm: deviceWithSmallHeight ? "80vh" : "45vh",
              md: deviceWithSmallHeight ? "80vh" : "40vh",
              lg: "65vh",
            },
            width: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            lg={1}
            sx={{
              display: { xs: "block", lg: "block" },
            }}
          />
          <Grid
            item
            xs={12}
            sm={6}
            lg={5}
            sx={{
              height: { xs: "70%", sm: "100%", lg: "100%" },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={{ xs: "flex-start", sm: "center", lg: "center" }}
              alignItems={{ xs: "center", lg: "center" }}
              width="100%"
              height="100%"
            >
              <CommonContainer>
                <HeroText
                  variant={isPhoneOrTab ? "h4" : "h3"}
                  customStyle={{ textAlign: "left", mt: { xs: 5, sm: 0 } }}
                >
                  {pageContent.landingPAge.title}
                </HeroText>

                <Box m={2} />

                <HeroSubHeader customStyle={{ textAlign: "left" }}>
                  {pageContent.landingPAge.description}
                </HeroSubHeader>
              </CommonContainer>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={2}
            lg={2}
            sx={{
              display: { xs: "block", lg: "block" },
            }}
          />{" "}
          <Grid
            item
            xs={12}
            sm={4}
            lg={4}
            className="theme-specific-background-color"
            sx={{
              position: "relative",
              height: { xs: "30%", sm: "100%", lg: "100%" },
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: {
                xs: "flex-start",
                sm: "center",
                lg: "center",
              },
            }}
          >
            <Card
              sx={{
                position: "absolute",
                transform: {
                  xs: "translate(0%, -50%)",
                  sm: "translate(-25%, 0%)",
                  lg: "translate(-25%, 0%)",
                },
                height: { xs: "100%", sm: "50%", lg: "75%" },
                width: { xs: "90%", sm: "125%", lg: "125%" },
                borderRadius: "24px",
              }}
            >
              <CardMedia
                component="img"
                image="/codingrippler/about-us/teamwork.jpg"
                alt="Description of the image"
                width="100%"
                height="100%"
              ></CardMedia>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      {/* 

section 2
*/}
      <Grid
        item
        xs={12}
        className="dark-purple-background-color"
        sx={{
          minHeight: "35vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container>
          {pageContent.page2.map((content, index) => (
            <Grid item xs={12} lg={6}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems={{ xs: "left", lg: "center" }}
                gap={2}
                width="100%"
                height="100%"
              >
                <CommonContainer
                  customStyle={{
                    borderLeft: {
                      xs: "4px solid #00ff91",
                      lg: "4px solid #00ff91",
                    },
                    key: index,
                    padding: { xs: 4, lg: 4 },
                  }}
                >
                  <HeroText
                    variant="h5"
                    customStyle={{
                      color: "white",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    {content.title}
                  </HeroText>

                  <Box m={2} />

                  <HeroSubHeader
                    customStyle={{
                      color: "white",
                      textAlign: "left",
                      width: "100%",
                    }}
                  >
                    {content.description}
                  </HeroSubHeader>
                </CommonContainer>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Grid>
      {/* 

section 3

       */}

      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <Grid container sx={{ mt: 5, mb: 5 }}>
            <Grid
              item
              lg={1}
              sx={{
                display: { xs: "none", lg: "block" },
              }}
            />

            <Grid item xs={12} lg={10}>
              <CommonContainer>
                <HeroText variant="h4" customStyle={{ textAlign: "left" }}>
                  {pageContent.page5.title}
                </HeroText>
              </CommonContainer>
              <CommonContainer>
                <Grid container spacing={2} sx={{ mt: 10 }}>
                  {pageContent.page5.advantages.map((advantage, index) => (
                    <Grid item xs={12} sm={12} lg={4} key={index}>
                      <CardWithBackgroundHoverEffect advantage={advantage} />
                    </Grid>
                  ))}
                </Grid>
              </CommonContainer>
            </Grid>

            <Grid
              item
              lg={1}
              sx={{
                display: { xs: "none", lg: "block" },
              }}
            />
          </Grid>
        </SlideInFromTopEffect>
      </Grid>

      {/* 

section 4


       */}

      <Grid item xs={12} className="dark-purple-background-color">
        <SlideInFromTopEffect>
          <Grid
            container
            sx={{
              mt: { xs: 10 },
            }}
          >
            <Grid item xs={12}>
              <TopicContainer topicText="Our Approach">
                <LayersIcon />
              </TopicContainer>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                mt: { xs: 2 },
              }}
            >
              <CommonContainer>
                <HeroText
                  variant="h4"
                  customStyle={{
                    width: "100%",
                    color: "white",
                  }}
                >
                  {pageContent.page4.ourApproach.title}
                </HeroText>

                <Box m={2} />

                <HeroSubHeader
                  customStyle={{
                    width: "100%",
                    color: "white",
                  }}
                >
                  {pageContent.page4.ourApproach.description}
                </HeroSubHeader>
              </CommonContainer>
            </Grid>

            <Grid
              item
              xs={12}
              sx={{
                mt: { xs: 2 },
              }}
            >
              <OurApproach
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                isPhoneOrTab={isPhoneOrTab}
                pageContent={pageContent.page4.ourApproach}
              />
            </Grid>
          </Grid>
        </SlideInFromTopEffect>
      </Grid>

      {/* 

section 5

 */}

      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      />

      <Grid
        item
        xs={12}
        lg={10}
        className="overlay"
        position="relative"
        sx={{
          minHeight: "40vh",
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          flexDirection: "column",
          mt: {
            xs: 5,
            sm: 0,
          },
        }}
      >
        <CommonContainer>
          <SlideInFromTopEffect>
            <HeroText
              variant="h4"
              customStyle={{
                textAlign: "left",
                width: "100%",
                mt: 5,
              }}
            >
              {pageContent.page3.title}
            </HeroText>
          </SlideInFromTopEffect>
        </CommonContainer>

        <CommonContainer>
          <SlideInFromTopEffect>
            <Services WhatWeDoData={pageContent.page3.items} />
          </SlideInFromTopEffect>
        </CommonContainer>
      </Grid>
      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      />

      {/* 

section 6

 */}

      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      />

      <Grid
        item
        xs={12}
        lg={10}
        className="overlay-bottom-right"
        position="relative"
      >
        <CommonContainer>
          <ContactCodingRippler
            pageContent={pageContent.page7.contactCodingRippler}
            isPhone={isPhone}
            isTab={isTab}
            isPhoneorTab={isPhoneOrTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
          />
        </CommonContainer>
      </Grid>

      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      />

      {/* 

section 7

 */}

      <Grid item xs={12} className="white-bg">
        <Grid container sx={{ mt: 10, mb: 10 }}>
          <Grid
            item
            lg={1}
            sx={{
              display: {
                xs: "none",
                lg: "block",
              },
            }}
          />

          <Grid item xs={12} lg={10}>
            <Grid item xs={12}>
              <CommonContainer>
                <HeroText variant="h4" customStyle={{ textAlign: "left" }}>
                  {pageContent.page6.title}
                </HeroText>
              </CommonContainer>
            </Grid>
            <Grid container spacing={2} sx={{ mt: 5 }}>
              {pageContent.page6.founders.map((founder, index) => (
                <Grid item xs={12} sm={12} md={12} lg={4} key={index}>
                  <CommonContainer>
                    <Card
                      sx={{
                        borderRadius: "12px",
                        overflow: "hidden",
                        position: "relative",
                        padding: 2,
                        height: {
                          lg: 450,
                          xl: 400,
                        },
                        transition: "transform 0.2s, box-shadow 0.2s", // Smooth transition
                        "&:hover": {
                          transform: "translateY(-4px)", // Lift more on hover
                        },
                      }}
                    >
                      <Avatar
                        alt={founder.name}
                        src={founder.icon}
                        sx={{ width: 100, height: 100, margin: "auto", mb: 2 }}
                      />
                      <CardContent sx={{ textAlign: "center" }}>
                        <HeroText
                          variant="h6"
                          customStyle={{
                            textAlign: "center",
                          }}
                        >
                          {founder.name}
                        </HeroText>
                        <HeroSubHeader
                          variant="subtitle2"
                          customStyle={{
                            textAlign: "center",
                          }}
                        >
                          {founder.designation}
                        </HeroSubHeader>
                      </CardContent>
                      <CardContent>
                        <HeroSubHeader
                          variant="body1"
                          customStyle={{
                            textAlign: "center",
                            width: "100%",
                          }}
                        >
                          {founder.description}
                        </HeroSubHeader>
                      </CardContent>
                    </Card>
                  </CommonContainer>
                </Grid>
              ))}
            </Grid>
          </Grid>
          <Grid
            item
            lg={1}
            sx={{
              display: {
                xs: "none",
                lg: "block",
              },
            }}
          />
        </Grid>
      </Grid>

      {/* <Grid item xs={12}>
        <Grid
          container
          sx={{
            height: {
              xs: deviceWithSmallHeight ? "50vh" : "50vh",
              sm: deviceWithSmallHeight ? "50vh" : "50vh",
              lg: "50vh",
            },
            width: "100%",
          }}
        >
          <Grid
            item
            xs={12}
            sm={2}
            lg={2}
            sx={{
              display: { xs: "block", lg: "block" },
            }}
          />{" "}
          <Grid
            item
            xs={12}
            sm={4}
            lg={4}
            sx={{
              position: "relative",
              height: { xs: "30%", sm: "100%", lg: "100%" },
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: {
                xs: "flex-start",
                sm: "center",
                lg: "center",
              },
            }}
          >
            <Card
              sx={{
                position: "absolute",
                transform: {
                  xs: "translate(0%, 0%)",
                  sm: "translate(0%, 0%)",
                  lg: "translate(0%, 0%)",
                },
                height: { xs: "100%", sm: "50%", lg: "75%" },
                width: { xs: "90%", sm: "125%", lg: "125%" },
                borderRadius: "24px",
              }}
            >
              <CardMedia
                component="img"
                image="/codingrippler/masonry/ourwork.jpg"
                alt="Description of the image"
                width="100%"
                height="100%"
              ></CardMedia>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            lg={1}
            sx={{
              display: { xs: "block", lg: "block" },
            }}
          />
          <Grid
            item
            xs={12}
            sm={6}
            lg={5}
            sx={{
              height: { xs: "70%", sm: "100%", lg: "100%" },
            }}
          >
            <Box
              display="flex"
              flexDirection="column"
              justifyContent={{ xs: "flex-start", sm: "center", lg: "center" }}
              alignItems={{ xs: "center", lg: "center" }}
              width="100%"
              height="100%"
            >
              <CommonContainer>
                <HeroText
                  variant={isPhoneOrTab ? "h4" : "h3"}
                  customStyle={{ textAlign: "left" }}
                >
                  {pageContent.landingPAge.title}
                </HeroText>

                <Box m={2} />

                <HeroSubHeader customStyle={{ textAlign: "left" }}>
                  {pageContent.landingPAge.description}
                </HeroSubHeader>
              </CommonContainer>
            </Box>
          </Grid>
        </Grid>
      </Grid> */}
    </Grid>
  );
};
