import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import { Box, Card, CardContent, Grid, Stack } from "@mui/material";
import {
  CommonButtonV2,
  CommonContainer,
  HeroSubHeader,
  HeroText,
  TopicContainer,
} from "../../../CodingRipplerWidgets";

export const ChooseByCategoryPage = ({ children, ...props }) => {
  const { pageContent } = props;

  const handleSubmit = () => {};

  const categoryTypes = pageContent[0].categoryTypes;

  return (
    <Grid container spacing={2}>
      <Grid
        item
        xs={12}
        sx={{
          mt: { xs: 5, lg: 10 },
        }}
      >
        <CommonContainer>
          <Stack spacing={4}>
            <TopicContainer topicText={pageContent[0].topicText}>
              <AutoAwesomeIcon />
            </TopicContainer>

            {/* <CommonHeaderTypography customStyle={customStyle.customHeader}>
            {pageContent[0].header}
          </CommonHeaderTypography> */}

            <HeroText variant="h4">{pageContent[0].header}</HeroText>

            {/* <Box m={2} /> */}

            {/* <CommonSubHeaderTypography customStyle={customStyle.customSubHeader}>
            {pageContent[0].subHeader}
          </CommonSubHeaderTypography> */}

            <HeroSubHeader>{pageContent[0].subHeader}</HeroSubHeader>

            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
            >
              <CommonButtonV2
                buttonText={pageContent[0].buttonText}
                onClick={handleSubmit}
                customStyle={{ fontSize: "1rem" }}
              />
            </Box>

            <Box
              display="flex"
              width="100%"
              justifyContent="center"
              alignItems="center"
              gap={2}
            >
              <Grid container spacing={2}>
                {categoryTypes.map((category, index) => (
                  <Grid item xs={6} md={4} lg={2} key={index}>
                    <Box
                      component={Card}
                      sx={{
                        minHeight: {
                          xs: 150,
                        },
                        height: {
                          xs: "20vh",
                          lg: "20vh",
                        },

                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "column",
                        backgroundColor: "#f7faff", // Change background color here

                        transition: "transform 0.2s, box-shadow 0.2s", // Smooth transition
                        "&:hover": {
                          transform: "translateY(-4px)", // Lift more on hover
                        },
                      }}
                    >
                      <CardContent>{category.icon}</CardContent>
                      <CardContent>{category.title}</CardContent>
                    </Box>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Stack>
        </CommonContainer>
      </Grid>

      <Grid item xs={12}></Grid>

      <Grid item xs={12}></Grid>
    </Grid>
  );
};
