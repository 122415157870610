import React from "react";
import { Link } from "react-scroll";
import PopularTours from "../PopularTours/PopularTours.jsx";
import { ReviewAndPostPage } from "../ReviewAndPostPage.js";
import { SubscribeNewsLetter } from "../subscribeNewsLatter.js";
import { TourAndTravelHomePage } from "../TourAndTravelHomePage.js";
import { WhyChooseUs } from "../WhyChooseUs.js";
import { DealsAndDiscountsPage } from "./DealsAndDiscountsPage.jsx";
import { FindATour } from "./FindATour.jsx";
import "./tourandtravel.css";
import { TourTypes } from "./TourTypes.jsx";

const postData = [
  {
    heading: "Before You Go",
    content:
      "Make sure to check the weather, local customs, and travel advisories before your trip. Safe travels!",
  },
];

const reviewData = [
  {
    customerName: "John Doe",
    customerPost:
      "Our time in Maui was pure magic! From snorkeling in clear waters to exploring lush rainforests, every moment was unforgettable. The warmth of the sun and the hospitality of the locals made it a dreamy escape. Can't wait to return! Mahalo, Maui!",
    postHeading: "Bali, Indonesia: A Tropical Paradise",
    customerProfilePic: "../tourandtravel/profilePic3.jpg",
  },
  {
    customerName: "Mark",
    customerPost:
      "Our time in Maui was pure magic! From snorkeling in clear waters to exploring lush rainforests, every moment was unforgettable. The warmth of the sun and the hospitality of the locals made it a dreamy escape. Can't wait to return! Mahalo, Maui!",
    postHeading: "Magical Maui Experience",
    customerProfilePic: "../tourandtravel/profilePic4.jpg",
  },
];

export const ToursAndTravel = ({ children, ...props }) => {
  const { tourData } = props;

  return (
    <>
      <Link
        to="Tour"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        <TourAndTravelHomePage />
      </Link>

      <Link
        to="whyChooseUs"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        <WhyChooseUs />
      </Link>

      <Link
        to="popularTours"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        <PopularTours popularToursData={tourData} />
      </Link>
      <Link
        to="dealsAndDiscountsPage"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        <DealsAndDiscountsPage dealsAndDiscountsData={tourData} />
      </Link>

      <Link
        to="findaTour"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        <FindATour />
      </Link>

      <Link
        to="tourTypes"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        <TourTypes />
      </Link>

      <Link
        to="reviewAndPostPage"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        <ReviewAndPostPage customerReviews={reviewData} posts={postData} />
      </Link>

      <Link
        to="subscribeNewsLetter"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        <SubscribeNewsLetter />
      </Link>
    </>
  );
};
