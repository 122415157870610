import { Route, Routes } from "react-router-dom";
import { AICustomerSupportHome } from "./AICustomerSupport/AICustomerSupportHome";
import { AIMarketingHome } from "./AIMarketing/AIMarketingHome";
import { AIOperationsHome } from "./AIOperations/AIOperationsHome";
import { AIResearchHome } from "./AIResearchHome/AIResearchHome";
import { AISalesHome } from "./AISales/AISalesHome";

export const AIFunctionsHome = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <>
      <Routes>
        <Route
          path="/sales"
          element={
            <AISalesHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/marketing"
          element={
            <AIMarketingHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/customer-support"
          element={
            <AICustomerSupportHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/research"
          element={
            <AIResearchHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/operations"
          element={
            <AIOperationsHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
      </Routes>
    </>
  );
};
