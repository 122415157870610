import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import HomeIcon from "@mui/icons-material/Home";
import HubIcon from "@mui/icons-material/Hub";
import LanguageIcon from "@mui/icons-material/Language";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import SearchIcon from "@mui/icons-material/Search";
import SettingsIcon from "@mui/icons-material/Settings";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { CardContent } from "@mui/material";
import React, { useState } from "react";
import { HomeButton } from "../../../../CodingRippler/Navigations/HomeButton";
import { DropDownButton } from "../../../AIWidgets";
import { LoginButton } from "./LoginButton";
import { PricingButton } from "./PricingButton";
import { SignUpButton } from "./SignUpButton";

import CodeIcon from "@mui/icons-material/Code";
import ConstructionIcon from "@mui/icons-material/Construction";
import GroupIcon from "@mui/icons-material/Group";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";

export const NavigationButtonsDrawer = ({ children, ...props }) => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleButtonClick = (index) => {
    setActiveDropdown((prevIndex) => (prevIndex === index ? null : index));
  };

  const buttons = [
    {
      name: "Product",
      shortcuts: [
        {
          name: "AI Agents",
          context: `/ai/products/ai-agents`,
          icon: <SupervisorAccountIcon />,
        },
        {
          name: "AI Teams",
          context: `/ai/products/ai-teams`,
          icon: <GroupIcon />,
        },
        {
          name: "AI Tools",
          context: `/ai/products/ai-tools`,
          icon: <ConstructionIcon />,
        },
        {
          name: "Integrations",
          context: `/ai/products/integrations`,
          icon: <SettingsInputComponentIcon />,
        },
        {
          name: "API",
          context: `/ai/products/api`,
          icon: <CodeIcon />,
        },
      ],
    },

    {
      name: "Function",
      shortcuts: [
        {
          name: "Sales",
          context: `/ai/functions/sales`,
          icon: <TrendingUpIcon />,
        },
        {
          name: "Marketing",
          context: `/ai/functions/marketing`,
          icon: <LeaderboardIcon />,
        },
        {
          name: "Customer Support",
          context: `/ai/functions/customer-support`,
          icon: <SupportAgentIcon />,
        },
        {
          name: "Research",
          context: `/ai/functions/research`,
          icon: <SearchIcon />,
        },
        {
          name: "Operations",
          context: `/ai/functions/operations`,
          icon: <SettingsIcon />,
        },
      ],
    },
    {
      name: "Agents",
      shortcuts: [
        {
          name: "Catalyst The Sales Agent",
          context: `/ai/agents/catalyst-the-sales-agent`,
          icon: <MoveToInboxIcon />,
        },
        {
          name: "Catalyst Inbound Agent",
          context: `/ai/agents/catalyst-inbound-agent`,
          icon: <MoveToInboxIcon />,
        },
        {
          name: "Catalyst Outbound Agent",
          context: `/ai/agents/catalyst-outbound-agent`,
          icon: <ForwardToInboxIcon />,
        },
      ],
    },
    {
      name: "Company",
      shortcuts: [
        {
          name: "Back to Coding Rippler",
          context: `/`,
          icon: <HomeIcon />,
        },
        {
          name: "Web Rippler",
          context: `/web`,
          icon: <LanguageIcon />,
        },
        {
          name: "AI Rippler",
          context: `/ai`,
          icon: <HubIcon />,
        },
      ],
    },
  ];

  return (
    <>
      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <HomeButton context="/ai" />
      </CardContent>

      {buttons.map((button, index) => (
        <CardContent sx={{ flexGrow: 1, width: "100%" }} key={index}>
          <DropDownButton
            activeDropdown={activeDropdown}
            handleButtonClick={handleButtonClick}
            buttonName={button.name}
            dropDownMenu={button.shortcuts}
            buttonIndex={index}
          />
        </CardContent>
      ))}

      {/* <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <ProductButton
          activeDropdown={activeDropdown}
          handleButtonClick={handleButtonClick}
        />
      </CardContent>
      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <FunctionButton
          activeDropdown={activeDropdown}
          handleButtonClick={handleButtonClick}
        />
      </CardContent>
      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <AgentsButton
          activeDropdown={activeDropdown}
          handleButtonClick={handleButtonClick}
        />
      </CardContent> */}
      {/* <CardContent
        sx={{
          flexGrow: 1,
          width: "100%",
        }}
      >
        <ResourcesButton />
      </CardContent> */}

      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <PricingButton />
      </CardContent>

      {/* <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <ReturnToCodingRippler />
      </CardContent> */}

      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <LoginButton />
      </CardContent>
      <CardContent sx={{ flexGrow: 1, width: "100%" }}>
        <SignUpButton />
      </CardContent>
    </>
  );
};
