import {
  Email,
  Facebook,
  Instagram,
  LinkedIn,
  LocationOn,
  Phone,
} from "@mui/icons-material";
import XIcon from "@mui/icons-material/X";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import {
  CommonButtonV2,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  DotSpinner,
} from "../../CodingRipplerWidgets";

export const ContactHome = ({ children, ...props }) => {
  const [formData, setFormData] = useState({
    fullName: "",
    email: "",
    phone: "",
    subject: "",
    projectGoals: "",
    timeline: "",
    customTimeline: "",
  });

  const { theme } = props;

  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false); // Add loading state

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleTimeLineChange = (e) => {
    const { name, value } = e.target;
    if (value !== "Custom Timeline") {
      setFormData({ ...formData, [name]: value, customTimeline: "" });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  const handleSubjectChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value, projectGoals: "" });
  };

  const validateForm = () => {
    const errors = {};
    if (!formData.fullName) errors.fullName = "Full Name is required";
    if (!formData.email) {
      errors.email = "Email Address is required";
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email is not valid";
    }
    if (!formData.subject) errors.subject = "Subject is required";

    return errors;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true); // Set loading state to true
    const errors = validateForm();
    if (Object.keys(errors).length === 0) {
      // Simulate form submission
      setLoading(false); // Set loading state to false
      setFormErrors({});
      setFormData({
        fullName: "",
        email: "",
        phone: "",
        subject: "",
        projectGoals: "",
        timeline: "",
        customTimeline: "",
      });
      window.location.href = "/contact/success"; // Redirect to Thank You page
    } else {
      setTimeout(() => {
        setLoading(false); // Set loading state to false
        setFormErrors(errors);
      }, 2000); // Simulate a 2-second delay
    }
  };

  const startOver = (e) => {
    e.preventDefault();
    setFormErrors({});
    setFormData({
      fullName: "",
      email: "",
      phone: "",
      subject: "",
      projectGoals: "",
      timeline: "",
      customTimeline: "",
    });
  };

  const pageContent = {
    header: "Contact Us",
    subHeader: "We'd love to hear from you. Let’s discuss how we can assist.",
    customStyle: {
      customHeader: {
        textAlign: "left",
        fontWeight: "normal",
        fontSize: {
          xs: "1.5rem",
        },
      },
      customSubHeader: {
        textAlign: "left",
        fontSize: {
          xs: "1.25rem",
        },
      },

      topHeading: {
        textAlign: "left",
        fontSize: {
          xs: "2rem",
          md: "3rem",
        },
      },

      topSubHeading: {
        textAlign: "left",
        fontSize: {
          xs: "1.25rem",
          sm: "1.5rem",
          lg: "1.5rem",
        },
      },
    },

    footer: {
      header: "Direct Contact Information",
      subHeader: "Follow Us",
      companyEmeil: "contact@codingrippler.com",
      companyPhone: "+91 8125162306",
      companyAddress: "289/35 Ahmedabad, Gujarat, India, 380021",
      responseTime:
        "Our team will respond to your inquiry within 24–48 business hours.",
      privacyAssurance:
        "We respect your privacy. Your information will only be used to contact you regarding your inquiry.",
      customStyle: {
        customHeader: {
          textAlign: "left",
          fontWeight: "normal",
          fontSize: {
            xs: "1.5rem",
          },
        },
        customSubHeader: {
          textAlign: "left",
          fontSize: {
            xs: "1.25rem",
          },
        },
      },
    },
  };

  return (
    <Grid container spacing={0} className="white-bg">
      <Grid
        item
        xs={12}
        sx={{
          minHeight: {
            xs: `max(10vh, 64px)`,
            sm: `max(12vh, 80px)`,
            lg: `max(20vh, 125px)`,
          },
        }}
      />

      <Grid item xs={12} lg={1} />
      <Grid
        item
        xs={12}
        lg={4}
        sx={{
          m: {
            xs: 2,
            lg: 0,
          },
        }}
      >
        <Card
          sx={{
            borderRadius: "24px",
            p: "1rem",
            boxShadow: "0px 0px 3px 1px rgba(0, 0, 0, 0.2)", // Reduced shadow
          }}
        >
          <CardContent>
            {loading && (
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  width: "100%",
                  height: "100%",
                  backgroundColor: "rgba(255, 255, 255, 0.8)",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  zIndex: 9999,
                  transition: "opacity 0.3s ease-in-out",
                }}
              >
                <DotSpinner />
              </Box>
            )}
            {/* Heading */}
            <CommonHeaderTypography
              theme={theme}
              customStyle={pageContent?.customStyle?.topHeading}
            >
              {pageContent.header}
            </CommonHeaderTypography>

            <Box m={2} />

            <CommonSubHeaderTypography
              theme={theme}
              customStyle={pageContent?.customStyle?.topSubHeading}
            >
              {pageContent.subHeader}
            </CommonSubHeaderTypography>

            {/* Contact Form */}
            <Box component="form" sx={{ mt: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Full Name"
                    name="fullName"
                    autoComplete="name"
                    value={formData.fullName}
                    onChange={handleChange}
                    error={!!formErrors.fullName}
                    helperText={formErrors.fullName}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#7281ff", // Replace with your custom hover border color
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        "&.Mui-focused": {
                          color: "#7281ff", // Replace with your custom focused label color
                        },
                      },
                    }}
                    inputProps={{
                      "aria-label": "Full Name",
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    required
                    fullWidth
                    label="Email Address"
                    name="email"
                    autoComplete="email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!formErrors.email}
                    helperText={formErrors.email}
                    inputProps={{
                      "aria-label": "Email Address",
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#7281ff", // Replace with your custom hover border color
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        "&.Mui-focused": {
                          color: "#7281ff", // Replace with your custom focused label color
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Phone Number"
                    name="phone"
                    autoComplete="tel"
                    type="tel"
                    value={formData.phone}
                    onChange={handleChange}
                    inputProps={{
                      "aria-label": "Phone Number",
                    }}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#7281ff", // Replace with your custom hover border color
                        },
                      },
                    }}
                    InputLabelProps={{
                      sx: {
                        "&.Mui-focused": {
                          color: "#7281ff", // Replace with your custom focused label color
                        },
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sx={{}}>
                  <FormControl
                    required
                    fullWidth
                    variant="outlined"
                    error={!!formErrors.subject}
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "&:hover fieldset": {
                          borderColor: "#7281ff", // Replace with your custom hover border color
                        },
                      },
                    }}
                  >
                    <InputLabel
                      sx={{
                        "&.Mui-focused": {
                          color: "#7281ff", // Example: Change the label color to green when focused
                        },
                      }}
                    >
                      Subject/Reason for Contact
                    </InputLabel>
                    <Select
                      value={formData.subject}
                      onChange={handleSubjectChange}
                      label="Subject/Reason for Contact"
                      name="subject"
                      MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                          sx: {
                            "& .MuiMenuItem-root.Mui-selected": {
                              backgroundColor: "#e6e9ff", // Replace with your custom selected item background color
                            },
                          },
                        },
                      }}
                    >
                      <MenuItem value="Web Development Inquiry">
                        Web Development Inquiry
                      </MenuItem>
                      <MenuItem value="Custom AI Solution Inquiry">
                        Custom AI Solution Inquiry
                      </MenuItem>
                      <MenuItem value="Partnership">
                        General Partnership
                      </MenuItem>
                    </Select>
                    <FormHelperText>{formErrors.subject}</FormHelperText>
                  </FormControl>
                </Grid>
                {(formData.subject === "Web Development Inquiry" ||
                  formData.subject === "Custom AI Solution Inquiry") && (
                  <>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Project Goals"
                        name="projectGoals"
                        placeholder={
                          formData.subject === "Web Development Inquiry"
                            ? "e.g., Build an e-commerce platform"
                            : "e.g., Integrate AI for customer insights"
                        }
                        value={formData.projectGoals}
                        onChange={handleChange}
                        multiline
                        rows={4}
                        helperText="Providing your project goals helps us serve you better."
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              borderColor: "#7281ff", // Replace with your custom hover border color
                            },
                          },
                        }}
                        InputLabelProps={{
                          sx: {
                            "&.Mui-focused": {
                              color: "#7281ff", // Replace with your custom focused label color
                            },
                          },
                        }}
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl
                        fullWidth
                        error={!!formErrors.timeline}
                        sx={{
                          "& .MuiOutlinedInput-root": {
                            "&:hover fieldset": {
                              borderColor: "#7281ff", // Replace with your custom hover border color
                            },
                          },
                        }}
                      >
                        <InputLabel
                          id="project-timeline-label"
                          sx={{
                            "&.Mui-focused": {
                              color: "#7281ff", // Example: Change the label color to green when focused
                            },
                          }}
                        >
                          Project Timeline
                        </InputLabel>
                        <Select
                          labelId="project-timeline-label"
                          id="project-timeline"
                          name="timeline"
                          value={formData.timeline}
                          onChange={handleTimeLineChange}
                          label="Project Timeline"
                          MenuProps={{
                            disableScrollLock: true,
                            PaperProps: {
                              sx: {
                                "& .MuiMenuItem-root.Mui-selected": {
                                  backgroundColor: "#e6e9ff", // Replace with your custom selected item background color
                                },
                              },
                            },
                          }}
                        >
                          <MenuItem value="1-2 weeks">1-2 Weeks</MenuItem>
                          <MenuItem value="1-3 months">1-3 Months</MenuItem>
                          <MenuItem value="3-6 months">3-6 Months</MenuItem>
                          <MenuItem value="More than 6 months">
                            More than 6 months
                          </MenuItem>
                          <MenuItem value="Unsure">Unsure</MenuItem>
                          <MenuItem value="Flexible">Flexible</MenuItem>
                          <MenuItem value="Custom Timeline">
                            Custom Timeline
                          </MenuItem>
                        </Select>
                        <FormHelperText>
                          Providing a timeline helps us tailor our response to
                          your needs.
                        </FormHelperText>
                      </FormControl>
                    </Grid>

                    {formData.timeline === "Custom Timeline" && (
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="Custom Timeline"
                          name="customTimeline"
                          value={formData.customTimeline}
                          onChange={handleChange}
                          variant="outlined"
                          placeholder="e.g., 5 weeks"
                          error={!!formErrors.customTimeline}
                          helperText={formErrors.customTimeline}
                          inputProps={{
                            "aria-label": "Project timeline",
                          }}
                          sx={{
                            "& .MuiOutlinedInput-root": {
                              "&:hover fieldset": {
                                borderColor: "#7281ff", // Replace with your custom hover border color
                              },
                            },
                          }}
                          InputLabelProps={{
                            sx: {
                              "&.Mui-focused": {
                                color: "#7281ff", // Replace with your custom focused label color
                              },
                            },
                          }}
                        />
                      </Grid>
                    )}
                  </>
                )}

                <Grid item xs={12}>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    gap={2}
                    width="100%"
                  >
                    <CommonButtonV2
                      buttonText={"Clear"}
                      clickHandler={startOver}
                      customClass={"button-white"}
                    />
                    <CommonButtonV2
                      buttonText={"Submit"}
                      clickHandler={handleSubmit}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} lg={1} />
      <Grid
        item
        xs={12}
        lg={5}
        sx={{
          minHeight: "75vh",
          maxHeight: "75vh",
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      >
        <CardMedia
          component="img"
          image={"/codingrippler/contact-us/contact-us.png"}
          alt={"Contact Us"}
          sx={{
            objectFit: "contain",
            height: "100%",
            width: "100%",
          }}
        />
      </Grid>
      <Grid
        item
        xs={1}
        sx={{
          display: {
            xs: "none",
            lg: "block",
          },
        }}
      />

      <Grid item xs={12} sx={{ minHeight: "5vh" }} />
      {/* Our workspace   */}
      <Grid item xs={12} lg={1}></Grid>

      <Grid
        item
        xs={12}
        lg={10}
        sx={{
          m: {
            xs: 2,
            lg: 0,
          },
        }}
      >
        <Card sx={{ borderRadius: "24px", p: "1rem" }}>
          <CardContent>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack sx={{ mt: 5 }} spacing={2}>
                  <CommonHeaderTypography
                    theme={theme}
                    customStyle={pageContent?.customStyle?.customHeader}
                  >
                    {pageContent?.footer?.header}
                  </CommonHeaderTypography>

                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap={2}
                  >
                    <Email className="theme-specific-color" />{" "}
                    <Typography
                      variant="body1"
                      className="theme-specific-color"
                    >
                      <Link
                        href="mailto:contact@codingrippler.com"
                        sx={{ color: theme.palette.primary.main }}
                        className="theme-specific-color"
                      >
                        {pageContent?.footer?.companyEmeil}
                      </Link>
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap={2}
                  >
                    <Phone className="theme-specific-color" />
                    <Typography variant="body1">
                      <Link
                        href="tel:+1234567890"
                        sx={{ color: theme.palette.primary.main }}
                        className="theme-specific-color"
                      >
                        {pageContent?.footer?.companyPhone}
                      </Link>
                    </Typography>
                  </Box>

                  <Box
                    display="flex"
                    justifyContent="flex-start"
                    alignItems="center"
                    gap={2}
                  >
                    <LocationOn className="theme-specific-color" />
                    <Typography
                      variant="body1"
                      sx={{ color: theme.palette.primary.main }}
                      className="theme-specific-color"
                    >
                      {pageContent?.footer?.companyAddress}
                    </Typography>
                  </Box>
                </Stack>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mt: 3 }}>
                  <CommonHeaderTypography
                    theme={theme}
                    customStyle={pageContent?.customStyle?.customHeader}
                  >
                    {pageContent?.footer?.subHeader}
                  </CommonHeaderTypography>
                  <Box
                    sx={{
                      mt: 2,
                    }}
                  >
                    <IconButton
                      href="https://facebook.com"
                      target="_blank"
                      rel="noopener"
                    >
                      <Facebook />
                    </IconButton>
                    <IconButton
                      href="https://twitter.com"
                      target="_blank"
                      rel="noopener"
                    >
                      <XIcon />
                    </IconButton>
                    <IconButton
                      href="https://linkedin.com"
                      target="_blank"
                      rel="noopener"
                    >
                      <LinkedIn />
                    </IconButton>
                    <IconButton
                      href="https://instagram.com"
                      target="_blank"
                      rel="noopener"
                    >
                      <Instagram />
                    </IconButton>
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12}>
                <Box sx={{ mt: 3 }}>
                  <CommonSubHeaderTypography
                    theme={theme}
                    customStyle={pageContent?.customStyle?.customSubHeader}
                  >
                    Our team will respond to your inquiry within 24–48 business
                    hours.
                  </CommonSubHeaderTypography>
                </Box>

                <Box sx={{ mt: 3 }}>
                  <Typography variant="body1" color="textSecondary">
                    We respect your privacy. Your information will only be used
                    to contact you regarding your inquiry.{" "}
                    <Link href="/privacy-policy">Privacy Policy</Link>
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>

      <Grid item xs={12} lg={1}></Grid>

      <Grid item xs={12} sx={{ minHeight: "5vh" }} />
    </Grid>
  );
};
