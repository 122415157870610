import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import EventRepeatIcon from "@mui/icons-material/EventRepeat";
import GroupIcon from "@mui/icons-material/Group";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import SettingsEthernetIcon from "@mui/icons-material/SettingsEthernet";
import { Button, Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RouteToAIPortal } from "../../../../../CodingRippler/CodingRipplerWidgets";

export const SalesTabDetails = (props) => {
  return (
    <Grid
      container
      spacing={5}
      sx={{
        pl: {
          lg: 10, // padding-left: 10 for large screens and up
        },
      }}
    >
      <Grid item xs={12}>
        <Box>
          <Typography
            variant="h4"
            fontWeight="bold"
            className="typography-custom-css"
          >
            Convert more leads and drive sales growth with AI-powered efficiency
          </Typography>

          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginTop={1}
          >
            AI-driven automation that turns leads into customers on autopilot.
          </Typography>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <Box display="flex" justifyContent="flex-start" alignItems="center">
          <GroupIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            Nurture leads and build lasting relationships
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <SettingsEthernetIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            Integrate seamlessly with your tech stack
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <EventRepeatIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            Book meetings 24/7, automatically
          </Typography>
        </Box>

        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={2}
        >
          <ModeEditIcon className="theme-background-color" />
          <Typography
            variant="h6"
            className="sub-header typography-custom-css"
            marginLeft={2}
          >
            Personalize outreach to boost engagement
          </Typography>
        </Box>

        <Box marginTop={4}>
          <Button
            variant="text"
            size="large"
            endIcon={<ArrowRightAltIcon />}
            style={{
              fontSize: "1rem",
              padding: "1rem 2rem",
              borderRadius: "14px",
              color: "#6056ff",
            }}
            onClick={RouteToAIPortal}
          >
            Learn more
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};
