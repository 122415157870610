// This avatar are used to display the profile pictures of the users. or green icons for the users. if they are live

import { Avatar } from "@mui/material";
export const AvatarComponent = ({ src, alt, ...props }) => {
  const defaultStyle = {
    width: 100,
    height: 100,
    border: "5px solid #fff",
  };

  const { customStyle } = props;

  const mergedStyle = { ...defaultStyle, ...customStyle };

  return (
    <Avatar
      alt={alt}
      src={src}
      sx={{
        width: mergedStyle.width,
        height: mergedStyle.height,
        border: mergedStyle.border,
      }}
    />
  );
};
