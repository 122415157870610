import React from "react";
import { Route, Routes } from "react-router-dom";
import { Link } from "react-scroll";
import { NavigationBar } from "../BoilarPlateJs/NavigationBar.jsx";
import { DealsAndDiscountsPage } from "../TourAndTravel/FilterTheTourPage/FilterByDealsAndDiscount/DealsAndDiscountsPage.jsx";
import { FilterByDestinationName } from "../TourAndTravel/FilterTheTourPage/FilterByDestination/FilterByDestinationName.jsx";
import { FilterByTourType } from "../TourAndTravel/FilterTheTourPage/FilterByType/FilterByTourType.jsx";
import { ToursAndTravel } from "../TourAndTravel/HomePages/ToursAndTravel.jsx";
import { IndividualTours } from "../TourAndTravel/IndvidualToursDetailPage/IndividualTours.jsx";
import { TourAndTravelFooter } from "../TourAndTravel/TourAndTravelFooterPage.js";
//TourData

//popular tours data
const PopularTourBackground = "/tourandtravel/PopularTourBackground.jpg";
let jsonData = [
  {
    pkId: 1,
    packageName: "Beach Holidays",
    destination: [
      {
        destinationId: 1,
        destinationName: "Goa, India",
        destinationImagePath: "/tourandtravel/GoaIndia.jpg",
        country: "India",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Special Offer",
    tripRating: 5,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$500",
    discountedTripAmount: "$300",
    tripHeading: "Goa",
    tripDescription:
      "Enjoy the beautiful beaches and vibrant nightlife of Goa.",
    tripDurationInDays: "5 Days",
    tripCustomerRating: "9/10",
    tourDuration: "Oct-Nov",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 2,
    tourReviews: [
      {
        reviewerName: "Mark Doe",
        postDescription:
          "Goa, India, offers beautiful beaches, lively nightlife, and rich cultural heritage, making it a must-visit destination.",
        postHeading: "Goa, India",
        reviewerProfilePicLocation: "/tourandtravel/profilePic3.jpg",
      },
      {
        reviewerName: "William Dalrymple",
        postDescription:
          "Goa, India, is a delightful blend of scenic beaches, vibrant culture, and warm hospitality.",
        postHeading: "Goa, India",
        reviewerProfilePicLocation: "/tourandtravel/profilePic3.jpg",
      },
    ],
  },
  {
    pkId: 2,
    packageName: "Beach Holidays",
    destination: [
      {
        destinationId: 2,
        destinationName: "Maldives",
        destinationImagePath: "/tourandtravel/Maldives.jpg",
        country: "Maldives",
      },
    ],
    isLowPriorityTrip: true,
    anyOffer: true,
    typeOfOffer: "Special Offer",
    tripRating: 4,
    imagePath: PopularTourBackground,
    showDiscount: false,
    actualTripAmount: "$400",
    discountedTripAmount: "$400",
    tripHeading: "Maldives",
    tripDescription:
      "Experience the romantic city of Maldives with its iconic landmarks.",
    tripDurationInDays: "7 Days",
    tripCustomerRating: "8/10",
    tourDuration: "Jan-Mar",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 1,
    tourReviews: [
      {
        reviewerName: "Emily Watson",
        postDescription:
          "Maldives, enchants visitors with its iconic landmarks, world-class cuisine, and timeless charm.",
        postHeading: "Maldives",
        reviewerProfilePicLocation: "/tourandtravel/profilePic3.jpg",
      },
      {
        reviewerName: "Sophia Turner",
        postDescription:
          "Maldives, dazzles with its romantic ambiance, exquisite art museums, and vibrant café culture.",
        postHeading: "Maldives",
        reviewerProfilePicLocation: "/tourandtravel/profilePic3.jpg",
      },
    ],
  },
  {
    pkId: 3,
    packageName: "Beach Holidays",
    destination: [
      {
        destinationId: 3,
        destinationName: "Bali, Indonesia",
        destinationImagePath: "/tourandtravel/Bali.jpg",
        country: "Indonesia",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Limited Time Offer",
    tripRating: 5,
    imagePath: PopularTourBackground,
    showDiscount: false,
    actualTripAmount: "$600",
    discountedTripAmount: "$600",
    tripHeading: "Indonesia",
    tripDescription: "Discover the beauty and culture of Bali, Indonesia.",
    tripDurationInDays: "6 Days",
    tripCustomerRating: "10/10",
    tourDuration: "Jan-Feb",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 0,
    tourReviews: [],
  },
  {
    pkId: 4,
    packageName: "Beach Holidays",
    destination: [
      {
        destinationId: 4,
        destinationName: "Hawaii, USA",
        destinationImagePath: "/tourandtravel/Hawaii.jpg",
        country: "USA",
      },
    ],
    isLowPriorityTrip: true,
    anyOffer: false,
    typeOfOffer: "No Offer",
    tripRating: 3,
    imagePath: PopularTourBackground,
    showDiscount: false,
    actualTripAmount: "$350",
    discountedTripAmount: "$350",
    tripHeading: "Tokyo, Japan",
    tripDescription:
      "Immerse yourself in the unique culture and technology of Tokyo.",
    tripDurationInDays: "8 Days",
    tripCustomerRating: "7/10",
    tourDuration: "Feb-Apl",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 0,
    tourReviews: [],
  },
  {
    pkId: 5,
    packageName: "Beach Holidays",
    destination: [
      {
        destinationId: 5,
        destinationName: "Caribbean islands, Barbados",
        destinationImagePath: "/tourandtravel/Barbados.jpg",
        country: "Barbados",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Early Bird Offer",
    tripRating: 4,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$700",
    discountedTripAmount: "$550",
    tripHeading: "Caribbean islands",
    tripDescription:
      "Experience the vibrant culture and stunning beaches of the Caribbean islands.",
    tripDurationInDays: "10 Days",
    tripCustomerRating: "8/10",
    tourDuration: "Feb-Apl",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 2,
    tourReviews: [
      {
        reviewerName: "Emily Watson",
        postDescription:
          "Sydney, Australia, enchants visitors with its iconic landmarks, world-class cuisine, and timeless charm.",
        postHeading: "Sydney, Australia",
        reviewerProfilePicLocation: "/tourandtravel/profilePic3.jpg",
      },
      {
        reviewerName: "Sophia Turner",
        postDescription:
          "Sydney, Australia, dazzles with its romantic ambiance, exquisite art museums, and vibrant café culture.",
        postHeading: "Sydney, Australia",
        reviewerProfilePicLocation: "/tourandtravel/profilePic3.jpg",
      },
    ],
  },
  {
    pkId: 6,
    packageName: "Adventure Tours",
    destination: [
      {
        destinationId: 6,
        destinationName: "Rafting",
        destinationImagePath: "/tourandtravel/Rafting.jpg",
        country: "New Zealand",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Special Offer",
    tripRating: 4,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$800",
    discountedTripAmount: "$600",
    tripHeading: "Rafting",
    tripDescription:
      "Experience the thrill of white-water rafting in New Zealand.",
    tripDurationInDays: "7 Days",
    tripCustomerRating: "8/10",
    tourDuration: "May-Jun",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 0,
    tourReviews: [],
  },
  {
    pkId: 7,
    packageName: "Adventure Tours",
    destination: [
      {
        destinationId: 7,
        destinationName: "Canadian Rockies",
        destinationImagePath: "/tourandtravel/Rafting.jpg",
        country: "Canada",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Flash Sale",
    tripRating: 5,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$1000",
    discountedTripAmount: "$750",
    tripHeading: "Canadian Rockies",
    tripDescription: "Explore the stunning landscapes of the Canadian Rockies.",
    tripDurationInDays: "6 Days",
    tripCustomerRating: "9/10",
    tourDuration: "Jul-Aug",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 1,
    tourReviews: [
      {
        reviewerName: "David Johnson",
        postDescription:
          "Our time in the Canadian Rockies was pure magic! From hiking in the mountains to exploring the crystal-clear lakes, every moment was unforgettable. The beauty of the Rockies and the warmth of the locals made it a dreamy escape. Can't wait to return!",
        postHeading: "Canadian Rockies",
        reviewerProfilePicLocation: "/tourandtravel/profilePic3.jpg",
      },
    ],
  },
  {
    pkId: 8,
    packageName: "Cruise",
    destination: [
      {
        destinationId: 8,
        destinationName: "Italy Cruises",
        destinationImagePath: "/tourandtravel/ItalyCruises.jpg",
        country: "Italy",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Last Minute Deal",
    tripRating: 4,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$900",
    discountedTripAmount: "$700",
    tripHeading: "Rio de Janeiro, Brazil",
    tripDescription: "Enjoy the vibrant culture and beautiful beaches of Rio.",
    tripDurationInDays: "8 Days",
    tripCustomerRating: "8/10",
    tourDuration: "Sep-Oct",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 0,
    tourReviews: [],
  },
  {
    pkId: 9,
    packageName: "Cruise Packages",
    destination: [
      {
        destinationId: 9,
        destinationName: "Viking Ocean Cruises",
        destinationImagePath: "/tourandtravel/vikingOceanCruises.jpg",
        country: "Denmark",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Early Booking Bonus",
    tripRating: 5,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$1200",
    discountedTripAmount: "$900",
    tripHeading: "Cape Town, South Africa",
    tripDescription:
      "Discover the stunning landscapes and diverse wildlife of Cape Town.",
    tripDurationInDays: "10 Days",
    tripCustomerRating: "9/10",
    tourDuration: "Nov-Dec",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 0,
    tourReviews: [],
  },
  {
    pkId: 10,
    isLowPriorityTrip: false,
    packageName: "Romantic Getaways",
    destination: [
      {
        destinationId: 10,
        destinationName: "private tours, Paris",
        destinationImagePath: "/tourandtravel/privateTours.jpg",
        country: "France",
      },
    ],
    anyOffer: true,
    typeOfOffer: "Limited Time Offer",
    tripRating: 4,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$1100",
    discountedTripAmount: "$850",
    tripHeading: "private tours",
    tripDescription: "Experience the romance and elegance of Paris.",
    tripDurationInDays: "7 Days",
    tripCustomerRating: "8/10",
    tourDuration: "Dec-Jan",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 0,
    tourReviews: [],
  },
  {
    pkId: 11,
    packageName: "Family Vacation Packages",
    destination: [
      {
        destinationId: 11,
        destinationName: "Disney World",
        destinationImagePath: "/tourandtravel/disneyWorld.jpg",
        country: "USA",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Special Offer",
    tripRating: 5,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$950",
    discountedTripAmount: "$700",
    tripHeading: "Disney World",
    tripDescription: "Experience the magic of Disney World in Orlando.",
    tripDurationInDays: "6 Days",
    tripCustomerRating: "9/10",
    tourDuration: "Jan-Feb",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 0,
    tourReviews: [],
  },
  {
    pkId: 12,
    packageName: "Nature and Wildlife Safaris",
    destination: [
      {
        destinationId: 12,
        destinationName: "Corbett National Park, India",
        destinationImagePath: "/tourandtravel/corbettNationalPark.jpg",
        country: "India",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Exclusive Deal",
    tripRating: 4,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$1050",
    discountedTripAmount: "$800",
    tripHeading: "Corbett National Park",
    tripDescription:
      "Come face to face with the majestic wildlife of Corbett National Park.",
    tripDurationInDays: "8 Days",
    tripCustomerRating: "8/10",
    tourDuration: "Feb-Mar",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 0,
    tourReviews: [],
  },
  {
    pkId: 13,
    packageName: "Nature and Wildlife Safaris",
    destination: [
      {
        destinationId: 13,
        destinationName: "Yala National Park, Sri Lanka",
        destinationImagePath: "/tourandtravel/yalaNationalPark.jpg",
        country: "Sri Lanka",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Holiday Special",
    tripRating: 5,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$1150",
    discountedTripAmount: "$900",
    tripHeading: "Yala National Park",
    tripDescription:
      "Embark on a thrilling safari adventure in the wilderness of Yala National Park.",
    tripDurationInDays: "10 Days",
    tripCustomerRating: "9/10",
    tourDuration: "Mar-Apr",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 0,
    tourReviews: [],
  },
  {
    pkId: 14,
    packageName: "Nature and Wildlife Safaris",
    destination: [
      {
        destinationId: 14,
        destinationName: "Yellowstone National Park, USA",
        destinationImagePath: "/tourandtravel/yellowstoneNationalPark.jpg",
        country: "USA",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Weekend Sale",
    tripRating: 4,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$750",
    discountedTripAmount: "$550",
    tripHeading: "Yellowstone National Park",
    tripDescription:
      "Discover the natural wonders and diverse wildlife of Yellowstone National Park.",
    tripDurationInDays: "7 Days",
    tripCustomerRating: "8/10",
    tourDuration: "Apr-May",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 0,
    tourReviews: [],
  },
  {
    pkId: 15,
    packageName: "Wellness and Retreat Packages",
    destination: [
      {
        destinationId: 15,
        destinationName: "wellness workshops",
        destinationImagePath: "/tourandtravel/yellowstoneNationalPark.jpg",
        country: "India",
      },
    ],
    isLowPriorityTrip: false,
    anyOffer: true,
    typeOfOffer: "Member Discount",
    tripRating: 5,
    imagePath: PopularTourBackground,
    showDiscount: true,
    actualTripAmount: "$850",
    discountedTripAmount: "$650",
    tripHeading: "Rishikesh",
    tripDescription:
      "Embark on a spiritual journey to Rishikesh, the yoga capital of the world.",
    tripDurationInDays: "6 Days",
    tripCustomerRating: "9/10",
    tourDuration: "May-Jun",
    tourIncludes:
      "Accommodations, Airfare included, Transportation, Professional guide",
    tourExludes: "Entrance fees, Guide gratuity",
    totalReviewsSubmitted: 1,
    tourReviews: [
      {
        reviewerName: "John Doe",
        postDescription:
          "Our time in Rishikesh was pure magic! From yoga on the banks of the Ganges to meditation in the Himalayas, every moment was unforgettable. The spirituality of the city and the warmth of the locals made it a dreamy escape. Can't wait to return!",
        postHeading: "Athens, Greece",
        reviewerProfilePicLocation: "/tourandtravel/profilePic3.jpg",
      },
    ],
  },
];

export function TourAndTravelServices() {
  return (
    <>
      <NavigationBar />

      <Routes>
        <Route path="/" element={<ToursAndTravel tourData={jsonData} />} />
        <Route
          path="/Tours"
          element={<IndividualTours tourData={jsonData} />}
        />
        <Route
          path="DealsAndDiscount"
          element={<DealsAndDiscountsPage tourData={jsonData} />}
        />

        <Route
          path="Destination"
          element={<FilterByDestinationName tourData={jsonData} />}
        />

        <Route path="Type" element={<FilterByTourType tourData={jsonData} />} />
      </Routes>

      <Link
        to="tourAndTravelFooter"
        spy={true}
        smooth={true}
        offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        <TourAndTravelFooter />
      </Link>
    </>
  );
}
