import { TabsContainer } from "./TabsContainer";
import { OutboundTabDetails } from "./TabWidgets/OutboundTabDetails";

export const OutboundTabPanel = (props) => {
  const { value } = props;

  return (
    <TabsContainer value={value} index={1}>
      <OutboundTabDetails />
    </TabsContainer>
  );
};
