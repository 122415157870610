import { Grid, Typography, useMediaQuery } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { useTheme } from "@mui/material/styles";
import { keyframes, styled } from "@mui/system";

const pulse = keyframes`
  0% {
    box-shadow: 0 0 16px 0 hsla(243.51063829787236, 96.91%, 61.96%, 0.10), 0 0 0 32px hsla(250.90909090909088, 78.46%, 96.91%, 1.00), 0 0 0 70px hsla(250.90909090909088, 100.00%, 98.67%, 1.00);
  }
  70% {
    box-shadow: 0 0 8px 0 hsla(243.51063829787236, 96.91%, 61.96%, 0.10), 0 0 0 16px hsla(250.90909090909088, 78.46%, 96.91%, 1.00), 0 0 0 35px hsla(250.90909090909088, 100.00%, 98.67%, 1.00);
  }
  100% {
    box-shadow: 0 0 16px 0 hsla(243.51063829787236, 96.91%, 61.96%, 0.10), 0 0 0 32px hsla(250.90909090909088, 78.46%, 96.91%, 1.00), 0 0 0 70px hsla(250.90909090909088, 100.00%, 98.67%, 1.00);
  }
`;

export const AnimatedBox = ({ children }) => (
  <Box
    sx={{
      animation: `${pulse} 2s infinite`,
      borderRadius: "24px",
    }}
  >
    {children}
  </Box>
);

export const YouTubeBox = ({ children, ...props }) => {
  return (
    <Box
      sx={{
        position: "relative",
        paddingBottom: "42.86%", // 16:9 aspect ratio
        overflow: "hidden",
        backgroundColor: "black",
        height: "100%",
      }}
    >
      <iframe
        src="https://www.youtube.com/embed/5rEZGSFgZVY"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video"
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
        }}
      ></iframe>
    </Box>
  );
};

export const ResponsiveBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column", // Default for small screens
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column", // Adjust as needed for small screens and up
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row", // Adjust as needed for medium screens and up
  },
}));

export const ResponsiveBoxCenteredChildren = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column", // Default for small screens
  [theme.breakpoints.up("sm")]: {
    flexDirection: "column", // Adjust as needed for small screens and up
  },
  [theme.breakpoints.up("md")]: {
    flexDirection: "row", // Adjust as needed for medium screens and up
  },
  justifyContent: "center",
  alignItems: "center",
}));

export const AICommonContainer = ({ children, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));

  const { customStyle } = props;

  const defaultStyle = {
    position: "relative",
    pt: 6,
    pl: isSmallScreen || isMediumScreen ? 2 : 2,
    pr: isSmallScreen || isMediumScreen ? 2 : 2,
  };

  const mergedStyle = { ...defaultStyle, ...customStyle };

  return (
    <Box
      sx={{
        position: mergedStyle.position,
        pt: mergedStyle.pt,
        pl: mergedStyle.pl,
        pr: mergedStyle.pr,
        pb: mergedStyle.pb,
      }}
    >
      <Container maxWidth="lg">{children}</Container>
    </Box>
  );
};

export const AICommonBoxContainer = ({ children, ...props }) => {
  return (
    // <Box
    //   sx={{
    //     marginLeft: {
    //       xs: "20px",
    //       sm: "30px",
    //       md: "30px",
    //       lg: "1.5%",
    //       xl: "10%",
    //     },
    //     marginRight: {
    //       xs: "20px",
    //       sm: "30px",
    //       md: "30px",
    //       lg: "1.5%",
    //       xl: "10%",
    //     },
    //     marginTop: {
    //       xs: "20px",
    //       sm: "30px",
    //       md: "30px",
    //       lg: "2.5%",
    //       xl: "2.5%",
    //     },

    //     backgroundColor: "#fff",
    //     ...customStyle,
    //   }}
    // >
    //   {" "}
    //   {children}
    // </Box>

    <Grid container>
      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
          },
        }}
      />

      <Grid item xs={12} lg={10} sx={{}}>
        {children}
      </Grid>

      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
          },
        }}
      />
    </Grid>
  );
};

export const CommonHeaderTypography = ({ children, ...props }) => {
  const { className = "typography-custom-css", variant, customStyle } = props;

  return (
    <Typography
      variant={variant || "h4"}
      className={className}
      fontWeight="bold"
      sx={{
        textAlign: { xs: "center", sm: "center", lg: "center" },
        ...customStyle,
      }}
    >
      {children}
    </Typography>
  );
};

export const CommonSubHeaderTypography = ({ children, ...props }) => {
  const { customStyle } = props;
  const { className = "typography-custom-css", variant } = props;

  return (
    <Typography
      variant={variant || "h6"}
      fontWeight="normal"
      className={`sub-header ${className}`}
      sx={{
        textAlign: { xs: "center", sm: "center", lg: "center" },
        ...customStyle,
      }}
    >
      {children}
    </Typography>
  );
};

//This function is used to truncate the string to a given length
export const truncate = ({ str, maxLength }) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3);
  } else {
    // if the string is already shorter than the maxLength then return null to handle the tooltip case
    // becuase if it is null we can display the non-truncated string
    return null;
  }
};
