import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import ConstructionIcon from "@mui/icons-material/Construction";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { RequestDemo, TryForFree } from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
} from "../../AIUtil/AIUtils";
import {
  AIToolsFeatures,
  CustomAIToolsContainer,
  ProductsPageHeader,
  ProductsPageSubHeader,
  ToolTemplateContainer,
  TopicContainer,
} from "../ProductsWidget";

export const AIToolsHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="AI Tool Builder">
          <AutoAwesomeIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                Boost Efficiency with{" "}
                <span className="theme-specfic-colored-text">Our Suite </span>{" "}
                of AI Tools{" "}
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Customize tools for your agents or use them independently to
                boost efficiency.{" "}
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ResponsiveBoxCenteredChildren>
          <TryForFree />
          <Box m={2} />
          <RequestDemo />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      {/* Build Custom AI Tools
       */}
      <Grid item xs={12}>
        <TopicContainer topicText="Build Custom AI Tools">
          <ConstructionIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                Unlock Advanced Capabilities for Your AI Agent
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Customize tools to boost your agent’s performance.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <AICommonBoxContainer>
          <CustomAIToolsContainer />
        </AICommonBoxContainer>
      </Grid>

      {/* Feature page  */}

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TopicContainer topicText="Features">
          <DataObjectOutlinedIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                Automate Your Processes for{" "}
                <span className="theme-specfic-colored-text">
                  Effortless Efficiency.{" "}
                </span>
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Transform repetitive tasks into seamless workflows, freeing up
                time for more strategic work.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <AICommonBoxContainer>
          <AIToolsFeatures />
        </AICommonBoxContainer>
      </Grid>

      {/* Build Custom AI Tools
       */}
      <Grid item xs={12}>
        <TopicContainer topicText="Tool Builder">
          <ConstructionIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <ProductsPageHeader
            headerText={
              <CommonHeaderTypography>
                Customize or Create Your Own Tools with Ease
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <ProductsPageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Select the right tool for your industry or create one from
                scratch to suit your specific needs.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <ToolTemplateContainer />
      </Grid>
    </Grid>
  );
};
