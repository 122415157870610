import React, { createContext, useContext, useState } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import CssBaseline from "@mui/material/CssBaseline";
import { createTheme } from "@mui/material/styles";
import { ThemeProvider } from "@mui/material/styles";

// const root = ReactDOM.createRoot(document.getElementById("root"));
// const theme = createTheme({
//   palette: {
//     mode: "dark",
//     constantColors: {
//       white: "#ffffff",
//       black: "#000000",
//       hawaiianOcean: "#429cb5",
//       dimGray: "#696969",
//       pink: "#ff47a0",
//     },
//   },
//   tourAndTravel: {
//     headingTextSize: { sm: "1.0rem", md: "1.5rem" },
//     bodyTextSize: { sm: "0.8rem", md: "1.0rem" },
//   },
// });

// root.render(
//   <ThemeProvider theme={theme}>
//     <CssBaseline />
//     <App />
//   </ThemeProvider>
// );

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

// Step 1: Create a context for theme mode
const ThemeModeContext = createContext();

// Step 2: Create a custom hook to use the theme mode context
export function useThemeMode() {
  return useContext(ThemeModeContext);
}

const root = ReactDOM.createRoot(document.getElementById("root"));

const AppWithTheme = () => {
  // Step 3: Use state to manage theme mode
  const [mode, setMode] = useState("light");

  // Function to toggle theme mode
  // const toggleTheme = () => {
  //   setMode((prevMode) => (prevMode === "dark" ? "light" : "dark"));
  // };

  const toggleTheme = (newMode) => {
    setMode(newMode);
  };

  // Create theme with dynamic mode
  const theme = createTheme({
    palette: {
      mode: mode,
      constantColors: {
        white: "#ffffff",
        black: "#000000",
        hawaiianOcean: "#429cb5",
        dimGray: "#696969",
        pink: "#ff47a0",
      },
    },
    tourAndTravel: {
      headingTextSize: { sm: "1.0rem", md: "1.5rem" },
      bodyTextSize: { sm: "0.8rem", md: "1.0rem" },
    },
    AI: {
      paddingParent: { sm: 2, md: 2, lg: 2 },
      marginParent: { sm: 2, md: 2, lg: 2 },
    },
  });

  // Step 4: Provide the theme mode and toggle function via context
  return (
    <ThemeModeContext.Provider value={{ toggleTheme }}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </ThemeModeContext.Provider>
  );
};

root.render(<AppWithTheme />);

reportWebVitals();
