import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Fab,
  Grid,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";

export const FeatureWidgets = (children, ...props) => {
  const theme = useTheme();

  return (
    <Box display="flex" justifyContent="center">
      <Fab
        variant="extended"
        size="large"
        sx={{ backgroundColor: theme.palette.background.default }}
        p={10}
        pointerEvents="none"
      >
        <DataObjectOutlinedIcon />

        <Typography ml={1}>Features</Typography>
      </Fab>
    </Box>
  );
};

export const FeatureHeaderUseCases = (children, ...props) => {
  return (
    <Box>
      <Typography
        variant="h4"
        fontWeight="bold"
        className="typography-custom-css"
        sx={{
          textAlign: { xs: "center", lg: "center" },
        }}
      >
        Put Your Business on{" "}
        <span className="theme-specfic-colored-text">Autopilot</span> for Better
        Results
      </Typography>
    </Box>
  );
};

export const FeatureSubHeaderHome = (children, ...props) => {
  return (
    <Box>
      <Typography
        variant="h6"
        fontWeight="normal"
        className="sub-header typography-custom-css"
        sx={{
          textAlign: { xs: "center", lg: "center" },
        }}
      >
        Streamline your operations and let AI handle the rest.
      </Typography>
    </Box>
  );
};

export const FeatureCard = ({ image, title, description, ...props }) => {
  return (
    <Card
      sx={{
        borderRadius: "24px",
        position: "relative",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        minHeight: { xs: "450px", xl: "450px" }, // Set a minimum height for consistency
        height: { xs: "450px", xl: "450px" }, // Set a minimum height for consistency
        maxHeight: { xs: "450px", xl: "450px" }, // Set a maximum height for consistency
        m: 2,
      }}
    >
      <CardMedia
        component="img"
        image={image}
        alt={title}
        sx={{
          height: "70%",
          width: "100%",
          objectFit: "cover",
        }}
      />

      <CardContent sx={{ mt: 5 }} height="30%">
        <Typography
          variant="h5"
          className="typography-custom-css"
          fontWeight="bold"
        >
          {title}
        </Typography>
        <Box m={2} />
        <Typography
          variant="body1"
          className="sub-header, typography-custom-css"
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};

export const FeaturesContainer = ({ children, ...props }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={6} xl={4}>
        <FeatureCard
          image="/airippler/features/no-coding-required.png"
          title="Zero coding required"
          description="No coding required—empower your business with customizable AI teammates."
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3} xl={4}>
        <FeatureCard
          image="/airippler/features/templatesToGetStarted.png"
          title="Instant Templates"
          description="Start fast with our growing library of customizable AI Agent templates."
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3} xl={4}>
        <FeatureCard
          image="/airippler/features/teachYourProcess.png"
          title="Turn Processes into Tasks"
          description="Easily convert your processes into actionable instructions for AI teammates."
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FeatureCard
          image="/airippler/features/agent-skill.png"
          title="Empower AI Agents"
          description="Empower your AI teammates with tools for everything—from Google search to YouTube transcription."
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={6}>
        <FeatureCard
          image="/airippler/features/IntegrateWithTechTrack.png"
          title="Seamless Integrations"
          description="Seamlessly integrate your AI teammate with your tech stack using Zapier, Snowflake, and more."
        />
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <FeatureCard
          image="/airippler/features/teachYourProcess.png"
          title="Flexible LLM Choices"
          description="Easily switch between leading LLM providers like OpenAI, Google, Meta, and Anthropic."
        />
      </Grid>
    </Grid>
  );
};
