import { Grid } from "@mui/material";
import { TabsContainer } from "./TabsContainer";
import { MarketingTabAnimationComponent } from "./TabWidgets/MarketingTabAnimationComponent";
import { MarketingTabDetails } from "./TabWidgets/MarketingTabDetails";

export const MarketingTabPanel = (props) => {
  const { value } = props;

  return (
    <TabsContainer value={value} index={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} lg={7}>
          <MarketingTabDetails />
        </Grid>

        <Grid item xs={12} md={4} lg={5}>
          <MarketingTabAnimationComponent />
        </Grid>
      </Grid>{" "}
    </TabsContainer>
  );
};
