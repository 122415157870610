import EmailIcon from "@mui/icons-material/Email";
import EventIcon from "@mui/icons-material/Event";
import PeopleIcon from "@mui/icons-material/People";
import PersonIcon from "@mui/icons-material/Person";
import PhoneIcon from "@mui/icons-material/Phone";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import { useTheme } from "@mui/material/styles";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";
import { useEffect } from "react";
import {
  TypographyOxygen,
  TypographyOxygenV2,
} from "../../BoilarPlateJs/TypographyStyled";

// Debounce function
const debounce = (func, wait) => {
  let timeout;
  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const TourBookingForm = ({ children, ...props }) => {
  const [selectedDate, setSelectedDate] = React.useState(null);
  const [numberOfPersons, setNumberOfPersons] = React.useState(1);

  const theme = useTheme();
  const { tourBookingFormRef } = props;
  const { bookNowVisibilityState } = props;
  const { data } = props;

  let cleanedAmount = data.discountedTripAmount.replace("$", "");

  if (data.anyOffer) {
    cleanedAmount = data.discountedTripAmount.replace("$", "");
  } else {
    cleanedAmount = data.actualTripAmount.replace("$", "");
  }

  const tourPricePerPerson = cleanedAmount;

  const handleScroll = React.useCallback(() => {
    if (tourBookingFormRef && tourBookingFormRef.current) {
      const {
        top,
        bottom,
      } = tourBookingFormRef.current.getBoundingClientRect();
      const viewportHeight =
        window.innerHeight || document.documentElement.clientHeight;

      // Check if the component's top or bottom is within the viewport
      const isComponentVisible = top < viewportHeight && bottom >= 0;
      bookNowVisibilityState(!isComponentVisible);
    }
  }, [tourBookingFormRef, bookNowVisibilityState]);

  useEffect(() => {
    // Debounce the handleScroll function
    const debouncedHandleScroll = debounce(handleScroll, 250); // Adjust the 250ms delay as needed

    // Step 2: Add scroll event listener
    window.addEventListener("scroll", debouncedHandleScroll, { passive: true });

    // Step 3: Initial check in case the component is out of view on initial load
    handleScroll();

    // Step 3: Initial check in case the component is out of view on initial load
    debouncedHandleScroll();

    // Step 4: Cleanup
    return () => {
      window.removeEventListener("scroll", debouncedHandleScroll);
    };
  }, [handleScroll]);

  return (
    <Box
      bgcolor={theme.palette.constantColors.hawaiianOcean}
      mt={5}
      p={2}
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      ref={tourBookingFormRef}
      id="book-the-tour-form"
    >
      <TypographyOxygen fontSize={theme.tourAndTravel.headingTextSize}>
        Book the Tour
      </TypographyOxygen>
      <TextField
        fullWidth
        label="Name"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <PersonIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Email Address"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <EmailIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <TextField
        label="Phone Number"
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton>
                <PhoneIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          label="Tour Date"
          value={selectedDate}
          onChange={(newValue) => {
            setSelectedDate(newValue);
          }}
          renderInput={(params) => (
            <TextField
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton>
                      <EventIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </LocalizationProvider>
      <TextField
        label="Number of Persons"
        type="number"
        InputProps={{
          inputProps: {
            min: 1,
          },

          endAdornment: (
            <InputAdornment position="end">
              <PeopleIcon /> {/* Assuming PeopleIcon is imported */}
            </InputAdornment>
          ),
        }}
        value={numberOfPersons}
        onChange={(e) => setNumberOfPersons(e.target.value)}
      />
      <TypographyOxygenV2 variant="h6" sx={{ mt: 2 }}>
        Total Amount: ${numberOfPersons * tourPricePerPerson}
      </TypographyOxygenV2>

      <Button
        variant="contained"
        size="large"
        sx={{
          mt: 2,
        }}
      >
        Book Now
      </Button>
    </Box>
  );
};
