import { TabContext, TabPanel } from "@mui/lab";
import TabList from "@mui/lab/TabList";
import { Tab } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import React from "react";

export const TabContainer = ({ children, ...props }) => {
  const theme = useTheme();
  const [value, setValue] = React.useState("0");
  const { tabDetails } = props;

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const { customStyle } = props;

  const defaultStyle = {
    color: "black",
    backgroundColor: theme.palette.primary.main,
    marginRight: "0px",
    tabIndicatorColor: theme.palette.primary.main,
    labelColor: theme.palette.text.primary,
  };

  const mergedStyle = { ...defaultStyle, ...customStyle };

  return (
    <Box>
      <TabContext value={value}>
        <TabList
          onChange={handleChange}
          aria-label="Tour and Travel"
          TabIndicatorProps={{
            style: {
              backgroundColor: mergedStyle.tabIndicatorColor,
            },
          }} // Add this line
        >
          {tabDetails.map((tabDetail, index) => (
            <Tab
              label={tabDetail.label}
              value={index.toString()}
              variant="fullWidth"
              size="small"
              key={index}
              style={{
                color: mergedStyle.labelColor,
                backgroundColor:
                  value === index.toString()
                    ? mergedStyle.selectedBackgroundColor
                    : mergedStyle.backgroundColor,
                marginRight:
                  index !== tabDetails.length - 1
                    ? mergedStyle.marginRight
                    : "0px",

                flexGrow: 1,
                padding: 0,
                width: "100%",
                maxWidth: "100%",
              }}
            />
          ))}
        </TabList>
        <TabPanel
          value={"0"}
          sx={{
            backgroundColor: "#429cb5",
            flexGrow: 1,
          }}
        >
          {children}
        </TabPanel>
      </TabContext>
    </Box>
  );
};
