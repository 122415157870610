import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { Box, styled } from "@mui/system";

const TitleSepareter = styled("div")(({ theme }) => ({
  position: "relative",
  "&:after": {
    content: '""',
    display: "block",
    background: "#47a0ff",
    position: "absolute",
    height: "5px",
    bottom: "-1px",
    left: "50%",
    transform: "translate(-50%, 0)",
    width: "50px",
  },
}));

export const TitleDecorationBottomCenter = ({ children, ...props }) => {
  const theme = useTheme();

  const defaultStyle = {
    borderBottom: "1px solid" + theme.palette.primary.main,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 2,
    marginBottom: 2,
  };

  const { customStyle } = props;
  const mergeStyle = { ...defaultStyle, ...customStyle };
  return (
    <Box
      borderBottom={defaultStyle.borderBottom}
      ml={mergeStyle.marginLeft}
      mr={mergeStyle.marginRight}
      mt={mergeStyle.marginTop}
      mb={mergeStyle.marginBottom}
    >
      <TitleSepareter />{" "}
    </Box>
  );
};
export const PageSeparator = ({ children, ...props }) => {
  const theme = useTheme();

  return (
    <Box display="flex" alignItems="center" justifyContent="center">
      <Box borderBottom={`1px solid ${theme.palette.primary.main}`} flex={1} />
      <IconButton
        color="primary"
        aria-label="upload picture to go top"
        component="span"
        onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
      >
        <ArrowUpwardIcon fontSize="large" />
      </IconButton>
      <Box borderBottom={`1px solid ${theme.palette.primary.main}`} flex={1} />
    </Box>
  );
};

export const StraigtLine = ({ children, ...props }) => {
  const theme = useTheme();

  const defaultStyle = {
    borderBottom: "1px solid" + theme.palette.primary.main,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 2,
    marginBottom: 2,
    pt: "",
    pb: "",
  };

  const { customStyle } = props;
  const mergeStyle = { ...defaultStyle, ...customStyle };
  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="center"
      pt={mergeStyle.pt}
      pb={mergeStyle.pb}
    >
      <Box borderBottom={mergeStyle.borderBottom} flex={1} />
    </Box>
  );
};
