import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Box, Button, CardMedia, Container, Grid } from "@mui/material";
import { jarallax } from "jarallax";
import "jarallax/dist/jarallax.css"; // Import the jarallax CSS
import React, { useEffect, useRef } from "react";
import { ContactUs } from "../../CodingRipplerHome/ContactUs/ContactUs";
import { Testimonial } from "../../CodingRipplerHome/Testimonial/Testimonial";
import { WhyUs } from "../../CodingRipplerHome/WhyUs/WhyUs";
import {
  CommonContainer,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  HeroSubHeader,
  HeroText,
} from "../../CodingRipplerWidgets";

export const ParallaxHome = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const parallaxRef1 = useRef(null);
  const parallaxRef2 = useRef(null);
  const parallaxRef3 = useRef(null);
  const parallaxRef4 = useRef(null);
  const parallaxRef5 = useRef(null);
  const parallaxRef6 = useRef(null);
  const parallaxRef7 = useRef(null);
  const parallaxRef8 = useRef(null);
  const parallaxRef9 = useRef(null);

  useEffect(() => {
    jarallax(parallaxRef1.current, { speed: 0.2 });
    jarallax(parallaxRef2.current, { speed: 0.2 });
    jarallax(parallaxRef3.current, { speed: 0.2 });
    jarallax(parallaxRef4.current, { speed: 0.2 });
    jarallax(parallaxRef5.current, { speed: 0.2 });
    jarallax(parallaxRef6.current, { speed: 0.2 });
    jarallax(parallaxRef7.current, { speed: 0.2 });
    jarallax(parallaxRef8.current, { speed: 0.2 });
    jarallax(parallaxRef9.current, { speed: 0.2 });
  }, []);

  let customStyleAdvertisementHeader = {
    color: "white",
    fontSize: "6rem",
    fontFamily: "'Inter', sans-serif",
    textAlign: "center",
  };
  let customStyleAdvertisementSubHeader = {
    color: "white",
    fontSize: "2rem",
    fontWeight: "semibold",
    fontFamily: "'Inter', sans-serif",
    textAlign: "center",
  };
  let dummyContainerHeight = "0vh";
  let headerText = "We create stunning websites and innovative AI solutions";
  if (isPhone) {
    dummyContainerHeight = "5vh";
    customStyleAdvertisementHeader = {
      color: "white",
      fontSize: "3.5rem",
      fontFamily: "'Inter', sans-serif",
      textAlign: "center",
    };

    customStyleAdvertisementSubHeader = {
      color: "white",
      fontSize: "1.25rem",
      fontWeight: "semibold",
      fontFamily: "'Inter', sans-serif",
      textAlign: "center",
    };
  } else if (isTab) {
    dummyContainerHeight = "5vh";
  }

  const advertisementCompany = [
    {
      path: "/codingrippler/figmaIcons/logos_google.svg",
    },
    {
      path: "/codingrippler/figmaIcons/logos_microsoft.svg",
    },
    {
      path: "/codingrippler/figmaIcons/logos_webflow.svg",
    },

    {
      path: "/codingrippler/figmaIcons/logos_wordpress.svg",
    },
  ];

  const parallaxImages = [
    {
      imagePath: "/codingrippler/parallax/goby.jpg",
      headerText: "GOBY",
      subHeaderText: "Electric toothbrush, designed for you",
      reference: parallaxRef1,
    },
    {
      imagePath: "/codingrippler/parallax/larq-bottles.jpg",
      headerText: "LARQ",
      subHeaderText: "Self-cleaning water bottle",
      reference: parallaxRef2,
    },
    {
      imagePath: "/codingrippler/parallax/larq-bottles.jpg",
      headerText: "LARQ",
      subHeaderText: "Self-cleaning water bottle",
      reference: parallaxRef3,
      isCustom: true,
      pageIdentifier: "WhyUs",
    },
    {
      imagePath: "/codingrippler/parallax/nike-shoes-1.jpg",
      headerText: "Nike",
      subHeaderText: "Just do it",
      reference: parallaxRef4,
    },
    {
      imagePath: "/codingrippler/parallax/track-cycle.jpg",
      headerText: "Trek",
      subHeaderText: "The world's best bikes and cycling gear",
      reference: parallaxRef5,
    },
    {
      imagePath: "/codingrippler/parallax/nike-shoes-2.jpg",
      headerText: "Nike",
      subHeaderText: "Just do it",
      reference: parallaxRef6,
      isCustom: true,
      pageIdentifier: "Testimonial",
    },
    {
      imagePath: "/codingrippler/parallax/curology.jpg",
      headerText: "Curology",
      subHeaderText: "Curology custom skincare",
      reference: parallaxRef7,
    },
    {
      imagePath: "/codingrippler/parallax/nike-shoes-2.jpg",
      headerText: "Nike",
      subHeaderText: "Just do it",
      reference: parallaxRef8,
    },
    {
      imagePath: "/codingrippler/parallax/nike-shoes-2.jpg",
      headerText: "Nike",
      subHeaderText: "Just do it",
      reference: parallaxRef9,
      isCustom: true,
      pageIdentifier: "ContactUs",
    },
  ];

  const leadData = [
    {
      value: 1000,
      suffix: "+",
      prefix: "",
      text: "1,000+ Hours Committed to Building Quality",
    },

    {
      value: 100,
      suffix: "+",
      prefix: "",
      text: "100+ Project Concepts Ready to Launch​",
    },
    {
      value: 3,
      suffix: "",
      prefix: "",
      text: "Serving 3 Unique Branches with Tailored Expertise",
    },
  ];

  const pageDetails = [
    {
      image: "/codingrippler/testimonial/logos_google.svg",
      title: "Google",
      heading: "Exceptional designs.",
      subHeading1:
        "This team truly delivers: outstanding quality, intuitive structure, and a seamless workflow.",
      subHeading2: "Absolutely fantastic and a joy to collaborate with.",
      reviewerName: "John Doe",
      reviewerDesignation: "Satisfied Partner",
    },
  ];

  const handleClick = () => {
    // Scroll to the next page
    window.scrollTo({
      top: window.innerHeight, // Scroll to the height of the viewport
      behavior: "smooth", // Smooth scrolling
    });
  };

  const renderParallaxSection = (
    index,
    ref,
    imagePath,
    headerText,
    subHeaderText
  ) => (
    <Grid item xs={12} sx={{ position: "relative" }} key={index}>
      <Box
        ref={ref}
        sx={{
          backgroundImage: `url(${imagePath})`, // Replace with your SVG image path
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Box
          sx={{
            position: "relative",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            minHeight: 650,
            width: "100%",
            height: "100vh",
          }}
        >
          <Container maxWidth="xl">
            <CommonHeaderTypography
              customStyle={customStyleAdvertisementHeader}
            >
              {headerText}
            </CommonHeaderTypography>

            <Box
              sx={{
                height: "20px",
              }}
            />
            <CommonSubHeaderTypography
              customStyle={customStyleAdvertisementSubHeader}
            >
              {subHeaderText}
            </CommonSubHeaderTypography>
          </Container>
        </Box>
      </Box>
    </Grid>
  );

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sx={{
          borderBottom: "1px solid",
        }}
        className="white-bg"
      >
        <Box
          display="flex"
          flexDirection="column"
          justifyContent={{ xs: "center", lg: "center" }}
          alignItems="center"
          minHeight={{
            xs: 650,
            sm: 600,
          }}
          height={{
            xs: "95vh",
            sm: "90vh",
            md: "99vh",
            lg: "99vh",
          }}
          mt={{
            xs: "64px",
            sm: "80px",
            lg: "0px",
          }}
          gap={4}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box
                display={isPhone || isTab ? "flex" : "none"}
                height={dummyContainerHeight}
              />
              <CommonContainer>
                <HeroText variant={isPhoneorTab ? "h4" : "h3"}>
                  {headerText}
                </HeroText>
              </CommonContainer>{" "}
            </Grid>

            <Grid item xs={12}>
              <CommonContainer>
                <HeroSubHeader>
                  Share your project details with us—requirements, budget, and
                  timeline—and we’ll connect you with up to four companies that
                  align with your vision, all at no cost.
                </HeroSubHeader>
              </CommonContainer>

              <Box m={2} />
            </Grid>

            <Grid item xs={2} lg={3}></Grid>

            <Grid item xs={8} lg={6}>
              <Box
                display="flex"
                gap={4}
                direction="row"
                justifyContent="center"
                alignItems="center"
              >
                {advertisementCompany.map((company, index) => (
                  <Box
                    sx={{
                      width: "100px", // Adjust the size as needed
                      height: "100px", // Adjust the size as needed
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                    key={index}
                  >
                    <CardMedia
                      component="img"
                      image={company.path}
                      alt="Coding Rippler"
                      sx={{
                        objectFit: "contain",
                        height: "100%",
                        width: "100%",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
            <Grid item xs={2} lg={3}></Grid>
          </Grid>

          <Button onClick={handleClick}>
            <KeyboardArrowDownIcon
              fontSize="large"
              className="theme-background-color "
            />
          </Button>
        </Box>
      </Grid>

      {parallaxImages.map((data, index) => {
        if (data.isCustom) {
          if (data.pageIdentifier === "WhyUs") {
            return (
              <WhyUs
                key={index}
                data={data}
                isPhone={isPhone}
                isTab={isTab}
                isPhoneorTab={isPhoneorTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                leadData={leadData}
              />
            );
          } else if (data.pageIdentifier === "Testimonial") {
            return (
              <Testimonial key={index} data={data} pageDetails={pageDetails} />
            );
          } else if (data.pageIdentifier === "ContactUs") {
            return (
              <ContactUs
                key={index}
                data={data}
                leadData={leadData}
                isPhone={isPhone}
                isTab={isTab}
                isPhoneorTab={isPhoneorTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
              />
            );
          } else {
            return null;
          }
        } else {
          return renderParallaxSection(
            index,
            data.reference,
            data.imagePath,
            data.headerText,
            data.subHeaderText
          );
        }
      })}

      <Grid item xs={12} sx={{ position: "relative" }}></Grid>
    </Grid>
  );
};
