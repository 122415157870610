import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import { Box, Button, CardMedia, Grid, Stack, Typography } from "@mui/material";
import React from "react";

export const OurWorkContainerEven = ({ children, ...props }) => {
  const { ourWork, buttonName } = props;

  const handleShortcutClick = (context) => {
    window.location.href = `${context}`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Stack spacing={2}>
            <Typography
              variant="h6"
              fontWeight="bold"
              className="typography-custom-css"
            >
              {ourWork.title}
            </Typography>

            <Typography
              variant="body1"
              className="typography-custom-css sub-header"
            >
              {ourWork.description}
            </Typography>

            <Box>
              <Button
                variant="text"
                size="large"
                endIcon={<ArrowRightAltOutlinedIcon />}
                style={{
                  fontSize: "1rem",
                  padding: "1rem 2rem",
                  borderRadius: "14px",
                  color: "#6056ff",
                }}
                onClick={() => {
                  handleShortcutClick(ourWork.context);
                }}
              >
                {buttonName}
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12}>
          <CardMedia
            component="img"
            image={ourWork.icon}
            alt="Description of the image"
            sx={{
              width: "100%",
              height: "100%",
            }}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export const OurWorkContainerOdd = ({ children, ...props }) => {
  const { ourWork, buttonName } = props;

  const learnMore = (context) => {
    window.location.href = `${context}`;
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Grid container spacing={2} p={2}>
        <Grid item md={12}>
          <CardMedia
            component="img"
            image={ourWork.icon}
            alt="Description of the image"
            width="100%"
            height="100%"
          />
        </Grid>
      </Grid>

      <Grid container spacing={2} p={2}>
        <Grid item md={12}>
          <Stack spacing={2}>
            <Typography
              variant="h6"
              fontWeight="bold"
              className="typography-custom-css"
            >
              {ourWork.title}
            </Typography>

            <Typography
              variant="body1"
              className="typography-custom-css sub-header"
            >
              {ourWork.description}
            </Typography>

            <Box>
              <Button
                variant="text"
                size="large"
                endIcon={<ArrowRightAltOutlinedIcon />}
                style={{
                  fontSize: "1rem",
                  padding: "1rem 2rem",
                  borderRadius: "14px",
                  color: "#6056ff",
                }}
                onClick={() => {
                  learnMore(ourWork.context);
                }}
              >
                {buttonName}
              </Button>
            </Box>
          </Stack>
        </Grid>
      </Grid>
    </Box>
  );
};

export const OurWorkContainerSm = ({ children, ...props }) => {
  const { ourWork, buttonName } = props;

  const handleShortcutClick = (context) => {
    window.location.href = `${context}`;
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Stack spacing={2}>
          <Typography
            variant="h6"
            fontWeight="bold"
            className="typography-custom-css"
          >
            {ourWork.title}
          </Typography>

          <Typography
            variant="body1"
            className="typography-custom-css sub-header"
          >
            {ourWork.description}
          </Typography>

          <Box>
            <Button
              variant="text"
              size="large"
              endIcon={<ArrowRightAltOutlinedIcon />}
              style={{
                fontSize: "1rem",
                padding: "1rem 2rem",
                borderRadius: "14px",
                color: "#6056ff",
              }}
              //      onClick={clickHandler}
              onClick={() => {
                handleShortcutClick(ourWork.context);
              }}
            >
              {buttonName}
            </Button>
          </Box>
        </Stack>
      </Grid>

      <Grid item xs={12}>
        <CardMedia
          component="img"
          image={ourWork.icon}
          alt="Description of the image"
        />
      </Grid>
    </Grid>
  );
};
