import { CardMedia, Grid, Tooltip } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import React from "react";
import { CircularContainer } from "../../BoilarPlateJs/CircularContainer";
import { truncate } from "../../BoilarPlateJs/JsUtils";
import { SwiperSlideContainerV2 } from "../../BoilarPlateJs/SwiperSlide";
import { TitleDecorationBottomCenter } from "../../BoilarPlateJs/TitleDecorationBottomCenter";
import {
  TypographyKaushanScript,
  TypographyOxygen,
} from "../../BoilarPlateJs/TypographyStyled";

const tourTypesData = [
  {
    tour_type: "Beach Holidays",
    description:
      "Explore historical sites, museums, and landmarks to learn about the rich cultural heritage of a region. This could include guided tours of ancient ruins, art galleries, or architectural wonders.",
  },
  {
    tour_type: "Adventure Tours",
    description:
      "For the adrenaline junkies, adventure tours offer activities such as hiking, rock climbing, white-water rafting, zip-lining, or even bungee jumping in scenic locations.",
  },
  {
    tour_type: "Romantic Getaways",
    description:
      "Experience the beauty of nature through tours of national parks, wildlife reserves, or botanical gardens. These tours often include activities like birdwatching, safari drives, or nature walks.",
  },
  {
    tour_type: "Family Vacation Packages",
    description:
      "Indulge your taste buds with food and wine tours that allow you to sample local cuisine, visit markets, and even participate in cooking classes or wine tastings.",
  },
  {
    tour_type: "Nature and Wildlife Safaris",
    description:
      "Relax and unwind on a beach tour, where you can soak up the sun, swim, snorkel, or engage in water sports like surfing or jet skiing.",
  },
  {
    tour_type: "Cruise",
    description:
      "Explore multiple destinations in comfort and luxury on a cruise tour. Whether it's a river cruise, ocean cruise, or expedition cruise, you can enjoy sightseeing, onboard entertainment, and shore excursions.",
  },
];

export const TourTypes = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery(theme.breakpoints.down("md"));
  const isBetweenMiddleAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );
  const isExtraSmallScreen = useMediaQuery("(max-width:380px)");
  const isExtraLargeScreen = useMediaQuery(theme.breakpoints.up("xl"));

  //Title Decoration
  var marginLeft = 10;
  var marginRight = 10;

  // subHeading font size
  var subHeadingFontSize = "3.0rem";

  // No of slides to show
  var slidesToShow = 5;

  //Custom styles for CircularContainer
  var circularContainerHeight = "15vw";
  var circularContainerWidth = "15vw";

  //grid container containing media padding
  var gridPaddingLeft = 10;
  var gridPaddingRight = 10;
  var circularContainerTransform = "translate(2.5%, 0%)";
  var translateGIF = "translate(10%, 0%)";
  var subHeadingTransform = "translate(0%, -50%)";

  if (isSmallScreen) {
    marginLeft = 2;
    marginRight = 2;
    slidesToShow = 1;
    circularContainerHeight = "50vw";
    circularContainerWidth = "50vw";
    gridPaddingLeft = 0;
    gridPaddingRight = 0;
    translateGIF = "translate(10%, 0%)";
    circularContainerTransform = "translate(27.5%,0%)";
    subHeadingTransform = "translate(0%, -50%)";
    if (isExtraSmallScreen) {
      subHeadingFontSize = "1.0rem";
      circularContainerTransform = "translate(25%,0%)";
    }
  } else if (isMediumScreen) {
    marginLeft = 3;
    marginRight = 3;
    slidesToShow = 3;
    slidesToShow = 2;
    circularContainerHeight = "25vw";
    circularContainerWidth = "25vw";
    circularContainerTransform = "translate(18%,0%)";
    subHeadingTransform = "translate(0%, 0%)";
    translateGIF = "translate(10%, 25%)";
    subHeadingFontSize = "1.3rem";
  } else if (isBetweenMiddleAndLargeScreen) {
    marginLeft = 4;
    marginRight = 4;
    slidesToShow = 4;
    slidesToShow = 3;
    circularContainerHeight = "20vw";
    circularContainerWidth = "20vw";
    circularContainerTransform = "translate(10%,0%)";
    subHeadingTransform = "translate(0%, 0%)";
    translateGIF = "translate(10%, 0%)";
    subHeadingFontSize = "1.3rem";
  } else if (isExtraLargeScreen) {
    translateGIF = "translate(10%, 0%)";
    subHeadingFontSize = "1.5rem";
  }

  return (
    <Grid
      container
      id={"tourTypes"}
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          backgroundImage: `url('../TourAndTravel/TourTypesBackground.jpg')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          opacity: 0.5,
          position: "absolute",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        },
      }}
      pt={5}
      pb={5}
    >
      <Grid item xs={12}>
        <Box textAlign={"center"}>
          <TypographyKaushanScript>Find a Tour by</TypographyKaushanScript>
          <TypographyOxygen fontSize={subHeadingFontSize}>
            TOUR TYPES
          </TypographyOxygen>
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TitleDecorationBottomCenter
          marginLeft={marginLeft}
          marginRight={marginRight}
        />
      </Grid>

      <Grid
        item
        xs={12}
        pt={5}
        pb={5}
        pl={gridPaddingLeft}
        pr={gridPaddingRight}
      >
        <SwiperSlideContainerV2 slidesToShow={slidesToShow}>
          {tourTypesData.map((tourType, index) => {
            const truncatedText = truncate({
              str: tourType.tour_type,
              maxLength: 15,
            });
            const tourTypeText = truncatedText
              ? truncatedText
              : tourType.tour_type;

            return (
              <CircularContainer
                customStyle={{
                  width: circularContainerWidth,
                  height: circularContainerHeight,
                  transform: circularContainerTransform,
                }}
                data={tourType}
                key={index}
              >
                <Grid container>
                  <Grid item xs={12}>
                    <Box
                      display={"flex"}
                      flexDirection={"column"}
                      alignItems={"center"}
                      sx={{
                        width: "100%",
                        height: "25vh",
                      }}
                    >
                      <Box
                        display="flex"
                        justifyContent={"center"}
                        alignItems={"center"}
                        //        border="2px solid red"
                        marginTop={{
                          xs: "10px",
                          sm: "30px",
                          md: "70px",
                          lg: "7px",
                        }}
                        sx={{
                          transform: translateGIF,
                        }}
                      >
                        <CardMedia
                          component="img"
                          alt="Image Alt"
                          image="../TourAndTravel/culturalTours.gif"
                        />
                      </Box>
                      <Box
                        //          border="2px solid red"
                        sx={{
                          transform: !isExtraLargeScreen
                            ? subHeadingTransform
                            : "translate(0%, 25%)",
                        }}
                      >
                        {truncatedText ? (
                          <Tooltip title={tourType.tour_type}>
                            <TypographyOxygen
                              fontSize={subHeadingFontSize}
                              padding={0}
                            >
                              {tourTypeText}
                            </TypographyOxygen>
                          </Tooltip>
                        ) : (
                          <TypographyOxygen
                            fontSize={subHeadingFontSize}
                            padding={0}
                          >
                            {tourTypeText}
                          </TypographyOxygen>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </CircularContainer>
            );
          })}
        </SwiperSlideContainerV2>
      </Grid>
    </Grid>
  );
};
