import ArrowBackIos from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIos from "@mui/icons-material/ArrowForwardIos";
import { Box, IconButton } from "@mui/material";
import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
//framer motion
import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SlickMotionEffect } from "./MotionEffects";

// Custom arrow components
const NextArrow = ({ className, style, onClick }) => (
  <IconButton
    //    type="button"
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
    style={{
      ...style,
      position: "absolute",
      top: "50%",
      right: "0",
      transform: "translateX(100%)",
    }}
  >
    <ArrowForwardIos />
  </IconButton>
);

const PrevArrow = ({ className, style, onClick }) => (
  <IconButton
    //    type="button"
    onClick={(e) => {
      e.stopPropagation();
      onClick();
    }}
    style={{
      ...style,
      position: "absolute",
      top: "50%",
      transform: "translateX(-100%)",
    }}
  >
    <ArrowBackIos />
  </IconButton>
);

export const SwiperSlideContainer = ({ children }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isMiddleScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const isBetweenMiddleAndLargeScreen = useMediaQuery(
    theme.breakpoints.between("md", "lg")
  );

  let slidesToShow = 3;
  if (isSmallScreen || isMiddleScreen) {
    slidesToShow = 1;
  } else if (isBetweenMiddleAndLargeScreen) {
    slidesToShow = 2;
  }

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  return (
    <SlickMotionEffect>
      <Box pl={isSmallScreen ? 5 : 15} pr={isSmallScreen ? 5 : 15} pb={10}>
        <Slider {...settings}>{children}</Slider>
      </Box>
    </SlickMotionEffect>
  );
};

export const SwiperSlideContainerV2 = ({ children, ...props }) => {
  const defaultBoxStyle = {
    paddingLeft: 5,
    paddingRight: 5,
    boxWidth: "auto",
    boxHeight: "auto",
  };
  const { customBoxStyle, slidesToShow } = props;
  const mergedStyle = { ...defaultBoxStyle, ...customBoxStyle };

  const childrenCount = React.Children.count(children);
  console.log(`Number of children: ${childrenCount}`);

  // There is knwon bug in this slider if sides to show is more then the childrens and the infinite is true it will replicate the same childrens
  var settings = {
    dots: true,
    infinite: childrenCount > slidesToShow,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    centeredSlides: true,
  };

  return (
    <SlickMotionEffect>
      <Box
        sx={{
          height: mergedStyle.boxHeight,
          width: mergedStyle.boxWidth,
        }}
        pl={mergedStyle.paddingLeft}
        pr={mergedStyle.paddingRight}
      >
        <Slider {...settings}>{children}</Slider>
      </Box>
    </SlickMotionEffect>
  );
};

export const SwiperSlideContainerV3 = ({ children, ...props }) => {
  const { noOfSlidesToShow } = props;

  const numberOfChildren = React.Children.count(children);
  let slidesToShow = Math.min(noOfSlidesToShow, numberOfChildren);

  var settings = {
    dots: true,
    infinite: numberOfChildren > noOfSlidesToShow,
    speed: 500,
    slidesToShow: slidesToShow,
    slidesToScroll: 1,
  };
  return (
    <SlickMotionEffect>
      <Box p={2}>
        <Slider {...settings}>{children}</Slider>
      </Box>
    </SlickMotionEffect>
  );
};
