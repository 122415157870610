import { Grid } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { DashedCardComponent } from "../../../../AIWidgets";

export const SalesTabDetails = ({ children, ...props }) => {
  const theme = useTheme();

  const {
    imageSrc,
    imageAlt,
    title,
    description,
    handleButtonClick,
    buttonText,
  } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <DashedCardComponent
          imageSrc={imageSrc}
          imageAlt={imageAlt}
          title={title}
          description={description}
          handleButtonClick={handleButtonClick}
          buttonText={buttonText}
          theme={theme}
        />
      </Grid>
    </Grid>
  );
};
