import { Routes, Route } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import TipsAndUpdatesOutlinedIcon from "@mui/icons-material/TipsAndUpdatesOutlined";
import DeveloperModeOutlinedIcon from "@mui/icons-material/DeveloperModeOutlined";
import ImportantDevicesOutlinedIcon from "@mui/icons-material/ImportantDevicesOutlined";
import AssessmentOutlinedIcon from "@mui/icons-material/AssessmentOutlined";
import { MasanoryHome } from "./MasanoryHome";

export const Masanory = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;
  const pageContent = [
    {
      headers: {
        title: "Our Work",
        heading: (
          <>
            We are a small agency of talented{" "}
            <span className="theme-specfic-colored-text">
              designers & developers
            </span>{" "}
          </>
        ),
        subHeading:
          "Unlike teams from big agencies, we will treat your project as ours. We will walk you through our smooth and simple process.",

        buttonText: "Contact Us",
        buttonPrefix: (
          <>
            <EmailIcon />
          </>
        ),
      },
      OurWork: [
        {
          title: "Idea",
          description:
            "We meet with your team to know more about your idea, project and goal. After that, our team sits to create an action plan and proposal for your project.",
          icon: (
            <>
              <TipsAndUpdatesOutlinedIcon fontSize="large" />
            </>
          ),
        },
        {
          title: "Design",
          description:
            "We start by designing a mockup or prototype of your website/app, and present it to you. Once with the initial mockup, we start the revision process to perfect it.",
          icon: (
            <>
              <DeveloperModeOutlinedIcon fontSize="large" />
            </>
          ),
        },
        {
          title: "Development",
          description:
            "IWe develop your website using the best practices and standards, so you have a perfectly responsive, lightning fast, and super scalable website.",
          icon: (
            <>
              <AssessmentOutlinedIcon fontSize="large" />
            </>
          ),
        },
        {
          title: "Launch & Maintenance",
          description:
            "When the project is ready, we help you to launch it and push it live. After that, we meet with your team to train them on how to edit, update and scale it.",
          icon: (
            <>
              <ImportantDevicesOutlinedIcon fontSize="large" />
            </>
          ),
        },
      ],
      additionalInfo: [],

      customCss: {
        customBgCss: "white-bg",
      },
    },
  ];

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <MasanoryHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              pageContent={pageContent}
              isPhoneorTab={isPhoneorTab}
            />
          }
        />
      </Routes>
    </>
  );
};
