import { Box, Grid } from "@mui/material";
import { RequestDemo, TryForFree } from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  AICommonContainer,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
} from "../../AIUtil/AIUtils";
import {
  CardWithMediaTitleAndDescription,
  InLineMediaContainer,
  PageHeader,
  PageSubHeader,
  TopicContainer,
} from "../../AIWidgets";
// import { CommonContainer } from "../../AIUtil/AIUtils";
import TrendingUpIcon from "@mui/icons-material/TrendingUp";
import { CardMedia } from "@mui/material";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";

export const AISalesHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  const useCaseData = [
    {
      title: "Understand Your Prospects Instantly",
      description:
        "Leverage AI to instantly gather, summarize, and store crucial information about your prospects, giving you a clear picture with minimal effort.",
      image: "/airippler/features/no-coding-required.png",
      minHeight: "500px",
    },
    {
      title: "Automate & Scale Your Sales Efforts",
      description:
        "Maximize your sales potential by automating processes with your AI teammate. Scale your business without the need to expand your team, making every interaction count.",
      image: "/airippler/features/templatesToGetStarted.png",
      minHeight: "500px",
    },
    {
      title: "Streamline & Scale Sales Processes",
      description:
        "Supercharge your sales processes with AI, ensuring each step is automated and optimized. Boost sales activity while focusing on growth, not increasing headcount.",
      image: "/airippler/features/teachYourProcess.png",
      minHeight: "500px",
    },
  ];

  const keyFeatureData = [
    {
      title: "Seamless Integrations with Your Tools",
      description:
        "Seamlessly connect with HubSpot, Salesforce, Zapier, Gmail, and more—making your tools work together effortlessly.",
      image: "/airippler/features/IntegrateWithTechTrack.png",
    },
    {
      title: "No Training on Your Data",
      description:
        "Your data stays yours—private and secure. It’s never used for model training, ensuring your business stays confidential.",
      image: "/airippler/features/teachYourProcess.png",
    },
    {
      title: "No-Code Builder",
      description:
        "Create and automate workflows without writing a single line of code. Turn your business processes into powerful AI-driven actions.",
      image: "/airippler/features/agent-skill.png",
    },
  ];

  const ListOfOurCustomers = [
    "/airippler/marquee/Webflow.svg",
    "/airippler/marquee/Async-Api.svg",
    "/airippler/marquee/Zapier.svg",
    "/airippler/marquee/Zoho.svg",
    "/airippler/marquee/Google-Gemini.svg",
    "/airippler/marquee/Hubspot.svg",
  ];

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="for Sales">
          <TrendingUpIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                <span className="theme-specfic-colored-text">
                  Boost Sales with AI Automation
                </span>{" "}
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Automate lead generation and personalize outreach to close deals
                faster.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ResponsiveBoxCenteredChildren>
          <TryForFree />
          <Box m={2} />
          <RequestDemo />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      <Grid item xs={12}>
        <AICommonContainer>
          <CardMedia
            component="img"
            image="/airippler/home-page/homePage.png"
            alt="Description of the image"
            width="100%"
            height="100%"
          />
        </AICommonContainer>
      </Grid>

      {/* Advertise page   */}

      <Grid item xs={12}>
        <AICommonContainer>
          <CommonSubHeaderTypography customStyle={{ opacity: 0.8 }}>
            Companies of all sizes trust AI Rippler
          </CommonSubHeaderTypography>

          <Box m={2} />
          <InLineMediaContainer data={ListOfOurCustomers} />
        </AICommonContainer>
      </Grid>

      {/* Example Use Case Page  */}

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TopicContainer topicText="Example use cases">
          <TrendingUpIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                From Research to Close in Record Time
              </CommonHeaderTypography>
            }
          />
        </CommonContainer>

        <Box m={2} />

        <AICommonBoxContainer>
          <CardWithMediaTitleAndDescription data={useCaseData} />
        </AICommonBoxContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TopicContainer topicText="Key Features">
          <TrendingUpIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Easily Build Your AI Sales Rep to Drive Results
              </CommonHeaderTypography>
            }
          />
          <Box m={2} />
        </CommonContainer>

        <AICommonBoxContainer>
          <CardWithMediaTitleAndDescription data={keyFeatureData} />
        </AICommonBoxContainer>
      </Grid>
    </Grid>
  );
};
