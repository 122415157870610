import { TabsContainer } from "./TabsContainer";
import { InboundTabDetails } from "./TabWidgets/InboundTabDetails";

export const InboundTabPanel = (props) => {
  const { value } = props;

  return (
    <TabsContainer value={value} index={0}>
      <InboundTabDetails />
    </TabsContainer>
  );
};
