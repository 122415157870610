import CloseIcon from "@mui/icons-material/Close";
import MenuIcon from "@mui/icons-material/Menu";
import { Card, CardContent, CardMedia, useMediaQuery } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { RouteToAIPortal } from "../../../CodingRippler/CodingRipplerWidgets";
import { DrawerButton } from "./NavigationButtons/DrawerButton";
import { NavigationButtons } from "./NavigationButtons/NavigationButtons";

export const AINavigation = ({ children, ...props }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { isPhone, isTab, deviceWithSmallHeight, logoPath } = props;

  //This side effect is added to close the drawer when the screen size changes
  useEffect(() => {
    setDrawerOpen(false);
  }, [isLargeScreen]);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <Card
        sx={{
          boxShadow: "0px 0px 6px 3px rgba(0, 0, 0, 0.2)", // Custom shadow
          marginLeft: {
            xs: "20px",
            sm: "30px",
            md: "30px",
            lg: "1.5%",
            xl: "10%",
          },
          marginRight: {
            xs: "20px",
            sm: "30px",
            md: "30px",
            lg: "1.5%",
            xl: "10%",
          },
          marginTop: {
            xs: "20px",
            sm: "30px",
            md: "30px",
            lg: "2.5%",
            xl: "2.5%",
          },

          borderRadius: "24px",
          backgroundColor: "#fff",
          display: "flex", // Enables flexbox
          flexDirection: "row", // Arranges children horizontally
          alignItems: "center", // Vertically centers the items (optional)
          justifyContent: "space-between", // Centers the items horizontally

          position: "fixed",
          height: { xs: "60px", sm: "80px", lg: "auto" }, // Height for xs and sm
          width: {
            xs: "calc(100% - 40px)", // 100% width minus left and right margins for xs
            sm: "calc(100% - 60px)", // 100% width minus left and right margins for sm
            md: "calc(100% - 60px)", // 100% width minus left and right margins for md
            lg: "calc(100% - 3%)", // 100% width minus left and right margins for lg
            xl: "calc(100% - 20%)", // 100% width minus left and right margins for xl
          },
          overflow: "visible",
          zIndex: 10000,
        }}
      >
        <CardContent>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-start",
              width: { xs: "10rem", lg: "15rem" },
              overflow: "hidden",
            }}
          >
            <CardMedia
              component="img"
              image={logoPath}
              alt="CodingRippler"
              onClick={() => RouteToAIPortal()}
              sx={{
                objectFit: "contain",
                maxHeight: "100%",
                maxWidth: "100%",
                cursor: "pointer", // Add this line to change the cursor on hover
              }}
            />
          </Box>
        </CardContent>

        {isLargeScreen && <NavigationButtons />}

        {!isLargeScreen && (
          <>
            <IconButton
              color="inherit"
              aria-label="menu"
              onClick={handleDrawerToggle}
              className="menuBox"
            >
              {drawerOpen ? <CloseIcon /> : <MenuIcon />}
            </IconButton>
          </>
        )}
      </Card>
      {!isLargeScreen && drawerOpen && (
        <DrawerButton
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          handleDrawerToggle={handleDrawerToggle}
          isPhone={isPhone}
          isTab={isTab}
          deviceWithSmallHeight={deviceWithSmallHeight}
          logoPath={""}
        />
      )}
    </>
  );
};
