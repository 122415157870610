import AttachMoneyOutlinedIcon from "@mui/icons-material/AttachMoneyOutlined";
import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import EventOutlinedIcon from "@mui/icons-material/EventOutlined";
import ForwardToInboxOutlinedIcon from "@mui/icons-material/ForwardToInboxOutlined";
import LeaderboardOutlinedIcon from "@mui/icons-material/LeaderboardOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import MarkEmailReadOutlinedIcon from "@mui/icons-material/MarkEmailReadOutlined";
import MoodBadOutlinedIcon from "@mui/icons-material/MoodBadOutlined";
import MoveToInboxIcon from "@mui/icons-material/MoveToInbox";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import ScheduleSendOutlinedIcon from "@mui/icons-material/ScheduleSendOutlined";
import SentimentDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentDissatisfiedOutlined";
import SentimentVeryDissatisfiedOutlinedIcon from "@mui/icons-material/SentimentVeryDissatisfiedOutlined";
import TravelExploreOutlinedIcon from "@mui/icons-material/TravelExploreOutlined";
import { Box, Container, Grid } from "@mui/material";
import { CommonContainer } from "../../../../CodingRippler/CodingRipplerWidgets";
import { Demo } from "../../../AIHome/HomePageWidgets/Buttons";
import {
  AICommonContainer,
  AnimatedBox,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
  YouTubeBox,
} from "../../../AIUtil/AIUtils";
import {
  InLineMediaContainer,
  PageHeader,
  PageSubHeader,
  TopicContainer,
} from "../../../AIWidgets";
import {
  AccelerateSales,
  AdvertiseLeadGenerationTool,
  AIAgentsFeatures,
  GettingStartedContainer,
  HowInboundWorks,
  WhyAI,
} from "../../AIAgentsWidgets";

export const InboundHome = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const ListOfOurCustomers = [
    "/airippler/marquee/Webflow.svg",
    "/airippler/marquee/Async-Api.svg",
    "/airippler/marquee/Zapier.svg",
    "/airippler/marquee/Zoho.svg",
    "/airippler/marquee/Google-Gemini.svg",
    "/airippler/marquee/Hubspot.svg",
  ];

  const negativeData = [
    {
      title: "Limited capacity to manage all inbound leads.",
      icon: (
        <SentimentDissatisfiedOutlinedIcon
          fontSize="large"
          sx={{ color: "#FF914D" }}
        />
      ),
    },
    {
      title: "Lower conversions due to lack of personalization.",
      icon: <MoodBadOutlinedIcon fontSize="large" sx={{ color: "#FF914D" }} />,
    },
    {
      title: "Slow, manual follow-ups impacting responsiveness.",
      icon: (
        <SentimentVeryDissatisfiedOutlinedIcon
          fontSize="large"
          sx={{ color: "#FF914D" }}
        />
      ),
    },
  ];

  const positiveData = [
    {
      title: "100% coverage of all leads and prospects.",
      icon: <DoneOutlinedIcon fontSize="large" sx={{ color: "#00BB38" }} />,
    },
    {
      title: "Personalized outreach and tailored responses.",
      icon: <DoneOutlinedIcon fontSize="large" sx={{ color: "#00BB38" }} />,
    },
    {
      title: "Automated, scalable follow-ups on autopilot.",
      icon: <DoneOutlinedIcon fontSize="large" sx={{ color: "#00BB38" }} />,
    },
  ];

  const steps = [
    {
      title: "Inbound Lead Capture",
      description: "Prospect reaches out by booking a demo or signing up.",
      icon: <PersonOutlineOutlinedIcon fontSize="small" />,
    },

    {
      title: "Research & Enrichment",
      description:
        "Catalyst gathers detailed insights from internal (CRM) and external sources (Google, LinkedIn).",
      icon: <TravelExploreOutlinedIcon fontSize="small" />,
    },

    {
      title: "Lead Scoring",
      description: "Assesses profile and engagement fit for prioritization.",
      icon: <LeaderboardOutlinedIcon />,
    },

    {
      title: "Crafting Value Proposition",
      description:
        "Develops a tailored value proposition, highlighting how your business supports the prospect’s needs.",
      icon: <AttachMoneyOutlinedIcon />,
    },

    {
      title: "Personalized Outreach",
      description:
        "Delivers hyper-personalized messages using research, intent data, and relevant value propositions.",
      icon: <ForwardToInboxOutlinedIcon />,
    },

    {
      title: "Inbox Management",
      description:
        "Facilitates rapid responses to prospect inquiries, handling objections and questions efficiently.",
      icon: <MarkEmailReadOutlinedIcon />,
    },

    {
      title: "Follow-up & CRM Updates",
      description:
        "Maintains follow-up cadences and keeps CRM records up-to-date.",
      icon: <ScheduleSendOutlinedIcon />,
    },

    {
      title: "Meeting Scheduling",
      description:
        "Coordinates availability, sends calendar invites, and ensures meeting bookings are confirmed.",
      icon: <EventOutlinedIcon />,
    },
  ];

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="Introducing Catalyst">
          <MoveToInboxIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                Meet{" "}
                <span className="theme-specfic-colored-text">Catalyst</span>,
                Your Sales Agent
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                The AI that drives your sales, 24/7.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveBoxCenteredChildren>
          <Demo />
          <Box m={2} />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
      </Grid>

      <Grid item xs={12}>
        <Container sx={{ mt: 2, mb: 2 }}>
          <AnimatedBox>
            <YouTubeBox />
          </AnimatedBox>
        </Container>
      </Grid>

      {/* Advertise page   */}

      <Grid item xs={12}>
        <AICommonContainer>
          <CommonSubHeaderTypography customStyle={{ opacity: 0.8 }}>
            Companies of all sizes trust AI Rippler
          </CommonSubHeaderTypography>

          <Box m={2} />
          <InLineMediaContainer data={ListOfOurCustomers} />
          <Box m={2} />
        </AICommonContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <AdvertiseLeadGenerationTool
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            isPhoneOrTab={isPhoneOrTab}
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <WhyAI negativeData={negativeData} positiveData={positiveData} />
      </Grid>

      {/* This section is commented currently as it is not needed  */}

      {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TopicContainer topicText="Customers">
          <PeopleAltOutlinedIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <AICommonBoxContainer
          customStyle={{
            //     border: "1px solid #868a97",
            boxShadow: "0px 0px 6px 3px rgba(0, 0, 0, 0.2)", // Custom shadow
            borderRadius: "24px",
          }}
        >
          <Container>
            <BlogContainer />
          </Container>
        </AICommonBoxContainer>
      </Grid> */}

      <Grid item xs={12}>
        <TopicContainer topicText="Features">
          <DataObjectOutlinedIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Onboard{" "}
                <span className="theme-specfic-colored-text">Catalyst</span>
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Designed in collaboration with industry experts to elevate your
                sales game.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={1}
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          />

          <Grid item xs={12} sm={10}>
            <CommonContainer>
              <AIAgentsFeatures />
            </CommonContainer>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TopicContainer topicText="How it works">
          <DescriptionIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Convert Prospects into Loyal Customers
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Build Trust, Drive Engagement, and Grow Your Customer Base
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            "&::before": {
              content: '""',
              position: "absolute",
              top: { xs: "0%", lg: "0%" },
              left: { xs: "20px", lg: "0%" },
              transform: {
                xs: "translate(0%, 0%)",
                lg: "translate(0%, 0%)",
                xl: "translate(0%, 10%)",
              },
              width: { lg: "100%" },
              height: { lg: "100%" },
              backgroundImage: {
                //      lg: "url(/airippler/ai-agents/inbound/curv-line.svg)",
              },
              backgroundSize: { xs: "cover", lg: "cover" },
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              opacity: 0.3,
              zIndex: 1,
            },
          }}
        >
          <HowInboundWorks steps={steps} />
        </Box>
      </Grid>

      <Grid item xs={12}>
        <TopicContainer topicText="Accelerate sales">
          <DescriptionIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Accelerate Your Sales Process
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                An end-to-end platform designed to drive faster conversions and
                free reps to close.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <AccelerateSales />
      </Grid>

      <Grid item xs={12}>
        <TopicContainer topicText="Getting Started">
          <LightModeOutlinedIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Get started in just{" "}
                <span className="theme-specfic-colored-text">one week</span>
              </CommonHeaderTypography>
            }
          />
          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                We have a simple, pain-free and proven implementation process
                tailored to your needs.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <GettingStartedContainer
          isPhone={isPhone}
          isTab={isTab}
          deviceWithSmallHeight={deviceWithSmallHeight}
          isPhoneOrTab={isPhoneOrTab}
        />
      </Grid>
    </Grid>
  );
};
