import { Routes, Route } from "react-router-dom";
import ECommerceDashboard from "./ECommerceDashboard";

export const Dashboards = ({ children, ...props }) => {
  const { isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <>
      <Routes>
        <Route
          path="/*"
          element={
            <ECommerceDashboard
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            />
          }
        />
      </Routes>
    </>
  );
};
