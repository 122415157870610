import { Card } from "@mui/material";
import React, { useEffect } from "react";
import { WRNavigationButtonsDrawer } from "./WRNavigationButtonsDrawer";

export const WRDrawerButton = ({ children, ...props }) => {
  const { setDrawerOpen } = props;

  useEffect(() => {
    const handleScroll = () => {
      setDrawerOpen(false);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setDrawerOpen]);

  return (
    <Card
      sx={{
        borderRadius: "24px",
        backgroundColor: "#fff",
        display: "flex", // Enables flexbox
        flexDirection: "column", // Arranges children horizontally
        alignItems: "center", // Vertically centers the items (optional)
        position: "fixed",
        zIndex: 100,
        width: "calc(100% - 40px)", // Adjust width to account for margins
        left: "20px", // Equal left spacing
        right: "20px", // Equal right spacing
        top: { xs: "80px", sm: "96px" }, // Equal top spacing
        overflow: "visible",
        maxHeight: "calc(100vh - 120px)", // Maximum height to fit within the viewport
        overflowY: "auto",
      }}
    >
      <WRNavigationButtonsDrawer />
    </Card>
  );
};
