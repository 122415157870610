import { KeyboardArrowUp } from "@mui/icons-material";
import HomeIcon from "@mui/icons-material/Home";
import HubIcon from "@mui/icons-material/Hub";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import LanguageIcon from "@mui/icons-material/Language";
import { Box, Button, Card, CardContent, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";

export const CompanyButton = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();

  const { customTextColor } = props;

  const handleClick = (context) => {
    window.location.href = context;
  };

  const shortcuts = [
    {
      name: "Back to Coding Rippler",
      context: `/`,
      icon: <HomeIcon />,
    },
    {
      name: "Web Rippler",
      context: `/web`,
      icon: <LanguageIcon />,
    },
    {
      name: "AI Rippler",
      context: `/ai`,
      icon: <HubIcon />,
    },
  ];

  return (
    <Box sx={{}}>
      <Button
        variant="text"
        style={{
          color: customTextColor ? "white" : theme.palette.text.primary,
        }}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
        onClick={() => setIsHovered(!isHovered)}
      >
        <Box display="flex" alignItems="center">
          <Typography
            variant="h6"
            fontFamily="Inter, sans-serif" // Add your desired font family here
            fontSize="1rem"
          >
            Company{" "}
          </Typography>{" "}
          {!isHovered && (
            <KeyboardArrowDownIcon onClick={() => setIsHovered(!isHovered)} />
          )}
          {isHovered && (
            <KeyboardArrowUp onClick={() => setIsHovered(!isHovered)} />
          )}
        </Box>
      </Button>

      {isHovered && (
        <Card
          sx={{
            width: "25%",
            position: "absolute",
            zIndex: 3,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} // Hide the card when the mouse leaves it
        >
          {shortcuts.map((shortcut, index) => (
            <CardContent
              className="customHoverEffect"
              onClick={() => {
                handleClick(shortcut.context);
              }}
              key={index}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                {shortcut.icon}
                <Box sx={{ ml: "10px" }}>
                  <Typography
                    variant="body2"
                    fontFamily="Inter, sans-serif" // Add your desired font family here
                  >
                    {shortcut.name}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          ))}
        </Card>
      )}
    </Box>
  );
};
