import { Box } from "@mui/material";
import React from "react";
import { CompanyButton } from "../../../../CodingRippler/Navigations/CompanyButton";
import { HomeButton } from "../../../../CodingRippler/Navigations/HomeButton";
import { AgentsButton } from "./AgentsButton";
import { FunctionButton } from "./FunctionButton";
import { LoginButton } from "./LoginButton";
import { PricingButton } from "./PricingButton";
import { ProductButton } from "./ProductButton";
import { SignUpButton } from "./SignUpButton";

export const NavigationButtons = ({ children, ...props }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 2,
        }}
      >
        <HomeButton context="/ai" />
        <ProductButton />
        <FunctionButton />
        <AgentsButton />
        <CompanyButton />
        {/* <CardContent sx={{ maxWidth: "100%" }}>
        <ResourcesButton />
      </CardContent> */}
        <PricingButton />
      </Box>

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          gap: 4,
          m: 2,
        }}
      >
        <LoginButton />
        <SignUpButton />
      </Box>
    </>
  );
};
