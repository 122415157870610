import React from "react";
import { CardContent } from "@mui/material";
import { HomeButton } from "../HomeButton";
import { Portfolio } from "../Portfolio";
import { ContactUs } from "../ContactUs";
import { CompanyButton } from "../CompanyButton";

export const WebRipplerButtons = ({ children, ...props }) => {
  const { customTextColor } = props;

  return (
    <>
      <CardContent sx={{ flexGrow: 1, maxWidth: "100%" }}>
        <HomeButton customTextColor={customTextColor} context="/web" />
      </CardContent>

      <CardContent sx={{ flexGrow: 1, maxWidth: "100%" }}>
        <Portfolio customTextColor={customTextColor} />
      </CardContent>

      <CardContent sx={{ flexGrow: 1, maxWidth: "100%" }}>
        <CompanyButton customTextColor={customTextColor} />
      </CardContent>
      <CardContent sx={{ flexGrow: 1, maxWidth: "100%", position: "relative" }}>
        <ContactUs customTextColor={customTextColor} />
      </CardContent>
    </>
  );
};
