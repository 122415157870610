import React from "react";
import { Grid } from "@mui/material";

import { Box } from "@mui/material";
import {
  TypographyKaushanScript,
  TypographyOxygen,
} from "../BoilarPlateJs/TypographyStyled";

export const SubscribeNewsLetter = ({ children, ...props }) => {
  var subHeadingFontSize = "1.0rem";

  return (
    <Grid
      id="subscribeNewsLetter"
      container
      sx={{
        position: "relative",
        "&::before": {
          content: '""',
          backgroundImage: `url('../TourAndTravel/subscribeNewsLetter2.jpg')`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          opacity: 0.2,
          position: "absolute",
          backgroundColor: "#429cb5",
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
        },
      }}
    >
      <Grid item xs={12}>
        <Box pt={10} pb={10}>
          <TypographyOxygen variant="h4" align="center">
            {" "}
            Join the Newsletter{" "}
          </TypographyOxygen>

          <TypographyKaushanScript variant="h6" align="center">
            To receive our best monthly deals{" "}
          </TypographyKaushanScript>

          <TypographyOxygen
            variant="body1"
            fontSize={subHeadingFontSize}
            align="center"
            lineHeight={2}
            fontWeight={"normal"}
          >
            Whoops, you're not connected to CodingRippler. You need to enter a
            valid Mailchimp API key.{" "}
          </TypographyOxygen>
        </Box>
      </Grid>
    </Grid>
  );
};
