import ArrowRightAltOutlinedIcon from "@mui/icons-material/ArrowRightAltOutlined";
import { CardMedia, Grid } from "@mui/material";
import Button from "@mui/material/Button";
import { Box } from "@mui/system";
import React from "react";
import { HeroSubHeader } from "../../CodingRipplerWidgets";

export const OurBlog = ({ children, ...props }) => {
  const { customStyle, deviceWithSmallHeight, pageContent } = props;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} />
      <Grid item xs={12} />

      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            lg: "block",
          },
        }}
      ></Grid>

      <Grid item xs={12} lg={10}>
        <Grid container spacing={4}>
          <Grid item xs={12} lg={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                minHeight: {
                  xs: 300,
                },
                height: {
                  xs: deviceWithSmallHeight ? "30vh" : "25vh",
                  sm: "30vh",
                  lg: "50vh",
                },
                width: "100%",
              }}
            >
              <CardMedia
                component="img"
                image={pageContent.image}
                sx={{
                  objectFit: "contain",
                  height: "100%", // Ensure CardMedia takes the full height of the parent Box
                  width: "100%", // Ensure CardMedia takes the full width of the parent Box
                }}
              />
            </Box>
          </Grid>

          <Grid item xs={12} lg={8} sx={{}}>
            <Box
              sx={{
                position: "relative",
                minHeight: {
                  xs: 350,
                },
                height: {
                  xs: "50vh",
                  sm: "30vh",
                  lg: "50vh",
                },
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "flex-start",
              }}
            >
              <HeroSubHeader customStyle={customStyle.customBlogHeader}>
                {pageContent.text}
              </HeroSubHeader>

              <Box m={2} />

              <Button
                variant="text"
                size="large"
                endIcon={<ArrowRightAltOutlinedIcon />}
                style={{
                  fontSize: "1rem",
                  padding: "1rem 2rem",
                  borderRadius: "14px",
                  color: "#6056ff",
                }}
                //      onClick={clickHandler}
              >
                Read More
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={1}
        sx={{
          display: {
            xs: "none",
            sm: "none",
            lg: "block",
          },
        }}
      ></Grid>
      <Grid item xs={12} />
      <Grid item xs={12} />
    </Grid>
  );
};
