import { Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { useMediaQuery } from "@mui/material";

export const TypographyStyled = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  overflowWrap: "break-word",
  padding: theme.spacing(2), // Add padding here

  "&:hover": {
    cursor: "pointer",
    transform: "scale(1.05)",
    transition: "all 0.3s ease",
    boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
  },
}));

export const TypographyTourAndTravelHeading = styled(Typography)(
  ({ theme }) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
    const isMiddleScreen = useMediaQuery(theme.breakpoints.between("sm", "md"));
    const isBetweenMiddleAndLargeScreen = useMediaQuery(
      theme.breakpoints.between("md", "lg")
    );
    const isExtraSmallScreen = useMediaQuery("(max-width:349px)");

    let fontSize = "10rem";

    if (isSmallScreen) {
      fontSize = "3.3rem";
      if (isExtraSmallScreen) {
        fontSize = "3rem";
      }
    } else if (isMiddleScreen) {
      fontSize = "6rem";
    } else if (isBetweenMiddleAndLargeScreen) {
      fontSize = "8rem";
    }

    return {
      color: theme.palette.constantColors.white,
      fontStyle: "italic", // Make the font italic
      fontFamily: "Pacifico, sans-serif", // Use Pacifico font here
      animation: "fadeIn 2s", // Add this line
      fontSize: fontSize,
      width: "auto",
      padding: theme.spacing(2), // Add padding here
      marginTop: "0", // Add top margin if the screen height is less than 700px
      "&:hover": {
        cursor: "pointer",
        transition: "all 0.3s ease",
        transform: "scale(1.02)",
        opacity: 0.7,
      },
      zIndex: 1,
    };
  }
);

export const TypographyTourAndTravelParagraph = styled(Typography)(
  ({ theme }) => {
    const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

    return {
      color: theme.palette.constantColors.white,
      fontSize: isSmallScreen ? "1.5rem" : "3rem",
      animation: "fadeIn 6s", // Add this line
      padding: theme.spacing(2), // Add padding here

      "&:hover": {
        cursor: "pointer",
        transition: "all 0.3s ease",
        transform: "scale(1.02)",
        opacity: 0.7,
      },
      zIndex: 1,
    };
  }
);

export const TypographyKaushanScript = styled(Typography)(
  ({
    theme,
    color = theme.palette.text.primary,
    fontFamily = "Kaushan Script",
    fontSize = "1.5rem",
    padding = theme.spacing(2),
  }) => {
    return {
      color: color,
      fontFamily: fontFamily, // Use the 'Regular Brush' font
      fontSize: fontSize,
      padding: padding, // Add padding here
      "&:hover": {
        cursor: "pointer",
        transition: "all 0.3s ease",
        transform: "scale(1.02)",
        opacity: 0.7,
      },
    };
  }
);

export const TypographyOxygen = styled(Typography)(
  ({
    theme,
    fontSize = "2.5rem",
    fontWeight = 700,
    fontStyle = "normal",
    fontFamily = '"Oxygen", sans-serif',
    textDecoration = "none",
    variant = "body1",
    padding = theme.spacing(1),
    ...props
  }) => {
    return {
      color: theme.palette.text.primary,
      fontFamily: fontFamily,
      fontWeight: fontWeight,
      fontStyle: fontStyle,
      fontSize: fontSize,
      padding: padding,
      textDecoration: textDecoration,
      variant: variant,
      props,
      "&:hover": {
        transition: "all 0.3s ease",
        transform: "scale(1.02)",
        opacity: 0.7,
      },
    };
  }
);

export const TypographyOxygenV2 = styled(Typography)(
  ({ theme, fontFamily = '"Oxygen", sans-serif' }) => {
    return {
      color: theme.palette.text.primary,
      fontFamily: fontFamily,
      "&:hover": {
        transition: "all 0.3s ease",
        transform: "scale(1.002)",
        opacity: 0.7,
      },
    };
  }
);
