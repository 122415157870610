import { Card } from "@mui/material";
import React, { useEffect } from "react";
import { NavigationButtonsDrawer } from "./NavigationButtonsDrawer";

export const DrawerButton = ({ children, ...props }) => {
  const { setDrawerOpen } = props;

  const { isPhone, isTab, deviceWithSmallHeight } = props;

  useEffect(() => {
    const handleScroll = () => {
      setDrawerOpen(false);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [setDrawerOpen]);

  return (
    <Card
      sx={{
        borderRadius: "24px",
        backgroundColor: "#fff",
        display: "flex", // Enables flexbox
        flexDirection: "column", // Arranges children horizontally
        alignItems: "center", // Vertically centers the items (optional)

        position: "fixed",
        zIndex: 10000,
        width: "calc(100% - 40px)", // Adjust width to account for margins
        maxHeight: "calc(100vh - 120px)", // Maximum height to fit within the viewport
        left: "20px", // Equal left spacing
        right: "20px", // Equal right spacing
        top: { xs: "100px", sm: "120px" }, // Equal top spacing
        overflow: "visible",
        overflowY: "auto",
      }}
    >
      <NavigationButtonsDrawer
        isPhone={isPhone}
        isTab={isTab}
        deviceWithSmallHeight={deviceWithSmallHeight}
        logoPath={""}
      />
    </Card>
  );
};
