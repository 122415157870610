import React from "react";
import { Grid } from "@mui/material";
import Rating from "@mui/material/Rating";
import { TypographyOxygenV2 } from "../../BoilarPlateJs/TypographyStyled";
import { useTheme } from "@mui/material/styles";
export const ReviewSummary = ({ children, ...props }) => {
  const theme = useTheme();

  const { individualTourInfo } = props;

  return (
    <Grid container>
      <Grid
        item
        xs={12}
        sm={4}
        sx={{
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.up("sm")]: {
            justifyContent: "flex-start",
          },
        }}
      >
        <Rating
          name="simple-controlled"
          value={individualTourInfo.tripRating}
          size="large"
          readOnly
        />
      </Grid>

      <Grid
        item
        xs={12}
        sm={8}
        sx={{
          display: "flex",
          justifyContent: "center",
          [theme.breakpoints.up("sm")]: {
            justifyContent: "flex-start",
          },
        }}
      >
        <TypographyOxygenV2 fontSize={"1.2rem"}>
          {individualTourInfo.tripRating} Based on{" "}
          {individualTourInfo.totalReviewsSubmitted} review{" "}
        </TypographyOxygenV2>
      </Grid>
    </Grid>
  );
};
