import { Grid } from "@mui/material";
import { TabsContainer } from "./TabsContainer";
import { MarketingTabDetails } from "./TabWidgets/MarketingTabDetails";

export const MarketingTabPanel = (props) => {
  const { value } = props;

  const handleUse = (id) => {
    window.location.href = `/ai`;
  };

  return (
    <TabsContainer value={value} index={3}>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <MarketingTabDetails
            imageSrc="/airippler/sales-tab-icons/linkedIn.svg"
            imageAlt="Description of the image"
            title="Improve LinkedIn Posts"
            description={`Elevate your LinkedIn strategy with our Improve LinkedIn Posts tool. This AI-powered tool helps you craft highly engaging LinkedIn posts tailored to resonate with your target audience. By analyzing your company’s website and reverse-engineering your ideal audience, it generates three variations of your draft post, boosting engagement and connection. Perfect for marketers aiming to maximize their social media impact with minimal effort.`}
            handleButtonClick={handleUse}
            buttonText={"Use"}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <MarketingTabDetails
            imageSrc="/airippler/ai-tools/featured/youtube-icon.png"
            imageAlt="Description of the image"
            title="YouTube Video -> Blog"
            description={`Repurpose your video content seamlessly with the YouTube Video -> Blog tool. Convert YouTube videos into SEO-optimized blog posts in just a few steps. Whether you’re a content creator or a marketer, this tool generates comprehensive blog posts in HTML format, including article outlines, engaging content, and FAQs, all based on the YouTube video link you provide. Ideal for expanding your reach and improving SEO with minimal effort.`}
            handleButtonClick={handleUse}
            buttonText={"Use"}
          />
        </Grid>

        <Grid item xs={12} lg={4}>
          <MarketingTabDetails
            imageSrc="/airippler/ai-tools/marketing/wordpress.png"
            imageAlt="Description of the image"
            title="WordPress Toolkit"
            description={`Simplify your blogging process with the WordPress Toolkit. Designed for content marketers and businesses, this tool streamlines managing your WordPress blogs. You can access and publish blogs from the Webflow CMS interface, view existing posts, and create new ones effortlessly. Keep your online presence consistent and up-to-date without the manual hassle.`}
            handleButtonClick={handleUse}
            buttonText={"Use"}
          />
        </Grid>
      </Grid>{" "}
    </TabsContainer>
  );
};
