import { Box, Grid } from "@mui/material";
import { CardViewContainerWithImage } from "../../BoilarPlateJs/CardViewContainer";
import {
  TypographyKaushanScript,
  TypographyOxygen,
} from "../../BoilarPlateJs/TypographyStyled";

import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SwiperSlideContainer } from "../../BoilarPlateJs/SwiperSlide";
import { TitleDecorationBottomCenter } from "../../BoilarPlateJs/TitleDecorationBottomCenter";

export default function PopularTours({ popularToursData }) {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const isExtraSmallScreen = useMediaQuery("(max-width:380px)");

  // Heading size
  var subHeadingFontSize = "3.0rem";

  //Title Decoration
  var marginLeft = 10;
  var marginRight = 10;

  if (isSmallScreen) {
    marginLeft = 2;
    marginRight = 2;
    if (isExtraSmallScreen) {
      subHeadingFontSize = "2.0rem";
    }
  }
  return (
    <Grid
      container
      id="popularTours"
      pt={5}
      style={{
        backgroundImage: `url(${"/TourAndTravel/popularTour.png"})`,
        backgroundColor: "#429cb5",
        maxHeight: "100vh",
      }}
    >
      <Grid item xs={12}>
        <Box textAlign={"center"}>
          <TypographyKaushanScript>Take a look at our</TypographyKaushanScript>
          <TypographyOxygen fontSize={subHeadingFontSize}>
            MOST POPULAR TOURS
          </TypographyOxygen>

          <TitleDecorationBottomCenter
            marginLeft={marginLeft}
            marginRight={marginRight}
          />
        </Box>
        <SwiperSlideContainer>
          {popularToursData.map((tour, index) => (
            <CardViewContainerWithImage key={index} tourData={tour} />
          ))}
        </SwiperSlideContainer>
      </Grid>
    </Grid>
  );
}
