import { Box, Grid } from "@mui/material";
import { jarallax } from "jarallax";
import React, { useEffect, useRef } from "react";
import { HireUs } from "../../CodingRipplerHome/ContactUs/HireUs";
import {
  AdvertiseBrands,
  CommonContainer,
  HeroSubHeader,
  HeroText,
  MasonryContainer,
} from "../../CodingRipplerWidgets";

export const MasanoryHome = ({ children, ...props }) => {
  const { isPhoneorTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const parallaxRef1 = useRef(null);

  useEffect(() => {
    jarallax(parallaxRef1.current, { speed: 0.2 });
  }, []);

  const brandList = [
    {
      imageList1: [
        {
          image: "/codingrippler/brandIconsblack/logos_slack.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/icons_amazon.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/logos_google.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/logos_microsoft.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/icons_zara.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/logos_netflix.svg",
        },
      ],
    },
    {
      imageList2: [
        {
          image: "/codingrippler/brandIconsblack/logos_youtube.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/logos_airbnb.svg",
        },
        {
          image: "/codingrippler/brandIconsblack/logos_hubspot.svg",
        },
      ],
    },
  ];

  const masnoryPageContent = [
    {
      image: "/codingrippler/masonry/img1.jpeg",
      height: isPhoneorTab ? "45vh" : "70vh",
      heading: "At vero eos",
      subHeading: "Sed ut perspiciatis unde.",
    },
    {
      image: "/codingrippler/masonry/img2.jpeg",
      height: isPhoneorTab ? "35vh" : "60vh",
      heading: "Sed ut",
      subHeading: "Iste natus error sit voluptatem.",
    },
    {
      image: "/codingrippler/masonry/img3.jpeg",
      height: isPhoneorTab ? "35vh" : "50vh",
      heading: "Neque porro",
      subHeading: "Qui dolorem ipsum quia consectetur.",
    },
    {
      image: "/codingrippler/masonry/img4.jpeg",
      height: isPhoneorTab ? "30vh" : "40vh",
      heading: "Temporibus autem",
      subHeading: "Et harum quidem rerum facilis.",
    },
    {
      image: "/codingrippler/masonry/img5.jpeg",
      height: isPhoneorTab ? "35vh" : "50vh",
      heading: "Quis autem vel",
      subHeading: "Qui in ea quam nihil molestiae.",
    },
    {
      image: "/codingrippler/masonry/img6.jpeg",
      height: isPhoneorTab ? "40vh" : "60vh",
      heading: "Nam libero",
      subHeading: "Nobis est eligendi optio.",
    },
    {
      image: "/codingrippler/masonry/img7.jpeg",
      height: isPhoneorTab ? "30vh" : "60vh",
      heading: "At vero eos et",
      subHeading: "Sed ut perspiciatis unde.",
    },
    {
      image: "/codingrippler/masonry/img8.jpeg",
      height: isPhoneorTab ? "35vh" : "50vh",
      heading: "Sed ut",
      subHeading: "Iste natus error sit.",
    },
    {
      image: "/codingrippler/masonry/img9.jpeg",
      height: isPhoneorTab ? "30vh" : "40vh",
      heading: "Neque porro",
      subHeading: "Qui dolorem ipsum.",
    },
  ];

  const hireUsPageContent = [
    {
      headerText: "Interested in working with us?",
      subHeaderText: "Hit us up and we'll get in touch with you.",
      buttonText: "Hire Us",
      context: "HireUs",
    },
  ];

  return (
    <>
      <Box
        ref={parallaxRef1}
        sx={{
          backgroundImage: `url(${"/codingrippler/masonry/ourwork.jpg"})`, // Replace with your SVG image path
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "relative",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          width: "100%",
          overflow: "hidden", // Ensures content fits within the 70vh height
          minHeight: {
            xs: 400,
          },
          height: {
            xs: deviceWithSmallHeight ? "75vh" : "60vh",
            sm: deviceWithSmallHeight ? "75vh" : "60vh",
            lg: "75vh",
          },
        }}
        gap={2}
      >
        <Grid container>
          <Grid item xs={12}>
            <CommonContainer>
              <HeroText
                customStyle={{ textAlign: "center", color: "white" }}
                variant={isPhoneorTab ? "h4" : "h2"}
              >
                Our work
              </HeroText>

              <Box
                sx={{
                  height: "20px",
                }}
              />
              <HeroSubHeader
                customStyle={{ textAlign: "center", color: "white" }}
              >
                Join millions of businesses today.
              </HeroSubHeader>
            </CommonContainer>
          </Grid>
        </Grid>
      </Box>

      <Box
        component="svg"
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          transform: "translateY(-50%)",
        }}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 100.1"
      >
        <path fill="#ffffff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
      </Box>

      <Box
        width="100%"
        m={{
          xs: 2,
          sm: 2.5,
        }}
      >
        <AdvertiseBrands
          isPhone={isPhone}
          isTab={isTab}
          deviceWithSmallHeight={deviceWithSmallHeight}
          pageContent={brandList}
          isPhoneorTab={isPhoneorTab}
        />
      </Box>

      <MasonryContainer
        isPhone={isPhone}
        isTab={isTab}
        deviceWithSmallHeight={deviceWithSmallHeight}
        pageContent={masnoryPageContent}
        isPhoneorTab={isPhoneorTab}
      />

      <Box
        component="svg"
        sx={{
          position: "relative",
          width: "100%",
          height: "auto",
          transform: "translateY(50%)",
        }}
        preserveAspectRatio="none"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1920 100.1"
      >
        <path fill="#f7faff" d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"></path>
      </Box>

      <HireUs
        isPhone={isPhone}
        isTab={isTab}
        deviceWithSmallHeight={deviceWithSmallHeight}
        pageContent={hireUsPageContent}
        isPhoneorTab={isPhoneorTab}
      />
    </>
  );
};
