import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import HubIcon from "@mui/icons-material/Hub";
import WorkIcon from "@mui/icons-material/Work";
import { Box, Button, Grid, useTheme } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import {
  CommonContainer,
  getButtonStyle,
  HeroSubHeader,
  HeroText,
  MarqueeEffect,
  RouteToAIPortal,
  RouteToWebPortal,
} from "../CodingRipplerWidgets";
import { Parallax } from "../CRAnimations/Animations";
import NavigationBox from "../Navigations/NavigationBox";
import { ContactCodingRippler } from "./ContactUs/ContactCodingRippler";
import { OurWork } from "./OurWork/OurWork";
import { WhatWeDo } from "./WhatWeDo/WhatWeDo";

export const CRHome = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const buttonRef = useRef(null);

  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [hasMoved, setHasMoved] = useState(false);
  // const [offsetY, setOffsetY] = useState(0);
  const {
    isPhoneorTab,
    isPhone,
    isTab,
    deviceWithSmallHeight,
    displayScrollViewNavigation,
    logoPath,
  } = props;

  let xAxisMovementControler = 25;
  let yAxisMovementControler = 10;
  let customStyleHeader = { color: "white", mt: { xs: 5, sm: 0 } };
  let customStyleSubHeader = { color: "white" };
  let headerText =
    "Driving Service Excellence with Tailored Web & AI Solutions";

  const handleMouseMove = (event) => {
    const { clientX, clientY } = event;
    setPosition({ x: clientX, y: clientY });
    setHasMoved(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
    setPosition({ x: 0, y: 0 });
  };

  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;

      // Handle opacity and offset
      const newOpacity = Math.max(0, Math.min(1, 1 - (scrollY - 200) / 200));
      setOpacity(newOpacity);
    };

    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const contactCodingRippler = {
    header: (
      <>
        {" "}
        Get started with{" "}
        <span className="theme-specfic-colored-text">
          Coding Rippler
        </span> today{" "}
      </>
    ),
    subHeader: "Let’s Build Innovative Solutions Tailored to Your Needs",
    buttonText: "View Pages",
  };

  return (
    <>
      <Grid container spacing={5}>
        <Grid
          item
          xs={12}
          mt={{
            xs: "64px",
            sm: "80px",
            lg: "0px",
          }}
          sx={{
            opacity: opacity,
            transition: "transform 0.5s ease-in-out", // Smooth transition
            position: "relative",
            "&::before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: {
                xs: "100%",
              },
              backgroundImage:
                "url(/codingrippler/background/cr-bg-indigo.svg)", // Replace with your SVG image path
              backgroundSize: "cover", // Adjust for small screens
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              opacity: 1, // Adjust the opacity as needed
              zIndex: -1000,
            },
          }}
        >
          <Box
            sx={{
              display: {
                xs: "none",
                lg: "block",
              },
            }}
          >
            <NavigationBox
              logoPath={logoPath}
              displayScrollViewNavigation={displayScrollViewNavigation}
            />
          </Box>
          <Box
            display="flex"
            flexDirection="column"
            justifyContent={isPhoneorTab ? "space-between" : "space-between"}
            alignItems="center"
            width="100%"
            minHeight={{
              xs: "40vh",
              lg: "60vh",
            }}
            mt={{
              xs: 2,
              sm: 10,
              lg: 10,
            }}
          >
            {/*    Hero Text Here    */}

            <CommonContainer>
              <HeroText
                variant={isPhoneorTab ? "h4" : "h3"}
                customStyle={customStyleHeader}
              >
                {headerText}{" "}
              </HeroText>

              <Box m={2} />

              {/*   Hero text ends here.   */}

              {/*   Subheading text goes here.  */}

              <HeroSubHeader customStyle={customStyleSubHeader}>
                Smarter Insights, Faster Results, Easier Integration.
              </HeroSubHeader>
            </CommonContainer>

            {/*   Subheading Ends here.  */}

            {/* Buttons go here.  */}

            <Grid container>
              <Grid item xs={12}>
                <CommonContainer>
                  <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    width={"100%"}
                    flexDirection={isPhone ? "column" : "row"}
                    gap={2}
                  >
                    <Button
                      fullWidth={isPhone ? true : false}
                      ref={buttonRef}
                      variant="contained"
                      size="large"
                      startIcon={<WorkIcon />}
                      endIcon={<ArrowRightAltIcon />}
                      className={
                        isHovered
                          ? "button-animation-hover"
                          : "button-animation"
                      }
                      onMouseEnter={() => {
                        setIsHovered(true);
                      }}
                      onMouseMove={handleMouseMove}
                      onMouseLeave={() => {
                        handleMouseLeave();
                        setHasMoved(false);
                      }}
                      sx={{
                        ...getButtonStyle({
                          isHovered,
                          theme,
                          buttonRef,
                          position,
                          hasMoved,
                          xAxisMovementControler,
                          yAxisMovementControler,
                        }),
                        fontSize: {
                          xs: "1.0rem",
                          sm: "1.5rem",
                          lg: "1.5rem",
                        },
                        padding: "1rem 2rem",
                        borderRadius: "14px",
                      }}
                      onClick={() => {
                        RouteToWebPortal("/web");
                      }}
                    >
                      Web Portfolio
                    </Button>

                    <Button
                      fullWidth={isPhone ? true : false}
                      ref={buttonRef}
                      variant="contained"
                      size="large"
                      startIcon={<HubIcon />}
                      endIcon={<ArrowRightAltIcon />}
                      className={"button-white"}
                      sx={{
                        fontSize: {
                          xs: "1.0rem",
                          sm: "1.5rem",
                          lg: "1.5rem",
                        },
                        padding: "1rem 2rem",
                        borderRadius: "14px",
                      }}
                      onClick={RouteToAIPortal}
                    >
                      AI Hub
                    </Button>
                  </Box>
                </CommonContainer>
              </Grid>
            </Grid>

            <Box
              sx={{
                width: "100%",
                height: {
                  xs: 150,
                },
                backgroundColor: "#377DFF",
                zIndex: 1,
              }}
              mt={{
                xs: 2,
                sm: 10,
                lg: 10,
                xl: 10,
              }}
            >
              <MarqueeEffect isPhone={isPhone} />
            </Box>
          </Box>
        </Grid>
        {/* <Grid
          item
          xs={12}
          sx={{
            opacity: opacity,
            transition: "transform 0.3s ease-in-out", // Smooth transition
            minHeight: {
              xs: deviceWithSmallHeight ? "10vh" : "10vh",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              height: {
                xs: "15%",
                lg: "15%",
              },
              maxHeight: "15%",
              backgroundColor: "#377DFF",
              transform: "translate(0, 0%)",
              position: "absolute",
              top: {
                xs: deviceWithSmallHeight ? "85vh" : "65vh",
                lg: "80vh",
              },
              zIndex: -1001,
              left: 0,
              right: 0,
            }}
          >
            <MarqueeEffect isPhone={isPhone} />
          </Box>
        </Grid> */}
      </Grid>

      <Grid
        container
        spacing={2}
        className="white-linear-gradient-background"
        mt={{
          xs: 2,
          sm: 5,
          md: 10,
          lg: 10,
        }}
      >
        <Grid item xs={12}>
          {!isPhone ? (
            <Parallax
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            >
              <WhatWeDo
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
              />
            </Parallax>
          ) : (
            <WhatWeDo
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            />
          )}
        </Grid>

        <Grid
          item
          sm={12}
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "none",
            },
          }}
          minHeight={{
            md: 100,
          }}
        />

        <Grid
          item
          xs={12}
          mt={{
            xs: 0,
            sm: 5,
            md: 10,
            lg: 10,
          }}
        >
          <OurWork isPhone={isPhone} />
        </Grid>

        <Grid item xs={12}>
          <Box
            component="svg"
            sx={{
              position: "relative",
              width: "100%",
              height: "auto",
              transform: `translateY(${isPhone ? "50%" : "25%"})`,
            }}
            preserveAspectRatio="none"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1920 100.1"
          >
            <path
              fill="#ffffff"
              d="M0,0c0,0,934.4,93.4,1920,0v100.1H0L0,0z"
            ></path>
          </Box>
        </Grid>
      </Grid>

      <Grid container>
        <Grid item xs={12} sx={{}}>
          <CommonContainer>
            <ContactCodingRippler
              pageContent={contactCodingRippler}
              isPhone={isPhone}
              isTab={isTab}
              isPhoneorTab={isPhoneorTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            />
          </CommonContainer>
        </Grid>
      </Grid>
    </>
  );
};
