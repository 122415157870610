import { Box, Button } from "@mui/material";
import React from "react";

export const BookNowButton = ({ children, ...props }) => {
  const { handleClick } = props;
  const { isVisible } = props;

  return (
    <>
      {isVisible && (
        <Box
          sx={{
            display: "flex", // Enable flexbox layout
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically (optional in this case)
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.5)", // Partially transparent
            zIndex: 1000, // Ensure it's above other content
            pointerEvents: "auto", // Allows clicks to pass through
            height: 100, // Adjust height as needed
          }}
          id="book-now-button"
        >
          <Button
            variant="contained"
            pointerEvents="auto"
            size="large"
            onClick={handleClick}
          >
            Book Now
          </Button>
        </Box>
      )}

      {/* Rest of the content */}
    </>
  );
};
