import DoneOutlinedIcon from "@mui/icons-material/DoneOutlined";
import {
  Button,
  Card,
  CardContent,
  Fab,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { Box } from "@mui/system";

export const PricingContainer = ({ children, ...props }) => {
  const { pricingDetails } = props;
  const theme = useTheme();

  const handleTryNow = (context) => {
    window.location.href = `/ai`;
  };

  const handleGetQuote = (context) => {
    window.location.href = `/ai`;
  };

  return (
    // <Container id="PricingContainer">
    <Grid container p={2} spacing={4}>
      {pricingDetails.map((pricingDetail, index) => (
        <Grid item xs={12} md={3} key={index}>
          <Card
            sx={{
              pt: 4,
              pl: 2,
              pr: 2,
              borderRadius: "24px",
              boxShadow: "0px 0px 6px 3px rgba(0, 0, 0, 0.2)", // Custom shadow
            }}
          >
            <CardContent>
              <Typography
                variant="h4"
                fontWeight="bold"
                className="typography-custom-css"
              >
                {pricingDetail.title}
              </Typography>

              <Typography
                variant="h6"
                className="typography-custom-css sub-header"
              >
                {pricingDetail.description}
              </Typography>
            </CardContent>

            <CardContent>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-end"
                gap={2}
              >
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  className="typography-custom-css"
                >
                  {pricingDetail.currency}
                  {pricingDetail.price}
                </Typography>

                <Typography
                  variant="h6"
                  className="typography-custom-css  sub-header"
                >
                  {pricingDetail.frequency}
                </Typography>
              </Box>
            </CardContent>

            <CardContent>
              {!pricingDetail.isCustom && (
                <Fab
                  variant="extended"
                  size="large"
                  color="primary"
                  className="button"
                  p={10}
                  sx={{ width: "100%" }}
                  onClick={handleTryNow}
                >
                  Try Now
                </Fab>
              )}

              {pricingDetail.isCustom && (
                <Button
                  variant="outlined"
                  style={{ color: theme.palette.text.primary, padding: "10px" }}
                  fullWidth
                  onClick={handleGetQuote}
                >
                  <Typography variant="body2">Get a Quote</Typography>
                </Button>
              )}
            </CardContent>

            <CardContent>
              <Typography
                variant="h6"
                className="typography-custom-css  sub-header"
                fontWeight={600}
              >
                Includes{" "}
              </Typography>

              <Box m={4} />
              <Stack spacing={2}>
                {pricingDetail.features_included.map((feature, index) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="flex-start"
                    gap={2}
                    key={index}
                  >
                    <DoneOutlinedIcon sx={{ color: "#00BB38" }} />
                    <Typography
                      variant="body1"
                      className="typography-custom-css  sub-header"
                      sx={{}}
                    >
                      {feature}
                    </Typography>
                  </Box>
                ))}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
    // </Container>
  );
};

export const AdditionalServiceCost = ({ children, ...props }) => {
  const { pricingDetails } = props;
  const theme = useTheme();

  const handleTryNow = (context) => {
    window.location.href = `/ai`;
  };

  return (
    <Grid container p={2} spacing={4}>
      {pricingDetails.map((pricingDetail, index) => (
        <Grid item xs={12} md={6} key={index}>
          <Card
            sx={{
              pt: 4,
              pl: 2,
              pr: 2,
              borderRadius: "24px",
              boxShadow: "0px 0px 6px 3px rgba(0, 0, 0, 0.2)", // Custom shadow
            }}
          >
            <CardContent>
              <Typography
                variant="h4"
                fontWeight="bold"
                className="typography-custom-css"
              >
                {pricingDetail.title}
              </Typography>

              <Typography
                variant="h6"
                className="typography-custom-css sub-header"
              >
                {pricingDetail.description}
              </Typography>
            </CardContent>

            <CardContent>
              <Box
                display="flex"
                justifyContent="flex-start"
                alignItems="flex-end"
                gap={2}
              >
                <Typography
                  variant="h4"
                  fontWeight="bold"
                  className="typography-custom-css"
                >
                  {pricingDetail.currency}
                  {pricingDetail.price}
                </Typography>

                <Typography
                  variant="h6"
                  className="typography-custom-css  sub-header"
                >
                  {pricingDetail.frequency}
                </Typography>
              </Box>
            </CardContent>

            <CardContent>
              <Button
                variant="outlined"
                style={{ color: theme.palette.text.primary, padding: "10px" }}
                fullWidth
                onClick={handleTryNow}
              >
                <Typography variant="body2">Try now</Typography>
              </Button>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
};
