import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Route, Routes, useLocation } from "react-router-dom";
import { SlideInFromTopEffect } from "../BoilarPlateJs/MotionEffects";
import { Basic } from "./CRDemo/Basic/Basic";
import { ECommerce } from "./CRDemo/ECommerce/ECommerce";
import { GridContainer } from "./CRDemo/Grid/GridContainer";
import { Masanory } from "./CRDemo/Masanory/Masanory";
import { OurWebPortfolio } from "./CRDemo/OurServices/OurWebPortfolio";
import { Services } from "./CRDemo/OurServices/Services";
import { Parallax } from "./CRDemo/Parallax/Parallax";
import { Dashboards } from "./CodingRipplerHome/Dashboards/Dashboards";
import { WRFooter } from "./Footer/WRFooter";
import { WRNavigationSmallerDevice } from "./Navigations/WebRipplerNavigation/SmallDevices/WRNavigationSmallerDevice";
import { WebRipplerNavigation } from "./Navigations/WebRipplerNavigation/WebRipplerNavigation";

export const WebRippler = ({ children, ...props }) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("lg"));
  const deviceWithSmallHeight = useMediaQuery("(max-height: 750px)");

  const isPhoneOrTab = isPhone || isTab;

  let { logoPath } = props;

  const location = useLocation();

  const logoPathScroll = logoPath;

  const getCustomNavigationTextColor = (pathname) => {
    if (!isPhoneOrTab && pathname.includes("masanory")) {
      logoPath = "/codingrippler/brand-logos/wr-logo-light.svg";
      return "rgb(255, 255, 255)";
    }
  };

  const customTextColor = getCustomNavigationTextColor(location.pathname);

  return (
    <Grid
      container
      sx={{
        maxWidth: "100%",
      }}
    >
      <Grid item xs={12}>
        {isPhoneOrTab ? (
          <WRNavigationSmallerDevice
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            logoPath={logoPath}
            logoPathScroll={logoPathScroll}
          />
        ) : (
          //   <CodingRipplerNavigation logoPath={logoPath} />
          <WebRipplerNavigation
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            logoPath={logoPath}
            customTextColor={customTextColor}
            logoPathScroll={logoPathScroll}
          />
        )}
      </Grid>

      <Routes>
        <Route
          path="/*"
          element={
            <Grid item xs={12}>
              <Services
                isPhone={isPhone}
                isTab={isTab}
                deviceWithSmallHeight={deviceWithSmallHeight}
                isPhoneorTab={isPhoneOrTab}
                theme={theme}
              />
            </Grid>
          }
        />
        <Route
          path="/parallax/*"
          element={
            <Parallax
              isPhone={isPhone}
              isTab={isTab}
              isPhoneorTab={isPhoneOrTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
            />
          }
        />

        <Route
          path="/basic/*"
          element={
            <Basic
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneOrTab}
            />
          }
        />

        <Route
          path="/grid/*"
          element={
            <GridContainer
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneOrTab}
            />
          }
        />

        <Route
          path="/masanory/*"
          element={
            <Masanory
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneOrTab}
            />
          }
        />

        <Route
          path="/e-commerce/*"
          element={
            <ECommerce
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneOrTab}
            />
          }
        />

        <Route
          path="/dashboards/*"
          element={
            <Dashboards
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneOrTab}
            />
          }
        />
        {/* 
        <Route
          path="/web/*"
          element={
            <Services
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneOrTab}
              theme={theme}
            />
          }
        /> */}

        {/* <Route
          path="/contact/*"
          element={
            <Contact
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneorTab={isPhoneOrTab}
              theme={theme}
            />
          }
        /> */}
        <Route
          path="/portfolio"
          element={
            <OurWebPortfolio
              isPhone={isPhone}
              isTab={isTab}
              isPhoneorTab={isPhoneOrTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              theme={theme}
            />
          }
        />
      </Routes>

      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <WRFooter
            isPhone={isPhone}
            isPhoneorTab={isPhoneOrTab}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
          />
        </SlideInFromTopEffect>
      </Grid>
    </Grid>
  );
};
