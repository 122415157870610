import { CRTopBanner } from "../Banners/CRBanners";
import { CRNavigationScrollView } from "./CRNavigationScrollView";

export const CodingRipplerNavigation = ({ children, ...props }) => {
  const { displayScrollViewNavigation, logoPath } = props;
  // const [
  //   displayScrollViewNavigation,
  //   setDisplayScrollViewNavigation,
  // ] = useState(false);

  // const { logoPath } = props;

  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 100) {
  //       setDisplayScrollViewNavigation(true);
  //     } else {
  //       setDisplayScrollViewNavigation(false);
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll, { passive: true });
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <>
      <CRNavigationScrollView
        visibility={displayScrollViewNavigation}
        logoPath={logoPath}
      />
      <CRTopBanner visibility={!displayScrollViewNavigation} />
    </>
  );
};
