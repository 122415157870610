import EmailIcon from "@mui/icons-material/Email";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import { Box, Container, Divider, Grid } from "@mui/material";
import React from "react";
import { AvatarComponent } from "../BoilarPlateJs/AvatarComponent";
import { PageSeparator } from "../BoilarPlateJs/TitleDecorationBottomCenter";
import { TypographyOxygen } from "../BoilarPlateJs/TypographyStyled";

export const TourAndTravelFooter = ({ children, ...props }) => {
  return (
    <Box
      component="footer"
      sx={{
        position: "relative",
        p: 6,
        "&::before": {
          content: '""',
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          opacity: 0.2,
          position: "absolute",
          backgroundColor: "#334960",
        },
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={2}>
          <Grid item xs={12} lg={3}>
            <TypographyOxygen variant="h6" fontSize={"1.5rem"}>
              About Us
            </TypographyOxygen>
            <TypographyOxygen
              variant="body2"
              lineHeight={2}
              fontSize={"1.0rem"}
              fontWeight={"normal"}
            >
              We are a small company based in San Francisco which delivers
              outstanding adventure travel experiences.
            </TypographyOxygen>

            <AvatarComponent
              src="/tourandtravel/award.png"
              alt="award"
              customStyle={{
                border: "none",
                height: "200px",
                width: "200px",
              }}
            ></AvatarComponent>
          </Grid>
          <Grid item xs={12} lg={3}>
            <TypographyOxygen variant="h6" fontSize={"1.5rem"}>
              From the Blog
            </TypographyOxygen>
            <Box display={"flex"}>
              <AvatarComponent
                src="/tourandtravel/profilePic3.jpg"
                alt="award"
                customStyle={{ width: "80px", height: "80px" }}
              ></AvatarComponent>

              <Box pl={2}>
                <TypographyOxygen
                  variant="h6"
                  fontSize={"1.0rem"}
                  fontWeight={"normal"}
                >
                  Bali, Indonesia: A Tropical Paradise{" "}
                </TypographyOxygen>
              </Box>
            </Box>
            <Box p={5}>
              <Divider
                variant="dashed"
                sx={{
                  borderStyle: "dashed",
                }}
              />
            </Box>

            <Box display={"flex"}>
              <AvatarComponent
                src="/tourandtravel/profilePic4.jpg"
                alt="award"
                customStyle={{ width: "80px", height: "80px" }}
              ></AvatarComponent>

              <Box pl={2}>
                <TypographyOxygen
                  variant="h6"
                  fontSize={"1.0rem"}
                  fontWeight={"normal"}
                >
                  Bali, Indonesia: A Tropical Paradise{" "}
                </TypographyOxygen>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} lg={3}>
            <TypographyOxygen variant="h6" fontSize={"1.5rem"}>
              Latest Tweets
            </TypographyOxygen>
            <TypographyOxygen
              variant="body2"
              lineHeight={2}
              fontSize={"1.0rem"}
              fontWeight={"normal"}
            >
              Please check the settings in the Twitter widget.
            </TypographyOxygen>
          </Grid>
          <Grid item xs={12} lg={3}>
            <TypographyOxygen variant="h6" fontSize={"1.5rem"}>
              Contact Us
            </TypographyOxygen>

            <Box display={"flex"} pt={2}>
              <Box>
                <LocationOnIcon />
              </Box>
              <Box pl={2}>
                <TypographyOxygen
                  variant="body2"
                  lineHeight={2}
                  fontSize={"1.0rem"}
                  fontWeight={"normal"}
                  padding={0}
                >
                  123 Main St, New York, NY 10001
                </TypographyOxygen>
              </Box>
            </Box>
            <Box display={"flex"} pt={2}>
              <Box>
                <PhoneIcon />
              </Box>

              <Box pl={2}>
                <TypographyOxygen
                  variant="body2"
                  lineHeight={2}
                  fontSize={"1.0rem"}
                  fontWeight={"normal"}
                  padding={0}
                >
                  (212) 555-1234
                </TypographyOxygen>
              </Box>
            </Box>

            <Box display={"flex"} pt={2}>
              <Box>
                <EmailIcon />
              </Box>
              <Box pl={2}>
                <TypographyOxygen
                  variant="body2"
                  lineHeight={2}
                  fontSize={"1.0rem"}
                  fontWeight={"normal"}
                  padding={0}
                >
                  contact@codingrippler.com
                </TypographyOxygen>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <PageSeparator />
      </Container>
    </Box>
  );
};
