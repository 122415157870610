import { KeyboardArrowUp } from "@mui/icons-material";
import CodeIcon from "@mui/icons-material/Code";
import ConstructionIcon from "@mui/icons-material/Construction";
import GroupIcon from "@mui/icons-material/Group";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SettingsInputComponentIcon from "@mui/icons-material/SettingsInputComponent";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import {
  Box,
  Button,
  Card,
  CardContent,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React, { useState } from "react";
import "../../../AIHome/globalAI.css";

export const ProductButton = ({ children, ...props }) => {
  const [isHovered, setIsHovered] = useState(false);
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  let { activeDropdown, handleButtonClick } = props;

  const handleClick = (context) => {
    window.location.href = context;
  };

  const shortcuts = [
    {
      name: "AI Agents",
      context: `/ai/products/ai-agents`,
      icon: <SupervisorAccountIcon />,
    },
    {
      name: "AI Teams",
      context: `/ai/products/ai-teams`,
      icon: <GroupIcon />,
    },
    {
      name: "AI Tools",
      context: `/ai/products/ai-tools`,
      icon: <ConstructionIcon />,
    },
    {
      name: "Integrations",
      context: `/ai/products/integrations`,
      icon: <SettingsInputComponentIcon />,
    },
    {
      name: "API",
      context: `/ai/products/api`,
      icon: <CodeIcon />,
    },
  ];

  return (
    <Box
      sx={{
        [theme.breakpoints.down("lg")]: { position: "relative" },
      }}
    >
      {isLargeScreen && (
        <Button
          variant="text"
          style={{ color: theme.palette.text.primary }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)}
          onClick={() => setIsHovered(!isHovered)}
        >
          <Box display="flex" alignItems="center">
            <Typography
              variant="h6"
              fontFamily="Inter, sans-serif" // Add your desired font family here
              fontSize="1rem"
            >
              Products{" "}
            </Typography>{" "}
            {!isHovered && (
              <KeyboardArrowDownIcon onClick={() => setIsHovered(!isHovered)} />
            )}
            {isHovered && (
              <KeyboardArrowUp onClick={() => setIsHovered(!isHovered)} />
            )}
          </Box>
        </Button>
      )}

      {!isLargeScreen && (
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Button
            variant="text"
            index={0}
            style={{ color: theme.palette.text.primary }}
            onMouseEnter={() => {
              // setIsHovered(true);
              handleButtonClick(0);
            }}
            onMouseLeave={() => {
              // setIsHovered(false);
              handleButtonClick(null);
            }}
            onClick={() => {
              // setIsHovered(!isHovered);
              handleButtonClick(0);
            }}
          >
            <Typography
              variant="h6"
              fontFamily="Inter, sans-serif" // Add your desired font family here
              fontSize="1rem"
            >
              Products{" "}
            </Typography>{" "}
          </Button>
          {activeDropdown === 0 ? (
            <KeyboardArrowUp
              onClick={() => {
                // setIsHovered(!isHovered);
                handleButtonClick(null);
              }}
            />
          ) : (
            <KeyboardArrowDownIcon
              onClick={() => {
                // setIsHovered(!isHovered);
                handleButtonClick(0);
              }}
            />
          )}{" "}
        </Box>
      )}

      {isLargeScreen && isHovered && (
        <Card
          sx={{
            position: "absolute",
            [theme.breakpoints.down("lg")]: {
              width: "100%",
              position: "relative",
            },
            [theme.breakpoints.up("lg")]: {
              width: "25%",
              position: "absolute",
            },
            zIndex: 3,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} // Hide the card when the mouse leaves it
        >
          {shortcuts.map((shortcut, index) => (
            <CardContent
              className="customHoverEffect"
              onClick={() => {
                handleClick(shortcut.context);
              }}
              key={index}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                {shortcut.icon}
                <Box sx={{ ml: "10px" }}>
                  <Typography
                    variant="body2"
                    fontFamily="Inter, sans-serif" // Add your desired font family here
                  >
                    {shortcut.name}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          ))}
        </Card>
      )}

      {!isLargeScreen && activeDropdown === 0 && (
        <Card
          sx={{
            position: "absolute",
            [theme.breakpoints.down("lg")]: {
              width: "100%",
              position: "relative",
            },
            [theme.breakpoints.up("lg")]: {
              width: "25%",
              position: "absolute",
            },
            zIndex: 3,
          }}
          onMouseEnter={() => setIsHovered(true)}
          onMouseLeave={() => setIsHovered(false)} // Hide the card when the mouse leaves it
        >
          {shortcuts.map((shortcut, index) => (
            <CardContent
              className="customHoverEffect"
              onClick={() => {
                handleClick(shortcut.context);
              }}
              key={index}
            >
              <Box display="flex" flexDirection="row" alignItems="center">
                {shortcut.icon}
                <Box sx={{ ml: "10px" }}>
                  <Typography
                    variant="body2"
                    fontFamily="Inter, sans-serif" // Add your desired font family here
                  >
                    {shortcut.name}
                  </Typography>
                </Box>
              </Box>
            </CardContent>
          ))}
        </Card>
      )}
    </Box>
  );
};
