import DataObjectOutlinedIcon from "@mui/icons-material/DataObjectOutlined";
import DescriptionIcon from "@mui/icons-material/Description";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import { Box, Container, Grid } from "@mui/material";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { Demo } from "../../AIHome/HomePageWidgets/Buttons";
import {
  AICommonContainer,
  AnimatedBox,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
  YouTubeBox,
} from "../../AIUtil/AIUtils";
import {
  InLineMediaContainer,
  PageHeader,
  PageSubHeader,
  TopicContainer,
} from "../../AIWidgets";
import {
  AdvertiseLeadGenerationTool,
  AIAgentsFeatures,
  GettingStartedContainer,
  HowItWorkContainer,
} from "../AIAgentsWidgets";

export const BoshTheSalesAgent = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  const ListOfOurCustomers = [
    "/airippler/marquee/Webflow.svg",
    "/airippler/marquee/Async-Api.svg",
    "/airippler/marquee/Zapier.svg",
    "/airippler/marquee/Zoho.svg",
    "/airippler/marquee/Google-Gemini.svg",
    "/airippler/marquee/Hubspot.svg",
  ];

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="Introducing Catalyst">
          <ForwardToInboxIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                Meet{" "}
                <span className="theme-specfic-colored-text">Catalyst</span>,
                Your Sales Agent
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                The AI that drives your sales, 24/7.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <ResponsiveBoxCenteredChildren>
          <Demo />
          <Box m={2} />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
      </Grid>

      <Grid item xs={12}>
        <Container sx={{ mt: 2, mb: 2 }}>
          <AnimatedBox>
            <YouTubeBox />
          </AnimatedBox>
        </Container>
      </Grid>

      {/* Advertise page   */}

      <Grid item xs={12}>
        <AICommonContainer>
          <CommonSubHeaderTypography customStyle={{ opacity: 0.8 }}>
            Companies of all sizes trust AI Rippler
          </CommonSubHeaderTypography>

          <Box m={2} />
          <InLineMediaContainer data={ListOfOurCustomers} />
        </AICommonContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          {/* 

          Might need to relook at this in 2nd phase
           */}
          <AdvertiseLeadGenerationTool
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            isPhoneOrTab={isPhoneOrTab}
          />
        </CommonContainer>
      </Grid>

      {/* Example Use Case Page  */}
      {/*

       Not required for now

      <Grid item xs={12}>
        <TopicContainer topicText="Customers">
          <PeopleAltOutlinedIcon />
        </TopicContainer>
      </Grid>
                
      <Grid item xs={12}>
        <AICommonBoxContainer
          customStyle={{
            //     border: "1px solid #868a97",
            boxShadow: "0px 0px 6px 3px rgba(0, 0, 0, 0.2)", // Custom shadow
            borderRadius: "24px",
          }}
        >
          <Container>
            <BlogContainer />
          </Container>
        </AICommonBoxContainer>
      </Grid> */}

      <Grid item xs={12}>
        <TopicContainer topicText="Features">
          <DataObjectOutlinedIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                <span className="theme-specfic-colored-text">
                  Features That Supercharge Your Sales
                </span>
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Maximize Every Sales Interaction with Catalyst AI
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid
            item
            xs={1}
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          />

          <Grid item xs={12} sm={10}>
            <CommonContainer>
              <AIAgentsFeatures />
            </CommonContainer>
          </Grid>
          <Grid
            item
            xs={1}
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          />
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <TopicContainer topicText="How it works">
          <DescriptionIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <HowItWorkContainer />
      </Grid>

      <Grid item xs={12}>
        <TopicContainer topicText="Getting Started">
          <LightModeOutlinedIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Start in Just{" "}
                <span className="theme-specfic-colored-text">one week</span>–
                Fast, Easy, and Ready
              </CommonHeaderTypography>
            }
          />
          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Our Proven, Tailored Process Gets You Up and Running Quickly
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12}>
        <GettingStartedContainer
          isPhone={isPhone}
          isTab={isTab}
          deviceWithSmallHeight={deviceWithSmallHeight}
          isPhoneOrTab={isPhoneOrTab}
        />
      </Grid>
    </Grid>
  );
};
