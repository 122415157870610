import { Button, Grid } from "@mui/material";
import React from "react";

export const PackageShortcuts = ({ children, ...props }) => {
  const { noOfColumns = 12 } = props;
  const { data } = props;

  const tourIncludeValues = data?.tourIncludes.split(",");

  return (
    <Grid container spacing={2} pt={5}>
      {tourIncludeValues.map((value, index) => (
        <Grid item sm={noOfColumns} md={4} lg={3} key={index}>
          <Button variant="contained" color="primary" size="small">
            {value.trim()} {/* Assuming you want to display the value */}
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
