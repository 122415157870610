import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import XIcon from "@mui/icons-material/X";
import { Box, IconButton } from "@mui/material";
import React from "react";

export const SocialMediaConnector = ({ children, ...props }) => {
  return (
    <Box>
      <Box display="flex" justifyContent="center">
        <IconButton
          color="primary"
          aria-label="upload picture to go top"
          component="span"
          sx={{
            "&:hover": {
              color: "#3b5998",
            },
          }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <FacebookIcon fontSize="large" />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="upload picture to go top"
          component="span"
          sx={{
            "&:hover": {
              color: "#000000", // Instagram's brand color
            },
          }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <XIcon fontSize="large" />
        </IconButton>
        <IconButton
          color="primary"
          aria-label="upload picture to go top"
          component="span"
          sx={{
            "&:hover": {
              color: "#E1306C", // Instagram's brand color
            },
          }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        >
          <InstagramIcon fontSize="large" />
        </IconButton>
      </Box>
    </Box>
  );
};
