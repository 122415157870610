import { Box, CardMedia } from "@mui/material";
import {
  CommonContainer,
  HeroSubHeader,
  HeroText,
} from "../../CodingRipplerWidgets";

export const Testimonial = ({ children, ...props }) => {
  const { key, data, isPhone, pageDetails } = props;

  let pagedetail = pageDetails[0];

  let customStyleHeader = { fontSize: "2rem" };

  if (isPhone) {
    customStyleHeader = { fontSize: "2rem" };
  }

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      minHeight={{
        xs: 650,
        sm: 600,
      }}
      height="100vh"
      width="100%"
      gap={2}
      key={key}
      ref={data.reference}
      className="white-bg"
    >
      <CommonContainer>
        <Box minHeight={25} height="5vh">
          <CardMedia
            component="img"
            image={pagedetail.image}
            alt="Description of the image"
            sx={{
              objectFit: "contain",
              width: "100%",
              height: "100%",
            }}
          />{" "}
        </Box>

        <Box m={2} />
        <HeroSubHeader>{pagedetail.heading}</HeroSubHeader>
        <Box m={2} />
        <HeroSubHeader>{pagedetail.subHeading1}</HeroSubHeader>
        <Box m={2} />
        <HeroSubHeader>{pagedetail.subHeading2}</HeroSubHeader>
        <Box m={2} />
        <HeroText variant="h5" customStyle={customStyleHeader}>
          {pagedetail.reviewerName}
        </HeroText>
        <Box m={2} />
        <HeroSubHeader>{pagedetail.reviewerDesignation}</HeroSubHeader>
      </CommonContainer>
    </Box>
  );
};
