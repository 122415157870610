import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { Box, CardMedia, Grid } from "@mui/material";
import { CommonContainer } from "../../../CodingRippler/CodingRipplerWidgets";
import { RequestDemo, TryForFree } from "../../AIHome/HomePageWidgets/Buttons";
import { IntroSubHeadingButtons } from "../../AIHome/HomePageWidgets/IntroWidgets";
import {
  AICommonBoxContainer,
  AICommonContainer,
  CommonHeaderTypography,
  CommonSubHeaderTypography,
  ResponsiveBoxCenteredChildren,
} from "../../AIUtil/AIUtils";
import {
  CardWithMediaTitleAndDescription,
  InLineMediaContainer,
  PageHeader,
  PageSubHeader,
  TopicContainer,
} from "../../AIWidgets";

export const AIMarketingHome = ({ children, ...props }) => {
  const { isPhoneOrTab } = props;

  const useCaseData = [
    {
      title: "Instant Customer Insights",
      description:
        "Unlock AI-powered insights from reports, interviews, and more to align your team instantly.",
      image: "/airippler/features/no-coding-required.png",
      minHeight: "500px",
    },
    {
      title: "AI-Driven Content Creation",
      description:
        "Create unique content tailored to your brand's voice, using your data, research, and guidelines.",
      image: "/airippler/features/templatesToGetStarted.png",
      minHeight: "500px",
    },
    {
      title: "Lead Nurturing Automation",
      description:
        "Develop hyper-targeted nurturing sequences that keep your leads engaged, always.",
      image: "/airippler/features/teachYourProcess.png",
      minHeight: "500px",
    },
  ];

  const keyFeatureData = [
    {
      title: "Effortless Tool Integration",
      description:
        "Connect with HubSpot, Salesforce, Zapier, Gmail, and more in seconds. Get your tools working in harmony.",
      image: "/airippler/features/IntegrateWithTechTrack.png",
    },
    {
      title: "Your Data, Always Secure",
      description:
        "Your data remains private and secure. It’s never used for training, so your business stays confidential.",
      image: "/airippler/features/teachYourProcess.png",
    },
    {
      title: "Create Without Code",
      description:
        "Automate workflows with ease—no coding required. Transform your processes into AI-powered actions instantly.",
      image: "/airippler/features/agent-skill.png",
    },
  ];

  const ListOfOurCustomers = [
    "/airippler/marquee/Webflow.svg",
    "/airippler/marquee/Async-Api.svg",
    "/airippler/marquee/Zapier.svg",
    "/airippler/marquee/Zoho.svg",
    "/airippler/marquee/Google-Gemini.svg",
    "/airippler/marquee/Hubspot.svg",
  ];

  return (
    <Grid container spacing={10} mt={2}>
      <Grid item xs={12}>
        <TopicContainer topicText="for Marketing">
          <LeaderboardIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography variant={isPhoneOrTab ? "h4" : "h2"}>
                <span className="theme-specfic-colored-text">
                  Boost Marketing with AI Automation
                </span>{" "}
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
          <PageSubHeader
            headerText={
              <CommonSubHeaderTypography>
                Streamline campaigns, engage your audience, and drive results
                faster with cutting-edge AI technology.
              </CommonSubHeaderTypography>
            }
          />
        </CommonContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <ResponsiveBoxCenteredChildren>
          <TryForFree />
          <Box m={2} />
          <RequestDemo />
        </ResponsiveBoxCenteredChildren>
        <Box m={2} />
        <IntroSubHeadingButtons />
      </Grid>

      <Grid item xs={12}>
        <AICommonContainer>
          <CardMedia
            component="img"
            image="/airippler/home-page/homePage.png"
            alt="Description of the image"
            width="100%"
            height="100%"
          />
        </AICommonContainer>
      </Grid>

      {/* Advertise page   */}

      <Grid item xs={12}>
        <AICommonContainer>
          <CommonSubHeaderTypography customStyle={{ opacity: 0.8 }}>
            Companies of all sizes trust AI Rippler
          </CommonSubHeaderTypography>

          <Box m={2} />
          <InLineMediaContainer data={ListOfOurCustomers} />
        </AICommonContainer>
      </Grid>

      {/* Example Use Case Page  */}

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TopicContainer topicText="Example use cases">
          <LeaderboardIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                From Campaign Creation to Conversion in Record Time
              </CommonHeaderTypography>
            }
          />

          <Box m={2} />
        </CommonContainer>

        <AICommonBoxContainer>
          <CardWithMediaTitleAndDescription data={useCaseData} />
        </AICommonBoxContainer>
      </Grid>

      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TopicContainer topicText="Key Features">
          <LeaderboardIcon />
        </TopicContainer>
      </Grid>

      <Grid item xs={12}>
        <CommonContainer>
          <PageHeader
            headerText={
              <CommonHeaderTypography>
                Easily Build Your AI Marketing Engine to Drive Conversions
              </CommonHeaderTypography>
            }
          />
        </CommonContainer>

        <Box m={2} />
        <AICommonBoxContainer>
          <CardWithMediaTitleAndDescription data={keyFeatureData} />
        </AICommonBoxContainer>
      </Grid>
    </Grid>
  );
};
