import {
  Box,
  Breadcrumbs,
  Grid,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@mui/material/styles";
import React from "react";
import { Link } from "react-scroll";
import { CommonContainer } from "../../../BoilarPlateJs/CommonTextContainer";
import { StraigtLine } from "../../../BoilarPlateJs/TitleDecorationBottomCenter";
import { TypographyOxygen } from "../../../BoilarPlateJs/TypographyStyled";
import { FilteredDataContainerWithPagination } from "../FilteredDataContainer";
const tourImage = "/TourAndTravel/popularTour.png";

export const DealsAndDiscountsPage = ({ children, ...props }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const { tourData } = props;

  var fontSize = "2.5rem";

  if (isSmallScreen) {
    fontSize = "1.5rem";
  }

  return (
    <>
      <Box
        sx={{
          height: "75vh",
          width: "100vw",
          position: "relative",
          "&::before": {
            content: '""',
            backgroundImage: `url(${tourImage})`,
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
            backgroundPosition: "50% 50%",
            opacity: 1,
            position: "absolute",
            backgroundColor: "#429cb5",
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
          },
        }}
        textAlign={"center"}
      >
        <CommonContainer>
          <Grid container>
            <Grid item xs={12}>
              <Breadcrumbs aria-label="breadcrumb">
                <Link color="inherit" to="/">
                  Home
                </Link>
                <Link color="inherit" to="/tours">
                  Tours
                </Link>
                <Typography color="textPrimary">Deals and Discounts</Typography>
              </Breadcrumbs>
            </Grid>
          </Grid>

          <Box pt={3}>
            <StraigtLine />
          </Box>
        </CommonContainer>
        <Box
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "60%",
          }}
        >
          <TypographyOxygen fontSize={fontSize} zIndex={1}>
            Deals and Discounts
          </TypographyOxygen>
        </Box>
      </Box>

      <Link
        to="filteredDataContainer"
        spy={true}
        smooth={true}
        //       offset={-70}
        duration={500}
        sx={{ border: "none" }}
      >
        {/* <FilteredDataContainer tourData={tourData} /> */}
        <FilteredDataContainerWithPagination tourData={tourData} />
      </Link>
    </>
  );
};
