import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, Card } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import { Box } from "@mui/system";
import "./CardViewContainer.css";

export const CardViewContainerV2 = ({ children, ...props }) => {
  // Need to make it back end driven
  const handleFilter = () => {
    alert("Filter button clicked");
  };
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Card
        sx={{
          borderRadius: "32px",
          margin: (theme) => theme.spacing(2),
          ...props,
          overflow: "visible",
        }}
        className="cardViewContainerV2 cardHoverEffectV2"
      >
        <CardContent sx={{ justifyContent: "flex-end" }}>
          {children}
        </CardContent>
        <CardActions sx={{ justifyContent: "center" }}>
          <Button
            style={{
              position: "absolute",
              backgroundColor: "#2e6d7f",
              transform: "translate(0%, 20%)", // Adjust the button's position
              borderRadius: "3rem 3rem 3rem 3rem",
              overflow: "visible",
            }}
            className="tourAndTravelButton"
            onClick={handleFilter}
          >
            <FilterListIcon />
            Filter
          </Button>
        </CardActions>
      </Card>
    </Box>
  );
};
