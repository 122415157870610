import { CardMedia } from "@mui/material";
import { Box } from "@mui/system";
import { useMediaQuery } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { useState } from "react";
import { useEffect } from "react";
import { WebRipplerButtons } from "./WebRipplerButtons";
import { WRNavigationScrollView } from "./WRNavigationScrollView";

export const WebRipplerNavigation = ({ children, ...props }) => {
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up("lg"));

  const { customTextColor } = props;

  const [
    displayScrollViewNavigation,
    setDisplayScrollViewNavigation,
  ] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 100) {
        setDisplayScrollViewNavigation(true);
      } else {
        setDisplayScrollViewNavigation(false);
      }
    };
    window.addEventListener("scroll", handleScroll, { passive: true });
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const { logoPath, logoPathScroll } = props;

  const clickHandler = () => {
    window.location.href = "/";
  };

  return (
    <>
      <WRNavigationScrollView
        visibility={displayScrollViewNavigation}
        logoPath={logoPathScroll}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          backgroundColor: "white",
          width: "100%",
          position: "fixed",
          zIndex: 100,
          overflow: "visible",
          top: 0,
          background: "transparent",
          visibility: displayScrollViewNavigation ? "hidden" : "visible",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: 140,
            width: { xs: "10rem", lg: "20rem" },
            overflow: "hidden",
            transform: "translate(5%, 0)",
          }}
        >
          <CardMedia
            component="img"
            image={logoPath}
            alt="CodingRippler"
            onClick={() => clickHandler()}
            sx={{
              objectFit: "contain",
              maxHeight: "100%",
              maxWidth: "100%",
              cursor: "pointer", // Add this line to change the cursor on hover
            }}
          />
        </Box>
        <Box
          sx={{
            borderRadius: "24px",
            display: "flex", // Enables flexbox
            flexDirection: "row", // Arranges children horizontally
            alignItems: "center", // Vertically centers the items (optional)
            backgroundColor: "transparent", // Set background to transparent
          }}
        >
          {isLargeScreen && (
            <WebRipplerButtons customTextColor={customTextColor} />
          )}
        </Box>
      </Box>
    </>
  );
};
