import { Grid } from "@mui/material";
import { TabsContainer } from "./TabsContainer";
import { KnowledgeTabDetails } from "./TabWidgets/KnowledgeTabDetails";

export const KnowledgeTabPanel = (props) => {
  const { value } = props;

  const handleUse = (id) => {
    window.location.href = `/ai`;
  };

  return (
    <TabsContainer value={value} index={5}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <KnowledgeTabDetails
            imageSrc="/airippler/ai-tools/sales/Industry.png"
            imageAlt="Description of the image"
            title="Industry Trends Insights"
            description={`The Industry Trends Insights tool uses AI to enrich your data with the top five current trends from Google searches, giving businesses a competitive edge in understanding their market. This tool is ideal for creating more targeted and relevant cold outreach, automating the analysis of industry shifts, and highlighting areas where your product or service can make a significant impact. Perfect for sales teams aiming to boost productivity and relevance in client engagements.`}
            handleButtonClick={handleUse}
            buttonText={"Use"}
            maxLength={300}
          />
        </Grid>
      </Grid>
    </TabsContainer>
  );
};
