import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import { SlideInFromTopEffect } from "../../../BoilarPlateJs/MotionEffects";
import { CRFooter } from "../../Footer/CRFooter";
import { CommonNavigation } from "../../Navigations/CommonNavigation/CommonNavigation";
import { CRNavigationSmallerDevice } from "../../Navigations/NavigationSmallerDevice/CRNavigationSmallerDevice";
import { AboutHome } from "./AboutHome";

export const About = ({ children, ...props }) => {
  const theme = useTheme();
  const isPhone = useMediaQuery(theme.breakpoints.down("sm"));
  const isTab = useMediaQuery(theme.breakpoints.down("lg"));
  const deviceWithSmallHeight = useMediaQuery("(max-height: 750px)");

  const isPhoneOrTab = isPhone || isTab;

  const { logoPath } = props;

  return (
    <Grid container>
      <Grid item xs={12}>
        {isPhoneOrTab ? (
          <CRNavigationSmallerDevice
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            logoPath={logoPath}
          />
        ) : (
          <CommonNavigation
            isPhone={isPhone}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
            logoPath={logoPath}
          />
        )}
      </Grid>
      <Routes>
        <Route
          path="/"
          element={
            <AboutHome
              isPhone={isPhone}
              isTab={isTab}
              isPhoneOrTab={isPhoneOrTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              theme={theme}
            />
          }
        />
      </Routes>

      <Grid item xs={12}>
        <SlideInFromTopEffect>
          <CRFooter
            isPhone={isPhone}
            isPhoneorTab={isPhoneOrTab}
            isTab={isTab}
            deviceWithSmallHeight={deviceWithSmallHeight}
          />
        </SlideInFromTopEffect>
      </Grid>
    </Grid>
  );
};
