import { Route, Routes } from "react-router-dom";
import { AIAgentsHome } from "./AIAgents/AIAgentsHome";
import { AgentTeamsHome } from "./AgentTeams/AgentTeamsHome";
import { AIToolsHome } from "./AITools/AIToolsHome";
import { AIIntegrationsHome } from "./AIIntegrations/AIIntegrationsHome";
import { AIApiHome } from "./AIAPI/AIApiHome";

export const ProductsHome = ({ children, ...props }) => {
  const { isPhoneOrTab, isPhone, isTab, deviceWithSmallHeight } = props;

  return (
    <>
      <Routes>
        <Route
          path="/ai-agents"
          element={
            <AIAgentsHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/ai-teams"
          element={
            <AgentTeamsHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/ai-tools"
          element={
            <AIToolsHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/integrations"
          element={
            <AIIntegrationsHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
        <Route
          path="/api"
          element={
            <AIApiHome
              isPhone={isPhone}
              isTab={isTab}
              deviceWithSmallHeight={deviceWithSmallHeight}
              isPhoneOrTab={isPhoneOrTab}
            />
          }
        />
      </Routes>
    </>
  );
};
