import { TabContext, TabPanel } from "@mui/lab";
import { Box, Divider, Grid } from "@mui/material";
import { AvatarComponent } from "../../BoilarPlateJs/AvatarComponent";
import { StarRating } from "../../BoilarPlateJs/RatingUtils";
import {
  StraigtLine,
  TitleDecorationBottomCenter,
} from "../../BoilarPlateJs/TitleDecorationBottomCenter";
import {
  TypographyKaushanScript,
  TypographyOxygen,
} from "../../BoilarPlateJs/TypographyStyled";
import { ReviewSummary } from "./ReviewSummary";
import { TakeReviews } from "./TakeReviews";

export const TourReviews = ({ children, ...props }) => {
  const { noOfReviews } = props;

  const { individualTourInfo } = props;
  const reviewData = individualTourInfo.tourReviews;

  return (
    <Grid container sx={{ backgroundColor: "#429cb5", zIndex: 1 }}>
      <Grid item xs={12}>
        <TabContext value="0">
          <TabPanel value="0" index="0">
            <Box textAlign={"center"}>
              <TypographyOxygen fontSize={"1.5rem"}>
                TOUR REVIEWS
              </TypographyOxygen>

              <TitleDecorationBottomCenter
                customStyle={{ marginLeft: 0, marginRight: 0 }}
              />
            </Box>
            {individualTourInfo.totalReviewsSubmitted === 0 ? (
              <Box textAlign={"center"}>
                <TypographyOxygen fontSize={"1.5rem"}>
                  There are no reviews yet.
                </TypographyOxygen>
              </Box>
            ) : (
              <ReviewSummary
                noOfReviews={noOfReviews}
                individualTourInfo={individualTourInfo}
              />
            )}
            <StraigtLine customStyle={{ pt: 2, pb: 2 }} />
            {reviewData.map((customerReview, index) => (
              <Grid container>
                <Grid item xs={4}>
                  {" "}
                  <Box
                    display={"flex"}
                    justifyContent={"center"}
                    flexDirection={"column"}
                    textAlign={"center"}
                    justifyItems={"center"}
                    alignItems={"center"}
                    sx={{
                      transform: "translateX(5%)",
                    }}
                  >
                    <AvatarComponent
                      src={customerReview.reviewerProfilePicLocation}
                      alt="profile pic"
                    />
                    <TypographyKaushanScript fontSize={"1.5rem"}>
                      {customerReview.reviewerName}
                    </TypographyKaushanScript>
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  {" "}
                  <TypographyOxygen fontSize={"1.5rem"}>
                    {customerReview.postHeading}
                  </TypographyOxygen>
                  <TypographyOxygen
                    variant="body2"
                    fontWeight={"normal"}
                    fontSize={"1.0rem"}
                    lineHeight={2}
                  >
                    {customerReview.postDescription}
                  </TypographyOxygen>
                  <StarRating
                    customStyle={{
                      rating: 5,
                      paddingTop: "20px",
                      paddingLeft: "20px",
                    }}
                    tripRating={individualTourInfo.tripRating}
                  />
                  <Divider
                    variant="dashed"
                    sx={{ paddingTop: "20px", borderStyle: "dashed" }}
                  />
                </Grid>
              </Grid>
            ))}
            <StraigtLine customStyle={{ pt: 2, pb: 2 }} />
            <TakeReviews />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  );
};
